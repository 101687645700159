import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={15}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      opacity={0.6}
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M13.125 4.237a63.563 63.563 0 0 0-6.263-.312c-1.237 0-2.474.063-3.712.188l-1.275.124M5.313 3.606l.137-.818c.1-.594.175-1.038 1.231-1.038H8.32c1.056 0 1.137.469 1.231 1.044l.137.812M11.781 6.213l-.406 6.293c-.069.982-.125 1.744-1.869 1.744H5.494c-1.744 0-1.8-.762-1.869-1.744l-.406-6.293M6.457 10.813h2.081M5.938 8.313h3.125" />
    </g>
  </svg>
)

export default SvgComponent
