import React, { memo } from "react";
import Container from "./Container";
import styles from "./Container.module.css";

type ButtonsContainerProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
};

function ButtonsContainer({ children, style }: ButtonsContainerProps) {
  return (
    <Container className={styles.btnsContainer} style={style}>
      {children}
    </Container>
  );
}

export default memo(ButtonsContainer);
