import { MdRefresh } from "react-icons/md";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";

type DocumentSignToolbarProps = {
  onResetPdf: () => void;
  children?: React.ReactNode;
};

export default function DocumentSignToolbar({
  onResetPdf,
  children,
}: DocumentSignToolbarProps) {
  return (
    <div className={"pdfToolBar"}>
      <div className={"pdfToolBarContainer"}>
        <Button
          onClick={onResetPdf}
          color={ButtonColors.Transparent}
          tooltip="Reset Pdf"
        >
          <MdRefresh size={20} color="#f1b70c" />
        </Button>
        {children}
      </div>
    </div>
  );
}
