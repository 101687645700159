import { useMutation } from "@tanstack/react-query";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import shiftConflictsService, {
  ShiftConflict,
} from "../services/shiftConflictsService";

type UseDismissShiftConflictMutation = {
  onSuccess?: (data: ShiftConflict) => void;
};

export default function useDismissShiftConflictMutation({
  onSuccess,
}: UseDismissShiftConflictMutation) {
  const handleErrors = useErrorHandling();
  return useMutation({
    mutationFn: shiftConflictsService.dismissShiftConflict,
    onSuccess,
    onError: handleErrors,
  });
}
