import { useQuery } from "@tanstack/react-query";
import documentService from "../services/documentService";
import { DocumentStatus } from "../types/document.types";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

type UseGetDocumentsProps = {
  status?: DocumentStatus;
  employeeId?: string;
};

export default function useGetDocuments({
  status,
  employeeId,
}: UseGetDocumentsProps = {}) {
  return useQuery({
    queryKey: [QueryKeys.DocumentsList, status, employeeId],
    queryFn: () => documentService.getDocuments(status, employeeId),
    refetchOnWindowFocus: false,
  });
}
