import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import documentService from "../services/documentService";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import { Document } from "../types/document.types";

type SignDocumentMutation = {
  onSuccess?: (data: Document) => void;
};

export default function useSignDocumentMutation({
  onSuccess,
}: SignDocumentMutation) {
  const handleErrors = useErrorHandling();

  return useMutation(documentService.signDocument, {
    onSuccess,
    onError: (error: AxiosError) => handleErrors(error),
  });
}
