import ListItemSubtitle from "src/components/Lists/ListItemSubtitle";
import formatDateToString from "src/features/datetime/utils/formatDateToString";

type ListItemDateProps = {
  date: Date;
};

export default function ListItemDate({ date }: ListItemDateProps) {
  return (
    <ListItemSubtitle>
      {formatDateToString(date, false, false)}
    </ListItemSubtitle>
  );
}
