import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useAlert } from "@blaumaus/react-alert";

import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import { useAppDispatch } from "src/store";
import memosService from "../services/memosService";
import {
  MemoFormSteps,
  setMemoFormStep,
  setSelectedMemoId,
} from "../slices/memoSlice";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

type MutationData = {
  id: string;
  data: FormData;
};

export default function usePartialUpdateMemoFormDataMutation() {
  const alert = useAlert();
  const handleErrors = useErrorHandling();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, data }: MutationData) =>
      memosService.partialUpdateMemoUsingFormData(id, data),
    {
      onSuccess: (data) => {
        dispatch(setMemoFormStep(MemoFormSteps.AssignViewableBy));
        dispatch(setSelectedMemoId(data.id));
        queryClient.invalidateQueries([QueryKeys.MemosList]);
        queryClient.setQueryData([QueryKeys.Memo, String(data.id)], data);
        alert.success("Memo image saved successfully!");
      },
      onError: (err: AxiosError) => handleErrors(err),
    }
  );
}
