import axiosClient from "src/features/axios/client";
import { EmployeeListItem } from "src/features/employees/types/employees.type";
import { EmployeeScheduleRoleFormDataType } from "../components/VerifyEmployeeScheduleRolesForm";

export type EmployeeScheduleRoleType = {
  id: number;
  employee: EmployeeListItem;
  hourly_rate: number;
  schedule_role: number;
};

class EmployeeScheduleRolesService {
  async getEmployeeScheduleRolesByEmployeeId(
    employeeId: number
  ): Promise<EmployeeScheduleRoleType[]> {
    return await axiosClient
      .get(`/api/employee-schedule-roles/`, {
        params: {
          employee_id: employeeId,
        },
      })
      .then((res) => res.data);
  }

  async getEmployeeScheduleRoles(
    scheduleRole?: number,
    departmentId?: number
  ): Promise<EmployeeScheduleRoleType[]> {
    return await axiosClient
      .get("/api/employee-schedule-roles/", {
        params: {
          schedule_role_id: scheduleRole,
          department_id: departmentId,
        },
      })
      .then((res) => res.data);
  }

  async partialUpdateEmployeeScheduleRole(
    id: number | string,
    data: Partial<EmployeeScheduleRoleFormDataType>
  ): Promise<EmployeeScheduleRoleType> {
    return await axiosClient
      .patch(`/api/employee-schedule-roles/${id}/`, data)
      .then((res) => res.data);
  }

  async syncEmployeeScheduleRoles() {
    return await axiosClient
      .post("/api/employee-schedule-roles/sync/")
      .then((res) => res.data);
  }
}

const employeeScheduleRolesService = new EmployeeScheduleRolesService();

export default employeeScheduleRolesService;
