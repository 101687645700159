import { useQuery } from "@tanstack/react-query";
import todosService from "../../services/todosService";
import { TodoObjectTypes } from "../../types/todosEnums.types";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

type GetScheduledTodosProps = {
  objectId?: number | string | null;
  objectType?: TodoObjectTypes;
  enabled?: boolean;
  refetchOnWindowFocus?: boolean;
};

export default function useGetScheduledTodosListQuery({
  objectId,
  objectType,
  enabled = true,
  refetchOnWindowFocus = false,
}: GetScheduledTodosProps) {
  return useQuery({
    queryKey: [QueryKeys.ScheduledTodosList, objectId, objectType],
    queryFn: objectId
      ? () => todosService.getScheduledTodos({ objectId, objectType })
      : undefined,
    enabled: !!objectId && !!objectType && enabled,
    refetchOnWindowFocus,
  });
}
