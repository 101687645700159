import { useEffect, useRef } from "react";

type UseScrollIntoViewProps = boolean | string | number | null | undefined;

export default function useScrollIntoView(
  dependency: UseScrollIntoViewProps = true
) {
  const scrollRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (dependency && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [dependency]);

  return scrollRef;
}
