import { useEffect, useMemo, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { useAlert } from "@blaumaus/react-alert";
import { AnnotationType } from "../types/template.types";
import useDocumentAnnotations from "./useDocumentAnnotations";
import useGetMyDocumentAnnotations from "./useGetMyDocumentAnnotations";
import useAuth from "src/features/auth/hooks/useAuth";
import useScrollIntoView from "src/features/page/hooks/useScrollIntoView";
import { OptionsType } from "../components/Documents/DocumentSignForm";
import { DocumentAnnotation } from "../types/document.types";

export default function useDocumentSignForm() {
  const alert = useAlert();
  const { employee } = useAuth();
  const sigCanvasRef = useRef<SignatureCanvas | null>(null);
  const [image, setImage] = useState<string | null>(null);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [completed, setCompleted] = useState<string[]>([]);
  const [selectedAnnotation, setSelectedAnnotation] =
    useState<DocumentAnnotation | null>(null);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [areAllComplete, setAreAllComplete] = useState(false);
  const [annotationOptions, setAnnotationOptions] = useState<OptionsType>({});
  const [isSignatureDialogOpen, setIsSignatureDialogOpen] = useState(false);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);

  const scrollRef = useScrollIntoView(areAllComplete);

  useGetMyDocumentAnnotations({});
  const { annotations, signature } = useDocumentAnnotations();

  const isSignatureRequired = useMemo(
    () => annotations.some((ann) => ann.type === AnnotationType.Signature),
    [annotations]
  );

  const checkboxAnnotations = useMemo(
    () => annotations.filter((ann) => ann.type === AnnotationType.Checkbox),
    [annotations]
  );

  const unsignedOnlyAnns = useMemo(
    () => annotations.filter((ann) => !completed.includes(ann.id)),
    [annotations, completed]
  );

  const unsignedAnns = useMemo(
    () => unsignedOnlyAnns.concat(checkboxAnnotations),
    [unsignedOnlyAnns, checkboxAnnotations]
  );

  const assigned = useMemo(
    () => annotations.filter((ann) => ann.assigned_to?.id === employee?.id),
    [annotations, employee]
  );

  const signedAnnotations = useMemo(
    () =>
      assigned.filter((ann) => completed.includes(ann.id)).map((ann) => ann.id),
    [assigned, completed]
  );

  useEffect(() => {
    if (annotations.length) {
      if (assigned.length === signedAnnotations.length) {
        alert.success(
          "All fields have been completed! Please click the 'Submit Document' button."
        );
      }
      setAreAllComplete(assigned.length === signedAnnotations.length);
    }
  }, [annotations, completed, signedAnnotations, assigned, alert]);

  useEffect(() => {
    if (selectedAnnotation) {
      const editableElement = document.getElementById(
        `editable-${selectedAnnotation?.id}`
      );
      if (editableElement) {
        editableElement.focus();
        const range = document.createRange();
        const sel = window.getSelection();
        range.selectNodeContents(editableElement);
        if (sel) {
          sel.removeAllRanges();
          sel.addRange(range);
        }
      }
    }
  }, [selectedAnnotation]);

  return {
    annotations,
    annotationOptions,
    agreeToTerms,
    areAllComplete,
    completed,
    image,
    isImageDialogOpen,
    isSignatureDialogOpen,
    isSignatureRequired,
    pdfUrl,
    scrollRef,
    selectedAnnotation,
    sigCanvasRef,
    signature,
    unsignedAnns,
    setAgreeToTerms,
    setAnnotationOptions,
    setCompleted,
    setImage,
    setIsImageDialogOpen,
    setIsSignatureDialogOpen,
    setPdfUrl,
    setSelectedAnnotation,
  };
}
