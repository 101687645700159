import axios from "axios";
import { EmployeeModuleContentList } from "../learningPaths.types";

class EmployeeModuleContentsService {
  async getEmployeeModuleContent({ id }: { id: string }) {
    return await axios
      .get(`/api/employee-module-content/${id}/`)
      .then((res) => res.data);
  }

  async getEmployeeModuleContentsByEmployeeModuleId({
    employeeModuleId,
  }: {
    employeeModuleId: string;
  }): Promise<EmployeeModuleContentList[]> {
    return await axios
      .get(`/api/employee-module-content/`, {
        params: {
          employee_module_id: employeeModuleId,
        },
      })
      .then((res) => res.data);
  }
}

const employeeModuleContentsService = new EmployeeModuleContentsService();

export default employeeModuleContentsService;
