import styles from "./ListContainer.module.css";

type ListContainerProps = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
};

function ListContainer({ children, style }: ListContainerProps) {
  return (
    <div className={styles.listContainer} style={style}>
      {children}
    </div>
  );
}

export default ListContainer;
