import { useQuery } from "@tanstack/react-query";
import weeklySchedulesService from "../services/weeklySchedulesService";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";

type GetOrCreateWeeklyScheduleParams = {
  departmentId?: number;
  startDate: string;
  endDate: string;
};

export default function useGetOrCreateWeeklySchedule({
  departmentId,
  startDate,
  endDate,
}: GetOrCreateWeeklyScheduleParams) {
  const handleErrors = useErrorHandling();
  return useQuery({
    queryFn:
      departmentId && startDate && endDate
        ? () =>
            weeklySchedulesService.getOrCreateWeeklySchedule({
              departmentId,
              startDate,
              endDate,
            })
        : undefined,
    queryKey: [
      QueryKeys.WeeklyScheduleGetOrCreate,
      { departmentId, startDate, endDate },
    ],
    onError: handleErrors,
    retry: false,
    enabled: !!departmentId && !!startDate && !!endDate,
  });
}
