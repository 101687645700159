import { useMemo } from "react";
import StepTitle from "../../../features/learningPaths/components/LPBuilder/StepTitle";

type UseVerticalStepProps<T> = {
  currentStep: T | null;
  activeStep: T;
  titleAction: React.ReactNode;
  activeIcon: React.ReactNode;
  inactiveIcon: React.ReactNode;
  defaultContent: React.ReactNode;
  activeContent: React.ReactNode;
};

function useVerticalStepperContent<T>({
  currentStep,
  activeStep,
  titleAction,
  inactiveIcon,
  activeIcon,
  defaultContent,
  activeContent,
}: UseVerticalStepProps<T>) {
  const icon = useMemo(() => {
    if (currentStep === activeStep) {
      return activeIcon;
    } else {
      return inactiveIcon;
    }
  }, [currentStep, activeStep, inactiveIcon, activeIcon]);

  const content = useMemo(() => {
    if (currentStep === activeStep) {
      return activeContent;
    } else {
      return defaultContent;
    }
  }, [currentStep, activeStep, defaultContent, activeContent]);

  const title = useMemo(
    () => <StepTitle title={activeStep as string} action={titleAction} />,
    [activeStep, titleAction]
  );

  return {
    icon: icon,
    title: title,
    content: content,
  };
}

export default useVerticalStepperContent;
