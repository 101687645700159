import { useNavigate, useParams } from "react-router-dom";
import { useAlert } from "@blaumaus/react-alert";
import { useQueryClient } from "@tanstack/react-query";

import Button from "src/components/Buttons/Button";
import ButtonsContainer from "src/components/Container/ButtonsContainer";
import { SAVE_AS_DRAFT } from "src/features/buttons/titles";
import StepContainer from "src/components/Steps/StepContainer";
import EditingContainer from "src/features/learningPaths/components/LPBuilder/Forms/EditingContainer";
import usePartialUpdateMemoMutation from "../../hooks/usePartialUpdateMemoMutation";
import useMemoFormState from "../../hooks/useMemoFormState";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import { ButtonColors } from "src/components/Buttons/buttons.types";

export default function PublishMemoForm() {
  const alert = useAlert();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const { selectedMemoId } = useMemoFormState();

  const combinedId = id || selectedMemoId;

  const partialUpdateMemoMutation = usePartialUpdateMemoMutation({
    onSuccess: async (data) => {
      await queryClient.invalidateQueries([QueryKeys.MyTodosList]);
      await queryClient.invalidateQueries([QueryKeys.MemosList]);
      queryClient.setQueryData([QueryKeys.Memo, String(data.id)], data);
      alert.success("Memo saved successfully!");
      navigate(`/memos/${data.id}`);
    },
  });

  const handleSaveAsDraft = () => {
    if (combinedId) {
      partialUpdateMemoMutation.mutate({
        id: combinedId,
        draft: true,
      });
    }
  };

  const handlePublishMemo = () => {
    if (combinedId)
      partialUpdateMemoMutation.mutate({
        id: combinedId,
        draft: false,
      });
  };

  return (
    <StepContainer>
      <div className="mb-20">
        <span className="text-muted">
          Publish the memo or save it as a draft. Once published, the memo will
          be sent to the designated recipients assigned in the Schedules. Saving
          as draft will remove any assigned "To-Do" tasks employees have already
          received.
        </span>
      </div>
      <EditingContainer>
        <ButtonsContainer>
          <Button
            title={SAVE_AS_DRAFT}
            color={ButtonColors.GrayAndYellow}
            onClick={handleSaveAsDraft}
          />
          <Button
            title="Publish Memo"
            color={ButtonColors.Yellow}
            onClick={handlePublishMemo}
          />
        </ButtonsContainer>
      </EditingContainer>
    </StepContainer>
  );
}
