import { useAlert } from "@blaumaus/react-alert";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import getFullName from "src/features/employees/utils/getFullName";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import employeeShiftsService, {
  EmployeeShift,
} from "../services/employeeShiftsService";
import { ShiftRequirementType } from "../services/shiftRequirementsService";

export type CreateEmployeeShiftMutationVariables = {
  employeeScheduleRoleId: number;
  shiftRequirementId: number;
  scheduleRoleId: number;
  weeklyScheduleId: number;
  departmentId: number;
};

export default function useCreateEmployeeShiftMutation() {
  const alert = useAlert();
  const queryClient = useQueryClient();
  const handleErrors = useErrorHandling();

  return useMutation({
    mutationFn: async ({
      employeeScheduleRoleId,
      shiftRequirementId,
      departmentId,
    }: CreateEmployeeShiftMutationVariables) =>
      employeeShiftsService.createEmployeeShift({
        employee_schedule_role: employeeScheduleRoleId,
        shift_requirement: shiftRequirementId,
        department: departmentId,
      }),
    onError: (error: AxiosError, _variables, _context) => handleErrors(error),
    onSuccess: async (data, variables, _context) => {
      alert.success(
        `${getFullName(
          data.employee_schedule_role.employee
        )} has been assigned to the shift`
      );
      queryClient.setQueryData<EmployeeShift[]>(
        [QueryKeys.EmployeeShiftsList, variables.scheduleRoleId],
        (prevData) => {
          // sort the employee shifts by shift_requirement.start_datetime and insert
          // the new shift at the correct position
          if (!prevData) return prevData;
          const newData = [...prevData, data].sort(
            (a, b) =>
              new Date(a.shift_requirement.start_datetime).getTime() -
              new Date(b.shift_requirement.start_datetime).getTime()
          );
          return newData;
        }
      );
      await queryClient.invalidateQueries([QueryKeys.EmployeeShiftsList]);
      await queryClient.invalidateQueries<ShiftRequirementType[]>([
        QueryKeys.ShiftRequirementsList,
        variables.weeklyScheduleId,
      ]);
    },
  });
}
