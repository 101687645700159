export const ADD_SAVED_ASSESSMENT = "Add Saved Assessment";
export const ADD_SAVED_POLICY = "Add Saved Policy";
export const ADD_SAVED_VIDEO = "Add Saved Video";
export const ADD_CONTENT = "Add Content";
export const ADD_NEW_QUESTIONS = "Add New Questions";
export const ADD_NEW_MODULE = "Add New Module";
export const APPLY = "Apply";
export const BACK = "Back";
export const BACK_TO_LEARNING_PATHS = "Back to Learning Paths";
export const CANCEL = "Cancel";
export const CLOSE = "Close";
export const CLEAR = "Clear";
export const CREATE_A_VIDEO = "Create Video";
export const CREATE_NEW_ASSESSMENT = "Create New Assessment";
export const DELETE = "Delete";
export const DONE_ADDING_MODULES = "I'm Done Adding Modules";
export const DONE = "I'm Done";
export const EDIT = "Edit";
export const EXPORT_TO_CSV = "Export Data to CSV";
export const FILTER = "Filter";
export const GENERATE = "Generate";
export const GENERATE_QUESTIONS = "Generate Questions";
export const GENERATE_QUESTIONS_FROM_MODULE_CONTENT =
  "Generate Questions From Module Content";
export const GENERATE_QUESTIONS_FROM_POLICY = "Generate Questions From Policy";
export const GENERATE_QUESTIONS_FROM_TEXT = "Generate Questions From Text";
export const GENERATE_QUESTIONS_FROM_VIDEO = "Generate Questions From Video";
export const INCOMPLETE = "Incomplete";
export const LOAD_MORE = "Load More";
export const LOGIN = "Login";
export const NEW = "New";
export const PUBLISH = "Publish";
export const REGISTER = "Register";
export const REMIND = "Remind";
export const REQUEST_USERNAME = "Request Username";
export const RESEND_CODE = "Resend Code";
export const RESEND_INVITE = "Resend Invite";
export const RESET_PASSWORD = "Reset Password";
export const SAVE = "Save";
export const SAVE_AS_DRAFT = "Save as Draft";
export const SAVE_QUESTION = "Save Question";
export const SAVE_MODULE = "Save Module";
export const SKIP_QUESTION = "Skip Question";
export const SUBMIT = "Submit";
export const TRY_AGAIN = "Try Again";
export const TYPE_IN_NEW_QUESTION = "Type in New Question";
export const UPLOAD_EXTERNAL_FILE = "Upload External File";
export const VERIFY_PHONE = "Verify Phone";
export const VIEW_REPORTS = "View Reports";
