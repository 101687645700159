import { useMutation } from "@tanstack/react-query";
import employeeScheduleRolesService from "../services/employeeScheduleRolesService";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";

type UseSyncEmployeeScheduleRolesMutation = {
  onSuccess?: () => void;
};

export default function useSyncEmployeeScheduleRolesMutation({
  onSuccess,
}: UseSyncEmployeeScheduleRolesMutation) {
  const handleErrors = useErrorHandling();
  return useMutation({
    mutationFn: employeeScheduleRolesService.syncEmployeeScheduleRoles,
    onSuccess,
    onError: handleErrors,
  });
}
