import { useQuery } from "@tanstack/react-query";

import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import timeOffRequestService, {
  TimeOffStatus,
} from "../services/timeOffRequestService";

type UseGetTimeOffRequestsQueryParams = {
  employeeId?: number;
  startDate?: string;
  endDate?: string;
  status?: TimeOffStatus;
};

export default function useGetTimeOffRequestsByEmployeeIdQuery({
  employeeId,
  startDate,
  endDate,
  status,
}: UseGetTimeOffRequestsQueryParams) {
  return useQuery({
    queryFn:
      employeeId && (startDate || endDate)
        ? () =>
            timeOffRequestService.getEmployeeTimeOffRequestsByEmployeeId({
              employeeId,
              startDate,
              endDate,
              status,
            })
        : undefined,
    queryKey: [
      QueryKeys.TimeOffRequests,
      { employeeId, startDate, endDate, status },
    ],
    enabled: !!employeeId && (!!startDate || !!endDate),
  });
}
