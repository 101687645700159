import useVerticalStepperContent from "src/components/Steps/hooks/useVerticalStepperContent";
import StepFiveYellowIcon from "src/components/Icons/StepFiveYellowIcon";
import StepFiveGrayIcon from "src/components/Icons/StepFiveGrayIcon";
import StepContainer from "src/components/Steps/StepContainer";
import useMemoFormState from "../../hooks/useMemoFormState";
import { MemoFormSteps } from "../../slices/memoSlice";
import PublishMemoForm from "./PublishMemoForm";

export default function MemoStepFive() {
  const { step } = useMemoFormState();

  return useVerticalStepperContent({
    currentStep: step,
    activeStep: MemoFormSteps.PublishMemo,
    titleAction: null,
    activeIcon: <StepFiveYellowIcon />,
    inactiveIcon: <StepFiveGrayIcon />,
    defaultContent: (
      <StepContainer>
        <span className="text-muted">
          Publish the memo or save it as a draft. Once published, the memo will
          be sent to the designated recipients assigned in the Schedules. Saving
          as draft will remove any assigned "To-Do" tasks employees have already
          received.
        </span>
      </StepContainer>
    ),
    activeContent: <PublishMemoForm />,
  });
}
