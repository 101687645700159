import Container from "src/components/Container/Container";
import { useState } from "react";
import styles from "./LearningPathTake.module.css";
import ImageV2 from "src/components/Images/ImageV2";

type LPImageProps = {
  src: string;
};

export default function LPImage({ src }: LPImageProps) {
  const [loaded, setLoaded] = useState(false);
  return (
    <Container>
      <div className={!loaded ? styles.mediaWrapper : undefined}>
        <div className={loaded ? styles.mediaContainer : styles.mediaLoading}>
          <ImageV2
            src={src}
            alt={"learning-path-content"}
            className={styles.mediaLoaded}
            loadedCallback={() => setLoaded(true)}
          />
        </div>
      </div>
    </Container>
  );
}
