import { ThunkDispatch, AnyAction } from "@reduxjs/toolkit";
import RootState from "src/features/redux/types/rootState.types";
import {
  setModalOpen,
  setModalTitle,
  setSelectedItem,
} from "./slices/modalSlice";
import { setRefetchTableGrid } from "../grid/slices/tableGridSlice";

export const setModalStateAction =
  <T>(selectedItem?: T) =>
  (title: string | null) =>
  (modalOpen: boolean) =>
  (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    if (selectedItem) {
      dispatch(setSelectedItem(selectedItem));
    } else {
      dispatch(setSelectedItem(null));
    }
    dispatch(setModalTitle(title));
    dispatch(setModalOpen(modalOpen));
  };

export const setRefetchTableGridAction =
  (deleted: boolean | null) =>
  (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    dispatch(setRefetchTableGrid(deleted));
  };
