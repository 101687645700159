import { useNavigate } from "react-router-dom";
import Button from "src/components/Buttons/Button";
import LogoutIcon from "src/components/Icons/LogoutIcon";
import styles from "./NavMenu.module.css";
import { ButtonColors } from "src/components/Buttons/buttons.types";

export default function LogoutButton() {
  const navigate = useNavigate();

  const handleLogout = () => navigate("/logout");

  return (
    <div className={styles.logoutButtonContainer}>
      <div className={styles.logoutBtnContainer}>
        <Button title="Logout" color={ButtonColors.Gray} onClick={handleLogout}>
          <LogoutIcon />
        </Button>
      </div>
    </div>
  );
}
