import { z } from "zod";

export const employeeTypeSchema = z.object({
  id: z.number().optional(),
  name: z.string().min(1, "Employee type name is required"),
  group: z.number().or(z.string()),
});

export const employeeTypesFormSchema = z.object({
  employeeTypes: z.array(employeeTypeSchema),
});

export type EmployeeTypeSchemaType = z.infer<typeof employeeTypeSchema>;

export type EmployeeTypesFormValues = z.infer<typeof employeeTypesFormSchema>;
