import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useAlert } from "@blaumaus/react-alert";

import myLearningPathsService from "../services/myLearningPathsService";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

export default function useMyLPTakeMutation() {
  const alert = useAlert();
  const queryClient = useQueryClient();
  const handleErrors = useErrorHandling();

  return useMutation(myLearningPathsService.postMyLearningPathTake, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.EmployeeModuleContent]);
      queryClient.invalidateQueries([QueryKeys.EmployeeModuleContents]);
      queryClient.invalidateQueries([QueryKeys.EmployeeLearningPath]);
      queryClient.removeQueries([QueryKeys.MyLearningPathAssessmentTake]);
      queryClient.removeQueries([QueryKeys.MyLearningPathsDetails]);
      queryClient.removeQueries([QueryKeys.MyLearningPathTake]);
      alert.success("Congratulations! You have completed this module content.");
    },
    onError: (error: AxiosError) => handleErrors(error),
  });
}
