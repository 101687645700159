import React, { memo, useMemo } from "react";
import useModal from "../../features/modal/hooks/useModal";
import styles from "./Modal.module.css";

interface ModalProps {
  children?: React.ReactNode;
}

function Modal({ children }: ModalProps): JSX.Element {
  const modal = useModal();
  const modalStyle = useMemo(
    () => ({ display: modal?.isVisible ? "flex" : "none" }),
    [modal?.isVisible]
  );

  return (
    <div className={styles.modalBg} style={modalStyle}>
      <div className={styles.modal}>{children}</div>
    </div>
  );
}

export default memo(Modal);
