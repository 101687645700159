import React from "react";
import styles from "./EditingContainer.module.css";

type EditingContainerProps = {
  children: React.ReactNode;
  color?: "Green" | "White" | "Red";
};
export default function EditingContainer({
  children,
  color = "White",
}: EditingContainerProps) {
  return (
    <div
      className={styles.editingContainer + " " + styles["container" + color]}
    >
      {children}
    </div>
  );
}
