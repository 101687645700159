import { useQuery } from "@tanstack/react-query";
import scheduleRolesService from "../services/scheduleRolesService";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

type UseGetScheduleRolesQuery = {
  departmentId?: string | number;
};

export default function useGetScheduleRolesQuery({
  departmentId,
}: UseGetScheduleRolesQuery) {
  return useQuery({
    queryFn: () => scheduleRolesService.getScheduleRoles(departmentId),
    queryKey: [QueryKeys.ScheduleRolesList, departmentId],
    refetchOnWindowFocus: false,
    retry: false,
  });
}
