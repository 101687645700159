import React, { CSSProperties } from "react";

import styles from "./Inputs.module.css";

import InputError from "../Errors/InputError";

type SelectInputProps = {
  errors?: string;
  id: string;
  label: string;
  labelIcon?: React.ReactNode;
  labelStyle?: CSSProperties;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onRemove?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  options: any[];
  optionLabel: string;
  optionKey: string;
  optionValue: string;
  removeBtn?: React.ReactNode;
  value: string | number;
};

export default function SelectInput({
  errors,
  id,
  label,
  labelIcon,
  labelStyle,
  name,
  onChange,
  options,
  optionLabel,
  optionKey,
  optionValue,
  removeBtn,
  value,
}: SelectInputProps) {
  return (
    <div className={styles.formInput} id={"group" + id}>
      <label htmlFor={name} className={styles.label} style={labelStyle}>
        <span
          style={{ flex: 1, display: "flex", alignItems: "center", gap: "5px" }}
        >
          {labelIcon} {label}
        </span>
        {removeBtn ? removeBtn : null}
      </label>
      <select
        className={styles.dropDown}
        name={name}
        id={id}
        onChange={onChange}
        value={value}
      >
        {options &&
          options.map((option) => {
            return (
              <option key={option[optionKey]} value={option[optionValue]}>
                {option[optionLabel]}
              </option>
            );
          })}
      </select>
      {errors ? <InputError error={errors} /> : null}
    </div>
  );
}
