import Skeleton from "react-loading-skeleton";
import ErrorFallback from "src/components/Errors/ErrorFallback";
import Placeholder from "src/components/Placeholder/Placeholder";
import useGetDepartmentsListQuery from "src/features/departments/hooks/useGetDepartmentsListQuery";
import LaborBudgetDepartmentSection from "./LaborBudgetDepartmentSection";

type LaborBudgetCardProps = {
  startOfWeekDate: Date;
  endOfWeekDate: Date;
};

export default function LaborBudgetCard({
  startOfWeekDate,
  endOfWeekDate,
}: LaborBudgetCardProps) {
  const {
    data: departments,
    isLoading: isDepartmentsLoading,
    refetch: refetchDepartments,
    isError: isDepartmentsError,
  } = useGetDepartmentsListQuery();

  if (isDepartmentsLoading) {
    return <Skeleton height={600} />;
  }

  if (isDepartmentsError) {
    return (
      <div className="flex flex-1 centered">
        <ErrorFallback
          error={
            "Oops! An error occurred! It has been reported and is being investigated."
          }
          onReload={refetchDepartments}
        />
      </div>
    );
  }

  if (!departments || departments.length === 0) {
    return (
      <div className="flex flex-1 centered">
        <Placeholder title="No departments found." />
      </div>
    );
  }

  return (
    <>
      {departments.map((department) => {
        return (
          <LaborBudgetDepartmentSection
            key={department.id}
            startOfWeekDate={startOfWeekDate}
            endOfWeekDate={endOfWeekDate}
            department={department}
          />
        );
      })}
    </>
  );
}
