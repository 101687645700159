import { useAlert } from "@blaumaus/react-alert";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { truncate } from "lodash";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { RotatingLines } from "react-loader-spinner";
import Skeleton from "react-loading-skeleton";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import Dialog from "src/components/Dialog/Dialog";
import DialogContent from "src/components/Dialog/DialogContent";
import DialogDescription from "src/components/Dialog/DialogDescription";
import DialogFooter from "src/components/Dialog/DialogFooter";
import DialogHeader from "src/components/Dialog/DialogHeader";
import EmployeeImage from "src/components/EmployeeImage/EmployeeImage";
import FormRow from "src/components/Forms/FormRow";
import InputContainerV2 from "src/components/Inputs/InputContainerV2";
import InputLabel from "src/components/Inputs/InputLabel";
import LocalSearchInput from "src/components/Inputs/LocalSearchInput";
import TextArea from "src/components/Inputs/TextArea";
import Placeholder from "src/components/Placeholder/Placeholder";
import useAuth from "src/features/auth/hooks/useAuth";
import useEmployees from "src/features/employees/hooks/useEmployees";
import getFullName from "src/features/employees/utils/getFullName";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import { z } from "zod";
import timeOffRequestService from "../services/timeOffRequestService";

const timeOffRequestSchema = z.object({
  dateRange: z
    .array(z.date().nullable())
    .length(2)
    .refine(
      ([start, end]) => start !== null && end !== null,
      "Both start and end dates are required"
    ),
  reason: z.string().min(1, "Reason is required"),
});

type TimeOffRequestFormValues = z.infer<typeof timeOffRequestSchema>;

type TimeOffCreateDialogProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function TimeOffCreateDialog({
  isOpen,
  setIsOpen,
}: TimeOffCreateDialogProps) {
  const [selectedEmployee, setSelectedEmployee] = useState<any>(null);
  const [employeeSearchInput, setEmployeeSearchInput] = useState("");
  const handleErrors = useErrorHandling();
  const queryClient = useQueryClient();
  const { employee } = useAuth();
  const alert = useAlert();

  const {
    data: employees,
    isLoading: isLoadingEmployees,
    isError: isEmployeesError,
  } = useEmployees({
    search: employeeSearchInput,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<TimeOffRequestFormValues>({
    resolver: zodResolver(timeOffRequestSchema),
  });

  const mutation = useMutation({
    mutationFn: timeOffRequestService.createTimeOffBySupervisor,
    onSuccess: () => {
      alert.success("Time-off request created successfully.");
      queryClient.invalidateQueries([QueryKeys.TimeOffRequests]);
      setIsOpen(false);
      handleClose();
    },
    onError: handleErrors,
  });

  const onSubmit = (data: TimeOffRequestFormValues) => {
    const [startDate, endDate] = data.dateRange;
    if (!startDate || !endDate || !selectedEmployee?.id || !employee?.id)
      return;

    const formattedStartDate = startDate.toISOString().split("T")[0];
    const formattedEndDate = endDate.toISOString().split("T")[0];

    mutation.mutate({
      employee: selectedEmployee.id,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      reason: data.reason,
      decision_at: new Date().toISOString(),
      decision_by: employee?.id,
    });
  };

  const handleClose = () => {
    reset();
    setSelectedEmployee(null);
    setEmployeeSearchInput("");
  };

  const handleSelectEmployee = (employee: any) => {
    setSelectedEmployee(employee);
  };

  const handleChangeEmployeeSearchInput = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => setEmployeeSearchInput(e.target.value);

  return (
    <Dialog isOpen={isOpen} setIsOpen={setIsOpen} onClose={handleClose}>
      <DialogHeader>New Time-Off Request</DialogHeader>
      <DialogDescription>
        {selectedEmployee
          ? `Select the date range and reason for the time-off for ${getFullName(
              selectedEmployee
            )}.`
          : "Select an employee to create the time-off request for. On the next step you will select the dates."}
      </DialogDescription>
      <DialogContent>
        {selectedEmployee ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormRow>
              <InputContainerV2>
                <InputLabel>Requested Dates</InputLabel>
                <Controller
                  name="dateRange"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      selected={value ? value[0] : null}
                      startDate={value ? value[0] : null}
                      endDate={value ? value[1] : null}
                      onChange={(dates) =>
                        onChange(dates as [Date | null, Date | null])
                      }
                      selectsRange
                      minDate={
                        new Date(new Date().setDate(new Date().getDate() + 1))
                      }
                      className={"inputBlack"}
                      monthsShown={2}
                      portalId="datepicker-portal"
                      popperPlacement="bottom"
                      placeholderText="Select Date Range"
                    />
                  )}
                />
                {errors.dateRange && <span>{errors.dateRange.message}</span>}
              </InputContainerV2>
            </FormRow>

            <FormRow>
              <InputContainerV2>
                <InputLabel>Reason for Time Off</InputLabel>
                <Controller
                  name="reason"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      {...field}
                      label="Reason"
                      name="reason"
                      placeholder="Reason for Time Off"
                      inputClassName="text-area-input-black"
                      errorClassName="text-area-input-black-error"
                      error={errors.reason?.message}
                      rows={10}
                      cols={50}
                    />
                  )}
                />
              </InputContainerV2>
            </FormRow>

            <DialogFooter>
              <Button
                color={ButtonColors.GrayAndYellow}
                onClick={() => setSelectedEmployee(null)}
              >
                Back
              </Button>
              <Button
                color={ButtonColors.Yellow}
                type="submit"
                disabled={mutation.isLoading}
              >
                {mutation.isLoading ? (
                  <RotatingLines
                    strokeColor="#000"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="20"
                    visible={true}
                  />
                ) : (
                  "Submit Time-Off Request"
                )}
              </Button>
            </DialogFooter>
          </form>
        ) : (
          <>
            {!isLoadingEmployees && !isEmployeesError && (
              <div className="mb-10">
                <LocalSearchInput
                  placeholder="Search"
                  onChange={handleChangeEmployeeSearchInput}
                  value={employeeSearchInput}
                />
              </div>
            )}
            <div className="flex h-40vh overflow-y-auto gap-5 border-light-gray p-5 border-radius-10 bg-cp-black-50">
              {isLoadingEmployees && <Skeleton count={10} height={30} />}
              {!isLoadingEmployees && !isEmployeesError && employees && (
                <>
                  {employees.length > 0 ? (
                    employees.map((employee: any) => (
                      <div
                        key={employee.id}
                        className="p-5 flex-row align-center gap-10 border-radius-5 font-w-500 cursor-pointer bg-dark-gray-hover text-sm"
                        onClick={() => handleSelectEmployee(employee)}
                      >
                        <EmployeeImage
                          alt={"Employee Image"}
                          src={employee.image}
                          width={25}
                          height={25}
                          containerStyle={{
                            width: "25px",
                            height: "25px",
                          }}
                        />
                        {truncate(getFullName(employee), {
                          length: 25,
                        })}
                      </div>
                    ))
                  ) : (
                    <div className="flex-1 centered">
                      <Placeholder title={"No employees found"} />
                    </div>
                  )}
                </>
              )}
              {isEmployeesError && (
                <div className="flex-1 centered">
                  <Placeholder title={"Error loading employees"} />
                </div>
              )}
            </div>
          </>
        )}
      </DialogContent>
      {!selectedEmployee && (
        <DialogFooter>
          <Button
            color={ButtonColors.GrayAndYellow}
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
        </DialogFooter>
      )}
    </Dialog>
  );
}
