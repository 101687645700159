import { useEffect, useRef, useState } from "react";

import { Document } from "../types/document.types";
import PdfViewer from "src/features/pdf/components/PdfViewer";
import ThreeDotsDropDownV2 from "src/components/DropDowns/ThreeDotsDropDownV2";
import ThreeDotsIcon from "src/components/Icons/ThreeDotsIcon";
import { DocumentTemplate } from "../types/template.types";

type DocumentListItemProps = {
  document: Document | DocumentTemplate;
  fileField: "file_url" | "newest_version";
  onDocumentClick?: (
    setIsFilterVisible: (value: boolean) => void,
    document: Document | DocumentTemplate
  ) => void;
  renderButton: (document: Document | DocumentTemplate) => React.ReactNode;
  dropDownItems?: (
    setIsFilterVisible: (value: boolean) => void,
    document: Document | DocumentTemplate
  ) => React.ReactNode;
};

export default function DocumentListItem({
  document,
  fileField,
  onDocumentClick,
  renderButton,
  dropDownItems,
}: DocumentListItemProps) {
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isInViewport, setIsInViewport] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const currentRef = containerRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting && !isLoaded) {
          setIsInViewport(true);
          setIsLoaded(true);
        }
      },
      {
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [isLoaded]);

  return (
    <div className="grid-item card flex" key={document.id} ref={containerRef}>
      <div className="flex">
        <div className="flex-1 flex-row gap-10">
          <div className="cp-yellow font-w-700 text-lg flex flex-1 document-description">
            <span
              className="document-description"
              title={document?.title ?? "No Title"}
            >
              {document?.title ?? "No Title"}
            </span>
          </div>
          <div>
            {dropDownItems ? (
              <ThreeDotsDropDownV2
                setIsFilterVisible={setIsFilterVisible}
                isFilterVisible={isFilterVisible}
                icon={<ThreeDotsIcon />}
              >
                {dropDownItems(setIsFilterVisible, document)}
              </ThreeDotsDropDownV2>
            ) : null}
          </div>
        </div>
        <span
          className="text-muted py-10 document-description"
          title={document?.description ?? "No Description"}
        >
          {document.description}
        </span>
      </div>
      {isInViewport &&
      containerRef.current?.clientWidth &&
      document[fileField as "file_url"] ? (
        <div
          className="cursor-pointer"
          onClick={() => onDocumentClick?.(setIsFilterVisible, document)}
        >
          <PdfViewer
            file={document[fileField as "file_url"]}
            width={containerRef.current?.clientWidth - 20}
          />
        </div>
      ) : null}
      {!document[fileField as "file_url"] && (
        <div className="py-10 flex-1 centered cp-yellow">
          <p>No file found.</p>
        </div>
      )}
      <div className="flex">{renderButton(document)}</div>
    </div>
  );
}
