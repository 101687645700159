import useVerticalStepperContent from "src/components/Steps/hooks/useVerticalStepperContent";
import { MemoFormSteps, setMemoFormStep } from "../../slices/memoSlice";
import useMemoFormState from "../../hooks/useMemoFormState";
import { useAppDispatch } from "src/store";
import StepEditButton from "src/features/learningPaths/components/LPBuilder/Steps/StepEditButton";
import StepTwoYellowIcon from "src/components/Icons/StepTwoYellowIcon";
import CheckCircleYellowIcon from "src/components/Icons/CheckCircleYellowIcon";
import StepTwoGrayIcon from "src/components/Icons/StepTwoGrayIcon";
import MemoImageForm from "./MemoImageForm";
import StepContainer from "src/components/Steps/StepContainer";

export default function MemoFormStepTwo() {
  const dispatch = useAppDispatch();
  const { step } = useMemoFormState();

  const handleStepButtonClick = () =>
    dispatch(setMemoFormStep(MemoFormSteps.UploadFile));

  return useVerticalStepperContent({
    currentStep: step,
    activeStep: MemoFormSteps.UploadFile,
    titleAction:
      step !== MemoFormSteps.UploadFile && step !== MemoFormSteps.AddDetails ? (
        <StepEditButton onClick={handleStepButtonClick} />
      ) : null,
    activeIcon: <StepTwoYellowIcon />,
    inactiveIcon:
      step === MemoFormSteps.AddDetails ? (
        <StepTwoGrayIcon />
      ) : (
        <CheckCircleYellowIcon />
      ),
    defaultContent: (
      <StepContainer>
        {step !== MemoFormSteps.UploadFile &&
        step !== MemoFormSteps.AddDetails ? (
          <span className="text-muted">This step is complete!</span>
        ) : (
          <span className="text-muted">
            Enhance your memo by adding an image, video, or PDF file. This
            allows you to provide richer content and more engaging information
            within your memo.
          </span>
        )}
      </StepContainer>
    ),
    activeContent: <MemoImageForm />,
  });
}
