import { useQuery } from "@tanstack/react-query";
import documentSignatoryOrderService from "../services/documentSignatoryOrderService";

type DocumentSignatoryOrder = {
  documentId?: string;
};

export default function useGetDocumentSignatoryOrders({
  documentId,
}: DocumentSignatoryOrder) {
  return useQuery({
    queryKey: ["documentSignatoryOrders", documentId],
    queryFn: documentId
      ? () =>
          documentSignatoryOrderService.getDocumentSignatoryOrders(documentId)
      : undefined,
    enabled: !!documentId,
  });
}
