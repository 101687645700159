import { useAlert } from "@blaumaus/react-alert";
import { useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { useState } from "react";
import { MdArrowRightAlt, MdCompareArrows } from "react-icons/md";
import Badge from "src/components/Badges/Badge";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import useAuth from "src/features/auth/hooks/useAuth";
import hasGroupPermissions from "src/features/auth/utils/hasGroupPermissions";
import getFullName from "src/features/employees/utils/getFullName";
import { CRUD_GROUP } from "src/features/groups/groups";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import useAcceptShiftSwapMutation from "../hooks/useAcceptShiftSwapMutation";
import useDeclineShiftSwapMutation from "../hooks/useDeclineShiftSwapMutation";
import {
  ShiftTransfer,
  ShiftTransferStatus,
  ShiftTransferType,
} from "../services/shiftTransferService";
import { getShiftTransferBadgeColor } from "../utils/getShiftTransferColorBadge";
import ShiftTransferDecisionDialog from "./ShiftTransferDecisionDialog";
import ShiftTransferFlowCards from "./ShiftTransferFlowCards";
import ShiftTransferPickupDialog from "./ShiftTransferPickupDialog";

type ShiftTransferCardProps = {
  transfer: ShiftTransfer;
};

export default function ShiftTransferCard({
  transfer,
}: ShiftTransferCardProps) {
  const queryClient = useQueryClient();
  const alert = useAlert();
  const { employee } = useAuth();

  const [isDecisionDialogOpen, setIsDecisionDialogOpen] = useState(false);
  const [isPickUpShiftDialogOpen, setIsPickUpShiftDialogOpen] = useState(false);
  const [shiftTransfer, setShiftTransfer] = useState<ShiftTransfer | null>(
    null
  );

  const handlePickUpShift = (transfer: ShiftTransfer) => {
    setShiftTransfer(transfer);
    setIsPickUpShiftDialogOpen(true);
  };

  const handleOpenDecisionDialog = () => setIsDecisionDialogOpen(true);

  const handleAcceptSwap = () => acceptShiftSwapMutation.mutate(transfer.id);

  const handleDeclineSwap = () => declineShiftSwapMutation.mutate(transfer.id);

  const declineShiftSwapMutation = useDeclineShiftSwapMutation({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.ShiftTransfersList]);
      queryClient.invalidateQueries([QueryKeys.ShiftTransfer]);
      alert.success("Shift swap declined!");
    },
  });

  const acceptShiftSwapMutation = useAcceptShiftSwapMutation({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.ShiftTransfersList]);
      queryClient.invalidateQueries([QueryKeys.ShiftTransfer]);
      alert.success("Shift swap accepted! Pending approval...");
    },
  });

  return (
    <div key={transfer.id} className="bg-cp-black border-radius-10 px-20 flex">
      {transfer ? (
        <ShiftTransferDecisionDialog
          transfer={transfer}
          onPickUpShift={handlePickUpShift}
          isOpen={isDecisionDialogOpen}
          setIsOpen={setIsDecisionDialogOpen}
        />
      ) : null}
      <div className="flex flex-row gap-10 align-center">
        <h2 className="cp-yellow">
          {transfer.type === ShiftTransferType.ShiftDrop
            ? "Shift Drop"
            : "Shift Swap"}
        </h2>
        <div className="flex flex-1 align-end">
          <Badge
            color={getShiftTransferBadgeColor(transfer.status)}
            text={transfer.status}
          />
        </div>
      </div>
      <div className="border-bottom-light-gray" />
      <div className="flex flex-row-mobile-column my-20">
        {shiftTransfer ? (
          <ShiftTransferPickupDialog
            isOpen={isPickUpShiftDialogOpen}
            setIsOpen={setIsPickUpShiftDialogOpen}
            transfer={shiftTransfer}
          />
        ) : null}
        <ShiftTransferFlowCards
          titleLeft={
            transfer.type === ShiftTransferType.ShiftDrop &&
            transfer.status === ShiftTransferStatus.Approved
              ? "Dropped by:"
              : transfer.type === ShiftTransferType.ShiftDrop
              ? "Dropped from:"
              : transfer.type === ShiftTransferType.ShiftSwap &&
                transfer.status === ShiftTransferStatus.Approved
              ? "Swapped from:"
              : "Swap from:"
          }
          titleRight={
            transfer.type === ShiftTransferType.ShiftDrop &&
            transfer.status === ShiftTransferStatus.Approved
              ? "Dropped to:"
              : transfer.type === ShiftTransferType.ShiftDrop
              ? "Available"
              : transfer.type === ShiftTransferType.ShiftSwap &&
                transfer.status === ShiftTransferStatus.Approved
              ? "Swapped to:"
              : "Swap to:"
          }
          employeeLeft={transfer.employee_schedule_role_from.employee}
          employeeRight={transfer.employee_schedule_role_to?.employee}
          shiftLeft={
            transfer.type === ShiftTransferType.ShiftSwap &&
            transfer.status === ShiftTransferStatus.Approved
              ? transfer.shift_from
              : transfer.type === ShiftTransferType.ShiftDrop
              ? transfer.shift_from
              : transfer.shift_to
          }
          shiftRight={
            transfer.type === ShiftTransferType.ShiftSwap &&
            transfer.status === ShiftTransferStatus.Approved
              ? transfer.shift_to
              : transfer.type === ShiftTransferType.ShiftDrop
              ? transfer.shift_from
              : transfer.shift_from
          }
          onPickUpShift={() => handlePickUpShift(transfer)}
          icon={
            transfer.type === ShiftTransferType.ShiftDrop ? (
              <MdArrowRightAlt size={70} color={"var(--clearpath-yellow)"} />
            ) : (
              <MdCompareArrows size={70} color={"var(--clearpath-yellow)"} />
            )
          }
        />
      </div>
      {transfer.status !== ShiftTransferStatus.Available ? (
        <>
          <div className="border-bottom-light-gray" />
          <div className="my-20">
            {transfer.status === ShiftTransferStatus.Pending &&
            hasGroupPermissions(employee, CRUD_GROUP) ? (
              <Button
                id={transfer.id.toString()}
                className="border-radius-5"
                color={ButtonColors.Yellow}
                onClick={handleOpenDecisionDialog}
              >
                Make Decision
              </Button>
            ) : null}
            {transfer.status !== ShiftTransferStatus.Pending &&
            transfer.decision_by ? (
              <>
                <div className="flex flex-row justify-between align-center">
                  <div className="flex flex-col gap-20">
                    <span className="cp-yellow font-bold">Decision by:</span>
                    <div className="flex flex-row gap-10 align-center">
                      <img
                        src={transfer.decision_by.image}
                        alt={getFullName(transfer.decision_by)}
                        className="w-40 h-40 border-radius-half"
                      />
                      <div className="flex flex-col">
                        <span className="font-bold">
                          {getFullName(transfer.decision_by)}
                        </span>
                        <span className="text-sm text-muted">
                          {transfer.decision_by.username}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-10 mt-20">
                  <div className="flex flex-row gap-10 align-center">
                    <span className="text-sm font-bold">
                      {transfer.status === ShiftTransferStatus.Approved &&
                      transfer.decision_at
                        ? "Approved On: "
                        : "Declined On: "}
                    </span>
                    <span className="text-sm">
                      {transfer.decision_at
                        ? format(new Date(transfer.decision_at), "PP")
                        : ""}
                    </span>
                  </div>
                </div>
              </>
            ) : null}
            {transfer.status === ShiftTransferStatus.Pending &&
            !hasGroupPermissions(employee, CRUD_GROUP) ? (
              <span className="cp-yellow font-bold">Pending approval...</span>
            ) : null}
            {transfer.status === ShiftTransferStatus.SwapRequested &&
            transfer.employee_schedule_role_from.employee.id ===
              employee?.id ? (
              <span className="cp-yellow font-bold">
                Awaiting other employee...
              </span>
            ) : null}
            {transfer.status === ShiftTransferStatus.SwapRequested &&
            transfer.employee_schedule_role_to.employee.id === employee?.id ? (
              <div className="flex flex-row gap-10">
                <Button
                  id={transfer.id.toString()}
                  className="border-radius-5"
                  color={ButtonColors.Yellow}
                  onClick={handleAcceptSwap}
                >
                  Accept Swap
                </Button>
                <Button
                  id={transfer.id.toString()}
                  className="border-radius-5"
                  color={ButtonColors.GrayAndYellow}
                  onClick={handleDeclineSwap}
                >
                  Decline Swap
                </Button>
              </div>
            ) : null}
          </div>
        </>
      ) : null}
    </div>
  );
}
