import { format } from "date-fns";
import Badge from "src/components/Badges/Badge";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import useAuth from "src/features/auth/hooks/useAuth";
import hasGroupPermissions from "src/features/auth/utils/hasGroupPermissions";
import getFullName from "src/features/employees/utils/getFullName";
import { CRUD_GROUP } from "src/features/groups/groups";
import { TimeOffRequest } from "../services/timeOffRequestService";
import { getTimeOffRequestsBadgeColor } from "../utils/getTimeOffRequestsBadgeColor";

type TimeOffRequestCardProps = {
  request: TimeOffRequest;
  onOpenDecisionDialog?: (e: React.MouseEvent<Element, MouseEvent>) => void;
};

export default function TimeOffRequestCard({
  request,
  onOpenDecisionDialog,
}: TimeOffRequestCardProps) {
  const { employee } = useAuth();

  return (
    <div key={request.id} className="bg-cp-black border-radius-10 p-20 flex">
      <div className="flex flex-row justify-between">
        <div className="flex flex-col gap-20">
          <span className="cp-yellow font-bold">Time-off requested by:</span>
          <div className="flex flex-row gap-10 align-center">
            <img
              src={request.employee?.image}
              alt={getFullName(request.employee)}
              className="w-40 h-40 border-radius-half"
            />
            <div className="flex flex-col">
              <span className="font-bold">{getFullName(request.employee)}</span>
              <span className="text-sm text-muted">
                {request.employee?.username}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-row h-full">
          <Badge
            color={getTimeOffRequestsBadgeColor(request.status)}
            text={request.status}
          />
        </div>
      </div>
      <div className="flex flex-col gap-10 my-20">
        <div className="flex flex-row gap-10 align-center">
          <span className="text-sm font-bold">Requested on: </span>
          <span className="text-sm">
            {format(new Date(request.requested_on), "PP")}
          </span>
        </div>
        <div className="flex flex-row gap-10 align-center">
          <span className="text-sm font-bold">Dates: </span>
          <span className="cp-yellow text-sm font-bold">
            {format(new Date(request.start_date + "T00:00:00"), "PP")} -{" "}
            {format(new Date(request.end_date + "T00:00:00"), "PP")}
          </span>
        </div>
        <div className="flex flex-row gap-10 align-center">
          <span className="text-sm font-bold">Reason: </span>
          <span className="text-sm">{request.reason}</span>
        </div>
      </div>
      <div className="border-bottom-light-gray" />
      <div className="mt-20">
        {request.status === "Pending" &&
        hasGroupPermissions(employee, CRUD_GROUP) ? (
          <Button
            id={request.id}
            className="border-radius-5"
            color={ButtonColors.Yellow}
            onClick={onOpenDecisionDialog}
          >
            Make Decision
          </Button>
        ) : request.status === "Pending" ? (
          <div className="flex flex-row justify-between align-center">
            <div className="flex flex-col gap-20">
              <span className="cp-yellow font-bold">Awaiting decision...</span>
            </div>
          </div>
        ) : null}
        {request.status !== "Pending" && request.decision_by && (
          <>
            <div className="flex flex-row justify-between align-center">
              <div className="flex flex-col gap-20">
                <span className="cp-yellow font-bold">Decision by:</span>
                <div className="flex flex-row gap-10 align-center">
                  <img
                    src={request.decision_by?.image}
                    alt={getFullName(request.decision_by)}
                    className="w-40 h-40 border-radius-half"
                  />
                  <div className="flex flex-col">
                    <span className="font-bold">
                      {getFullName(request.decision_by)}
                    </span>
                    <span className="text-sm text-muted">
                      {request.decision_by?.username}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-10 mt-20">
              <div className="flex flex-row gap-10 align-center">
                <span className="text-sm font-bold">
                  {request.status === "Approved" && request.decision_at
                    ? "Approved On: "
                    : "Declined On: "}
                </span>
                <span className="text-sm">
                  {request.decision_at
                    ? format(new Date(request.decision_at), "PP")
                    : ""}
                </span>
              </div>
              {request.notes && (
                <div className="flex flex-row gap-10 align-center">
                  <span className="text-sm font-bold">Notes: </span>
                  <span className="text-sm">{request.notes}</span>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
