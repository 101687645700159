import { truncate } from "lodash";
import { useCallback, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { useAlert } from "@blaumaus/react-alert";

import CardDetailHeader from "src/components/Cards/CardDetailHeader";
import EmployeeImageGroup from "src/components/EmployeeImage/EmployeeImageGroup";
import ListItemText from "src/components/Lists/ListItemText";
import Tooltip from "src/components/Tooltip/Tooltip";
import useGetDocumentSignatoryOrders from "../../hooks/useGetDocumentSignatoryOrders";
import ThreeDotsMenuItem from "src/components/DropDowns/ThreeDotsMenuItem";
import ThreeDotsDropDownV2 from "src/components/DropDowns/ThreeDotsDropDownV2";
import ThreeDotsIcon from "src/components/Icons/ThreeDotsIcon";
import useGetDocument from "../../hooks/useGetDocument";

export default function DocumentCardHeader() {
  const { id } = useParams();
  const alert = useAlert();
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const { data: doc, isLoading } = useGetDocument();
  const { data: docSigOrders, isLoading: isDocSigLoading } =
    useGetDocumentSignatoryOrders({
      documentId: id,
    });

  const renderImageGroup = useCallback(() => {
    if (isDocSigLoading || isLoading)
      return <Skeleton height={40} width={40} circle={true} />;
    if (!isDocSigLoading && docSigOrders?.length) {
      const employees = docSigOrders.map((doc) => doc.employee);
      return <EmployeeImageGroup employees={employees} />;
    }
    return null;
  }, [docSigOrders, isDocSigLoading, isLoading]);

  const getTotalSigned = useMemo(() => {
    const validDocSigOrders = Array.isArray(docSigOrders) ? docSigOrders : [];
    return validDocSigOrders.filter(
      (docSigOrder) =>
        docSigOrder.signed_on !== null ||
        docSigOrder.approved_on !== null ||
        docSigOrder.denied_on !== null
    ).length;
  }, [docSigOrders]);

  const handleDownloadDocument = async () => {
    if (doc && doc.newest_version) {
      try {
        const response = await fetch(doc.newest_version);
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "Document.pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
      } catch (err) {
        alert.error("Error downloading the document. Please try again.");
      }
    }
  };

  return (
    <CardDetailHeader
      display={renderImageGroup()}
      info={
        <Tooltip showTooltip={true} content={undefined}>
          <ListItemText
            title={
              doc?.title ? (
                truncate(doc.title, { length: 30 })
              ) : (
                <Skeleton width={100} />
              )
            }
            subTitle={
              <>
                {isDocSigLoading || isLoading ? <Skeleton width={60} /> : null}
                {!isDocSigLoading && !isLoading && docSigOrders?.length
                  ? `${getTotalSigned} signed / ${docSigOrders.length} total`
                  : null}
              </>
            }
          />
        </Tooltip>
      }
      actions={
        <>
          <ThreeDotsDropDownV2
            setIsFilterVisible={setIsFilterVisible}
            isFilterVisible={isFilterVisible}
            icon={<ThreeDotsIcon />}
          >
            <ThreeDotsMenuItem id={id} onClick={handleDownloadDocument}>
              Download
            </ThreeDotsMenuItem>
          </ThreeDotsDropDownV2>
        </>
      }
    />
  );
}
