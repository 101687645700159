import React, { memo } from "react";
import Button from "./Button";
import styles from "./Button.module.css";

type ToggleButtonProps = {
  id: string;
  isSelected: boolean;
  selectedClassName?: string;
  unselectedClassName?: string;
  title: string;
  onClick: (e: React.MouseEvent<Element, MouseEvent>) => void;
  disabled?: boolean;
};

function ToggleButton({
  id,
  isSelected,
  selectedClassName = styles.toggleBtnSelected,
  unselectedClassName = styles.toggleBtnUnselected,
  title,
  onClick,
  disabled,
  ...props
}: ToggleButtonProps) {
  const className = isSelected ? selectedClassName : unselectedClassName;

  return (
    <Button
      id={id}
      className={className}
      title={title}
      onClick={onClick}
      disabled={disabled}
      {...props}
    />
  );
}

export default memo(ToggleButton);
