import { useQuery } from "@tanstack/react-query";
import useSearch from "src/features/search/hooks/useSearch";
import getFullName from "../utils/getFullName";
import employeeService from "../employeeService";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

type useEmployeesProps = {
  search?: string;
};

export default function useEmployees({ search }: useEmployeesProps) {
  const { searchTerm } = useSearch();

  return useQuery({
    queryKey: [QueryKeys.Employees],
    queryFn: () => employeeService.getEmployees(),
    refetchOnWindowFocus: false,
    select: (employees) => {
      const currentSearchTerm = search || searchTerm;

      if (currentSearchTerm === "") {
        return employees;
      }

      return employees.filter((employee) => {
        const fullName = getFullName(employee).toLowerCase();
        const roleName = employee?.role?.name?.toLowerCase();
        return (
          fullName.includes(currentSearchTerm.toLowerCase()) ||
          roleName.includes(currentSearchTerm.toLowerCase())
        );
      });
    },
  });
}
