import React, { memo } from "react";

type ImageProps = {
  src: string | File | null;
  alt: string;
  className?: string;
  style?: React.CSSProperties;
  loading?: "lazy" | "eager" | undefined;
};

function Image({
  src,
  alt,
  className,
  style,
  loading = "lazy",
}: ImageProps): JSX.Element {
  return (
    <>
      {src && (
        <img
          alt={alt}
          src={typeof src === "string" ? src : URL.createObjectURL(src)}
          className={className}
          style={style}
          loading={loading}
        />
      )}
    </>
  );
}

export default memo(Image);
