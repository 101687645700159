import axiosClient from "src/features/axios/client";
import { EmployeeTypeSchemaType } from "../zod/employeeTypesFormSchema";
import { EmployeeTypeDetail } from "../types/employeeTypes.types";
import { TodoObjectTypes } from "src/features/todos/types/todosEnums.types";

type ObjectViewableByData = {
  objectType: TodoObjectTypes;
};

class RolesService {
  async createEmployeeType(
    data: EmployeeTypeSchemaType
  ): Promise<EmployeeTypeDetail> {
    return await axiosClient.post("/api/roles/", data).then((res) => res.data);
  }

  async deleteEmployeeType({ id }: { id: number }) {
    return await axiosClient.delete(`/api/roles/${id}/`);
  }

  async getEmployeeTypes(): Promise<EmployeeTypeDetail[]> {
    return await axiosClient.get("/api/roles/").then((res) => res.data);
  }

  async getObjectViewableByData({ objectType }: ObjectViewableByData) {
    return await axiosClient
      .get(`/api/employee-types/object-viewable-by-count/`, {
        params: { object_type: objectType },
      })
      .then((res) => res.data);
  }

  async getRoles({ path }: { path: string }) {
    return await axiosClient
      .get(`/api/employee_types/${path}/`)
      .then((res) => res.data);
  }

  async updateEmployeeType(
    data: EmployeeTypeSchemaType
  ): Promise<EmployeeTypeDetail> {
    return await axiosClient
      .put(`/api/roles/${data.id}/`, data)
      .then((res) => res.data);
  }
}

const rolesService = new RolesService();

export default rolesService;
