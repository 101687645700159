import React, { useEffect, useRef } from "react";
import useWindowSize from "src/features/page/hooks/useWindowSize";
import styles from "./DiscussionContainer.module.css";

type DiscussionContainerProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
};

export default function DiscussionContainer({
  children,
  style,
}: DiscussionContainerProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { windowWidth, windowHeight } = useWindowSize();

  useEffect(() => {
    if (ref.current) {
      if (windowWidth > 576) {
        ref.current.style.height = `${windowHeight - 90}px`;
      } else {
        ref.current.style.height = `${windowHeight - 145}px`;
      }
    }
  }, [windowHeight, windowWidth]);

  return (
    <div className={styles.discussionContainer} style={style} ref={ref}>
      {children}
    </div>
  );
}
