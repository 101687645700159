import { useEffect } from "react";
import useGetScheduleRolesQuery from "../hooks/useGetScheduleRolesQuery";
import LoadingAnimation from "src/components/Animations/LoadingAnimation";
import ScheduleRolesForm from "../components/VerifyScheduleRolesForm";
import Header from "src/components/Headers/Header";
import DescriptionText from "src/components/Text/DescriptionText";
import { useAppOutletContext } from "src/features/reactRouterDom/hooks/useAppOutletContext";

export default function VerifyScheduleRoles() {
  const { setCurrentStep } = useAppOutletContext() || {};
  const { data: scheduleRoles, isLoading: isScheduleRolesLoading } =
    useGetScheduleRolesQuery({});

  useEffect(() => {
    setCurrentStep?.(2);
  }, [setCurrentStep]);

  if (isScheduleRolesLoading) {
    return (
      <div className="centered vh-100-w-header">
        <LoadingAnimation />
      </div>
    );
  }

  if (!scheduleRoles) {
    return <div>No schedule roles found</div>;
  }

  return (
    <div className={"onboardingCard"}>
      <Header
        title={"Verify Schedule Roles"}
        containerStyle={{ maxHeight: "40px" }}
        id={"setUpRolesHeader"}
      />
      <DescriptionText>
        Verify the schedule roles for your organization
      </DescriptionText>
      <ScheduleRolesForm scheduleRoles={scheduleRoles} />
    </div>
  );
}
