import { useMutation } from "@tanstack/react-query";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import shiftTypeService, { ShiftType } from "../services/shiftTypeService";

type PartialShiftType = {
  onSuccess: (shiftType: ShiftType) => void;
};

export default function usePartialUpdateShiftTypeMutation({
  onSuccess,
}: PartialShiftType) {
  const handleErrors = useErrorHandling();
  return useMutation({
    mutationFn: shiftTypeService.partialUpdateShiftType,
    onSuccess,
    onError: handleErrors,
  });
}
