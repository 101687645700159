import cn from "src/features/utils/cn";

type KeyProps = {
  className?: string;
  color: string;
  text: string;
};

export default function ScheduleKey({ className, color, text }: KeyProps) {
  return (
    <div className="flex-row align-center gap-5">
      <div
        className={cn("w-20 h-20 rounded-full", className)}
        style={{
          backgroundColor: className ? "" : color,
          border: "1px solid var(--clearpath-light-gray)",
        }}
      />
      <span className="text-xs">{text}</span>
    </div>
  );
}
