import { useState } from "react";
import AnimatedNumbers from "react-animated-numbers";
import Button from "src/components/Buttons/Button";
import {
  ButtonColors,
  ButtonSizes,
} from "src/components/Buttons/buttons.types";
import ProgressBar from "src/components/ProgressBar/ProgressBar";
import { config1 } from "src/features/animatedNumbers/configs";
import { DepartmentDetail } from "src/features/departments/types/departments.type";
import useGetLaborBudgetsByStartAndEndDateQuery from "../hooks/useGetLaborBudgetsByStartAndEndDateQuery";
import { LaborBudget } from "../services/laborBudgetService";
import LaborBudgetScheduleRoleFormDialog from "./LaborBudgetScheduleRoleFormDialog";

type LaborBudgetDepartmentSectionProps = {
  startOfWeekDate: Date;
  endOfWeekDate: Date;
  department: DepartmentDetail;
};

export default function LaborBudgetDepartmentSection({
  startOfWeekDate,
  endOfWeekDate,
  department,
}: LaborBudgetDepartmentSectionProps) {
  const [selectedLaborBudget, setSelectedLaborBudget] =
    useState<LaborBudget | null>(null);
  const [isLaborBudgetFormDialogOpen, setIsLaborBudgetFormDialogOpen] =
    useState(false);

  const { data: laborBudgets } = useGetLaborBudgetsByStartAndEndDateQuery({
    startDate: startOfWeekDate.toISOString().slice(0, 10),
    endDate: endOfWeekDate.toISOString().slice(0, 10),
    departmentId: department.id,
  });

  const handleEditRoleBudget = (roleId: number) => {
    const budget = laborBudgets?.find(
      (budget) => budget.schedule_role.role.id === roleId
    );
    if (budget) {
      setSelectedLaborBudget(budget);
      setIsLaborBudgetFormDialogOpen(true);
    }
  };

  const budgetsByRole: { [key: number]: any[] } = {};
  laborBudgets?.forEach((budget) => {
    const roleId = budget.schedule_role.role.id;
    if (!budgetsByRole[roleId]) {
      budgetsByRole[roleId] = [];
    }
    budgetsByRole[roleId].push(budget);
  });

  const roleBudgets = Object.keys(budgetsByRole).map((roleIdStr) => {
    const roleId = parseInt(roleIdStr, 10);
    const budgets = budgetsByRole[roleId];
    const budget_hours = budgets.reduce(
      (acc: number, budget: any) => acc + parseFloat(budget.budget_hours),
      0
    );
    const scheduled_hours = budgets.reduce(
      (acc: number, budget: any) => acc + parseFloat(budget.scheduled_hours),
      0
    );
    const budget_amount = budgets.reduce(
      (acc: number, budget: any) => acc + parseFloat(budget.budget_amount),
      0
    );
    const scheduled_amount = budgets.reduce(
      (acc: number, budget: any) => acc + parseFloat(budget.scheduled_amount),
      0
    );
    const roleName = budgets[0]?.schedule_role.role.name || "Unknown Role";

    const hoursProgress =
      budget_hours > 0 ? (scheduled_hours / budget_hours) * 100 : 0;
    const amountProgress =
      budget_amount > 0 ? (scheduled_amount / budget_amount) * 100 : 0;

    return {
      roleId,
      roleName,
      budget_hours,
      scheduled_hours,
      budget_amount,
      scheduled_amount,
      hoursProgress,
      amountProgress,
    };
  });

  const total_budget_hours =
    laborBudgets?.reduce(
      (acc: number, budget: any) => acc + parseFloat(budget.budget_hours),
      0
    ) ?? 0;

  const total_scheduled_hours =
    laborBudgets?.reduce(
      (acc: number, budget: any) => acc + parseFloat(budget.scheduled_hours),
      0
    ) ?? 0;

  const total_budget_amount =
    laborBudgets?.reduce(
      (acc: number, budget: any) => acc + parseFloat(budget.budget_amount),
      0
    ) ?? 0;

  const total_scheduled_amount =
    laborBudgets?.reduce(
      (acc: number, budget: any) => acc + parseFloat(budget.scheduled_amount),
      0
    ) ?? 0;

  const total_hoursProgress =
    total_budget_hours > 0
      ? (total_scheduled_hours / total_budget_hours) * 100
      : 0;

  const total_amountProgress =
    total_budget_amount > 0
      ? (total_scheduled_amount / total_budget_amount) * 100
      : 0;

  return (
    <div className="bg-cp-black border-radius-10 px-20">
      {selectedLaborBudget ? (
        <LaborBudgetScheduleRoleFormDialog
          laborBudget={selectedLaborBudget}
          isOpen={isLaborBudgetFormDialogOpen}
          setIsOpen={setIsLaborBudgetFormDialogOpen}
        />
      ) : null}
      <div key={department.id} className="my-10">
        <div className="flex flex-row">
          <h2 className="cp-yellow font-bold">{department.name} Department</h2>
        </div>
        {/* Total Budgets */}
        <div className="flex flex-col gap-10">
          <div className="flex flex-row gap-10">
            <span className="font-bold">Total Budgeted Hours:</span>
            <span className="cp-yellow font-bold">
              <AnimatedNumbers
                includeComma
                animateToNumber={total_budget_hours}
                configs={config1}
              />
            </span>
          </div>
          <div className="flex flex-row gap-10">
            <span className="font-bold">Total Scheduled Hours:</span>
            <span className="cp-yellow font-bold">
              <AnimatedNumbers
                includeComma
                animateToNumber={total_scheduled_hours}
                configs={config1}
              />
            </span>
          </div>
          <div className="flex-1 py-10">
            <ProgressBar
              progress={Math.min(total_hoursProgress, 100)}
              progressClassName={
                total_hoursProgress > 100 ? "bg-cp-danger" : "bg-cp-green"
              }
              containerClassName="bg-cp-yellow-10"
            />
          </div>
          <div className="flex flex-row">
            <span className="font-bold mr-10">Total Budgeted Amount:</span>
            <span className="cp-yellow font-bold">$</span>
            <span className="cp-yellow font-bold">
              <AnimatedNumbers
                includeComma
                animateToNumber={total_budget_amount}
                configs={config1}
              />
            </span>
          </div>
          <div className="flex flex-row">
            <span className="font-bold mr-10">Total Scheduled Amount:</span>
            <span className="cp-yellow font-bold">$</span>
            <span className="cp-yellow font-bold">
              <AnimatedNumbers
                includeComma
                animateToNumber={total_scheduled_amount}
                configs={config1}
              />
            </span>
          </div>
          <div className="flex-1 py-10">
            <ProgressBar
              progress={Math.min(total_amountProgress, 100)}
              progressClassName={
                total_amountProgress > 100 ? "bg-cp-danger" : "bg-cp-green"
              }
              containerClassName="bg-cp-yellow-10"
            />
          </div>
        </div>
        {/* Budgets per Role */}
        {roleBudgets.map((roleBudget) => (
          <div key={roleBudget.roleId} className="my-10">
            <div className="flex flex-row">
              <h3 className="cp-yellow font-bold">{roleBudget.roleName}</h3>
              <div className="flex flex-1 align-end justify-center">
                <Button
                  className="border-radius-5"
                  color={ButtonColors.GrayAndYellow}
                  size={ButtonSizes.SM}
                  title="Edit Budget"
                  onClick={() => handleEditRoleBudget(roleBudget.roleId)}
                />
              </div>
            </div>
            <div className="flex flex-col gap-10">
              <div className="flex flex-row gap-10">
                <span className="font-bold">Budgeted Hours:</span>
                <span className="cp-yellow font-bold">
                  <AnimatedNumbers
                    includeComma
                    animateToNumber={roleBudget.budget_hours}
                    configs={config1}
                  />
                </span>
              </div>
              <div className="flex flex-row gap-10">
                <span className="font-bold">Scheduled Hours:</span>
                <span className="cp-yellow font-bold">
                  <AnimatedNumbers
                    includeComma
                    animateToNumber={roleBudget.scheduled_hours}
                    configs={config1}
                  />
                </span>
              </div>
              <div className="flex-1 py-10">
                <ProgressBar
                  progress={Math.min(roleBudget.hoursProgress, 100)}
                  progressClassName={
                    roleBudget.hoursProgress > 100 ||
                    roleBudget.scheduled_hours > roleBudget.budget_hours
                      ? "bg-cp-danger"
                      : "bg-cp-green"
                  }
                  containerClassName="bg-cp-yellow-10"
                />
              </div>
              <div className="flex flex-row">
                <span className="font-bold mr-10">Budgeted Amount:</span>
                <span className="cp-yellow font-bold">$</span>
                <span className="cp-yellow font-bold">
                  <AnimatedNumbers
                    includeComma
                    animateToNumber={roleBudget.budget_amount}
                    configs={config1}
                  />
                </span>
              </div>
              <div className="flex flex-row">
                <span className="font-bold mr-10">Scheduled Amount:</span>
                <span className="cp-yellow font-bold">$</span>
                <span className="cp-yellow font-bold">
                  <AnimatedNumbers
                    includeComma
                    animateToNumber={roleBudget.scheduled_amount}
                    configs={config1}
                  />
                </span>
              </div>
              <div className="flex-1 py-10">
                <ProgressBar
                  progress={Math.min(roleBudget.amountProgress, 100)}
                  progressClassName={
                    roleBudget.amountProgress > 100 ||
                    roleBudget.scheduled_amount > roleBudget.budget_amount
                      ? "bg-cp-danger"
                      : "bg-cp-green"
                  }
                  containerClassName="bg-cp-yellow-10"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
