import withProtectedRoute from "src/features/auth/components/withProtectedRoute";
import { ALL_GROUPS } from "src/features/groups/groups";
import { UrlRoutes } from "src/features/site/types/routes.types";
import SchedulesRoute from "./SchedulesRoute";

export default withProtectedRoute(
  SchedulesRoute,
  UrlRoutes.Dashboard,
  ALL_GROUPS
);
