import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum AIGeneratorTypes {
  Text = "text",
  Video = "video",
  Policy = "policy",
}

export enum AIGeneratorStatus {
  Processing = "processing",
  Completed = "completed",
  Failed = "failed",
  Retry = "retry",
}

export enum AITextGeneratorSteps {
  ReceivedText = "received_text",
  RecievedVideo = "received_video",
  ReceivedAudio = "received_audio",
  ExtractingAudioFromVideo = "extracting_audio_from_video",
  ExtractingTextFromAudio = "extracting_text_from_audio",
  GeneratingQuestions = "generating_questions",
  FinalizingData = "finalizing_data",
  ProcessedText = "processed_text",
}

export type AIQuestionGeneratorState = {
  generatorType: AIGeneratorTypes | null;
  generatorStatus: AIGeneratorStatus | null;
  generatorStep: AITextGeneratorSteps | null;
};

const aiGeneratorInitialState: AIQuestionGeneratorState = {
  generatorType: null,
  generatorStatus: null,
  generatorStep: null,
};

const aIQuestionGeneratorSlice = createSlice({
  name: "aiQuestionGenerator",
  initialState: aiGeneratorInitialState,
  reducers: {
    setGeneratorType(state, action: PayloadAction<AIGeneratorTypes | null>) {
      state.generatorType = action.payload;
    },
    setGeneratorStatus(state, action: PayloadAction<AIGeneratorStatus | null>) {
      state.generatorStatus = action.payload;
    },
    setGeneratorStep(
      state,
      action: PayloadAction<AITextGeneratorSteps | null>
    ) {
      state.generatorStep = action.payload;
    },
    resetInitialState() {
      return aiGeneratorInitialState;
    },
  },
});

export const {
  setGeneratorType,
  setGeneratorStatus,
  setGeneratorStep,
  resetInitialState,
} = aIQuestionGeneratorSlice.actions;

export default aIQuestionGeneratorSlice.reducer;
