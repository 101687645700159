import React from "react";
import styles from "./EmployeeImage.module.css";
import Image from "./Image";

type EmployeeImageProps = {
  src: string;
  alt: string;
  containerStyle?: React.CSSProperties;
  imgStyle?: React.CSSProperties;
};

function EmployeeImage({
  src,
  alt,
  containerStyle,
  imgStyle,
}: EmployeeImageProps) {
  return (
    <div style={containerStyle}>
      <Image
        src={src}
        alt={alt}
        className={styles.employeeImage}
        style={imgStyle}
      />
    </div>
  );
}

export default EmployeeImage;
