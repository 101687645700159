import { useEffect } from "react";
import { useAlert } from "@blaumaus/react-alert";

// TOOD: Remove this. Use useMutation from react-query instead.
export default function useAPIErrorAlert(apiFunc: any) {
  const alert = useAlert();
  useEffect(() => {
    if (apiFunc.error) {
      let nonFieldErrors =
        apiFunc.error?.non_field_errors || apiFunc.error?.detail;
      if (nonFieldErrors) {
        alert.error(nonFieldErrors);
      } else {
        alert.error("Please fix the errors below.");
      }
    }
  }, [apiFunc.error, alert]);
}
