import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={18}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 7h16M4.5 1h8.31M4.5 13h8.31"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
)

export default SvgComponent
