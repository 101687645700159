import { addDays, format, startOfWeek } from "date-fns";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import Badge from "src/components/Badges/Badge";
import Button from "src/components/Buttons/Button";
import {
  ButtonColors,
  ButtonSizes,
} from "src/components/Buttons/buttons.types";
import CardSectionHeader from "src/components/Cards/CardSectionHeader";
import ListCard from "src/components/Lists/ListCard";
import ListCardHeader from "src/components/Lists/ListCardHeader";
import useAuth from "src/features/auth/hooks/useAuth";
import formatDateToShortString from "src/features/datetime/utils/formatDateToShortString";
import getFullName from "src/features/employees/utils/getFullName";
import useGetEmployeeScheduleRolesByEmployeeId from "../hooks/useGetEmployeeScheduleRolesByEmployeeId";
import useGetShiftTransfersByEmployeeScheduleRolesQuery from "../hooks/useGetShiftTransfersByEmployeeScheduleRolesQuery";
import useGetTimeOffRequestsByEmployeeIdQuery from "../hooks/useGetTimeOffRequestsByEmployeeIdQuery";
import {
  ShiftTransferStatus,
  ShiftTransferType,
} from "../services/shiftTransferService";
import { getShiftTransferBadgeColor } from "../utils/getShiftTransferColorBadge";
import TimeOffRequestDialog from "./TimeOffRequestDialog";

export default function MyWeeklyScheduleSidebar() {
  const navigate = useNavigate();
  const { employee } = useAuth();
  const [isNewTimeOffDialogOpen, setIsNewTimeOffDialogOpen] = useState(false);

  const { data: timeOffRequests, isLoading: isTimeOffRequestsLoading } =
    useGetTimeOffRequestsByEmployeeIdQuery({
      employeeId: employee?.id || undefined,
      startDate: startOfWeek(new Date(), { weekStartsOn: 0 })
        .toISOString()
        .slice(0, 10),
    });

  const {
    data: employeeScheduleRoles,
    isLoading: isEmployeeScheduleRolesLoading,
  } = useGetEmployeeScheduleRolesByEmployeeId({
    employeeId: employee?.id || undefined,
  });

  const today = new Date();
  const localDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  )
    .toISOString()
    .slice(0, 10);

  const { data: openShifts, isLoading: isOpenShiftsLoading } =
    useGetShiftTransfersByEmployeeScheduleRolesQuery({
      scheduleRoles: employeeScheduleRoles
        ?.map((esr) => esr.schedule_role)
        .join(","),
      startDate: localDate,
      type: ShiftTransferType.ShiftDrop,
      status: ShiftTransferStatus.Available,
      notFrom: employee?.id,
    });

  const { data: swapRequests, isLoading: isSwapRequestsLoading } =
    useGetShiftTransfersByEmployeeScheduleRolesQuery({
      scheduleRoles: employeeScheduleRoles
        ?.map((esr) => esr.schedule_role)
        .join(","),
      startDate: addDays(new Date(), -7).toISOString().slice(0, 10),
      type: ShiftTransferType.ShiftSwap,
      employeeId: employee?.id,
    });

  const handleTimeOffRequest = () => setIsNewTimeOffDialogOpen(true);

  const handleNavigateToTimeOffRequestDetail = (id: string | number) =>
    navigate(`/schedules/time-off-requests/${id}`);

  const handleNavigateToShiftTransferDetail = (id: number) =>
    navigate(`/schedules/shift-transfers/${id}`);

  return (
    <ListCard
      id="dashboardOrgCard"
      isLoading={false}
      style={{
        minHeight: "100%",
        minWidth: "250px",
        flexDirection: "column",
      }}
    >
      <ListCardHeader title="Actions" />
      <div className="p-10 flex overflow-y-auto">
        <CardSectionHeader title="Time-Off Requests">
          <Button
            color={ButtonColors.Yellow}
            size={ButtonSizes.SM}
            onClick={handleTimeOffRequest}
            title="New Request"
          />
          <TimeOffRequestDialog
            isOpen={isNewTimeOffDialogOpen}
            setIsOpen={setIsNewTimeOffDialogOpen}
          />
        </CardSectionHeader>
        <div className="flex gap-5 mt-10">
          {isTimeOffRequestsLoading
            ? Array.from({ length: 2 }).map((_, i) => (
                <Skeleton height={100} key={i} />
              ))
            : null}
          {!isTimeOffRequestsLoading &&
            timeOffRequests &&
            timeOffRequests.length === 0 && (
              <div className="centered my-40">
                <span className="text-muted">No time-off requests</span>
              </div>
            )}
          {!isTimeOffRequestsLoading &&
            timeOffRequests &&
            timeOffRequests.length > 0 &&
            timeOffRequests.map((request) => (
              <div
                key={request.id}
                className="flex darkListItemContainer h-unset cursor-pointer p-10 border-radius-10 gap-10"
                onClick={() => handleNavigateToTimeOffRequestDetail(request.id)}
              >
                <div
                  className="flex flex-row"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Badge
                    key={request.id}
                    color={
                      request.status === "Approved"
                        ? "var(--clearpath-green)"
                        : request.status === "Declined"
                        ? "var(--clearpath-danger)"
                        : "var(--clearpath-yellow)"
                    }
                    text={request.status}
                  />
                  <span className="text-xxs text-muted">
                    {new Date(request.requested_on).toLocaleDateString()}
                  </span>
                </div>
                <div className="flex justify-center text-sm">
                  <span>
                    {formatDateToShortString(request.start_date)} -{" "}
                    {formatDateToShortString(request.end_date)}
                  </span>
                </div>
                <span className="text-xs">Reason: {request.reason}</span>
                {request.notes && (
                  <span className="text-xxs text-muted">
                    Notes: {request.notes}
                  </span>
                )}
              </div>
            ))}
        </div>
        <CardSectionHeader title="Swap Requests" />
        <div className="flex gap-5 mt-10">
          {isSwapRequestsLoading
            ? Array.from({ length: 2 }).map((_, i) => (
                <Skeleton height={100} key={i} />
              ))
            : null}
          {!isSwapRequestsLoading &&
            swapRequests &&
            swapRequests.length === 0 && (
              <div className="centered my-40">
                <span className="text-muted">No shift-swap requests</span>
              </div>
            )}
          {!isSwapRequestsLoading &&
            swapRequests &&
            swapRequests.length > 0 &&
            swapRequests.map((request) => (
              <div
                key={request.id}
                className="flex darkListItemContainer h-unset cursor-pointer p-10 border-radius-10 gap-10"
                onClick={() => handleNavigateToShiftTransferDetail(request.id)}
              >
                <div
                  className="flex flex-row"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Badge
                    key={request.id}
                    color={
                      request.status === ShiftTransferStatus.Approved
                        ? "var(--clearpath-green)"
                        : request.status === ShiftTransferStatus.Pending
                        ? "var(--clearpath-yellow)"
                        : request.status === ShiftTransferStatus.Declined
                        ? "var(--clearpath-danger)"
                        : request.status === ShiftTransferStatus.Expired
                        ? "var(--clearpath-danger)"
                        : "var(--clearpath-yellow)"
                    }
                    text={request.status}
                  />
                  <span className="text-xxs text-muted">
                    {new Date(request.requested_at).toLocaleDateString()}
                  </span>
                </div>
                <div className="flex justify-center text-sm">
                  <span>
                    {getFullName(request.employee_schedule_role_from.employee)}{" "}
                    - {getFullName(request.employee_schedule_role_to.employee)}
                  </span>
                </div>
                <div className="flex justify-center text-xs text-muted">
                  <span>
                    {formatDateToShortString(
                      request.shift_from.shift_requirement.start_datetime
                    )}{" "}
                    -{" "}
                    {formatDateToShortString(
                      request.shift_from.shift_requirement.end_datetime
                    )}
                  </span>
                </div>
              </div>
            ))}
        </div>
        <CardSectionHeader title="Open Shifts" />
        <div className="flex gap-5 mt-10">
          {isOpenShiftsLoading
            ? Array.from({ length: 2 }).map((_, i) => (
                <Skeleton height={100} key={i} />
              ))
            : null}
          {!isOpenShiftsLoading &&
            !isEmployeeScheduleRolesLoading &&
            openShifts &&
            openShifts.length === 0 && (
              <div className="centered my-40">
                <span className="text-muted">No available shifts</span>
              </div>
            )}
          {!isOpenShiftsLoading &&
            !isEmployeeScheduleRolesLoading &&
            openShifts &&
            openShifts.length > 0 &&
            openShifts.map((shift) => (
              <div
                key={shift.id}
                className="flex darkListItemContainer h-unset cursor-pointer p-10 border-radius-10 gap-10"
                onClick={() =>
                  navigate(`/schedules/shift-transfers/${shift.id}`)
                }
              >
                <div className="flex flex-row align-center justify-between">
                  <Badge
                    color={getShiftTransferBadgeColor(shift.status)}
                    text={shift.status}
                  />
                  <span className="text-xxs text-muted">
                    {new Date(shift.updated_on).toLocaleDateString()}
                  </span>
                </div>
                <div className="flex flex-col gap-5">
                  <div className="flex flex-row gap-10 align-center">
                    <span className="text-sm">
                      {shift.shift_from.shift_requirement.name}
                    </span>
                  </div>
                  <div className="flex flex-row gap-10 align-center">
                    <span className="text-sm">
                      {format(
                        new Date(
                          shift.shift_from.shift_requirement.start_datetime
                        ),
                        "PP"
                      )}
                    </span>
                  </div>
                  <div className="flex flex-row gap-10 align-center">
                    <span className="text-xs">
                      {format(
                        new Date(
                          shift.shift_from.shift_requirement.start_datetime
                        ),
                        "p"
                      )}{" "}
                      -{" "}
                      {format(
                        new Date(
                          shift.shift_from.shift_requirement.end_datetime
                        ),
                        "p"
                      )}
                    </span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </ListCard>
  );
}
