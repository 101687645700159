import { useQuery } from "@tanstack/react-query";
import { memo } from "react";
import Skeleton from "react-loading-skeleton";
import AssessmentsIcon from "src/components/Icons/AssessmentsIcon";
import DashboardIcon from "src/components/Icons/DashboardIcon";
import DiscussionsIcon from "src/components/Icons/DiscussionsIcon";
import MemosIcon from "src/components/Icons/MemosIcon";
import OrganizationIcon from "src/components/Icons/OrganizationIcon";
import PoliciesIcon from "src/components/Icons/PoliciesIcon";
import SettingsIcon from "src/components/Icons/SettingsIcon";
import VideoIcon from "src/components/Icons/VideoIcon";
import useAuth from "src/features/auth/hooks/useAuth";
import hasGroupPermissions from "src/features/auth/utils/hasGroupPermissions";
import {
  ORGANIZATION_CRUD_GROUP,
  ORGANIZATION_VIEW_GROUP,
} from "src/features/groups/groups";
import { getOrganizationFeatures } from "src/features/organization/organizationApis";
import styles from "./NavMenu.module.css";
import NavMenuLink from "./NavMenuLink";

function NavMenuLinkList() {
  const { employee } = useAuth();

  const { data: features, isLoading } = useQuery({
    queryKey: ["companyFeatures"],
    queryFn: getOrganizationFeatures,
  });

  return (
    <div className={styles.navLinksContainer} id="navLinks">
      <ul className={styles.navLinksList}>
        {isLoading &&
          Array.from({ length: 7 }, (_, i) => (
            <div key={i} className={styles.navLinkListItem}>
              <Skeleton key={i} height={40} />
            </div>
          ))}
        {!isLoading && (
          <NavMenuLink
            icon={<DashboardIcon />}
            text={"Dashboard"}
            to="/dashboard"
          />
        )}
        {!isLoading && features?.schedules && (
          <NavMenuLink
            icon={<OrganizationIcon />}
            text={"Schedules"}
            to="/schedules"
          />
        )}
        {!isLoading && features?.discussions && (
          <NavMenuLink
            icon={<DiscussionsIcon />}
            text={"Discussions"}
            to="/discussions"
          />
        )}
        {!isLoading && features?.memos && (
          <NavMenuLink icon={<MemosIcon />} text={"Memos"} to="/memos" />
        )}
        {!isLoading && features?.policies && (
          <NavMenuLink
            icon={<PoliciesIcon />}
            text={"Policies"}
            to="/policies"
          />
        )}
        {!isLoading && features?.videos && (
          <NavMenuLink icon={<VideoIcon />} text={"Videos"} to="/videos" />
        )}
        {!isLoading && features?.assessments && (
          <NavMenuLink
            icon={<AssessmentsIcon />}
            text={"Assessments"}
            to="/assessments"
          />
        )}
        {!isLoading && features?.learning_paths && (
          <NavMenuLink
            icon={<AssessmentsIcon />}
            text={"Learning Paths"}
            to="/learning-paths"
            beta={false}
            id="learningPathsLink"
          />
        )}
        {!isLoading && features?.e_signatures && (
          <NavMenuLink
            icon={<PoliciesIcon />}
            text={"E-Signatures"}
            beta={false}
            to="/e-signatures"
          />
        )}
        {!isLoading &&
          hasGroupPermissions(employee, ORGANIZATION_VIEW_GROUP) && (
            <NavMenuLink
              icon={<OrganizationIcon />}
              text={"Organization"}
              to="/organization"
            />
          )}
        {!isLoading &&
          hasGroupPermissions(employee, ORGANIZATION_CRUD_GROUP) && (
            <NavMenuLink
              icon={<SettingsIcon />}
              text={"Settings"}
              to="/settings"
            />
          )}
      </ul>
    </div>
  );
}

export default memo(NavMenuLinkList);
