import { useQuery } from "@tanstack/react-query";
import policiesService from "../services/policiesService";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

type PoliciesCategories = {
  enabled?: boolean;
  refetchOnWindowFocus?: boolean;
};

export default function useGetPoliciesCategories({
  enabled = true,
  refetchOnWindowFocus = false,
}: PoliciesCategories = {}) {
  return useQuery({
    queryKey: [QueryKeys.PoliciesCategoriesList],
    queryFn: policiesService.getPolicyCategories,
    enabled,
    refetchOnWindowFocus,
  });
}
