import { createSlice } from "@reduxjs/toolkit";
import { ContainerSize } from "../routes/DocumentDetailRoute";

export type DocumentViewerState = {
  numPages: number | null;
  pageNumber: number;
  containerSize: ContainerSize;
};

const initialState: DocumentViewerState = {
  numPages: null,
  pageNumber: 1,
  containerSize: {
    width: null,
    height: null,
  },
};

export const documentViewerSlice = createSlice({
  name: "documentViewer",
  initialState,
  reducers: {
    setNumPages: (state, action) => {
      state.numPages = action.payload;
    },
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload;
    },
    setContainerSize: (state, action) => {
      state.containerSize = action.payload;
    },
  },
});

export const { setNumPages, setPageNumber, setContainerSize } =
  documentViewerSlice.actions;

export default documentViewerSlice.reducer;
