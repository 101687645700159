interface BaseAnnotation {
  uuid: string;
}

function findAnnotationByUuid<T extends BaseAnnotation>(
  annotations: T[],
  uuid: string
): T | undefined {
  if (!annotations) {
    return undefined;
  }
  return annotations.find((a) => a.uuid === uuid);
}

export default findAnnotationByUuid;
