import { useQuery } from "@tanstack/react-query";

import groupsService from "../services/groupsService";

export default function useGetGroupsQuery() {
  return useQuery({
    queryKey: ["groups"],
    queryFn: groupsService.getGroups,
  });
}
