import { useQuery } from "@tanstack/react-query";
import todosService from "../../services/todosService";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

type GetScheduledTodoProps = {
  id?: string;
  refetchOnWindowFocus?: boolean;
};

export default function useGetScheduledTodoQuery({
  id,
  refetchOnWindowFocus = false,
}: GetScheduledTodoProps) {
  return useQuery({
    queryKey: [QueryKeys.ScheduledTodoDetail, id],
    queryFn: id ? () => todosService.getScheduledTodo({ id }) : undefined,
    enabled: !!id,
    refetchOnWindowFocus,
  });
}
