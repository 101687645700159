import { useMutation } from "@tanstack/react-query";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import documentService from "../services/documentService";

type CreateBulkDocumentMutation = {
  onSuccess: (data: any) => void;
};

export default function useCreateBulkDocumentMutation({
  onSuccess,
}: CreateBulkDocumentMutation) {
  const handleErrors = useErrorHandling();
  return useMutation({
    mutationFn: documentService.createDocumentsBulk,
    onSuccess,
    onError: handleErrors,
  });
}
