import { useQuery } from "@tanstack/react-query";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import laborBudgetSettingsService, {
  LaborBudgetSettings,
} from "../services/laborBudgetSettingsService";

type UseGetOrCreateLaborBudgetSettingsQuery = {
  onSuccess?: (data: LaborBudgetSettings) => void;
};

export default function useGetOrCreateLaborBudgetSettingsQuery({
  onSuccess,
}: UseGetOrCreateLaborBudgetSettingsQuery) {
  const handleErrors = useErrorHandling();
  return useQuery({
    queryKey: [QueryKeys.LaborBudgetSettings],
    queryFn: laborBudgetSettingsService.getOrCreateLaborBudgetSettings,
    onError: handleErrors,
    onSuccess,
  });
}
