import { useMutation } from "@tanstack/react-query";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import weeklySchedulesService, {
  WeeklySchedulePublishResponse,
} from "../services/weeklySchedulesService";

type PublishWeeklyScheduleMutationParams = {
  onSuccess?: (data: WeeklySchedulePublishResponse) => void;
};

export default function usePublishWeeklyScheduleMutation({
  onSuccess,
}: PublishWeeklyScheduleMutationParams) {
  const handleErrors = useErrorHandling();

  return useMutation({
    mutationFn: async (weeklyScheduleId: number) =>
      weeklySchedulesService.publishWeeklySchedule(weeklyScheduleId),
    onSuccess,
    onError: handleErrors,
  });
}
