import React from "react";

type RightContentProps = {
  children: React.ReactNode;
};

function RightContent({ children }: RightContentProps) {
  return <div className={"sidebarContainer"}>{children}</div>;
}

export default RightContent;
