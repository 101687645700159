import { useAlert } from "@blaumaus/react-alert";
import { useQueryClient } from "@tanstack/react-query";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import Dialog from "src/components/Dialog/Dialog";
import DialogDescription from "src/components/Dialog/DialogDescription";
import DialogFooter from "src/components/Dialog/DialogFooter";
import DialogHeader from "src/components/Dialog/DialogHeader";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import useDeleteShiftTypeMutation from "../hooks/useDeleteShiftTypeMutation";
import { ShiftType } from "../services/shiftTypeService";

type DeleteShiftTypeDialogProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClose?: () => void;
  shiftType: ShiftType;
};

export default function ShiftTypeDeleteDialog({
  isOpen,
  setIsOpen,
  onClose,
  shiftType,
}: DeleteShiftTypeDialogProps) {
  const alert = useAlert();
  const queryClient = useQueryClient();

  const deleteShiftTypeMutation = useDeleteShiftTypeMutation({
    onSuccess: async () => {
      setIsOpen(false);
      await queryClient.invalidateQueries([QueryKeys.ShiftTypesList]);
      alert.success("Shift type deleted successfully.");
    },
  });

  const handleDeleteShiftType = () => {
    if (!shiftType?.id) return;
    deleteShiftTypeMutation.mutate(shiftType?.id);
  };

  return (
    <Dialog isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <DialogHeader>Delete Shift Type</DialogHeader>
      <DialogDescription>
        Are you sure you want to delete the shift type "{shiftType.name}"? This
        action cannot be undone.
      </DialogDescription>
      <DialogFooter>
        <Button
          color={ButtonColors.GrayAndYellow}
          onClick={() => setIsOpen(false)}
        >
          Cancel
        </Button>
        <Button color={ButtonColors.Red} onClick={handleDeleteShiftType}>
          Delete
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
