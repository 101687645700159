export enum TodoObjectTypes {
  Memo = "memo",
  Assessment = "assessment",
  Policy = "policy",
  LearningPath = "learning_path",
  Video = "video",
  Discussion = "discussion",
  Document = "document",
}

export enum TodoStatus {
  Assigned = 0,
  Complete = 1,
  DidNotComplete = 2,
}

export enum TodoStatusDisplay {
  Assigned = "Assigned",
  Complete = "Complete",
  DidNotComplete = "Did Not Complete",
}

export enum ScheduledTodoStatus {
  Draft = 0,
  Published = 1,
  Assigned = 2,
}

export enum TodoRecurrenceChoices {
  OneTime = 0,
  Weekly = 1,
  BiWeekly = 2,
  Monthly = 2,
  Quarterly = 3,
  SemiAnnually = 4,
  Annually = 5,
}
