import { z } from "zod";

export const policyUploadPdfSchema = z.object({
  id: z.number().or(z.string()),
  file_url: z.string().nullable(),
  name: z.string().nullable(),
  ext: z.string().nullable(),
});

export type PolicyUploadPdfSchemaType = z.infer<typeof policyUploadPdfSchema>;
