import { NavLink } from "react-router-dom";
import { useAppDispatch } from "src/store";
import { closeMenu } from "../slices/navMenuSlice";
import styles from "./NavMenu.module.css";

type NavMenuLinkProps = {
  to: string;
  text: string;
  id?: string;
  icon: React.ReactNode;
  beta?: boolean;
};

export default function NavMenuLink({
  to,
  text,
  id,
  icon,
  beta,
}: NavMenuLinkProps) {
  const dispatch = useAppDispatch();

  const handleCloseMenu = () => dispatch(closeMenu());
  return (
    <li className={styles.navLinkListItem}>
      <NavLink
        className={({ isActive }) =>
          isActive ? styles.navLinkSelected : styles.navLink
        }
        onClick={handleCloseMenu}
        to={to}
        id={id}
      >
        {icon ? icon : null}
        <span className={styles.navLinkText}>{text}</span>
        {beta ? <span className={styles.betaTag}>{"beta"}</span> : null}
      </NavLink>
    </li>
  );
}
