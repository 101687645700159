import React from "react";

interface MainContentProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export default function MainContent({
  children,
  style,
}: MainContentProps): JSX.Element {
  return (
    <div className={"mainContent"} style={style}>
      {children}
    </div>
  );
}
