import * as z from "zod";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { RotatingLines } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useAlert } from "@blaumaus/react-alert";
import inputStyles from "src/components/Inputs/Inputs.module.css";
import Button from "src/components/Buttons/Button";
import ButtonsContainer from "src/components/Container/ButtonsContainer";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import { EmployeeScheduleRoleType } from "../services/employeeScheduleRolesService";
import getFullName from "src/features/employees/utils/getFullName";
import { ScheduleRoleType } from "../services/scheduleRolesService";
import usePartialUpdateEmployeeScheduleRolesMutation from "../hooks/usePartialUpdateEmployeeScheduleRolesMutation";
import EmployeeImage from "src/components/EmployeeImage/EmployeeImage";

const employeeScheduleRoleSchema = z.object({
  id: z.number(),
  schedule_role: z.number(),
  hourly_rate: z.number().or(z.string()),
  employee: z.number(),
});

export type EmployeeScheduleRoleFormDataType = z.infer<
  typeof employeeScheduleRoleSchema
>;

const employeeScheduleRolesFormSchema = z.object({
  scheduleRoles: z.array(employeeScheduleRoleSchema),
});

type EmployeeScheduleRoleFormType = z.infer<
  typeof employeeScheduleRolesFormSchema
>;

type EmployeeScheduleRolesFormProps = {
  employeeScheduleRoles: EmployeeScheduleRoleType[];
  scheduleRoles: ScheduleRoleType[];
};

export default function VerifyEmployeeScheduleRolesForm({
  employeeScheduleRoles,
  scheduleRoles,
}: EmployeeScheduleRolesFormProps) {
  const navigate = useNavigate();
  const alert = useAlert();

  const partialUpdateEmployeeScheduleRolesMutation =
    usePartialUpdateEmployeeScheduleRolesMutation({});

  const { control, handleSubmit } = useForm<EmployeeScheduleRoleFormType>({
    resolver: zodResolver(employeeScheduleRolesFormSchema),
    defaultValues: {
      scheduleRoles: employeeScheduleRoles.map((scheduleRole) => ({
        id: scheduleRole.id,
        schedule_role: scheduleRole.schedule_role,
        hourly_rate: scheduleRole.hourly_rate,
        employee: scheduleRole.employee.id,
      })),
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "scheduleRoles",
    keyName: "uuid",
  });

  const handleSaveScheduleRoles = (data: EmployeeScheduleRoleFormType) => {
    const mutations = data.scheduleRoles.map((scheduleRole) =>
      partialUpdateEmployeeScheduleRolesMutation.mutateAsync(scheduleRole)
    );

    Promise.all(mutations)
      .then(() => {
        navigate("/schedules");
      })
      .catch(() => {
        alert.error(
          "An error occurred while saving. It has been reported and is being investigated."
        );
      });
  };

  const isLoading = partialUpdateEmployeeScheduleRolesMutation.isLoading;

  return (
    <form
      onSubmit={handleSubmit(handleSaveScheduleRoles)}
      style={{ padding: "10px 10px 0 10px", flex: 1 }}
    >
      <table className="table" style={{ backgroundColor: "white" }}>
        <thead>
          <tr>
            <th>Employee</th>
            <th>Role</th>
            <th>Hourly Rate</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((field, index) => (
            <tr key={field.uuid}>
              <td>
                <div className="flex-row items-center gap-10">
                  <EmployeeImage
                    alt={"Employee Image"}
                    src={employeeScheduleRoles[index]?.employee?.image}
                    containerClassName="tableImageContainer"
                  />
                  <div>
                    {getFullName(employeeScheduleRoles[index].employee)}
                  </div>
                </div>
              </td>
              <td>
                <div>
                  {
                    scheduleRoles?.find(
                      (scheduleRole) =>
                        scheduleRole.id ===
                        employeeScheduleRoles[index].schedule_role
                    )?.role?.name
                  }
                </div>
              </td>
              <td>
                <Controller
                  name={`scheduleRoles.${index}.hourly_rate`}
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      disabled={isLoading}
                      type="number"
                      min={0}
                      className={inputStyles.darkInput}
                      id={`hourlyRate${index}`}
                    />
                  )}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ButtonsContainer>
        <Button color={ButtonColors.Yellow} disabled={isLoading} type="submit">
          {isLoading ? (
            <RotatingLines
              strokeColor="#000"
              strokeWidth="5"
              animationDuration="0.75"
              width="20"
              visible={true}
            />
          ) : (
            "Save Changes"
          )}
        </Button>
      </ButtonsContainer>
    </form>
  );
}
