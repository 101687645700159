/**
 * Adjusts the end time by adding one day if it is before the start time.
 * @param startTime The start datetime object.
 * @param endTime The end datetime object.
 * @returns The adjusted end datetime object.
 */
function adjustEndTimeByAddingDayIfBeforeStart(
  startTime: Date,
  endTime: Date
): Date {
  if (endTime < startTime) {
    endTime.setDate(endTime.getDate() + 1);
  }
  return endTime;
}

export default adjustEndTimeByAddingDayIfBeforeStart;
