const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#F4B90D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M6.668 1.667v2.5M13.332 1.667v2.5M2.918 7.575h14.167M17.5 7.083v7.084c0 2.5-1.25 4.166-4.167 4.166H6.667c-2.917 0-4.167-1.666-4.167-4.166V7.083c0-2.5 1.25-4.166 4.167-4.166h6.666c2.917 0 4.167 1.666 4.167 4.166Z"
    />
    <path
      stroke="#F4B90D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13.076 11.417h.008M13.076 13.917h.008M9.994 11.417h.008M9.994 13.917h.008M6.912 11.417h.008M6.912 13.917h.008"
    />
  </svg>
);
export default SvgComponent;
