import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import EmployeeTypesForm from "../../employeeTypes/components/EmployeeTypesForm";
import Header from "src/components/Headers/Header";
import { useAppOutletContext } from "src/features/reactRouterDom/hooks/useAppOutletContext";

export default function OnboardingEmployeeTypes() {
  const { setCurrentStep } = useAppOutletContext() || {};
  const navigate = useNavigate();

  const handleSuccessfulSave = () => navigate("/register/departments");

  useEffect(() => {
    if (setCurrentStep) setCurrentStep(2);
  }, [setCurrentStep]);

  return (
    <div className={"onboardingCard"}>
      <Header
        title={"Set Up Employee Types"}
        containerStyle={{ maxHeight: "40px" }}
        id={"setUpRolesHeader"}
      />
      <EmployeeTypesForm onSuccessfulSaveCallback={handleSuccessfulSave} />
    </div>
  );
}
