import * as React from "react"

const SvgComponent = (props: any) => (
  <svg
    width={21}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.466 14.58c-.243.229-.588.42-.891.42-.304 0-.649-.2-.902-.43L0 9.212 1.803 7.51l4.782 4.515L19.227 0 21 1.732 7.466 14.579Z"
      fill="#000"
    />
  </svg>
)

export default SvgComponent
