import { useEffect } from "react";
import { useSelector } from "react-redux";
import RootState from "src/features/redux/types/rootState.types";

function useModal() {
  const modal = useSelector((state: RootState) => state.modal);

  useEffect(() => {
    let html = document.getElementsByTagName("html")[0];
    if (modal.isVisible) {
      html.style.overflowY = "hidden";
    } else {
      html.style.overflowY = "auto";
    }
  }, [modal.isVisible]);

  return modal;
}

export default useModal;
