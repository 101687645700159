import { useAlert } from "@blaumaus/react-alert";
import { useQueryClient } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import Dialog from "src/components/Dialog/Dialog";
import DialogContent from "src/components/Dialog/DialogContent";
import DialogDescription from "src/components/Dialog/DialogDescription";
import DialogFooter from "src/components/Dialog/DialogFooter";
import DialogHeader from "src/components/Dialog/DialogHeader";
import InputLabel from "src/components/Inputs/InputLabel";
import getFullName from "src/features/employees/utils/getFullName";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import { z } from "zod";
import useAllocateEmployeeShiftMutation from "../hooks/useAllocateEmployeeShiftMutation";
import { EmployeeShift } from "../services/employeeShiftsService";
import { ScheduleRoleType } from "../services/scheduleRolesService";
import { ShiftRequirementType } from "../services/shiftRequirementsService";
import { ShiftRequirementDetails } from "./ScheduleCalendar";

type SelectEmployeeScheduleRoleDialogProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClose?: () => void;
  details: ShiftRequirementDetails;
  scheduleRoles?: ScheduleRoleType[];
};

const allocateEmployeeShiftSchema = z.object({
  name: z.string(),
  employeeId: z.number(),
  scheduleRoleId: z
    .union([z.number(), z.string()])
    .refine((val) => val !== "", {
      message: "Please select a role.",
    }),
  departmentId: z.number(),
  weeklyScheduleId: z.number(),
  startDateTime: z.string(),
  endDateTime: z.string(),
});

export type AllocateEmployeeShift = z.infer<typeof allocateEmployeeShiftSchema>;

export default function SelectEmployeeScheduleRoleDialog({
  isOpen,
  setIsOpen,
  onClose,
  details,
  scheduleRoles,
}: SelectEmployeeScheduleRoleDialogProps) {
  const alert = useAlert();
  const queryClient = useQueryClient();

  const { handleSubmit, control } = useForm<AllocateEmployeeShift>({
    defaultValues: {
      name: details?.name,
      employeeId: details?.employeeId,
      scheduleRoleId: "",
      departmentId: details?.departmentId,
      weeklyScheduleId: details?.weeklyScheduleId,
      startDateTime: details?.startDateTime,
      endDateTime: details?.endDateTime,
    },
  });

  const allocateEmployeeShiftMutation = useAllocateEmployeeShiftMutation({
    onSuccess: (data) => {
      alert.success(
        `${getFullName(
          data.employee_schedule_role.employee
        )} has been assigned to the shift`
      );
      queryClient.setQueryData<EmployeeShift[]>(
        [
          QueryKeys.EmployeeShiftsList,
          {
            department: details?.departmentId,
            weeklySchedule: details?.weeklyScheduleId,
          },
        ],
        (prevData) => {
          // sort the employee shifts by shift_requirement.start_datetime and insert
          // the new shift at the correct position
          if (!prevData) return prevData;
          const newData = [...prevData, data].sort(
            (a, b) =>
              new Date(a.shift_requirement.start_datetime).getTime() -
              new Date(b.shift_requirement.start_datetime).getTime()
          );
          return newData;
        }
      );
      queryClient.setQueryData<EmployeeShift[]>(
        [
          QueryKeys.EmployeeShiftsList,
          data.employee_schedule_role.schedule_role,
        ],
        (prevData) => {
          // sort the employee shifts by shift_requirement.start_datetime and insert
          // the new shift at the correct position
          if (!prevData) return prevData;
          const newData = [...prevData, data].sort(
            (a, b) =>
              new Date(a.shift_requirement.start_datetime).getTime() -
              new Date(b.shift_requirement.start_datetime).getTime()
          );
          return newData;
        }
      );
      queryClient.invalidateQueries<ShiftRequirementType[]>([
        QueryKeys.ShiftRequirementsList,
        details?.weeklyScheduleId,
      ]);
      setIsOpen(false);
    },
  });

  const onSubmit = (data: AllocateEmployeeShift) => {
    // need to check if the employee is associated with an employee shcedule
    // role which is the same as this schedule role. If not, then we need to
    // create a new employee schedule role for the employee. We also need to
    // check if a shift requirement exists for this start, end, department,
    // schedule_role, and weekly schedule. If not, then we need to create a new shift
    // requirement as pending status. Otherwise create the employee shift and associate
    // it with the shift requirement and set the shift requirement status to pending.
    const formattedData = {
      ...data,
      startDateTime: new Date(data.startDateTime).toISOString().slice(0, 10),
      endDateTime: new Date(data.endDateTime).toISOString().slice(0, 10),
    };
    allocateEmployeeShiftMutation.mutate(formattedData);
  };

  const handleCloseDialog = () => setIsOpen(false);

  return (
    <Dialog isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <DialogHeader>Select Employee Role</DialogHeader>
      <DialogDescription>
        Select the employee's role for this shift.
      </DialogDescription>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <div className={"mb-20"}>
            <InputLabel>Role</InputLabel>
            <Controller
              name="scheduleRoleId"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <select {...field} className="inputBlack">
                  <option value="" disabled>
                    Select Role
                  </option>
                  {scheduleRoles?.map((scheduleRole: any) => (
                    <option key={scheduleRole.id} value={scheduleRole.id}>
                      {scheduleRole.role.name}
                    </option>
                  ))}
                </select>
              )}
            />
          </div>
        </DialogContent>
        <DialogFooter>
          <Button
            color={ButtonColors.GrayAndYellow}
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
          <Button color={ButtonColors.Yellow} type="submit">
            Save Changes
          </Button>
        </DialogFooter>
      </form>
    </Dialog>
  );
}
