import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useHandleOptimisticMutationError } from "src/features/reactQuery/hooks/useHandleOptimisticMutationError";
import shiftRequirementsService, {
  ShiftRequirementStatus,
  ShiftRequirementType,
} from "../services/shiftRequirementsService";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

export type CreateShiftRequirementVariables = {
  name: string;
  department: number;
  weeklySchedule: number;
  scheduleRole: number;
  startDateTime: string;
  endDateTime: string;
  requiredCount: number;
  filledCount: number;
};

type MutationContext = {
  prevData: ShiftRequirementType[] | undefined;
  optimisticallyAdded?: ShiftRequirementType | null;
};

type CreateShiftRequirementMutation = {
  onSuccess?: (data: ShiftRequirementType) => void;
};

export default function useCreateShiftRequirementMutation({
  onSuccess,
}: CreateShiftRequirementMutation) {
  const queryClient = useQueryClient();

  const handleOptimisticMutationError = useHandleOptimisticMutationError<any>();

  return useMutation<
    ShiftRequirementType,
    AxiosError,
    CreateShiftRequirementVariables,
    MutationContext
  >({
    mutationFn: (variables: CreateShiftRequirementVariables) =>
      shiftRequirementsService.createShiftRequirement(variables),

    onMutate: async (variables): Promise<MutationContext> => {
      // Transform variables to match backend format
      const newShiftRequirement: ShiftRequirementType = {
        name: variables.name,
        status: ShiftRequirementStatus.Pending,
        department: variables.department,
        weekly_schedule: variables.weeklySchedule,
        schedule_role: variables.scheduleRole,
        start_datetime: variables.startDateTime,
        end_datetime: variables.endDateTime,
        required_count: variables.requiredCount,
        filled_count: variables.filledCount,
      };

      // update the shift requirements query with the new shift requirement
      const prevData = queryClient.getQueryData<ShiftRequirementType[]>([
        QueryKeys.ShiftRequirementsList,
        variables.weeklySchedule,
      ]);

      if (prevData) {
        const updatedData = [...prevData, newShiftRequirement].sort((a, b) => {
          const startComparison =
            new Date(a.start_datetime).getTime() -
            new Date(b.start_datetime).getTime();
          if (startComparison !== 0) {
            return startComparison;
          }
          return (
            new Date(a.end_datetime).getTime() -
            new Date(b.end_datetime).getTime()
          );
        });

        queryClient.setQueryData(
          [QueryKeys.ShiftRequirementsList, variables.weeklySchedule],
          updatedData
        );
        return { prevData, optimisticallyAdded: newShiftRequirement };
      }
      return { prevData, optimisticallyAdded: null };
    },

    onSuccess: (data, variables, context) => {
      const { prevData, optimisticallyAdded } = context || {};

      if (prevData && optimisticallyAdded) {
        const updatedData = prevData
          .filter(
            (item: ShiftRequirementType) =>
              !(
                item.name === optimisticallyAdded.name &&
                item.start_datetime === optimisticallyAdded.start_datetime &&
                item.end_datetime === optimisticallyAdded.end_datetime
              )
          )
          .concat(data)
          .sort((a: ShiftRequirementType, b: ShiftRequirementType) => {
            const startComparison =
              new Date(a.start_datetime).getTime() -
              new Date(b.start_datetime).getTime();
            if (startComparison !== 0) {
              return startComparison;
            }
            return (
              new Date(a.end_datetime).getTime() -
              new Date(b.end_datetime).getTime()
            );
          });

        queryClient.setQueryData(
          [QueryKeys.ShiftRequirementsList, variables.weeklySchedule],
          updatedData
        );
      }
      onSuccess?.(data);
    },

    onError: (error: AxiosError, variables, context) => {
      if (context?.prevData) {
        queryClient.setQueryData(
          [QueryKeys.ShiftRequirementsList, variables.weeklySchedule],
          context.prevData
        );
      }
      handleOptimisticMutationError(
        error,
        [QueryKeys.ShiftRequirementsList, variables.weeklySchedule],
        context?.prevData
      );
    },
  });
}
