import { createSlice } from "@reduxjs/toolkit";

export interface SearchState {
  searchTerm: string;
}

const initialState: SearchState = {
  searchTerm: "",
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    resetSearch: (state) => {
      state.searchTerm = "";
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
  },
});

export const { resetSearch, setSearchTerm } = searchSlice.actions;
export default searchSlice.reducer;
