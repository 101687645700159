import * as React from "react";
const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={34}
    height={34}
    fill="none"
    {...props}
  >
    <circle cx={17} cy={17} r={17} fill="url(#a)" />
    <path
      fill="#000"
      d="M18.854 24v-2.6h-6.9v-2.04l6.56-9.36h2.9v9.18h1.84v2.22h-1.84V24h-2.56Zm-4.16-4.82h4.32v-6.36l-4.32 6.36Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={17}
        x2={17}
        y1={0}
        y2={34}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FEC110" />
        <stop offset={0} stopColor="#FEC110" />
        <stop offset={1} stopColor="#CC9800" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgComponent;
