import hasGroupPermissions from "src/features/auth/utils/hasGroupPermissions";
import { CRUD_GROUP } from "src/features/groups/groups";
import { useAppDispatch } from "src/store";
import useNavMenu from "../slices/hooks/useNavMenu";
import Button from "src/components/Buttons/Button";
import useAuth from "src/features/auth/hooks/useAuth";
import NavMenuLinkList from "./NavMenuLinkList";
import LogoutButton from "./LogoutButton";
import NavMenuLogo from "./NavMenuLogo";
import { setModalStateAction } from "src/features/modal/handlers";
import NavMenuEmployeeProfile from "./NavMenuEmployeeProfile";
import styles from "./NavMenu.module.css";
import globalStyles from "src/globalStyles.module.css";
import { ButtonColors } from "src/components/Buttons/buttons.types";

const inviteBtnsClasses = `${styles.inviteButtonsContainer} ${globalStyles.hideOnDesktop}`;

export default function NavigationMenu(): JSX.Element {
  const dispatch = useAppDispatch();
  const { isVisible } = useNavMenu();
  const { employee, isAuthenticated } = useAuth();

  const handleOpenInviteModal = (e: React.MouseEvent) =>
    dispatch(setModalStateAction(null)(e?.currentTarget.id || "")(true));

  return (
    <div
      id="navMenu"
      className={
        isVisible
          ? `${styles.leftNavContainer} ${styles.leftNavContainerShown}`
          : `${styles.leftNavContainer} ${styles.leftNavContainerHidden}`
      }
    >
      <nav className={styles.leftNav}>
        <NavMenuLogo />
        {employee ? <NavMenuEmployeeProfile employee={employee} /> : null}
        {isAuthenticated && <NavMenuLinkList />}
        {hasGroupPermissions(employee, CRUD_GROUP) ? (
          <div className={inviteBtnsClasses}>
            <Button
              title="Invite Employees"
              color={ButtonColors.GrayAndYellow}
              id="Invite an Employee"
              onClick={handleOpenInviteModal}
            />
            <Button
              title="Invite Supervisors"
              color={ButtonColors.Yellow}
              id="Invite a Supervisor"
              onClick={handleOpenInviteModal}
            />
          </div>
        ) : null}
        {isAuthenticated ? <LogoutButton /> : null}
      </nav>
    </div>
  );
}
