import { useAlert } from "@blaumaus/react-alert";
import { useQueryClient } from "@tanstack/react-query";
import { format, parseISO } from "date-fns";
import { truncate } from "lodash";
import React, { useState } from "react";
import { MdCompareArrows } from "react-icons/md";
import { RotatingLines } from "react-loader-spinner";
import Skeleton from "react-loading-skeleton";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import Dialog from "src/components/Dialog/Dialog";
import DialogContent from "src/components/Dialog/DialogContent";
import DialogDescription from "src/components/Dialog/DialogDescription";
import DialogFooter from "src/components/Dialog/DialogFooter";
import DialogHeader from "src/components/Dialog/DialogHeader";
import EmployeeImage from "src/components/EmployeeImage/EmployeeImage";
import LocalSearchInput from "src/components/Inputs/LocalSearchInput";
import Placeholder from "src/components/Placeholder/Placeholder";
import getFullName from "src/features/employees/utils/getFullName";
import useCreateShiftTransferMutation from "../hooks/useCreateShiftTransferMutation";
import useGetEmployeeShiftsListQuery from "../hooks/useGetEmployeeShiftsListQuery";
import {
  EmployeeShift,
  EmployeeShiftStatus,
} from "../services/employeeShiftsService";
import { ShiftTransferType } from "../services/shiftTransferService";
import ShiftTransferFlowCards from "./ShiftTransferFlowCards";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

type ShiftTransferSwapDialogProps = {
  shiftFrom: EmployeeShift;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const currentDate = new Date().toISOString();

export default function ShiftTransferSwapDialog({
  shiftFrom,
  isOpen,
  setIsOpen,
}: ShiftTransferSwapDialogProps) {
  const alert = useAlert();
  const queryClient = useQueryClient();
  const [employeeSearchInput, setEmployeeSearchInput] = useState("");
  const [selectedEmployeeShift, setSelectedEmployeeShift] =
    useState<EmployeeShift | null>(null);

  const handleSelectEmployeeshift = (shift: EmployeeShift) => {
    setSelectedEmployeeShift((prev) => {
      if (prev?.id === shift.id) return null;
      return shift;
    });
  };

  const {
    data: employeeShifts,
    isLoading: isEmployeeShiftsLoading,
    isError: isEmployeeShiftsError,
  } = useGetEmployeeShiftsListQuery({
    scheduleRoleId: shiftFrom.employee_schedule_role.schedule_role,
    startDate: currentDate,
    status: EmployeeShiftStatus.Published,
    notEmployeeId: shiftFrom.employee_schedule_role.employee.id,
    select: (data) => {
      let filteredData = data;

      if (employeeSearchInput) {
        filteredData = filteredData?.filter((employeeShift) => {
          const fullName = getFullName(
            employeeShift.employee_schedule_role.employee
          );
          return fullName
            .toLowerCase()
            .includes(employeeSearchInput.toLowerCase());
        });
      }

      return filteredData;
    },
  });

  const createShiftTransferMutation = useCreateShiftTransferMutation({
    onSuccess: async (data) => {
      alert.success("Shift swap request sent successfully!");
      setIsOpen(false);
      handleClose();
      queryClient.setQueryData(
        [QueryKeys.ShiftTransfer, String(data.id)],
        data
      );
      await queryClient.invalidateQueries([
        QueryKeys.ShiftTransfer,
        String(data.id),
      ]);
      await queryClient.invalidateQueries([QueryKeys.ShiftTransfersList]);
      await queryClient.invalidateQueries([QueryKeys.EmployeeShiftsList]);
    },
  });

  const shiftsByDay = React.useMemo(() => {
    if (!employeeShifts) return {};

    return employeeShifts.reduce((groups: any, shift: EmployeeShift) => {
      const date = format(
        parseISO(shift.shift_requirement.start_datetime),
        "yyyy-MM-dd"
      );

      if (!groups[date]) groups[date] = [];
      groups[date].push(shift);
      return groups;
    }, {});
  }, [employeeShifts]);

  const dates = React.useMemo(
    () => Object.keys(shiftsByDay).sort(),
    [shiftsByDay]
  );

  const handleChangeEmployeeSearchInput = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => setEmployeeSearchInput(e.target.value);

  const handleCreateShiftTransfer = () => {
    if (!selectedEmployeeShift) return;

    createShiftTransferMutation.mutate({
      type: ShiftTransferType.ShiftSwap,
      employee_schedule_role_from: shiftFrom.employee_schedule_role.id,
      employee_schedule_role_to:
        selectedEmployeeShift.employee_schedule_role.id,
      shift_from: shiftFrom.id,
      shift_to: selectedEmployeeShift.id,
    });
  };

  const handlePickUpShift = (shift: EmployeeShift) => {
    console.log("Pick up shift", shift);
  };

  const handleClose = () => {
    setSelectedEmployeeShift(null);
    setEmployeeSearchInput("");
  };

  return (
    <Dialog isOpen={isOpen} setIsOpen={setIsOpen} onClose={handleClose}>
      <DialogHeader>Shift Swap Request</DialogHeader>
      <DialogDescription>
        Pick an employee to swap shifts with. After selecting an employee, the
        system will send a notification to the selected employee to confirm the
        swap.
      </DialogDescription>
      <DialogContent>
        {selectedEmployeeShift ? (
          <div className="flex flex-row-mobile-column my-20">
            <ShiftTransferFlowCards
              cardClassName="bg-cp-black-50"
              titleLeft="Swap from:"
              titleRight="Swap to:"
              employeeLeft={shiftFrom.employee_schedule_role.employee}
              employeeRight={
                selectedEmployeeShift.employee_schedule_role.employee
              }
              shiftLeft={shiftFrom}
              shiftRight={selectedEmployeeShift}
              onPickUpShift={() => handlePickUpShift(selectedEmployeeShift)}
              icon={
                <MdCompareArrows size={70} color={"var(--clearpath-yellow)"} />
              }
            />
          </div>
        ) : null}
        {!isEmployeeShiftsLoading &&
        !isEmployeeShiftsError &&
        !selectedEmployeeShift ? (
          <div className="mb-10">
            <LocalSearchInput
              placeholder="Search"
              onChange={handleChangeEmployeeSearchInput}
              value={employeeSearchInput}
            />
          </div>
        ) : null}
        {!selectedEmployeeShift ? (
          <div className="flex h-40vh overflow-y-auto gap-5 border-light-gray p-5 border-radius-10 bg-cp-black-50">
            {!isEmployeeShiftsLoading &&
              !isEmployeeShiftsError &&
              dates.length > 0 &&
              dates.map((date) => (
                <React.Fragment key={date}>
                  <span className="font-bold text-lg cp-yellow p-10">
                    {format(parseISO(date), "MMM dd, yyyy")}
                  </span>
                  {shiftsByDay[date].map((shift: EmployeeShift) => (
                    <div
                      key={shift.id}
                      className={
                        "p-5 flex-row align-center gap-10 border-radius-5 font-w-500 cursor-pointer bg-dark-gray-hover text-sm"
                      }
                      onClick={() => handleSelectEmployeeshift(shift)}
                    >
                      <EmployeeImage
                        alt={"Employee Image"}
                        src={shift.employee_schedule_role.employee.image}
                        width={25}
                        height={25}
                        containerStyle={{
                          width: "25px",
                          height: "25px",
                        }}
                      />
                      {truncate(
                        getFullName(shift.employee_schedule_role.employee),
                        {
                          length: 25,
                        }
                      )}

                      <div className="flex flex-1 align-end text-xs p-5">
                        {format(
                          new Date(shift.shift_requirement.start_datetime),
                          "hh:mm a"
                        )}{" "}
                        -{" "}
                        {format(
                          new Date(shift.shift_requirement.end_datetime),
                          "hh:mm a"
                        )}
                      </div>
                    </div>
                  ))}
                </React.Fragment>
              ))}
            {!isEmployeeShiftsLoading && dates.length === 0 && (
              <div className="flex-1 centered">
                <Placeholder title={"No positions filled"} />
              </div>
            )}
            {isEmployeeShiftsLoading && <Skeleton count={10} height={30} />}
          </div>
        ) : null}
      </DialogContent>
      <DialogFooter>
        {!selectedEmployeeShift ? (
          <Button
            color={ButtonColors.GrayAndYellow}
            disabled={createShiftTransferMutation.isLoading}
            onClick={() => setIsOpen(false)}
          >
            {createShiftTransferMutation.isLoading ? (
              <RotatingLines
                strokeColor="#f1b70c"
                strokeWidth="5"
                animationDuration="0.75"
                width="20"
                visible={true}
              />
            ) : (
              "Cancel"
            )}
          </Button>
        ) : null}
        {selectedEmployeeShift ? (
          <Button
            color={ButtonColors.GrayAndYellow}
            disabled={createShiftTransferMutation.isLoading}
            onClick={() => setSelectedEmployeeShift(null)}
          >
            {createShiftTransferMutation.isLoading ? (
              <RotatingLines
                strokeColor="#f1b70c"
                strokeWidth="5"
                animationDuration="0.75"
                width="20"
                visible={true}
              />
            ) : (
              "Back"
            )}
          </Button>
        ) : null}
        <Button
          color={ButtonColors.Yellow}
          onClick={handleCreateShiftTransfer}
          disabled={
            !selectedEmployeeShift || createShiftTransferMutation.isLoading
          }
        >
          {createShiftTransferMutation.isLoading ? (
            <RotatingLines
              strokeColor="#000"
              strokeWidth="5"
              animationDuration="0.75"
              width="20"
              visible={true}
            />
          ) : (
            "Submit"
          )}
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
