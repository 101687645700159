import React from "react";
import Button from "../Buttons/Button";

type DropDownV2ButtonProps = {
  id?: string;
  icon?: React.ReactNode;
  title: React.ReactNode | string;
  onClick?: (
    e:
      | React.MouseEvent<Element, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
};

export default function DropDownV2Button({
  id,
  icon,
  title,
  onClick,
}: DropDownV2ButtonProps) {
  return (
    <Button className={"dropDownV2Button"} onClick={onClick} id={id}>
      {icon ? icon : null} {title}
    </Button>
  );
}
