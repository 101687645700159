import Card from "src/components/Cards/Card";
import CardHeader from "src/components/Cards/CardHeader";
import Tab from "src/components/Tabs/Tab";
import TabNavigation from "src/components/Tabs/TabNavigation";
import TemplatesList from "./TemplatesList";
import DraftDocumentsList from "./DraftDocumentsList";
import InProgressDocumentsList from "./InProgressDocumentsList";
import CompletedDocumentsList from "./CompletedDocumentsList";
import styles from "../ESignatures.module.css";
import hasGroupPermissions from "src/features/auth/utils/hasGroupPermissions";
import { CRUD_GROUP_WITH_HR } from "src/features/groups/groups";
import useAuth from "src/features/auth/hooks/useAuth";

export default function ESignaturesListSidebar() {
  const { employee } = useAuth();
  return (
    <Card className={styles.sidebarCard}>
      <CardHeader title={"Documents"} />
      <div className={styles.tabsContainer}>
        <TabNavigation defaultActiveTab={0} id="documents">
          <Tab title={"Templates"} className={styles.tab}>
            <TemplatesList />
          </Tab>
          {hasGroupPermissions(employee, CRUD_GROUP_WITH_HR) ? (
            <Tab title={"Drafts"} className={styles.tab}>
              <DraftDocumentsList />
            </Tab>
          ) : (
            <></>
          )}
          <Tab title={"Pending"} className={styles.tab}>
            <InProgressDocumentsList />
          </Tab>
          <Tab title={"Completed"} className={styles.tab}>
            <CompletedDocumentsList />
          </Tab>
        </TabNavigation>
      </div>
    </Card>
  );
}
