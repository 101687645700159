import * as React from "react";
const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={34}
    height={34}
    fill="none"
    {...props}
  >
    <circle cx={17} cy={17} r={17} fill="url(#a)" />
    <path
      fill="#000"
      d="M17.679 24.24c-1.027 0-1.913-.18-2.66-.54-.733-.36-1.313-.853-1.74-1.48a4.625 4.625 0 0 1-.72-2.16h2.5c.12.573.413 1.053.88 1.44.466.373 1.046.56 1.74.56.747 0 1.353-.273 1.82-.82.48-.547.72-1.227.72-2.04 0-.84-.24-1.507-.72-2-.467-.493-1.06-.74-1.78-.74-.6 0-1.12.147-1.56.44-.44.293-.753.66-.94 1.1h-2.46l1.2-8h7.76v2.24h-5.84l-.64 3.24c.293-.32.693-.58 1.2-.78a4.342 4.342 0 0 1 1.7-.32c1 0 1.833.227 2.5.68a4.235 4.235 0 0 1 1.52 1.76c.347.72.52 1.507.52 2.36 0 .973-.213 1.84-.64 2.6-.414.76-1 1.36-1.76 1.8-.747.44-1.613.66-2.6.66Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={17}
        x2={17}
        y1={0}
        y2={34}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FEC110" />
        <stop offset={0} stopColor="#FEC110" />
        <stop offset={1} stopColor="#CC9800" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgComponent;
