import { useQueryClient } from "@tanstack/react-query";
import { useAlert } from "@blaumaus/react-alert";
import { memo } from "react";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

import StepContainer from "src/components/Steps/StepContainer";
import { useAppDispatch } from "src/store";
import usePartialUpdateMemoMutation from "../../hooks/usePartialUpdateMemoMutation";
import {
  MemoFormSteps,
  setMemoFormStep,
  setSelectedMemoId,
} from "../../slices/memoSlice";
import useGetMemoQuery from "../../hooks/useGetMemoQuery";
import useMemoFormState from "../../hooks/useMemoFormState";
import EditingContainer from "src/features/learningPaths/components/LPBuilder/Forms/EditingContainer";
import AssignViewableByStepForm from "src/components/Steps/AssignViewableByStepForm";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

function MemoAssignViewableByForm() {
  const alert = useAlert();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const { selectedMemoId } = useMemoFormState();

  const combinedId = id || selectedMemoId;

  const { data: memo, isInitialLoading: isMemoLoading } = useGetMemoQuery({
    id: combinedId ? String(combinedId) : undefined,
  });

  const partialUpdateMemoMutation = usePartialUpdateMemoMutation({
    onSuccess: (data) => {
      dispatch(setMemoFormStep(MemoFormSteps.ScheduleMemo));
      dispatch(setSelectedMemoId(data.id));
      queryClient.invalidateQueries([QueryKeys.MemosList]);
      queryClient.setQueryData(["memo", String(data.id)], data);
      alert.success("Memo viewable by updated successfully!");
    },
  });

  const handleGoBack = () =>
    dispatch(setMemoFormStep(MemoFormSteps.UploadFile));

  return (
    <StepContainer>
      <div className="mb-20">
        <span className="text-muted">
          Specify who can view this memo by assigning it to specific employee
          roles, departments, or the entire organization.
        </span>
      </div>
      <EditingContainer>
        {!isMemoLoading && memo ? (
          <AssignViewableByStepForm
            objectDetail={memo}
            partialUpdateMutation={partialUpdateMemoMutation}
            onGoBack={handleGoBack}
          />
        ) : null}
        {isMemoLoading ? <Skeleton height={200} /> : null}
      </EditingContainer>
    </StepContainer>
  );
}

export default memo(MemoAssignViewableByForm);
