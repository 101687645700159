import React from "react";
import styles from "./ContentBody.module.css";

type ContentBodyProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
};

function ContentBody({ children, style }: ContentBodyProps) {
  return (
    <div className={styles.contentBody} style={style}>
      {children}
    </div>
  );
}

export default ContentBody;
