import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={70}
    height={70}
    viewBox="0 0 101 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle opacity={0.1} cx={50.5} cy={50.5} r={50.5} fill="#fff" />
    <g opacity={0.8} fill="#fff">
      <path d="M28.222 67.875a5 5 0 0 0 5 5h35.556a5 5 0 0 0 5-5v-2.813c-.005-2.9-1.687-5.68-4.676-7.73-2.989-2.05-7.042-3.204-11.269-3.207H44.166c-4.227.003-8.28 1.157-11.269 3.207-2.99 2.05-4.67 4.83-4.675 7.73v2.813ZM40.063 40.063a10.937 10.937 0 1 0 21.873 0 10.937 10.937 0 0 0-21.873 0Z" />
    </g>
  </svg>
)

export default SvgComponent
