import { useMutation } from "@tanstack/react-query";
import shiftRequirementsService from "../services/shiftRequirementsService";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";

type DeleteShiftRequirementMutation = {
  onSuccess?: () => void;
};

export default function useDeleteShiftRequirementMutation({
  onSuccess,
}: DeleteShiftRequirementMutation) {
  const handleErrors = useErrorHandling();

  return useMutation({
    mutationFn: async (shiftRequirementId: number) =>
      shiftRequirementsService.deleteShiftRequirement(shiftRequirementId),
    onSuccess,
    onError: handleErrors,
  });
}
