import React, { useCallback, useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Skeleton from "react-loading-skeleton";
import { useAlert } from "@blaumaus/react-alert";
import { useQueryClient } from "@tanstack/react-query";
import { RotatingLines } from "react-loader-spinner";

import useGetEmployeeTypesQuery from "../hooks/useGetEmployeeTypesQuery";
import Form from "src/components/Form/Form";
import FormRow from "src/components/Forms/FormRow";
import InputLabel from "src/components/Inputs/InputLabel";
import RoleSmallIcon from "src/components/Icons/RoleSmallIcon";
import inputStyles from "src/components/Inputs/Inputs.module.css";
import DynamicFormInput from "src/components/DynamicForm/DynamicFormInput";
import useGetGroupsQuery from "src/features/groups/hooks/useGetGroupsQuery";
import OrganizationSmallIcon from "src/components/Icons/OrganizationSmallIcon";
import Container from "src/components/Container/Container";
import Button from "src/components/Buttons/Button";
import CloseSmallIcon from "src/components/Icons/CloseSmallIcon";
import {
  EmployeeTypesFormValues,
  employeeTypesFormSchema,
} from "../zod/employeeTypesFormSchema";
import useCreateEmployeeTypeMutation from "../hooks/useCreateEmployeeTypeMutation";
import useUpdateEmployeeTypeMutation from "../hooks/useUpdateEmployeeTypeMutation";
import ButtonsContainer from "src/components/Container/ButtonsContainer";
import useDeleteEmployeeTypeMutation from "../hooks/useDeleteEmployeeTypeMutation";
import JoyrideWrapper from "src/components/JoyRide/JoyrideWrapper";
import { steps } from "src/components/JoyRide/SetUpRolesSteps";
import { ButtonColors } from "src/components/Buttons/buttons.types";

type EmployeeTypeFormProps = {
  onSuccessfulSaveCallback?: () => void;
  onCancelCallback?: () => void;
};

export default function EmployeeTypesForm({
  onSuccessfulSaveCallback,
  onCancelCallback,
}: EmployeeTypeFormProps) {
  const alert = useAlert();
  const queryClient = useQueryClient();
  const { data: groups, isLoading: isGroupsLoading } = useGetGroupsQuery();
  const {
    data: employeeTypes,
    isLoading: isEmployeeTypesLoading,
    refetch: refetchGetEmployeeTypeQuery,
  } = useGetEmployeeTypesQuery();

  const createEmployeeTypeMutation = useCreateEmployeeTypeMutation({});
  const updateEmployeeTypeMutation = useUpdateEmployeeTypeMutation({});
  const deleteEmployeeTypeMutation = useDeleteEmployeeTypeMutation({
    onSuccess: () => {
      alert.success("Employee type deleted successfully!");
      refetchGetEmployeeTypeQuery();
    },
  });

  const isMutationsLoading =
    createEmployeeTypeMutation.isLoading ||
    updateEmployeeTypeMutation.isLoading ||
    deleteEmployeeTypeMutation.isLoading;
  const isQueriesLoading = isGroupsLoading || isEmployeeTypesLoading;
  const isCombinedLoading = isMutationsLoading || isQueriesLoading;

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<EmployeeTypesFormValues>({
    resolver: zodResolver(employeeTypesFormSchema),
    defaultValues: {
      employeeTypes: [
        { id: undefined, name: "", group: 1 },
        { id: undefined, name: "", group: 2 },
        { id: undefined, name: "", group: 3 },
        { id: undefined, name: "", group: 4 },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "employeeTypes",
    keyName: "uuid",
  });

  const getEmployeeInitialFormValues = useCallback(() => {
    if (!employeeTypes) return [];
    return employeeTypes.map((employeeType) => ({
      id: employeeType.id,
      name: employeeType.name,
      group: employeeType.group.id,
    }));
  }, [employeeTypes]);

  const getAdminGroupId = () => {
    if (!groups) return;
    return groups.find((group) => group.name === "Admin")?.id;
  };

  const removeEmptyFields = async () => {
    return fields.forEach((field, index) => {
      if (!field.name) {
        remove(index);
      }
    });
  };

  const verifyAdminEmployeeTypeExists = (data: EmployeeTypesFormValues) => {
    removeEmptyFields();
    const adminGroupId = getAdminGroupId();
    return data.employeeTypes.some(
      (field) => String(field.group) === String(adminGroupId)
    );
  };

  const onSubmit = async (data: EmployeeTypesFormValues) => {
    const adminEmployeeTypeExists = verifyAdminEmployeeTypeExists(data);

    if (!adminEmployeeTypeExists) {
      alert.error("Please make sure an Admin group exists.");
      return;
    }

    const promises = data.employeeTypes.map((employeeType) => {
      if (employeeType.id)
        return updateEmployeeTypeMutation.mutateAsync(employeeType);
      return createEmployeeTypeMutation.mutateAsync(employeeType);
    });

    try {
      await Promise.all(promises);
      alert.success("Employee types saved successfully!");
      onSuccessfulSaveCallback?.();
    } catch (error) {
      alert.error("Failed to update employee types!");
      queryClient.invalidateQueries(["departments"]);
    }
  };

  const handleAddEmployeeType = () =>
    append({ id: undefined, name: "", group: 1 });

  const handleRemoveField = (id: number, index: number) => {
    const lengthOfFields = fields.length;
    if (lengthOfFields === 4) {
      alert.error("You must have at least one employee type in each group.");
      return;
    }
    if (!id) {
      return remove(index);
    }
    deleteEmployeeTypeMutation.mutate({ id });
  };

  useEffect(() => {
    if (isQueriesLoading || !employeeTypes?.length) return;
    reset({ employeeTypes: getEmployeeInitialFormValues() });
  }, [isQueriesLoading, employeeTypes, getEmployeeInitialFormValues, reset]);

  useEffect(() => {
    if (localStorage.getItem("showSetUpRolesSteps")) return;
  }, [employeeTypes]);

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      style={{ padding: "10px", flex: 1 }}
    >
      {!isQueriesLoading && fields?.length > 0 ? (
        <JoyrideWrapper steps={steps} storageKey="hideRoleFormSteps" />
      ) : null}
      {isQueriesLoading ? (
        <Skeleton height={40} count={10} style={{ margin: "5px 0" }} />
      ) : (
        <>
          {fields.map((field, index) => (
            <React.Fragment key={field.uuid}>
              <FormRow>
                <Container>
                  <InputLabel
                    className={inputStyles.inputLabel}
                    labelIcon={<RoleSmallIcon />}
                  >
                    Employee Type
                  </InputLabel>
                  <Controller
                    name={`employeeTypes.${index}.name`}
                    control={control}
                    render={({ field }) => (
                      <DynamicFormInput
                        {...field}
                        id={`input${index}`}
                        type="text"
                        placeholder="Enter Employee Type"
                        required
                        error={errors.employeeTypes?.[index]?.name?.message}
                        inputClassName={inputStyles.darkInput}
                        containerClassName={inputStyles.inputContainer}
                        errorClassName={inputStyles.darkInputError}
                      />
                    )}
                  />
                </Container>
                <Container>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <InputLabel
                      className={inputStyles.inputLabel}
                      labelIcon={<OrganizationSmallIcon />}
                      action={undefined}
                    >
                      Assign Group
                    </InputLabel>
                    <Button
                      id={field.id}
                      onClick={() =>
                        handleRemoveField(field.id as number, index)
                      }
                      style={{
                        all: "unset",
                        marginLeft: "auto",
                        cursor: "pointer",
                      }}
                    >
                      <CloseSmallIcon />
                    </Button>
                  </div>
                  <Controller
                    name={`employeeTypes.${index}.group`}
                    control={control}
                    render={({ field }) => (
                      <select
                        {...field}
                        className={inputStyles.darkInput}
                        id={`group${index}`}
                      >
                        {groups?.length &&
                          groups.map((group) => (
                            <option key={group.name} value={group.id}>
                              {group.name}
                            </option>
                          ))}
                      </select>
                    )}
                  />
                </Container>
              </FormRow>
            </React.Fragment>
          ))}
          <Container style={{ marginLeft: "auto", padding: "10px 0" }}>
            <button
              id={"addButton"}
              className={inputStyles.addEmployeeTypeButton}
              onClick={handleAddEmployeeType}
            >
              +
            </button>
          </Container>
        </>
      )}
      <ButtonsContainer>
        {onCancelCallback ? (
          <Button
            type="button"
            disabled={isQueriesLoading}
            color={ButtonColors.GrayAndYellow}
            onClick={onCancelCallback}
          >
            {isCombinedLoading ? (
              <RotatingLines
                strokeColor="#f1b70c"
                strokeWidth="5"
                animationDuration="0.75"
                width="20"
                visible={true}
              />
            ) : (
              "Cancel"
            )}
          </Button>
        ) : null}
        <Button
          type="submit"
          color={ButtonColors.Yellow}
          disabled={isQueriesLoading}
        >
          {isCombinedLoading ? (
            <RotatingLines
              strokeColor="#000"
              strokeWidth="5"
              animationDuration="0.75"
              width="20"
              visible={true}
            />
          ) : (
            "Save"
          )}
        </Button>
      </ButtonsContainer>
    </Form>
  );
}
