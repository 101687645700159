import { createSlice } from "@reduxjs/toolkit";

enum PageHeaders {
  DASHBOARD = "Dashboard",
  LEARNING_PATHS = "Learning Paths",
  LEARNING_PATH_REPORTS = "Learning Path Reports",
  DISCUSSIONS = "Discussions",
  VIDEOS = "Videos",
  MEMOS = "Memos",
  POLICIES = "Policies",
  ASSESSEMENTS = "Assessments",
  ORGANIZATION = "Organization",
  SETTINGS = "Settings",
}

export type HeaderState = {
  pageHeader: PageHeaders | null;
};

const initialState: HeaderState = {
  pageHeader: null,
};

const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    setPageHeader: (state, action) => {
      state.pageHeader = action.payload;
    },
  },
});

export const { setPageHeader } = headerSlice.actions;
export default headerSlice.reducer;
