import { PaginatedApiResponse } from "../axios/axios.types";
import axiosClient from "../axios/client";
import { MULTIPART_FORM_HEADERS } from "../axios/headers";
import {
  ROUTE_API_EMPLOYEE,
  ROUTE_API_EMPLOYEES,
  ROUTE_API_EMPLOYEES_INACTIVE,
  ROUTE_API_EMPLOYEE_DASHBOARD_GRAPHS,
  ROUTE_API_EMPLOYEE_STATUS,
} from "../site/routes";
import { UpdateEmployeeFormData } from "./hooks/useEmployeeImageMutation";
import { AuthenticatedEmployee, EmployeeLogin } from "./types/employees.type";

class EmployeeService {
  async getEmployee(id: number | string) {
    return await axiosClient
      .get(`${ROUTE_API_EMPLOYEES}/${id}/`)
      .then((res) => res.data);
  }

  async getEmployees(): Promise<AuthenticatedEmployee[]> {
    return await axiosClient.get("/api/employees").then((res) => res.data);
  }

  async getEmployeeLoginsPaginated(
    { employee_id, page = 1, sortBy, sortDirection, ...filters } = {} as any
  ): Promise<PaginatedApiResponse<EmployeeLogin>> {
    return await axiosClient.get("/api/employee-logins/", {
      params: {
        employee_id,
        page,
        sortBy,
        sortDirection,
        ...filters,
      },
    });
  }

  async getInactiveEmployees() {
    return await axiosClient
      .get(`${ROUTE_API_EMPLOYEES_INACTIVE}`)
      .then((res) => res.data);
  }

  async getEmployeeDashboardGraphData(id?: string | number) {
    return await axiosClient
      .get(`${ROUTE_API_EMPLOYEE_DASHBOARD_GRAPHS}/${id}/`)
      .then((res) => res.data);
  }

  async getEmployeesDashboardGraphData() {
    return await axiosClient
      .get(`${ROUTE_API_EMPLOYEE_DASHBOARD_GRAPHS}/`)
      .then((res) => res.data);
  }

  async getEmployeeAISummary(id: number | string) {
    return await axiosClient
      .get(`/api/employee-summary/`, {
        params: { id: id },
      })
      .then((res) => res.data);
  }

  async getSupervisors(): Promise<AuthenticatedEmployee[]> {
    return await axiosClient.get("/api/supervisors/").then((res) => res.data);
  }

  // TODO: When updating an employee, shouldn't we just do updateEmployee and submit everything? or patch?
  async updateEmployeeImage({ id, formData }: UpdateEmployeeFormData) {
    return await axiosClient
      .patch(`${ROUTE_API_EMPLOYEE}/${id}/upload-image/`, formData, {
        headers: MULTIPART_FORM_HEADERS,
      })
      .then((res) => res.data);
  }

  async updateEmployeeStatus(id: number | string) {
    return await axiosClient
      .put(`${ROUTE_API_EMPLOYEE_STATUS}/${id}/`)
      .then((res) => res.data);
  }

  async updateUsername({
    id,
    username,
  }: {
    id: number | string;
    username: string;
  }) {
    return await axiosClient
      .patch(`${ROUTE_API_EMPLOYEES}/${id}/`, { username })
      .then((res) => res.data);
  }
}

const employeeService = new EmployeeService();

export default employeeService;
