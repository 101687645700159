function formatDateToString(
  dateString?: Date | string,
  includeHours: boolean = true,
  includeMinutes: boolean = true
) {
  if (!dateString) {
    return "";
  }
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(dateString);
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  let year = ("" + date.getFullYear()).slice(-2);

  let timeString = "";

  if (includeHours || includeMinutes) {
    const hours = date.getHours();
    const amPm = hours >= 12 ? "PM" : "AM";
    const hours12 = includeHours ? hours % 12 || 12 : 0;

    const minutes = includeMinutes
      ? date.getMinutes().toString().padStart(2, "0")
      : "00";

    timeString = ` ${hours12}:${minutes}${amPm}`;
  }

  return `${month} ${day}, ${year}${timeString}`;
}

export default formatDateToString;
