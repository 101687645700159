import { useQuery } from "@tanstack/react-query";
import scheduleSetupService from "../services/scheduleSetupService";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

export default function useScheduleSetupStateQuery() {
  return useQuery({
    queryFn: scheduleSetupService.getScheduleSetups,
    queryKey: [QueryKeys.SchedulesSetupList],
    refetchOnWindowFocus: false,
  });
}
