import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { addDays, startOfWeek } from "date-fns";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { MdDeleteForever } from "react-icons/md";
import { RotatingLines } from "react-loader-spinner";
import Skeleton from "react-loading-skeleton";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import Dialog from "src/components/Dialog/Dialog";
import DialogContent from "src/components/Dialog/DialogContent";
import DialogDescription from "src/components/Dialog/DialogDescription";
import DialogFooter from "src/components/Dialog/DialogFooter";
import DialogHeader from "src/components/Dialog/DialogHeader";
import EmployeeImage from "src/components/EmployeeImage/EmployeeImage";
import ErrorFallback from "src/components/Errors/ErrorFallback";
import InputContainerV2 from "src/components/Inputs/InputContainerV2";
import InputLabel from "src/components/Inputs/InputLabel";
import LocalSearchInput from "src/components/Inputs/LocalSearchInput";
import Placeholder from "src/components/Placeholder/Placeholder";
import Tab from "src/components/Tabs/Tab";
import TabNavigation from "src/components/Tabs/TabNavigation";
import { DATETIME_FORMAT } from "src/features/datetime/dateOptions";
import formatDateRange from "src/features/datetime/utils/formatDateRange";
import getFullName from "src/features/employees/utils/getFullName";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import { z } from "zod";
import useCreateEmployeeShiftMutation from "../hooks/useCreateEmployeeShiftMutation";
import useDeleteEmployeeShiftMutation from "../hooks/useDeleteEmployeeShiftMutation";
import useGetAvailableEmployeeScheduleRolesQuery from "../hooks/useGetAvailableEmployeeScheduleRoles";
import useGetEmployeeShiftsCancelledOrPendingQuery from "../hooks/useGetEmployeeShiftsCancelledOrPendingQuery";
import useGetFulfilledShiftsListQuery from "../hooks/useGetFulfilledEmployeeShiftsListQuery";
import useGetScheduleRolesQuery from "../hooks/useGetScheduleRolesQuery";
import useGetShiftTransfersByDatesQuery from "../hooks/useGetShiftTransfersByStartDate";
import useGetTimeOffRequestsByScheduleRoleQuery from "../hooks/useGetTimeOffRequestsByScheduleRoleQuery";
import usePartialUpdateEmployeeShiftMutation from "../hooks/usePartialUpdateEmployeeShiftMutation";
import usePartialUpdateShiftRequirementMutation from "../hooks/usePartialUpdateShiftRequirementMutation";
import { EmployeeScheduleRoleType } from "../services/employeeScheduleRolesService";
import {
  EmployeeShift,
  EmployeeShiftStatus,
} from "../services/employeeShiftsService";
import {
  ShiftRequirementStatus,
  ShiftRequirementType,
} from "../services/shiftRequirementsService";
import {
  ShiftTransfer,
  ShiftTransferStatus,
  ShiftTransferType,
} from "../services/shiftTransferService";
import {
  TimeOffRequest,
  TimeOffStatus,
} from "../services/timeOffRequestService";
import ShiftRequirementDeleteDialog from "./ShiftRequirementDeleteDialog";
import ShiftTransferDecisionDialog from "./ShiftTransferDecisionDialog";
import ShiftTransferPickupDialog from "./ShiftTransferPickupDialog";
import TimeOffRequestDecisionDialog from "./TimeOffRequestDecisionDialog";
import TimeOffStatusBadge from "./TimeOffStatusBadge";

const shiftRequirementTypeSchema = z.object({
  id: z.number().or(z.string()),
  required_count: z.union([
    z.number().min(1, "Required Employees is required"),
    z.string().refine(
      (value) => {
        const parsed = Number(value);
        return !isNaN(parsed) && Number.isInteger(parsed) && parsed >= 1;
      },
      {
        message: "Required Employees must be a whole number and at least 1",
      }
    ),
  ]),
});

export type ShiftRequirementPartialUpdateTypeForm = z.infer<
  typeof shiftRequirementTypeSchema
>;

type ShiftDetailsDialogProps = {
  shift: ShiftRequirementType | null;
  setShift: (shift: ShiftRequirementType | null) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export default function ShiftRequirementPlanningDialog({
  shift,
  setShift,
  isOpen,
  setIsOpen,
}: ShiftDetailsDialogProps) {
  const queryClient = useQueryClient();
  const [filledSearchInput, setFilledSearchInput] = useState<string>("");
  const [droppedSearchInput, setDroppedSearchInput] = useState<string>("");
  const [availableSearchinput, setAvailableSearchinput] = useState<string>("");
  const [timeOffSearchInput, setTimeOffSearchInput] = useState<string>("");
  const [isDeleteShiftDialogOpen, setIsDeleteShiftDialogOpen] = useState(false);
  const [isPickUpShiftDialogOpen, setIsPickUpShiftDialogOpen] = useState(false);
  const [pickUpShiftTransfer, setPickUpShiftTransfer] =
    useState<ShiftTransfer | null>(null);
  const [selectedShiftTransfer, setSelectedShiftTransfer] =
    useState<ShiftTransfer | null>(null);
  const [isTransferDialogOpen, setIsTransferDialogOpen] = useState(false);
  const [
    isTimeOffRequestDecisionDialogOpen,
    setIsTimeOffRequestDecisionDialogOpen,
  ] = useState(false);
  const [selectedTimeOffRequest, setSelectedTimeOffRequest] =
    useState<TimeOffRequest | null>(null);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ShiftRequirementPartialUpdateTypeForm>({
    resolver: zodResolver(shiftRequirementTypeSchema),
    defaultValues: {
      id: shift?.id || undefined,
      required_count: shift?.required_count || 1,
    },
  });

  const {
    data: availableEmployeeScheduleRoles,
    isLoading: isAvailableEmployeeScheduleRolesLoading,
    isError: isAvailableEmployeeScheduleRolesError,
    refetch: refetchAvailableEmployeeScheduleRoles,
  } = useGetAvailableEmployeeScheduleRolesQuery({
    scheduleRole: shift?.schedule_role,
    shift,
    search: availableSearchinput,
  });

  const { data: scheduleRoles, isLoading: isScheduleRolesLoading } =
    useGetScheduleRolesQuery({
      departmentId: shift?.department,
    });

  const {
    data: timeOffRequests,
    isLoading: isTimeOffRequestsLoading,
    isError: isTimeOffRequestsError,
    refetch: refetchTimeOffRequests,
  } = useGetTimeOffRequestsByScheduleRoleQuery({
    startDate: shift?.start_datetime
      ? new Date(shift?.start_datetime).toISOString().slice(0, 10)
      : undefined,
    endDate: shift?.end_datetime
      ? new Date(shift?.end_datetime).toISOString().slice(0, 10)
      : undefined,
    scheduleRole: shift?.schedule_role,
    search: timeOffSearchInput,
  });

  const {
    data: employeeShifts,
    isLoading: isEmployeeShiftsLoading,
    isError: isEmployeeShiftsError,
    refetch: refetchEmployeeShifts,
  } = useGetFulfilledShiftsListQuery({
    scheduleRoleId: shift?.schedule_role,
    shift,
    search: filledSearchInput,
  });

  const {
    data: employeeShiftsCancelledOrPending,
    isLoading: isLoadingCancelledOrPending,
    isError: isErrorCancelledOrPending,
    refetch: refetchEmployeeShiftsCancelledOrPending,
  } = useGetEmployeeShiftsCancelledOrPendingQuery({
    scheduleRoleId: shift?.schedule_role,
    shift,
  });

  const {
    data: shiftTransfers,
    isLoading: isShiftTransfersLoading,
    isError: isShiftTransfersError,
    refetch: refetchShiftTransfers,
  } = useGetShiftTransfersByDatesQuery({
    startDate: startOfWeek(new Date(), { weekStartsOn: 0 })
      .toISOString()
      .slice(0, 10),
    endDate: addDays(startOfWeek(new Date(), { weekStartsOn: 0 }), 7)
      .toISOString()
      .slice(0, 10),
    status: ShiftTransferStatus.Approved,
    type: ShiftTransferType.ShiftDrop,
  });

  const droppedShiftTransfers = shiftTransfers?.filter(
    (transfer) => transfer.shift_from.shift_requirement.id === shift?.id
  );

  const createEmployeeShiftMutation = useCreateEmployeeShiftMutation();

  const partialUpdateShiftRequirementMutation =
    usePartialUpdateShiftRequirementMutation({
      onSuccess: (data) => {
        queryClient.setQueryData<ShiftRequirementType[]>(
          [QueryKeys.ShiftRequirementsList, data.weekly_schedule],
          (prevData) =>
            prevData?.map((shift) => (shift.id === data.id ? data : shift))
        );
        setIsOpen(false);
      },
    });

  const deleteEmployeeShiftMutation = useDeleteEmployeeShiftMutation({
    scheduleRoleId: shift?.schedule_role,
    departmentId: shift?.department,
  });

  const partialUpdateEmployeeShiftMutation =
    usePartialUpdateEmployeeShiftMutation({
      onSuccess: (data) => {
        queryClient.invalidateQueries<EmployeeShift[]>([
          QueryKeys.EmployeeShiftsList,
          shift?.schedule_role,
        ]);
        queryClient.setQueryData<EmployeeShift[]>(
          [
            QueryKeys.EmployeeShiftsList,
            {
              department: shift?.department,
              weeklySchedule: shift?.weekly_schedule,
            },
          ],
          (prevData) => {
            return prevData?.map((empShift) =>
              empShift.id === data.id ? data : empShift
            );
          }
        );

        // determine if we should add or remove one from the shift_requirement filled_count
        // by the status of the employee shift
        const amountToAdd =
          data.status === EmployeeShiftStatus.Pending ? 1 : -1;

        queryClient.setQueryData<ShiftRequirementType[]>(
          [QueryKeys.ShiftRequirementsList, shift?.weekly_schedule],
          (prevData) => {
            if (!prevData) return prevData;
            return prevData.map((shiftRequirement) => {
              if (shiftRequirement.id === shift?.id) {
                return {
                  ...shiftRequirement,
                  filled_count: shiftRequirement.filled_count + amountToAdd,
                  status: ShiftRequirementStatus.Pending,
                };
              }
              return shiftRequirement;
            });
          }
        );
      },
    });

  const handleScheduleEmployee = (role: EmployeeScheduleRoleType) => {
    if (areShiftMutationsLoading) return;

    if (!shift?.id) return;

    const employeeShift = {
      employeeScheduleRoleId: role?.id,
      shiftRequirementId: shift?.id,
      scheduleRoleId: shift?.schedule_role,
      weeklyScheduleId: shift?.weekly_schedule,
      departmentId: shift?.department,
    };

    // if an employeeShiftPendingCancellation.employee_schedule_role matches
    // the role.id we want to update the employee shift status to "Pending"
    const shiftsCancelled = employeeShiftsCancelledOrPending?.find(
      (empShift) => empShift.employee_schedule_role.id === role.id
    );

    if (shiftsCancelled) {
      partialUpdateEmployeeShiftMutation.mutate({
        id: shiftsCancelled.id,
        status: EmployeeShiftStatus.Pending,
      });
      return;
    }
    createEmployeeShiftMutation.mutate(employeeShift);
  };

  const areShiftMutationsLoading =
    createEmployeeShiftMutation.isLoading ||
    partialUpdateEmployeeShiftMutation.isLoading ||
    deleteEmployeeShiftMutation.isLoading;

  const handleCancelEmployeeShift = (employeeShift: EmployeeShift) => {
    if (areShiftMutationsLoading) return;
    if (!shift?.id || !shift?.weekly_schedule) return;
    // if the shift is pending, we want to delete the employee shift.
    if (employeeShift.status === EmployeeShiftStatus.Pending) {
      deleteEmployeeShiftMutation.mutate({
        id: employeeShift.id,
        weeklyScheduleId: shift?.weekly_schedule,
        shiftRequirementId: shift?.id,
      });
      return;
    }
    // if the shift is published, we want to set the status to "PendingCancellation"
    // to indicate we still need to notify the employee when the schedule is published.
    partialUpdateEmployeeShiftMutation.mutate({
      id: employeeShift.id,
      status: EmployeeShiftStatus.PendingCancellation,
    });
  };

  const handleOpenDeleteShiftDialog = () => setIsDeleteShiftDialogOpen(true);

  const handleOpenTimeOffRequestDecisionDialog = (e: React.MouseEvent) => {
    const id = e.currentTarget.id;
    setIsTimeOffRequestDecisionDialogOpen(true);
    setSelectedTimeOffRequest(
      timeOffRequests?.find((request) => request.id === Number(id)) || null
    );
  };

  const handleCloseDecisionDialog = () => {
    setIsTimeOffRequestDecisionDialogOpen(false);
    setSelectedTimeOffRequest(null);
  };

  const handleCloseShiftRequirementDialog = () => {
    setShift(null);
    setIsOpen(false);
  };

  const handleOpenTransferDialog = (transfer: ShiftTransfer) => {
    setSelectedShiftTransfer(transfer);
    setIsTransferDialogOpen(true);
  };

  const handlePickUpShift = (transfer: ShiftTransfer) => {
    setPickUpShiftTransfer(transfer);
    setIsPickUpShiftDialogOpen(true);
  };

  const handleChangeAvailableSearchInput = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => setAvailableSearchinput(e.target.value);

  const handleChangeFilledSearchInput = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => setFilledSearchInput(e.target.value);

  const handleChangeTimeOffSearchInput = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => setTimeOffSearchInput(e.target.value);

  const handleChangeDroppedSearchInput = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => setDroppedSearchInput(e.target.value);

  const onSubmit = (data: ShiftRequirementPartialUpdateTypeForm) =>
    partialUpdateShiftRequirementMutation.mutate({ data });

  const dialogDescription = `${
    scheduleRoles?.find((role) => role.id === shift?.schedule_role)?.role
      .name || ""
  } - ${shift?.name}`;

  const approvedTimeOffRequests = timeOffRequests?.filter(
    (request) => request.status === TimeOffStatus.Approved
  );

  const filteredAvailableEmployeeScheduleRoles =
    availableEmployeeScheduleRoles?.filter(
      (role) =>
        !approvedTimeOffRequests?.some(
          (request) => request.employee?.id === role.employee.id
        )
    );

  return (
    <>
      <Dialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={() => setShift(null)}
      >
        <DialogHeader>Shift Details</DialogHeader>
        <DialogDescription>
          {isScheduleRolesLoading ? (
            <Skeleton width={200} />
          ) : (
            dialogDescription
          )}
          <MdDeleteForever
            className="cursor-pointer"
            color="var(--clearpath-danger)"
            size={24}
            onClick={handleOpenDeleteShiftDialog}
          />
        </DialogDescription>
        <DialogDescription>
          {shift?.start_datetime &&
            shift?.end_datetime &&
            formatDateRange(
              shift?.start_datetime,
              shift?.end_datetime,
              "en-US",
              DATETIME_FORMAT
            )}
        </DialogDescription>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <TabNavigation id={"shiftDetailsTabs"} updateSearchParams={false}>
              <Tab title={"Settings"}>
                <InputContainerV2>
                  <InputLabel>Required Employees</InputLabel>
                  <input
                    type="number"
                    step="1"
                    min="0"
                    placeholder="Required Employees"
                    {...register("required_count")}
                    className={
                      errors?.required_count ? "inputBlackError" : "inputBlack"
                    }
                  />
                  {errors?.required_count && (
                    <span className={"inputError"}>
                      {errors.required_count.message}
                    </span>
                  )}
                </InputContainerV2>
              </Tab>
              <Tab
                title={"Filled"}
                badge={
                  <span className="badge-round">
                    {employeeShifts?.length || 0}
                  </span>
                }
              >
                {isEmployeeShiftsError ? (
                  <ErrorFallback onReload={refetchEmployeeShifts} />
                ) : null}
                {!isEmployeeShiftsLoading && !isEmployeeShiftsError ? (
                  <div className="mb-10">
                    <LocalSearchInput
                      placeholder="Search"
                      onChange={handleChangeFilledSearchInput}
                      value={filledSearchInput}
                    />
                  </div>
                ) : null}
                <div className="flex h-40vh overflow-y-auto gap-5 border-light-gray p-5 border-radius-10 bg-cp-black-50">
                  {!isEmployeeShiftsLoading &&
                    !isEmployeeShiftsError &&
                    employeeShifts &&
                    employeeShifts.length > 0 &&
                    employeeShifts.map((shift) => (
                      <div
                        key={shift.id}
                        className="p-5 flex-row align-center gap-10 border-radius-5 bg-dark-gray-hover font-w-500 cursor-pointer text-sm"
                        onClick={() => handleCancelEmployeeShift(shift)}
                      >
                        <EmployeeImage
                          alt={"Employee Image"}
                          src={shift.employee_schedule_role.employee.image}
                          width={25}
                          height={25}
                          containerStyle={{
                            width: "25px",
                            height: "25px",
                          }}
                        />
                        {getFullName(shift.employee_schedule_role.employee)}
                        <div className="flex flex-1 align-end text-xs p-5">
                          {!areShiftMutationsLoading ? (
                            shift.status
                          ) : (
                            <RotatingLines
                              strokeColor="#f1b70c"
                              strokeWidth="5"
                              animationDuration="0.75"
                              width="15"
                              visible={true}
                            />
                          )}
                        </div>
                      </div>
                    ))}
                  {!isEmployeeShiftsLoading &&
                    employeeShifts &&
                    employeeShifts.length === 0 && (
                      <div className="flex-1 centered">
                        <Placeholder title={"No positions filled"} />
                      </div>
                    )}
                  {isEmployeeShiftsLoading && (
                    <Skeleton count={10} height={30} />
                  )}
                </div>
              </Tab>
              <Tab
                title={"Available"}
                badge={
                  <span className="badge-round">
                    {filteredAvailableEmployeeScheduleRoles?.length || 0}
                  </span>
                }
              >
                {isAvailableEmployeeScheduleRolesError ? (
                  <ErrorFallback
                    onReload={refetchAvailableEmployeeScheduleRoles}
                  />
                ) : null}
                {isErrorCancelledOrPending ? (
                  <ErrorFallback
                    onReload={refetchEmployeeShiftsCancelledOrPending}
                  />
                ) : null}
                {!isAvailableEmployeeScheduleRolesLoading &&
                !isAvailableEmployeeScheduleRolesError ? (
                  <div className="mb-10">
                    <LocalSearchInput
                      placeholder="Search"
                      onChange={handleChangeAvailableSearchInput}
                      value={availableSearchinput}
                    />
                  </div>
                ) : null}
                <div className="flex h-40vh overflow-y-auto gap-5 border-light-gray p-5 border-radius-10 bg-cp-black-50">
                  {!isAvailableEmployeeScheduleRolesLoading &&
                    !isLoadingCancelledOrPending &&
                    filteredAvailableEmployeeScheduleRoles &&
                    filteredAvailableEmployeeScheduleRoles?.length > 0 &&
                    filteredAvailableEmployeeScheduleRoles.map((role) => (
                      <div
                        className="p-5 flex-row align-center gap-10 border-radius-5 bg-dark-gray-hover font-w-500 cursor-pointer text-sm"
                        onClick={() => handleScheduleEmployee(role)}
                        key={role.id}
                      >
                        <EmployeeImage
                          alt={"Employee Image"}
                          src={role.employee.image}
                          width={25}
                          height={25}
                          containerStyle={{
                            width: "25px",
                            height: "25px",
                          }}
                        />
                        {getFullName(role.employee)}
                        <div className="flex flex-1 align-end text-xs p-5">
                          {areShiftMutationsLoading ? (
                            <RotatingLines
                              strokeColor="#f1b70c"
                              strokeWidth="5"
                              animationDuration="0.75"
                              width="15"
                              visible={true}
                            />
                          ) : null}
                        </div>
                      </div>
                    ))}
                  {isAvailableEmployeeScheduleRolesLoading && (
                    <Skeleton count={10} height={30} />
                  )}
                  {!isAvailableEmployeeScheduleRolesLoading &&
                    filteredAvailableEmployeeScheduleRoles &&
                    filteredAvailableEmployeeScheduleRoles.length === 0 && (
                      <div className="flex-1 centered">
                        <Placeholder title={"No available employees"} />
                      </div>
                    )}
                </div>
              </Tab>
              <Tab
                title={"Dropped"}
                badge={
                  <span className="badge-round">
                    {droppedShiftTransfers?.length || 0}
                  </span>
                }
              >
                {isShiftTransfersError ? (
                  <ErrorFallback onReload={refetchShiftTransfers} />
                ) : null}
                {!isShiftTransfersLoading && !isShiftTransfersError ? (
                  <div className="mb-10">
                    <LocalSearchInput
                      placeholder="Search"
                      onChange={handleChangeDroppedSearchInput}
                      value={droppedSearchInput}
                    />
                  </div>
                ) : null}
                <div className="flex h-40vh overflow-y-auto gap-5 border-light-gray p-5 border-radius-10 bg-cp-black-50">
                  {!isShiftTransfersLoading &&
                    !isShiftTransfersError &&
                    droppedShiftTransfers &&
                    droppedShiftTransfers.length > 0 &&
                    droppedShiftTransfers.map((transfer) => (
                      <div
                        key={transfer.id}
                        className="p-5 flex-row align-center gap-10 border-radius-5 bg-dark-gray-hover font-w-500 cursor-pointer text-sm"
                        onClick={() => handleOpenTransferDialog(transfer)}
                      >
                        <EmployeeImage
                          alt={"Employee Image"}
                          src={
                            transfer.shift_from.employee_schedule_role.employee
                              .image
                          }
                          width={25}
                          height={25}
                          containerStyle={{
                            width: "25px",
                            height: "25px",
                          }}
                        />
                        {getFullName(
                          transfer.shift_from.employee_schedule_role.employee
                        )}
                        <div className="flex flex-1 align-end text-xs p-5">
                          {transfer.status}
                        </div>
                      </div>
                    ))}
                  {!isShiftTransfersLoading &&
                    droppedShiftTransfers &&
                    droppedShiftTransfers.length === 0 && (
                      <div className="flex-1 centered">
                        <Placeholder title={"No dropped shifts"} />
                      </div>
                    )}
                  {isShiftTransfersLoading && (
                    <Skeleton count={10} height={30} />
                  )}
                </div>
              </Tab>
              <Tab
                title={"Time Off"}
                badge={
                  <span className="badge-round">
                    {approvedTimeOffRequests?.length || 0}
                  </span>
                }
              >
                {isTimeOffRequestsError ? (
                  <ErrorFallback onReload={refetchTimeOffRequests} />
                ) : null}
                {!isTimeOffRequestsLoading && !isTimeOffRequestsError ? (
                  <div className="mb-10">
                    <LocalSearchInput
                      placeholder="Search"
                      onChange={handleChangeTimeOffSearchInput}
                      value={availableSearchinput}
                    />
                  </div>
                ) : null}
                <div className="flex h-40vh overflow-y-auto gap-5 border-light-gray p-5 border-radius-10 bg-cp-black-50">
                  {!isTimeOffRequestsLoading &&
                    timeOffRequests &&
                    timeOffRequests?.length > 0 &&
                    timeOffRequests.map((request) => (
                      <div
                        className="p-5 flex-row align-center gap-10 border-radius-5 bg-dark-gray font-w-500 cursor-pointer text-sm"
                        key={request.id}
                        id={request.id.toString()}
                        onClick={handleOpenTimeOffRequestDecisionDialog}
                      >
                        <EmployeeImage
                          alt={"Employee Image"}
                          src={request.employee?.image || ""}
                          width={25}
                          height={25}
                          containerStyle={{
                            width: "25px",
                            height: "25px",
                          }}
                        />
                        {getFullName(request.employee)}
                        <div className="flex flex-1 align-end text-xs p-5">
                          <TimeOffStatusBadge status={request.status} />
                        </div>
                      </div>
                    ))}
                  {isTimeOffRequestsLoading && (
                    <Skeleton count={10} height={30} />
                  )}
                  {!isTimeOffRequestsLoading &&
                    timeOffRequests &&
                    timeOffRequests.length === 0 && (
                      <div className="flex-1 centered">
                        <Placeholder title={"All employees are available."} />
                      </div>
                    )}
                </div>
              </Tab>
            </TabNavigation>
          </DialogContent>
          <DialogFooter>
            <Button color={ButtonColors.Yellow} type="submit">
              Save Changes
            </Button>
          </DialogFooter>
        </form>
      </Dialog>
      {pickUpShiftTransfer ? (
        <ShiftTransferPickupDialog
          isOpen={isPickUpShiftDialogOpen}
          setIsOpen={setIsPickUpShiftDialogOpen}
          transfer={pickUpShiftTransfer}
        />
      ) : null}
      {selectedShiftTransfer ? (
        <ShiftTransferDecisionDialog
          transfer={selectedShiftTransfer}
          onPickUpShift={handlePickUpShift}
          isOpen={isTransferDialogOpen}
          setIsOpen={setIsTransferDialogOpen}
        />
      ) : null}
      {shift ? (
        <ShiftRequirementDeleteDialog
          isOpen={isDeleteShiftDialogOpen}
          setIsOpen={setIsDeleteShiftDialogOpen}
          onClose={handleCloseShiftRequirementDialog}
          shiftRequirement={shift}
        />
      ) : null}
      {selectedTimeOffRequest ? (
        <TimeOffRequestDecisionDialog
          timeOffRequest={selectedTimeOffRequest}
          isOpen={isTimeOffRequestDecisionDialogOpen}
          setIsOpen={setIsTimeOffRequestDecisionDialogOpen}
          onClose={handleCloseDecisionDialog}
        />
      ) : null}
    </>
  );
}
