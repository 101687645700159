import { useMutation } from "@tanstack/react-query";
import { EmployeeScheduleRoleFormDataType } from "../components/VerifyEmployeeScheduleRolesForm";
import employeeScheduleRolesService from "../services/employeeScheduleRolesService";

type PartialUpdateEmployeeScheduleRolesMutationType = {
  onSuccess?: () => void;
};

export default function usePartialUpdateEmployeeScheduleRolesMutation({
  onSuccess,
}: PartialUpdateEmployeeScheduleRolesMutationType) {
  return useMutation({
    mutationFn: (data: EmployeeScheduleRoleFormDataType) =>
      employeeScheduleRolesService.partialUpdateEmployeeScheduleRole(
        data.id,
        data
      ),
    onSuccess,
  });
}
