import { format } from "date-fns";
import { useState } from "react";
import Button from "src/components/Buttons/Button";
import {
  ButtonColors,
  ButtonSizes,
} from "src/components/Buttons/buttons.types";
import cn from "src/features/utils/cn";
import {
  EmployeeShift,
  EmployeeShiftStatus,
} from "../services/employeeShiftsService";
import EmployeeShiftStatusBadge from "./EmployeeShiftStatusBadge";
import ShiftDropConfirmDialog from "./ShiftDropConfirmDialog";
import ShiftTransferCancelDialog from "./ShiftTransferCancelDialog";
import ShiftTransferSwapDialog from "./ShiftTransferSwapDialog";

const getEmployeeShiftCardStatusBorder = (status: EmployeeShiftStatus) => {
  switch (status) {
    case EmployeeShiftStatus.Published:
    case EmployeeShiftStatus.PickedUp:
      return "";
    case EmployeeShiftStatus.Dropped:
      return "border-danger";
    case EmployeeShiftStatus.PendingDrop:
      return "border-blue";
    default:
      return "";
  }
};

type DayEmployeeShiftCardProps = {
  shift: EmployeeShift;
};

export default function EmployeeWeeklyScheduleCard({
  shift,
}: DayEmployeeShiftCardProps) {
  const [isDropDialogOpen, setIsDropDialogOpen] = useState(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [isSwapDialogOpen, setIsSwapDialogOpen] = useState(false);

  const handleClickSwapRequest = () => setIsSwapDialogOpen(true);
  const handleClickDropShift = () => setIsDropDialogOpen(true);
  const handleClickCancelTransfer = () => setIsCancelDialogOpen(true);

  return (
    <div
      key={shift.id}
      className={cn(
        "flex flex-col flex-1 mh-100 bg-dark-gray border-radius-5 p-10 gap-10",
        getEmployeeShiftCardStatusBorder(shift.status)
      )}
    >
      <ShiftTransferSwapDialog
        isOpen={isSwapDialogOpen}
        setIsOpen={setIsSwapDialogOpen}
        shiftFrom={shift}
      />
      <ShiftDropConfirmDialog
        isOpen={isDropDialogOpen}
        setIsOpen={setIsDropDialogOpen}
        shift={shift}
      />
      <ShiftTransferCancelDialog
        isOpen={isCancelDialogOpen}
        setIsOpen={setIsCancelDialogOpen}
        shift={shift}
      />
      <div className="flex flex-1 flex-row">
        <span className="flex flex-1 font-bold text-lg cp-yellow">
          {shift.shift_requirement.name}
        </span>
        <div className="flex justify-start">
          <EmployeeShiftStatusBadge status={shift.status} />
        </div>
      </div>
      <div className="flex flex-1 flex-col gap-10">
        <span className="text-md font-bold">
          {format(new Date(shift.shift_requirement.start_datetime), "hh:mm a")}{" "}
          - {format(new Date(shift.shift_requirement.end_datetime), "hh:mm a")}
        </span>
      </div>
      <div className="flex flex-col">
        {shift.status === EmployeeShiftStatus.Published ? (
          <>
            <div className="flex flex-row justify-end gap-10 mt-5">
              <Button
                size={ButtonSizes.SM}
                color={ButtonColors.GrayAndYellow}
                onClick={handleClickSwapRequest}
                className="border-radius-5"
                title="Request Swap"
              ></Button>
              <Button
                size={ButtonSizes.SM}
                color={ButtonColors.GrayAndYellow}
                onClick={handleClickDropShift}
                className="border-radius-5"
                title="Request Drop"
              ></Button>
            </div>
          </>
        ) : null}
        {shift.status === EmployeeShiftStatus.PendingDrop ? (
          <div className="flex flex-row justify-end gap-10 mt-5">
            <Button
              size={ButtonSizes.SM}
              color={ButtonColors.Gray}
              onClick={handleClickCancelTransfer}
              className="border-radius-5"
              title="Cancel Drop"
            ></Button>
          </div>
        ) : null}
      </div>
    </div>
  );
}
