import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import cn from "src/features/utils/cn";
import Button from "../Buttons/Button";
import { ButtonColors } from "../Buttons/buttons.types";
import CloseIcon from "../Icons/CloseIcon";

let openDialogsCount = 0;

type DialogProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children: React.ReactNode;
  onClose?: () => void;
};

const Dialog = ({
  isOpen: externalIsOpen,
  setIsOpen,
  children,
  onClose,
}: DialogProps) => {
  const dialogRoot = document.getElementById("dialog-root");
  const [internalIsOpen, setInternalIsOpen] = useState(externalIsOpen);
  const [isAnimatingOut, setIsAnimatingOut] = useState(false);
  const [zIndex, setZIndex] = useState(1000); // Base z-index for the dialog
  const closeTimeoutId = useRef<number | null>(null);

  const incrementOpenDialogsCount = () => {
    openDialogsCount++;
    if (openDialogsCount === 1) {
      document.documentElement.style.overflow = "hidden"; // Prevent scrolling on <html>
      document.body.style.overflow = "hidden"; // Prevent scrolling on <body>
    }
  };

  const decrementOpenDialogsCount = () => {
    openDialogsCount--;
    if (openDialogsCount === 0) {
      document.documentElement.style.overflow = ""; // Restore scrolling on <html>
      document.body.style.overflow = ""; // Restore scrolling on <body>
    }
  };

  useEffect(() => {
    if (externalIsOpen) {
      incrementOpenDialogsCount();
      setZIndex(1000 + openDialogsCount * 2);
      setInternalIsOpen(true);
    } else if (!externalIsOpen && internalIsOpen && !isAnimatingOut) {
      setIsAnimatingOut(true);
    }

    return () => {
      if (internalIsOpen) {
        decrementOpenDialogsCount();
      }
    };
  }, [externalIsOpen, internalIsOpen, isAnimatingOut]);

  const onAnimationEnd = useCallback(
    (e: React.AnimationEvent<HTMLDivElement>) => {
      if (e.animationName === "fadeOutScaleDown") {
        if (closeTimeoutId.current) {
          clearTimeout(closeTimeoutId.current);
          closeTimeoutId.current = null;
        }
        decrementOpenDialogsCount();
        setInternalIsOpen(false);
        setIsAnimatingOut(false);
        setIsOpen(false);
        if (onClose) {
          onClose();
        }
      }
    },
    [setIsOpen, onClose]
  );

  const closeDialog = useCallback(() => {
    setIsAnimatingOut(true);
  }, []);

  useEffect(() => {
    if (isAnimatingOut) {
      closeTimeoutId.current = window.setTimeout(() => {
        decrementOpenDialogsCount();
        setInternalIsOpen(false);
        setIsAnimatingOut(false);
        setIsOpen(false);
        if (onClose) {
          onClose();
        }
      }, 100);
      return () => {
        if (closeTimeoutId.current) {
          clearTimeout(closeTimeoutId.current);
          closeTimeoutId.current = null;
        }
      };
    }
  }, [isAnimatingOut, setIsOpen, onClose]);

  const renderDialog = useCallback(
    () => (
      <>
        <div
          className="dialog-overlay"
          style={{ zIndex: zIndex - 1 }}
          onClick={closeDialog}
        />
        <div
          className={cn("dialog", isAnimatingOut ? "dialog-exit" : undefined)}
          style={{ zIndex }}
          onAnimationEnd={onAnimationEnd}
        >
          <div className="dialog-close-button">
            <Button color={ButtonColors.Transparent} onClick={closeDialog}>
              <CloseIcon />
            </Button>
          </div>
          {children}
        </div>
      </>
    ),
    [isAnimatingOut, onAnimationEnd, closeDialog, children, zIndex]
  );

  return internalIsOpen && dialogRoot
    ? ReactDOM.createPortal(renderDialog(), dialogRoot)
    : null;
};

export default Dialog;
