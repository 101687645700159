import "react-loading-skeleton/dist/skeleton.css";
import { Outlet, useLocation } from "react-router-dom";
import PageContainer from "./components/Layout/PageContainer";

type AppProps = {
  style?: React.CSSProperties;
};

export default function App({ style }: AppProps) {
  const location = useLocation();
  const isLanding = location.pathname === "/landing";
  const removeDisplayFlex = isLanding ? { display: "block" } : {};
  return (
    <PageContainer
      style={{
        flexDirection: "unset",
        alignItems: "unset",
        ...removeDisplayFlex,
        ...style,
      }}
    >
      <Outlet />
    </PageContainer>
  );
}
