// Takes in a phone number and returns a normalized version of it.
const normalizePhoneNumber = (newPhoneNum: string, prevPhoneNum: string) => {
  // return nothing if no newPhoneNum
  if (!newPhoneNum) return newPhoneNum;

  // only allows 0-9 inputs
  const phoneNum = newPhoneNum.replace(/[^\d]/g, "");
  const phoneNumLength = phoneNum?.length;

  if (
    !prevPhoneNum ||
    newPhoneNum?.length > prevPhoneNum?.length ||
    prevPhoneNum?.length > 1
  ) {
    // returns: "x", "xx", "xxx"
    if (phoneNumLength < 4) return phoneNum;

    // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
    if (phoneNumLength < 7)
      return `(${phoneNum.slice(0, 3)}) ${phoneNum.slice(3)}`;

    // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
    return `(${phoneNum.slice(0, 3)}) ${phoneNum.slice(3, 6)}-${phoneNum.slice(
      6,
      10
    )}`;
  }
};

export { normalizePhoneNumber };
