import { useQuery } from "@tanstack/react-query";
import todosService from "../../services/todosService";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

type UseGetScheduledTodosDeadlineChoices = {
  enabled?: boolean;
  refetchOnWindowFocus?: boolean;
};

export default function useGetScheduledTodosDeadlineChoicesQuery({
  enabled = true,
  refetchOnWindowFocus = false,
}: UseGetScheduledTodosDeadlineChoices) {
  return useQuery({
    queryKey: [QueryKeys.ScheduledTodoDeadlineChoices],
    queryFn: todosService.getScheduledTodosDeadlineChoices,
    enabled,
    refetchOnWindowFocus,
  });
}
