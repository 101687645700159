import { useEffect, useState } from "react";
import debounce from "lodash/debounce";

export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const debouncedFn = debounce(() => setDebouncedValue(value), delay);
    debouncedFn();
    return () => debouncedFn.cancel();
  }, [value, delay]);

  return debouncedValue;
}
