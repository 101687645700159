import { useAlert } from "@blaumaus/react-alert";
import { useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { useState } from "react";
import Badge from "src/components/Badges/Badge";
import Button from "src/components/Buttons/Button";
import {
  ButtonColors,
  ButtonSizes,
} from "src/components/Buttons/buttons.types";
import getFullName from "src/features/employees/utils/getFullName";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import useCancelEmployeeShiftMutation from "../hooks/useCancelEmployeeShiftMutation";
import {
  ShiftConflict,
  ShiftConflictResolutionStatus,
} from "../services/shiftConflictsService";
import getShiftConflictBadgeColor from "../utils/getShiftConflictBadgeColor";
import ShiftConflictDismissDialog from "./ShiftConflictDismissDialog";

type ShiftConflictCardProps = {
  conflict: ShiftConflict;
};

export default function ShiftConflictCard({
  conflict,
}: ShiftConflictCardProps) {
  const [isDismissDialogOpen, setIsDismissDialogOpen] = useState(false);
  const alert = useAlert();
  const queryClient = useQueryClient();

  const cancelEmployeeShiftMutation = useCancelEmployeeShiftMutation({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.LaborBudgets]);
      queryClient.invalidateQueries([QueryKeys.EmployeeShiftsList]);
      queryClient.invalidateQueries([QueryKeys.ShiftRequirementsList]);
      queryClient.invalidateQueries([QueryKeys.ShiftConflictsList]);
      alert.success("Employee shift has been canceled successfully.");
    },
  });

  const handleCancelShift = (shiftId: number) =>
    cancelEmployeeShiftMutation.mutate({
      id: conflict.id,
      employeeShiftId: shiftId,
    });

  return (
    <div className="bg-cp-black border-radius-10 px-20 flex">
      <ShiftConflictDismissDialog
        isOpen={isDismissDialogOpen}
        setIsOpen={setIsDismissDialogOpen}
        conflict={conflict}
      />
      <div className="flex flex-row gap-10 align-center">
        <h2 className="cp-yellow">
          {conflict.conflict_type.replace(/([A-Z])/g, " $1").trim()}
        </h2>
        <div className="flex flex-1 align-end">
          <Badge
            color={getShiftConflictBadgeColor(conflict.resolution_status)}
            text={conflict.resolution_status}
          />
        </div>
      </div>
      <div className="border-bottom-light-gray" />
      <div className="my-20">
        <div className="flex flex-col gap-10">
          <span className="cp-yellow font-bold">Employee:</span>
          <div className="flex flex-row gap-10">
            <div key={conflict.shifts[0].id} className="flex flex-col gap-10">
              <img
                src={conflict.shifts[0].employee_schedule_role.employee.image}
                alt={getFullName(
                  conflict.shifts[0].employee_schedule_role.employee
                )}
                className="w-40 h-40 border-radius-half"
              />
              <span>
                {getFullName(
                  conflict.shifts[0].employee_schedule_role.employee
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="border-bottom-light-gray" />
      <div className="my-20">
        <div className="flex flex-col gap-10">
          <span className="cp-yellow font-bold">Shifts Involved:</span>
          {conflict.shifts.map((shift) => (
            <>
              <div
                key={shift.id}
                className="flex flex-row gap-10 align-center text-sm"
              >
                <span className="font-bold">
                  {shift.shift_requirement.name}:
                </span>
                <span>
                  {format(
                    new Date(shift.shift_requirement.start_datetime),
                    "PPp"
                  )}{" "}
                  -{" "}
                  {format(
                    new Date(shift.shift_requirement.end_datetime),
                    "PPp"
                  )}
                </span>
                <div className="flex flex-1 flex-row gap-10 justify-end">
                  {conflict.resolution_status ===
                    ShiftConflictResolutionStatus.Unresolved &&
                  new Date(
                    shift.shift_requirement.start_datetime
                  ).toISOString() > new Date().toISOString() ? (
                    <Button
                      className="border-radius-5"
                      color={ButtonColors.GrayAndYellow}
                      size={ButtonSizes.SM}
                      title="Cancel Shift"
                      onClick={() => handleCancelShift(shift.id)}
                    />
                  ) : null}
                </div>
              </div>
            </>
          ))}
        </div>
      </div>

      {conflict?.notes && conflict.notes?.length > 0 && (
        <>
          <div className="border-bottom-light-gray" />
          <div className="my-20 flex flex-col gap-10">
            <span className="cp-yellow font-bold">Notes:</span>
            {conflict.notes.map((note, index) => (
              <span key={index}>- {note}</span>
            ))}
          </div>
        </>
      )}

      {conflict.resolution_status ===
        ShiftConflictResolutionStatus.Unresolved && (
        <>
          <div className="border-bottom-light-gray" />
          <div className="my-20">
            <Button
              className="border-radius-5"
              color={ButtonColors.Yellow}
              onClick={() => setIsDismissDialogOpen(true)}
            >
              Dismiss Conflict
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
