export const steps = [
  {
    disableBeacon: true,
    target: "#setupAdminAccountHeader",
    title: "The last step is to create your Admin account.",
    content:
      "The Admin account has full control over the organization within ClearPath.",
  },
  {
    disableBeacon: true,
    target: "#role",
    title: "Select which Admin role you belong to.",
    content: "These roles were created on the roles page.",
  },
  {
    disableBeacon: true,
    target: "#backButton",
    title: "You can go back at any time.",
    content:
      "If you need to make changes to departments or roles, you can go back by using this button.",
  },
];
