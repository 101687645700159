import { useMutation } from "@tanstack/react-query";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import shiftTypeService from "../services/shiftTypeService";

type DeleteShiftType = {
  onSuccess: () => void;
};

export default function useDeleteShiftTypeMutation({
  onSuccess,
}: DeleteShiftType) {
  const handleErrors = useErrorHandling();
  return useMutation({
    mutationFn: shiftTypeService.deleteShiftType,
    onSuccess,
    onError: handleErrors,
  });
}
