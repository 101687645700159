import StepOneYellowIcon from "src/components/Icons/StepOneYellowIcon";
import useVerticalStepperContent from "src/components/Steps/hooks/useVerticalStepperContent";
import StepEditButton from "src/features/learningPaths/components/LPBuilder/Steps/StepEditButton";
import CheckCircleYellowIcon from "src/components/Icons/CheckCircleYellowIcon";
import { useAppDispatch } from "src/store";
import StepContainer from "src/components/Steps/StepContainer";
import usePolicyFormState from "../../hooks/usePolicyFormState";
import { PolicyFormSteps, setPolicyFormStep } from "../../slices/policySlice";
import PolicyDetailsForm from "./PolicyDetailsForm";

export default function PolicyFormStepOne() {
  const dispatch = useAppDispatch();
  const { step } = usePolicyFormState();

  const handleStepButtonClick = () =>
    dispatch(setPolicyFormStep(PolicyFormSteps.AddDetails));

  return useVerticalStepperContent({
    currentStep: step,
    activeStep: PolicyFormSteps.AddDetails,
    titleAction:
      step !== PolicyFormSteps.AddDetails ? (
        <StepEditButton onClick={handleStepButtonClick} />
      ) : null,
    activeIcon: <StepOneYellowIcon />,
    inactiveIcon: <CheckCircleYellowIcon />,
    defaultContent: (
      <StepContainer>
        {step !== PolicyFormSteps.AddDetails ? (
          <span className="text-muted">This step is complete!</span>
        ) : (
          <span className="text-muted">
            Please enter a title and specify a category for your policy.
          </span>
        )}
      </StepContainer>
    ),
    activeContent: <PolicyDetailsForm />,
  });
}
