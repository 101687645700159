import { Outlet, useLocation, useNavigate } from "react-router-dom";
import PageLayout from "src/components/Layout/PageLayout";
import useSetHeaderTitle from "src/features/header/hooks/useSetHeaderTitle";
import SiteHeader from "src/components/Headers/SiteHeader";
import SearchInput from "src/components/Inputs/SearchInput";
import UnreadDiscussionsIndicator from "src/features/discussions/components/UnreadDiscussionsIndicator";
import Button from "src/components/Buttons/Button";
import hasGroupPermissions from "src/features/auth/utils/hasGroupPermissions";
import { CRUD_GROUP_WITH_HR } from "src/features/groups/groups";
import useAuth from "src/features/auth/hooks/useAuth";
import { useAppDispatch } from "src/store";
import {
  setESignatureFormStep,
  ESignatureFormSteps,
  resetESignatureFormState,
} from "../slices/eSignatureFormSlice";
import { resetDocumentAnnotationState } from "../slices/documentAnnotationsSlice";
import { resetDocumentAssignFormState } from "../slices/documentAssignFormSlice";
import { resetTemplateAnnotationsState } from "../slices/templateAnnotationsSlice";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import Header from "src/components/Headers/Header";
import CloseSmallIcon from "src/components/Icons/CloseSmallIcon";
import Modal from "src/components/Modal/Modal";
import ModalForm from "src/components/Modal/ModalForm";
import { setModalStateAction } from "src/features/modal/handlers";
import useModal from "src/features/modal/hooks/useModal";
import ESignaturesListSidebar from "../components/ListSidebar/ESignaturesListSidebar";

export default function ESignaturesWithSidebarRoute() {
  const { employee } = useAuth();
  const { title: modalTitle } = useModal();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isDocumentPage = /\/e-signatures\/documents\/[^/]+/.test(pathname);
  const isDocumentSignPage = /\/e-signatures\/documents\/[^/]+\/sign/.test(
    pathname
  );
  const isDocumentAssignPage = /\/e-signatures\/documents\/[^/]+\/assign/.test(
    pathname
  );

  const headerTitle = isDocumentAssignPage
    ? "Assign Document"
    : isDocumentSignPage
    ? "Sign Document"
    : isDocumentPage
    ? "Completed Document"
    : "E-Signatures";

  useSetHeaderTitle(headerTitle);

  const handleOpenNewTemplateForm = () => {
    dispatch(resetDocumentAnnotationState());
    dispatch(resetDocumentAssignFormState());
    dispatch(resetESignatureFormState());
    dispatch(resetTemplateAnnotationsState());
    dispatch(setESignatureFormStep(ESignatureFormSteps.AddDetails));
    navigate("/e-signatures/templates/create");
  };

  const handleCloseModal = () =>
    dispatch(setModalStateAction(null)(null)(false));

  return (
    <PageLayout
      header={
        <SiteHeader
          id="e-signature-header"
          actions={
            <>
              <SearchInput id="search" />
              <UnreadDiscussionsIndicator />
              {hasGroupPermissions(employee, CRUD_GROUP_WITH_HR) ? (
                <Button
                  title={"New Template"}
                  color={ButtonColors.Yellow}
                  id={"createTemplateBtn"}
                  onClick={handleOpenNewTemplateForm}
                />
              ) : null}
              <Modal>
                <Header title={modalTitle} id={"modalTitle"}>
                  <Button style={{ all: "unset" }} onClick={handleCloseModal}>
                    <CloseSmallIcon />
                  </Button>
                </Header>
                <ModalForm />
              </Modal>
            </>
          }
        />
      }
      mainContent={<Outlet />}
      rightContent={<ESignaturesListSidebar />}
    />
  );
}
