import axiosClient from "src/features/axios/client";
import { ScheduleRoleType } from "./scheduleRolesService";

export type LaborBudget = {
  id: number;
  weekly_schedule: number;
  department: number;
  schedule_role: ScheduleRoleType;
  scheduled_hours: string;
  scheduled_amount: string;
  budget_hours: string;
  budget_amount: string;
};

class LaborBudgetService {
  async getLaborBudgets(
    startDate: string,
    endDate: string,
    weeklyScheduleId?: number,
    departmentId?: number,
    scheduleRoleId?: number
  ): Promise<LaborBudget[]> {
    return await axiosClient
      .get("/api/labor-budgets", {
        params: {
          weekly_schedule_id: weeklyScheduleId,
          department_id: departmentId,
          schedule_role_id: scheduleRoleId,
          start_date: startDate,
          end_date: endDate,
        },
      })
      .then((response) => response.data);
  }

  async partialUpdateLaborBudget(
    data: Partial<LaborBudget>
  ): Promise<LaborBudget> {
    return await axiosClient
      .patch(`/api/labor-budgets/${data.id}/`, data)
      .then((response) => response.data);
  }
}

const laborBudgetService = new LaborBudgetService();

export default laborBudgetService;
