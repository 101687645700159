import * as React from "react"

const VideoIcon = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.442 17.017H5.175c-2.633 0-3.508-1.75-3.508-3.509V6.492c0-2.634.875-3.509 3.508-3.509h5.267c2.633 0 3.508.875 3.508 3.509v7.016c0 2.634-.883 3.509-3.508 3.509ZM16.267 14.25l-2.317-1.625V7.367l2.317-1.625c1.133-.792 2.067-.309 2.067 1.083v6.35c0 1.392-.934 1.875-2.067 1.075Z"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.583 9.167a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Z"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default VideoIcon;
