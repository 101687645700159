import axiosClient from "src/features/axios/client";
import {
  Document,
  DocumentAnnotation,
  DocumentStatus,
} from "../types/document.types";
import { DocumentDetailsFormSchemaType } from "../zod/documentDetailsFormSchema";

export type DocumentForm = {
  id?: number;
  template: number;
  status: DocumentStatus;
};

export type PublishDocumentForm = {
  id: string;
  status: DocumentStatus;
};

export type DocumentBulkAssignData = {
  template: number;
  departments?: number[];
  roles?: number[];
  employees?: number[];
  status: DocumentStatus;
};

class DocumentService {
  async createDocument(data: DocumentForm): Promise<Document> {
    return await axiosClient
      .post("/api/documents/", data)
      .then((response) => response.data);
  }

  async createDocumentsBulk(data: DocumentBulkAssignData): Promise<Document[]> {
    return await axiosClient
      .post("/api/documents/bulk/", data)
      .then((response) => response.data);
  }

  async deleteDocument(id: string): Promise<void> {
    return await axiosClient.delete(`/api/documents/${id}/`);
  }

  async getAnnotations(id: string): Promise<DocumentAnnotation[]> {
    return await axiosClient
      .get(`/api/documents/${id}/annotations/`)
      .then((response) => response.data);
  }

  async getDocument(id: string): Promise<Document> {
    return await axiosClient
      .get(`/api/documents/${id}/`)
      .then((response) => response.data);
  }

  async getDocuments(
    status?: DocumentStatus,
    employeeId?: string
  ): Promise<Document[]> {
    return await axiosClient
      .get("/api/documents/", {
        params: {
          status,
          employee_id: employeeId,
        },
      })
      .then((response) => response.data);
  }

  async getMyAnnotations(id: string): Promise<DocumentAnnotation[]> {
    return await axiosClient
      .get(`/api/documents/${id}/my-annotations/`)
      .then((response) => response.data);
  }

  async sendDocument(id: string): Promise<Document> {
    return await axiosClient
      .post(`/api/documents/${id}/send-document/`)
      .then((response) => response.data);
  }

  async signDocument({
    id,
    data,
  }: {
    id: number;
    data: FormData;
  }): Promise<Document> {
    return await axiosClient
      .post(`/api/documents/${id}/sign/`, data)
      .then((response) => response.data);
  }

  async updateDocumentDetails(
    data: DocumentDetailsFormSchemaType
  ): Promise<Document> {
    return await axiosClient
      .patch(`/api/documents/${data.id}/`, data)
      .then((response) => response.data);
  }

  async partialUpdateDocument(data: PublishDocumentForm): Promise<Document> {
    return await axiosClient
      .patch(`/api/documents/${data.id}/`, data)
      .then((response) => response.data);
  }
}

const documentService = new DocumentService();

export default documentService;
