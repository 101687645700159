import { useEffect } from "react";

import Card from "src/components/Cards/Card";
import Container from "src/components/Container/Container";
import VerticalStepper from "src/components/Steps/VerticalStepper";
import useSetHeaderTitle from "src/features/header/hooks/useSetHeaderTitle";
import { useAppDispatch } from "src/store";
import { MemoFormSteps, setMemoFormStep } from "../../slices/memoSlice";
import MemoFormStepOne from "./MemoFormStepOne";
import styles from "./MemoSteps.module.css";
import MemoFormStepTwo from "./MemoFormStepTwo";
import MemoFormStepThree from "./MemoFormStepThree";
import MemoStepFour from "./MemoStepFour";
import MemoStepFive from "./MemoStepFive";

export default function MemoCreateEditPage() {
  const dispatch = useAppDispatch();
  useSetHeaderTitle("Memo Form");

  useEffect(() => {
    dispatch(setMemoFormStep(MemoFormSteps.AddDetails));
  }, [dispatch]);

  const steps = [
    MemoFormStepOne(),
    MemoFormStepTwo(),
    MemoFormStepThree(),
    MemoStepFour(),
    MemoStepFive(),
  ];

  return (
    <Card>
      <Container style={{ padding: "20px 10px" }}>
        <VerticalStepper steps={steps} className={styles.step} />
      </Container>
    </Card>
  );
}
