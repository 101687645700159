import { UploadProgressCallback } from "../hooks/useFileUpload";

export const handleUploadProgress = (
  progressEvent: { loaded?: number; total?: number },
  callback: UploadProgressCallback
) => {
  if (!progressEvent.total || !progressEvent.loaded) {
    return;
  }
  const progress = Math.round(
    (progressEvent.loaded / progressEvent.total) * 100
  );
  callback(progress);
};
