import StepFourGrayIcon from "src/components/Icons/StepFourGrayIcon";
import useVerticalStepperContent from "src/components/Steps/hooks/useVerticalStepperContent";
import StepFourYellowIcon from "src/components/Icons/StepFourYellowIcon";
import CheckCircleYellowIcon from "src/components/Icons/CheckCircleYellowIcon";
import StepEditButton from "src/features/learningPaths/components/LPBuilder/Steps/StepEditButton";
import { useAppDispatch } from "src/store";
import useMemoFormState from "../../hooks/useMemoFormState";
import { MemoFormSteps, setMemoFormStep } from "../../slices/memoSlice";
import MemoScheduleContent from "./MemoScheduleForm";
import StepContainer from "src/components/Steps/StepContainer";
import { useEffect } from "react";
import {
  ScheduledTodosFormSteps,
  setScheduledTodosFormStep,
} from "src/features/todos/todosSlice";

export default function MemoStepFour() {
  const { step } = useMemoFormState();
  const dispatch = useAppDispatch();

  const handleStepButtonClick = () =>
    dispatch(setMemoFormStep(MemoFormSteps.ScheduleMemo));

  useEffect(() => {
    dispatch(
      setScheduledTodosFormStep(ScheduledTodosFormSteps.ListScheduledTodos)
    );
  }, [dispatch]);

  return useVerticalStepperContent({
    currentStep: step,
    activeStep: MemoFormSteps.ScheduleMemo,
    titleAction:
      step !== MemoFormSteps.AddDetails &&
      step !== MemoFormSteps.UploadFile &&
      step !== MemoFormSteps.AssignViewableBy &&
      step !== MemoFormSteps.ScheduleMemo ? (
        <StepEditButton onClick={handleStepButtonClick} />
      ) : null,
    activeIcon: <StepFourYellowIcon />,
    inactiveIcon:
      step === MemoFormSteps.PublishMemo ? (
        <CheckCircleYellowIcon />
      ) : (
        <StepFourGrayIcon />
      ),
    defaultContent: (
      <StepContainer>
        {step !== MemoFormSteps.AddDetails &&
        step !== MemoFormSteps.UploadFile &&
        step !== MemoFormSteps.AssignViewableBy &&
        step !== MemoFormSteps.ScheduleMemo ? (
          <span className="text-muted">This step is complete!</span>
        ) : (
          <span className="text-muted">
            Assign "To-Do" tasks related to this memo to specific employees,
            roles, or departments. These tasks must be completed by the
            designated recipients. Please note that scheduled tasks will only be
            dispatched once the memo is published.
          </span>
        )}
      </StepContainer>
    ),
    activeContent: <MemoScheduleContent />,
  });
}
