import { FormDefs } from "src/features/forms/formDefs.types";
import { z } from "zod";

const lpViewableByFormDefs = {
  id: "lpViewableByForm",
  description: "",
  fields: [
    {
      name: "viewable_by",
      description: "Select the employee types that can view this assessment",
      type: "checkbox",
      placeholder: "Viewable By",
      required: true,
      label: "Viewable By",
      labelIcon: null,
      defaultValue: "",
    },
  ],
} as FormDefs;

const lpViewableBySchema = z.object({
  id: z.number().or(z.string()),
  viewable_by: z.array(z.string().or(z.number())),
});

export type LPViewableByFormSchemaType = z.infer<typeof lpViewableBySchema>;

export { lpViewableBySchema, lpViewableByFormDefs };
