import { EmployeeShiftStatus } from "../services/employeeShiftsService";
import useGetEmployeeShiftsListQuery from "./useGetEmployeeShiftsListQuery";
import { ShiftRequirementType } from "../services/shiftRequirementsService";

type UseGetShiftsPendingCancellationQuery = {
  scheduleRoleId?: number;
  shift: ShiftRequirementType | null;
};

export default function useGetEmployeeShiftsCancelledOrPendingQuery({
  scheduleRoleId,
  shift,
}: UseGetShiftsPendingCancellationQuery) {
  return useGetEmployeeShiftsListQuery({
    scheduleRoleId,
    select: (data) => {
      // Filter by shift requirement if shift is provided
      if (shift !== null) {
        return data?.filter((employeeShift) => {
          return (
            employeeShift.shift_requirement.id === shift.id &&
            (employeeShift.status === EmployeeShiftStatus.PendingCancellation ||
              employeeShift.status === EmployeeShiftStatus.Cancelled)
          );
        });
      }

      return data;
    },
  });
}
