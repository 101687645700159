import { useMutation } from "@tanstack/react-query";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import employeeShiftsService, {
  EmployeeShift,
} from "../services/employeeShiftsService";

type UsePartialUpdateEmployeeShiftMutation = {
  onSuccess?: (shift: EmployeeShift) => void;
};

export default function usePartialUpdateEmployeeShiftMutation({
  onSuccess,
}: UsePartialUpdateEmployeeShiftMutation) {
  const handleErrors = useErrorHandling();
  return useMutation({
    mutationFn: async (data: Partial<EmployeeShift>) =>
      employeeShiftsService.partialUpdateEmployeeShift(data),
    onSuccess,
    onError: handleErrors,
  });
}
