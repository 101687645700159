import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import useWeekFromUrl from "../hooks/useWeekFromUrl";
import LaborBudgetCard from "./LaborBudgetCard";

export default function LaborBudgetWeeklyPage() {
  const { handleNavigateWeek, weekDates, weekDateTitle } = useWeekFromUrl();

  return (
    <div className="detail-card">
      <div className="h-60 px-20 border-bottom-light-gray">
        <div className="flex flex-1 flex-row align-center justify-between gap-20 h-60">
          <div className="flex flex-1 flex-row gap-20 align-center justify-center absolute left-1/2 transform -translate-x-1/2">
            <Button
              color={ButtonColors.Yellow}
              className="border-radius-5 p-0"
              onClick={() => handleNavigateWeek("prev")}
            >
              <MdChevronLeft className="cursor-pointer" size={28} />
            </Button>
            <span className="mobile-title">{weekDateTitle}</span>
            <Button
              color={ButtonColors.Yellow}
              className="border-radius-5 p-0"
              onClick={() => handleNavigateWeek("next")}
            >
              <MdChevronRight className="cursor-pointer" size={28} />
            </Button>
          </div>
        </div>
      </div>
      <div className="flex-1 flex p-10 gap-10">
        <LaborBudgetCard
          startOfWeekDate={weekDates[0]}
          endOfWeekDate={weekDates[6]}
        />
      </div>
    </div>
  );
}
