import axiosClient from "src/features/axios/client";
import { AuthenticatedEmployee } from "src/features/employees/types/employees.type";

export type GetEmployeeTimeOffRequestsParams = {
  employeeId: string | number;
  startDate?: string;
  endDate?: string;
  status?: TimeOffStatus;
};

// export type TimeOffStatus = "Pending" | "Approved" | "Declined";

export enum TimeOffStatus {
  Pending = "Pending",
  Approved = "Approved",
  Declined = "Declined",
}

export type TimeOffRequest = {
  id: number;
  created_on: string;
  updated_on: string;
  status: TimeOffStatus;
  start_date: string;
  end_date: string;
  reason: string;
  notes?: string | null;
  requested_on: string;
  decision_at?: string | null;
  employee: AuthenticatedEmployee | null;
  decision_by: AuthenticatedEmployee | null;
};

type CreateTimeOffRequestParams = {
  employee: number;
  start_date: string;
  end_date: string;
  reason: string;
};

class TimeOffRequestService {
  async createTimeOffRequest({
    employee,
    start_date,
    end_date,
    reason,
  }: CreateTimeOffRequestParams): Promise<TimeOffRequest> {
    return await axiosClient
      .post("/api/time-off-requests/", {
        employee,
        start_date,
        end_date,
        reason,
      })
      .then((res) => res.data);
  }

  async createTimeOffBySupervisor({
    employee,
    start_date,
    end_date,
    reason,
    decision_at,
    decision_by,
  }: CreateTimeOffRequestParams & {
    decision_at: string;
    decision_by: number;
  }): Promise<TimeOffRequest> {
    return await axiosClient
      .post("/api/time-off-requests/supervisor-create/", {
        employee,
        start_date,
        end_date,
        reason,
        decision_at,
        decision_by,
      })
      .then((res) => res.data);
  }

  async getTimeOffRequestById(id: string): Promise<TimeOffRequest> {
    return await axiosClient
      .get(`/api/time-off-requests/${id}/`)
      .then((res) => res.data);
  }

  async getEmployeeTimeOffRequestsByEmployeeId({
    employeeId,
    startDate,
    endDate,
    status,
  }: GetEmployeeTimeOffRequestsParams): Promise<TimeOffRequest[]> {
    return await axiosClient
      .get("/api/time-off-requests", {
        params: {
          employee_id: employeeId,
          start_date_gte: startDate,
          end_date_gte: endDate,
          status,
        },
      })
      .then((res) => res.data);
  }

  async getTimeOffRequestsByScheduleRole({
    scheduleRole,
    startDate,
    endDate,
    status,
  }: {
    scheduleRole: number | string;
    startDate: string;
    endDate: string;
    status?: TimeOffStatus;
  }): Promise<TimeOffRequest[]> {
    return await axiosClient
      .get("/api/time-off-requests", {
        params: {
          schedule_role: scheduleRole,
          date_overlap_after: startDate,
          status,
        },
      })
      .then((res) => res.data);
  }

  async getTimeOffRequests({
    startDate,
    endDate,
    status,
  }: {
    startDate: string;
    endDate?: string;
    status?: TimeOffStatus;
  }): Promise<TimeOffRequest[]> {
    return await axiosClient
      .get("/api/time-off-requests", {
        params: {
          date_overlap_after: startDate,
          date_overlap_before: endDate,
          status,
        },
      })
      .then((res) => res.data);
  }

  async updateDecision({
    id,
    status,
    notes,
  }: {
    id: number;
    status: TimeOffStatus;
    notes?: string | null;
  }): Promise<TimeOffRequest> {
    return await axiosClient
      .patch(`/api/time-off-requests/${id}/decision/`, {
        status,
        notes,
      })
      .then((res) => res.data);
  }
}

const timeOffRequestService = new TimeOffRequestService();

export default timeOffRequestService;
