import { useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { RotatingLines } from "react-loader-spinner";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import Dialog from "src/components/Dialog/Dialog";
import DialogContent from "src/components/Dialog/DialogContent";
import DialogDescription from "src/components/Dialog/DialogDescription";
import DialogFooter from "src/components/Dialog/DialogFooter";
import DialogHeader from "src/components/Dialog/DialogHeader";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import useDropEmployeeShiftMutation from "../hooks/useDropEmployeeShiftMutation";
import { EmployeeShift } from "../services/employeeShiftsService";

type ShiftDropConfirmDialogProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClose?: () => void;
  shift: EmployeeShift;
};

export default function ShiftDropConfirmDialog({
  isOpen,
  setIsOpen,
  onClose,
  shift,
}: ShiftDropConfirmDialogProps) {
  const queryClient = useQueryClient();
  const dropShiftMutation = useDropEmployeeShiftMutation({
    onSuccess: () => {
      setIsOpen(false);
      queryClient.invalidateQueries([QueryKeys.ShiftTransfersList]);
      queryClient.invalidateQueries([QueryKeys.EmployeeShiftsList]);
    },
  });

  const handleDropShift = () => {
    dropShiftMutation.mutate(shift.id);
  };
  return (
    <Dialog isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <DialogHeader>Request to drop this shift?</DialogHeader>
      <DialogDescription>
        Are you sure you want to drop this shift? If this shift is not picked up
        by another employee, you will be responsible for working this shift.
      </DialogDescription>
      <DialogContent>
        <div className="flex gap-10 p-20 border-radius-10 bg-cp-black-50">
          <span className="font-bold text-lg cp-yellow">
            {shift.shift_requirement.name}
          </span>
          <div className="flex flex-row gap-10 align-center">
            <span className="text-sm font-bold">
              {format(new Date(shift.shift_requirement.start_datetime), "PP")}
            </span>
          </div>
          <div className="flex flex-row gap-10 align-center">
            <span className="text-sm font-bold">
              {format(new Date(shift.shift_requirement.start_datetime), "p")} -{" "}
              {format(new Date(shift.shift_requirement.end_datetime), "p")}
            </span>
          </div>
        </div>
      </DialogContent>
      <DialogFooter>
        <Button
          color={ButtonColors.GrayAndYellow}
          disabled={dropShiftMutation.isLoading}
          onClick={() => setIsOpen(false)}
        >
          {dropShiftMutation.isLoading ? (
            <RotatingLines
              strokeColor="#f1b70c"
              strokeWidth="5"
              animationDuration="0.75"
              width="20"
              visible={true}
            />
          ) : (
            "Cancel"
          )}
        </Button>
        <Button
          disabled={dropShiftMutation.isLoading}
          color={ButtonColors.Yellow}
          onClick={handleDropShift}
        >
          {dropShiftMutation.isLoading ? (
            <RotatingLines
              strokeColor="#000"
              strokeWidth="5"
              animationDuration="0.75"
              width="20"
              visible={true}
            />
          ) : (
            "Request Drop"
          )}
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
