import { createSlice } from "@reduxjs/toolkit";

export enum ScheduledTodosFormSteps {
  ListScheduledTodos = "List Scheduled Todos",
  CreateUpdateScheduledTodos = "Create Update Scheduled Todos",
}

export type ScheduledTodosFormState = {
  scheduledTodoToUpdate: string | number | null;
  scheduledTodosFormStep: ScheduledTodosFormSteps | null;
};

const initialState: ScheduledTodosFormState = {
  scheduledTodoToUpdate: null,
  scheduledTodosFormStep: null,
};

const scheduledTodoFormSlice = createSlice({
  name: "scheduledTodo",
  initialState,
  reducers: {
    setScheduledTodoToUpdate(state, action) {
      state.scheduledTodoToUpdate = action.payload;
    },
    setScheduledTodosFormStep(state, action) {
      state.scheduledTodosFormStep = action.payload;
    },
  },
});

export const { setScheduledTodosFormStep, setScheduledTodoToUpdate } =
  scheduledTodoFormSlice.actions;

export default scheduledTodoFormSlice.reducer;
