import { useState } from "react";
import { Outlet } from "react-router-dom";
import styles from "../../organization/styles/OrganizationRegistration.module.css";
import RegisterHeader from "../../organization/components/RegisterHeader";
import StepsProgressBar from "../../../components/Steps/StepsProgressBar";
import PageContent from "src/components/Layout/PageContent";
import PageContainer from "src/components/Layout/PageContainer";

type OnboardingWrapperProps = {
  totalSteps?: number;
};

function OnboardingWrapper({ totalSteps }: OnboardingWrapperProps) {
  const [currentStep, setCurrentStep] = useState(1);

  return (
    <PageContainer>
      <RegisterHeader />
      <PageContent
        style={{ width: "100%", alignItems: "center", display: "flex" }}
      >
        <StepsProgressBar current={currentStep} totalSteps={totalSteps} />
        <div className={styles.registrationWindowContainer}>
          <Outlet context={{ setCurrentStep }} />
        </div>
      </PageContent>
    </PageContainer>
  );
}

export default OnboardingWrapper;
