import axiosClient from "src/features/axios/client";
import { DepartmentListItem } from "src/features/departments/types/departments.type";
import { EmployeeTypeListItem } from "src/features/employeeTypes/types/employeeTypes.types";
import { ScheduleRoleFormDataType } from "../components/VerifyScheduleRolesForm";

export type ScheduleRoleType = {
  id: number;
  role: EmployeeTypeListItem;
  department: DepartmentListItem;
  hourly_rate: number;
};

class ScheduleRolesService {
  async getScheduleRoles(
    departmentId?: string | number
  ): Promise<ScheduleRoleType[]> {
    return await axiosClient
      .get("/api/schedule-roles/", { params: { department_id: departmentId } })
      .then((res) => res.data);
  }

  async syncScheduleRoles() {
    return await axiosClient
      .post("/api/schedule-roles/sync/")
      .then((res) => res.data);
  }

  async partialUpdateScheduleRole(
    id: number | string,
    data: Partial<ScheduleRoleFormDataType>
  ): Promise<ScheduleRoleType> {
    return await axiosClient
      .patch(`/api/schedule-roles/${id}/`, data)
      .then((res) => res.data);
  }
}

const scheduleRolesService = new ScheduleRolesService();

export default scheduleRolesService;
