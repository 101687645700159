import React, { memo, useState, useEffect, useRef } from "react";
import styles from "./Tooltip.module.css";

const TOOLTIP_DELAY = 100;

type TooltipProps = {
  children: React.ReactNode;
  content: React.ReactNode;
  showTooltip?: boolean;
};

function Tooltip({ children, content, showTooltip = false }: TooltipProps) {
  const [tooltip, setTooltip] = useState({ visible: false, opacity: 1 });
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const handleMouseOver = () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      if (showTooltip) setTooltip({ visible: true, opacity: 1 });
    };

    const handleMouseOut = (e: MouseEvent) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(e.relatedTarget as Node)
      ) {
        timeoutRef.current = setTimeout(() => {
          setTooltip({ ...tooltip, opacity: 0 });
          const transitionEnd = () =>
            setTooltip({ ...tooltip, visible: false });
          tooltipRef.current?.addEventListener("transitionend", transitionEnd);
          return () =>
            tooltipRef.current?.removeEventListener(
              "transitionend",
              transitionEnd
            );
        }, TOOLTIP_DELAY);
      }
    };

    const tooltipNode = tooltipRef.current;

    if (tooltipNode) {
      tooltipNode.addEventListener("mouseover", handleMouseOver);
      tooltipNode.addEventListener("mouseout", handleMouseOut);

      return () => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        tooltipNode.removeEventListener("mouseover", handleMouseOver);
        tooltipNode.removeEventListener("mouseout", handleMouseOut);
      };
    }
  }, [tooltip, showTooltip]);

  return (
    <div ref={tooltipRef} className={styles.tooltipContainer}>
      {tooltip.visible ? (
        <div
          className={styles.tooltipContent}
          style={{ opacity: tooltip.opacity }}
        >
          {content}
        </div>
      ) : null}
      {children}
    </div>
  );
}

export default memo(Tooltip);
