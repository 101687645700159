import axiosClient from "src/features/axios/client";
import { DocumentSignatoryOrder } from "../types/document.types";

class DocumentSignatoryOrderService {
  async getDocumentSignatoryOrders(
    documentId: string
  ): Promise<DocumentSignatoryOrder[]> {
    return axiosClient
      .get(`/api/document-signatory-order/`, {
        params: {
          document: documentId,
        },
      })
      .then((res) => res.data);
  }

  async updateDocumentSignatoryOrderOrder(
    id: string,
    newPosition: number
  ): Promise<DocumentSignatoryOrder> {
    return axiosClient
      .post(`/api/document-signatory-order/${id}/update-order/`, {
        new_position: newPosition,
      })
      .then((res) => res.data);
  }
}

const documentSignatoryOrderService = new DocumentSignatoryOrderService();

export default documentSignatoryOrderService;
