/**
 * Combines a Date object with a time string (HH:mm) to create a new Date object.
 * @param date The date object to combine with the time.
 * @param timeString The time string in HH:mm format.
 * @returns A new Date object with the combined date and time.
 */
function combineDateWithTime(date: Date, timeString: string): Date {
  const [hours, minutes] = timeString.split(":").map(Number);
  const combinedDate = new Date(date);
  combinedDate.setHours(hours, minutes, 0, 0); // Set hours, minutes, and reset seconds and milliseconds
  return combinedDate;
}

export default combineDateWithTime;
