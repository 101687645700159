export enum PermissionsGroup {
  Admin = "Admin",
  HumanResources = "Human Resources",
  Supervisor = "Supervisor",
  SupervisorReadOnly = "Supervisor (read-only)",
  Employee = "Employee",
}

export interface Group {
  id: number;
  name: PermissionsGroup;
}
