const getUserAnswer = (question: any, userAnswers: any) => {
  if (!userAnswers) {
    return undefined;
  }

  if (!question?.id) {
    return "No question provided";
  }

  const userAnswer = userAnswers[question.id];

  if (
    userAnswer === undefined ||
    userAnswer === null ||
    userAnswer.length === 0
  ) {
    return undefined;
  }

  if (question.type === "Multiple Choice") {
    if (Array.isArray(userAnswer)) {
      return userAnswer
        .map(
          (answerId) =>
            question.answers.find((answer: any) => answer.id === answerId)
              ?.content || answerId
        )
        .join(", ");
    } else {
      const answer = question.answers.find(
        (answer: any) => answer.id === Number(userAnswer)
      );
      return answer ? answer.content : userAnswer;
    }
  } else {
    return userAnswer;
  }
};

export default getUserAnswer;
