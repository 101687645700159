import withProtectedRoute from "src/features/auth/components/withProtectedRoute";
import { NON_EMPLOYEES_GROUP } from "src/features/groups/groups";
import { UrlRoutes } from "src/features/site/types/routes.types";
import SchedulerCalendar from "../components/ScheduleCalendar";

export default withProtectedRoute(
  SchedulerCalendar,
  UrlRoutes.Schedules,
  NON_EMPLOYEES_GROUP
);
