import { z } from "zod";

export type AssignViewableByStepFormSchemaType = z.infer<
  typeof assignViewableByStepFormSchema
>;

export const assignViewableByStepFormSchema = z.object({
  id: z.number().or(z.string()),
  viewable_by: z.array(z.string().or(z.number())),
});
