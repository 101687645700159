import { useMutation } from "@tanstack/react-query";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import shiftTransferService, {
  ShiftTransfer,
} from "../services/shiftTransferService";

type UsePickUpEmployeeShiftMutationProps = {
  onSuccess?: (shift: ShiftTransfer) => void;
};

export default function usePickUpEmployeeShiftMutation({
  onSuccess,
}: UsePickUpEmployeeShiftMutationProps) {
  const handleErrors = useErrorHandling();
  return useMutation({
    mutationFn: shiftTransferService.pickUpEmployeeShift,
    onSuccess,
    onError: handleErrors,
  });
}
