import axiosClient from "src/features/axios/client";
import {
  TemplateDetailsFormSchemaType,
  TemplateViewableByFormSchemaType,
} from "../zod/pdfTemplateDetailsFormSchema";
import { DocumentTemplate, TemplateAnnotation } from "../types/template.types";

class TemplateService {
  async createTemplateDetails(
    data: TemplateDetailsFormSchemaType
  ): Promise<DocumentTemplate> {
    return await axiosClient
      .post("/api/templates/", data)
      .then((res) => res.data);
  }

  async deleteTemplate(id: string): Promise<void> {
    return await axiosClient.delete(`/api/templates/${id}/`);
  }

  async getAnnotations(id: string): Promise<TemplateAnnotation[]> {
    return await axiosClient
      .get(`/api/templates/${id}/annotations/`)
      .then((res) => res.data);
  }

  async getTemplate(id: string): Promise<DocumentTemplate> {
    return await axiosClient
      .get(`/api/templates/${id}/`)
      .then((res) => res.data);
  }

  async getTemplates(): Promise<DocumentTemplate[]> {
    return await axiosClient.get("/api/templates/").then((res) => res.data);
  }

  async updateTemplateDetails(
    data: TemplateDetailsFormSchemaType
  ): Promise<DocumentTemplate> {
    return await axiosClient
      .patch(`/api/templates/${data.id}/`, data)
      .then((res) => res.data);
  }

  async partialUpdateTemplateViewableBy(
    data: TemplateViewableByFormSchemaType
  ): Promise<DocumentTemplate> {
    return await axiosClient
      .patch(`/api/templates/${data.id}/`, data)
      .then((res) => res.data);
  }

  async partialUpdateTemplateUsingFormData(
    id: string,
    data: FormData
  ): Promise<DocumentTemplate> {
    return await axiosClient
      .patch(`/api/templates/${id}/`, data)
      .then((res) => res.data);
  }
}

const templateService = new TemplateService();

export default templateService;
