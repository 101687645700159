import axiosClient from "src/features/axios/client";
import { TodoDetail } from "../types/todos.types";
import { TodoObjectTypes, TodoStatus } from "../types/todosEnums.types";

export type GetMyTodosParams = {
  objectType?: TodoObjectTypes;
  status?: TodoStatus;
  startDate?: Date | null;
  endDate?: Date | null;
};

class MyTodosService {
  async getMyTodos({
    objectType,
    status,
    startDate,
    endDate,
  }: GetMyTodosParams): Promise<TodoDetail[]> {
    return await axiosClient
      .get("/api/my-todos/", {
        params: {
          object_type: objectType,
          status,
          start_date: startDate,
          end_date: endDate,
        },
      })
      .then((res) => res.data);
  }
}

const myTodosService = new MyTodosService();

export default myTodosService;
