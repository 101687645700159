import { format, parseISO } from "date-fns";
import { TimeOffRequest } from "../services/timeOffRequestService";

type TimeOffBoxProps = {
  timeOffRequest: TimeOffRequest;
  onClick: (timeOff: TimeOffRequest) => void;
};

export default function TimeOffBox({
  timeOffRequest,
  onClick,
}: TimeOffBoxProps) {
  return (
    <div
      className="bg-cp-danger"
      style={{
        color: "var(--clearpath-white)",
        padding: "8px",
        borderRadius: "4px",
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
        cursor: "pointer",
        fontSize: "12px",
        textAlign: "center",
      }}
      onClick={() => onClick(timeOffRequest)}
    >
      <div className="font-bold">Time Off</div>
      <div className="text-xxs">
        {format(parseISO(timeOffRequest.start_date), "PP")} -{" "}
        {format(parseISO(timeOffRequest.end_date), "PP")}
      </div>
      {/* <div>Status: {timeOff.status}</div> */}
    </div>
  );
}
