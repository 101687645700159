import { useQueryClient } from "@tanstack/react-query";
import { MdArrowRightAlt, MdCompareArrows } from "react-icons/md";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import Dialog from "src/components/Dialog/Dialog";
import DialogContent from "src/components/Dialog/DialogContent";
import DialogDescription from "src/components/Dialog/DialogDescription";
import DialogFooter from "src/components/Dialog/DialogFooter";
import DialogHeader from "src/components/Dialog/DialogHeader";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import useMakeShiftTransferDecisionMutation from "../hooks/useMakeShiftTransferDecisionMutation";
import {
  ShiftTransfer,
  ShiftTransferStatus,
  ShiftTransferType,
} from "../services/shiftTransferService";
import ShiftTransferBadge from "./ShiftTransferBadge";
import ShiftTransferFlowCards from "./ShiftTransferFlowCards";

type ShiftTransferDecisionDialogProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClose?: () => void;
  onPickUpShift: (transfer: ShiftTransfer) => void;
  transfer: ShiftTransfer;
};

export default function ShiftTransferDecisionDialog({
  isOpen,
  setIsOpen,
  onClose,
  onPickUpShift,
  transfer,
}: ShiftTransferDecisionDialogProps) {
  const queryClient = useQueryClient();
  const makeShiftTransferDecisionMutation =
    useMakeShiftTransferDecisionMutation({
      onSuccess: async (data) => {
        setIsOpen(false);
        queryClient.setQueryData(
          [QueryKeys.ShiftTransfer, String(data.id)],
          data
        );
        await queryClient.invalidateQueries([
          QueryKeys.ShiftTransfer,
          String(data.id),
        ]);
      },
    });

  const handleSubmitShiftTransferDecision = (
    e: React.MouseEvent<Element, MouseEvent>
  ) => {
    const status = e.currentTarget.id as ShiftTransferStatus;
    makeShiftTransferDecisionMutation.mutate({
      id: transfer.id,
      status,
    });
  };

  return (
    <Dialog isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <DialogHeader>
        Shift
        {transfer.type === ShiftTransferType.ShiftDrop ? " Drop " : " Swap "}
        Decision
      </DialogHeader>
      <DialogDescription>
        {transfer.status !== ShiftTransferStatus.Approved &&
        transfer.status !== ShiftTransferStatus.Declined &&
        transfer.status !== ShiftTransferStatus.Expired
          ? `Make a decision on this shift
        ${transfer.type === ShiftTransferType.ShiftDrop ? " drop " : " swap "}
        request. You can approve or decline the request.`
          : `A decision has been made on this shift ${
              transfer.type === ShiftTransferType.ShiftDrop
                ? " drop "
                : " swap "
            } request. You may view the decision below.`}
      </DialogDescription>
      <DialogContent>
        <div className="flex flex-row justify-end">
          <ShiftTransferBadge status={transfer.status} />
        </div>
        <div className="flex flex-row-mobile-column my-20">
          <ShiftTransferFlowCards
            cardClassName="bg-cp-black-50"
            titleLeft={
              transfer.type === ShiftTransferType.ShiftDrop &&
              transfer.status === ShiftTransferStatus.Approved
                ? "Dropped by:"
                : transfer.type === ShiftTransferType.ShiftDrop
                ? "Dropped from:"
                : transfer.type === ShiftTransferType.ShiftSwap &&
                  transfer.status === ShiftTransferStatus.Approved
                ? "Swapped from:"
                : "Swap from:"
            }
            titleRight={
              transfer.type === ShiftTransferType.ShiftDrop &&
              transfer.status === ShiftTransferStatus.Approved
                ? "Dropped to:"
                : transfer.type === ShiftTransferType.ShiftDrop
                ? "Available"
                : transfer.type === ShiftTransferType.ShiftSwap &&
                  transfer.status === ShiftTransferStatus.Approved
                ? "Swapped to:"
                : "Swap to:"
            }
            employeeLeft={transfer.employee_schedule_role_from.employee}
            employeeRight={transfer.employee_schedule_role_to?.employee}
            shiftLeft={
              transfer.type === ShiftTransferType.ShiftSwap &&
              transfer.status === ShiftTransferStatus.Approved
                ? transfer.shift_from
                : transfer.shift_to
            }
            shiftRight={
              transfer.type === ShiftTransferType.ShiftSwap &&
              transfer.status === ShiftTransferStatus.Approved
                ? transfer.shift_to
                : transfer.shift_from
            }
            onPickUpShift={() => onPickUpShift(transfer)}
            icon={
              transfer.type === ShiftTransferType.ShiftDrop ? (
                <MdArrowRightAlt size={70} color={"var(--clearpath-yellow)"} />
              ) : (
                <MdCompareArrows size={70} color={"var(--clearpath-yellow)"} />
              )
            }
          />
        </div>
      </DialogContent>
      <DialogFooter>
        {transfer.status !== ShiftTransferStatus.Approved &&
        transfer.status !== ShiftTransferStatus.Declined &&
        transfer.status !== ShiftTransferStatus.Expired ? (
          <>
            <Button
              id={ShiftTransferStatus.Declined}
              onClick={handleSubmitShiftTransferDecision}
              color={ButtonColors.Red}
              title="Decline"
            />
            <Button
              id={ShiftTransferStatus.Approved}
              onClick={handleSubmitShiftTransferDecision}
              color={ButtonColors.Green}
              title="Approve"
            />
          </>
        ) : (
          <Button
            onClick={() => setIsOpen(false)}
            color={ButtonColors.GrayAndYellow}
            title="Close"
          />
        )}
      </DialogFooter>
    </Dialog>
  );
}
