import React from "react";

type DialogHeaderProps = {
  children?: React.ReactNode;
};

export default function DialogHeader({ children }: DialogHeaderProps) {
  return (
    <div className="dialog-header">
      <h2 className="dialog-title">{children}</h2>
    </div>
  );
}
