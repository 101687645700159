import { useMutation } from "@tanstack/react-query";
import shiftTypeService, { ShiftType } from "../services/shiftTypeService";
import { AxiosError } from "axios";

type CreateShiftTypeMutation = {
  onSuccess: (data: ShiftType) => void;
  onError: (error: AxiosError) => void;
};

export default function useCreateShiftTypeMutation({
  onSuccess,
  onError,
}: CreateShiftTypeMutation) {
  return useMutation({
    mutationFn: shiftTypeService.createShiftType,
    onSuccess,
    onError,
  });
}
