import React from "react";
import Skeleton from "react-loading-skeleton";

import Button from "src/components/Buttons/Button";
import ImageV2 from "src/components/Images/ImageV2";
import getFullName from "src/features/employees/utils/getFullName";

import useToggleEmployeeAnswerMutation from "../hooks/useToggleEmployeeAnswerMutation";
import getUserAnswer from "../utils/getUserAnswer";
import isAnswerCorrect from "../utils/isAnswerCorrect";
import styles from "./EmployeeSitting.module.css";
import calculateDuration from "src/features/datetime/utils/calculateDuration";
import formatDurationToString from "src/features/datetime/utils/formatDurationToString";
import Badge from "src/components/Badges/Badge";
import countCommaSeparatedValues from "src/features/utils/countCommaSeparatedValues";
import {
  ButtonColors,
  ButtonSizes,
} from "src/components/Buttons/buttons.types";
import useGetSittingReview from "../hooks/useGetSittingReview";

type Props = {
  sittingId: string;
};

export default function EmployeeSittingTable({ sittingId }: Props) {
  const { data: sitting, isLoading } = useGetSittingReview({ id: sittingId });

  const toggleEmployeeAnswerMutation = useToggleEmployeeAnswerMutation();

  const userAnswers = sitting ? JSON.parse(sitting.user_answers) : {};

  const handleToggleEmployeeAnswer = (
    e: React.MouseEvent<Element, MouseEvent>
  ) => {
    const questionId = e.currentTarget.id;
    const sittingId = sitting?.id;

    if (questionId && sittingId) {
      toggleEmployeeAnswerMutation.mutate({
        id: sittingId,
        questionId,
      });
    }
  };

  const handleOpenImageTab = (e: React.MouseEvent<Element, MouseEvent>) =>
    window.open(e.currentTarget.id, "_blank");

  return (
    <>
      <table className={styles.descriptionTable}>
        <tbody>
          <tr>
            <td>{isLoading ? <Skeleton width={100} /> : <b>Employee:</b>}</td>
            <td>
              {isLoading ? (
                <Skeleton width={100} />
              ) : (
                getFullName(sitting?.user)
              )}
            </td>
          </tr>
          <tr>
            <td>{isLoading ? <Skeleton width={100} /> : <b>Assessment:</b>}</td>
            <td>
              {isLoading ? <Skeleton width={100} /> : sitting?.assessment}
            </td>
          </tr>
          <tr>
            <td>{isLoading ? <Skeleton width={100} /> : <b>Duration:</b>}</td>
            <td>
              {isLoading ? (
                <Skeleton width={100} />
              ) : (
                `${formatDurationToString(
                  calculateDuration(
                    new Date(sitting?.start),
                    new Date(sitting?.end)
                  )
                )}`
              )}
            </td>
          </tr>
          <tr>
            <td>{isLoading ? <Skeleton width={100} /> : <b>Status:</b>}</td>
            <td>
              {isLoading ? (
                <Skeleton width={100} />
              ) : sitting?.status === 0 ? (
                <Badge text={"In Progress"} color={"#f1b70c"} />
              ) : sitting?.status === 1 ? (
                <Badge text={"Complete"} color={"#28a745"} />
              ) : (
                <Badge text={"Did Not Complete"} color={"#cd3c44"} />
              )}
            </td>
          </tr>
          {sitting?.status !== 0 ? (
            <tr>
              <td>{isLoading ? <Skeleton width={100} /> : <b>Passed:</b>}</td>
              <td>
                {isLoading ? (
                  <Skeleton width={100} />
                ) : sitting?.passed ? (
                  <Badge text={"Pass"} color={"#28a745"} />
                ) : (
                  <Badge text={"No Pass"} color={"#cd3c44"} />
                )}
              </td>
            </tr>
          ) : null}
          <tr>
            <td>
              {isLoading ? (
                <Skeleton width={100} />
              ) : (
                <b>{sitting?.complete ? "Score" : "Current Score"}</b>
              )}
            </td>
            <td>
              {isLoading ? (
                <Skeleton width={100} />
              ) : (
                `${sitting?.percent_correct}%`
              )}
            </td>
          </tr>
          <tr>
            <td>{isLoading ? <Skeleton width={100} /> : <b>Questions:</b>}</td>
            <td>
              {isLoading ? (
                <Skeleton width={100} />
              ) : (
                sitting?.questions?.length
              )}
            </td>
          </tr>
          <tr>
            <td>
              {isLoading ? <Skeleton width={100} /> : <b>Incorrect Answers:</b>}
            </td>
            <td>
              {isLoading ? (
                <Skeleton width={100} />
              ) : sitting?.incorrect_questions ? (
                countCommaSeparatedValues(sitting.incorrect_questions)
              ) : (
                "0"
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ textAlign: "left" }}>
        <h3 className="cp-yellow">
          {isLoading ? <Skeleton width={100} /> : "Questions"}
        </h3>
        {sitting?.questions?.length === 0 && <h4>No questions found</h4>}
      </div>
      {sitting?.questions?.length > 0 ? (
        <table className={styles.questionsTable}>
          <thead style={{ textAlign: "left" }}>
            <tr>
              {isLoading ? (
                <>
                  <th>
                    <Skeleton width={100} height={30} />
                  </th>
                  <th>
                    <Skeleton width={100} height={30} />
                  </th>
                  <th>
                    <Skeleton width={100} height={30} />
                  </th>
                </>
              ) : null}
              {!isLoading ? (
                <>
                  <th>Correctness</th>
                  <th>Answer</th>
                  <th>Question</th>
                </>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td>
                  <Skeleton width={100} height={30} />
                </td>
                <td>
                  <Skeleton width={100} height={30} />
                </td>
                <td>
                  <Skeleton width={100} height={30} />
                </td>
              </tr>
            ) : null}
            {!isLoading &&
              sitting?.questions?.map((question: any) => {
                const userAnswer = getUserAnswer(question, userAnswers);
                const answerCorrect = isAnswerCorrect(
                  sitting?.incorrect_questions,
                  question?.id
                );

                return (
                  <tr key={question?.id}>
                    <td>
                      <Button
                        id={question?.id}
                        color={
                          answerCorrect ? ButtonColors.Green : ButtonColors.Red
                        }
                        size={ButtonSizes.SM}
                        title={answerCorrect ? "Correct" : "Incorrect"}
                        onClick={handleToggleEmployeeAnswer}
                      />
                    </td>
                    <td>{userAnswer ? userAnswer : "No answer"}</td>
                    <td
                      style={{
                        gap: "10px",
                      }}
                    >
                      {question?.figure ? (
                        <Button
                          color={ButtonColors.Transparent}
                          onClick={handleOpenImageTab}
                          id={question?.figure}
                        >
                          <ImageV2
                            src={question?.figure}
                            alt={question?.content}
                            className={styles.questionImage}
                            height={40}
                            width={40}
                            circle={true}
                          />
                        </Button>
                      ) : null}
                      {question?.content}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      ) : null}
    </>
  );
}
