export const steps = [
  {
    disableBeacon: true,
    target: "#setupDepartmentsHeader",
    title: "Next step is to set up the departments in your organization.",
    content:
      "Departments are used to group users with different roles together. Roles with 'Supervisor' group permissions can oversee every user in their department. Departments can be changed at a later time.",
  },
  {
    disableBeacon: true,
    target: "#department-name-0",
    title: "You can add a department name here.",
    content:
      "A department is an organizational unit. Your organization may have a department named 'Casino' which contains 'Dealer', 'Casino Manager', and 'Floor Supervisor' roles. This is where you can create those departments.",
  },
  {
    disableBeacon: true,
    target: "#group-0",
    title: "Select which roles belong to this department.",
    content:
      "Each department can have multiple roles. Select all that apply to this department.",
  },
  {
    disableBeacon: true,
    target: "#addDepartment",
    title: "You can add more departments here.",
    content: "Click this button to add more departments to this form.",
  },
  {
    disableBeacon: true,
    target: "#backButton",
    title: "You can go back at any time.",
    content:
      "If you need to make changes to roles, you can go back by using this button.",
  },
];
