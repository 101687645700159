import { useNavigate, useParams } from "react-router-dom";
import Button from "src/components/Buttons/Button";
import ArrowSubmitSmallIcon from "src/components/Icons/ArrowSubmitSmallIcon";
import EditPencilSmallIcon from "src/components/Icons/EditPencilSmallIcon";
import TrashIcon from "src/components/Icons/TrashIcon";
import { setModalStateAction } from "src/features/modal/handlers";
import { CREATE_DOCUMENT, DELETE_TEMPLATE } from "src/features/modal/titles";
import ListContentPdfViewer from "./ListContentPdfViewer";
import { useAppDispatch } from "src/store";
import ListItemDate from "./ListItemDate";
import useGetTemplates from "../../hooks/useGetTemplates";
import GenericExpandableBoxList from "src/components/Lists/GenericExpandableBoxList";
import { DocumentTemplate } from "../../types/template.types";
import hasGroupPermissions from "src/features/auth/utils/hasGroupPermissions";
import useAuth from "src/features/auth/hooks/useAuth";
import { ORGANIZATION_CRUD_GROUP } from "src/features/groups/groups";
import { ButtonColors } from "src/components/Buttons/buttons.types";

export default function TemplatesList() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { employee } = useAuth();

  const { data: templates, isLoading } = useGetTemplates();

  const handleAssignTemplate = (tempId: string) => () =>
    dispatch(setModalStateAction(tempId)(CREATE_DOCUMENT)(true));

  const handleEditTemplate = (tempId: string) => () =>
    navigate(`/e-signatures/templates/${tempId}/edit`);

  const handleDeleteTemplate = (tempId: string) => () =>
    dispatch(setModalStateAction(tempId)(DELETE_TEMPLATE)(true));

  return (
    <GenericExpandableBoxList<DocumentTemplate>
      items={templates || []}
      isLoading={isLoading}
      boxKey="id"
      boxTitleKey="title"
      placeholderText="No templates yet..."
      defaultExpandedKey="id"
      defaultExpandedValue={id}
      isSelected={(item) => String(item.id) === id}
      titleActions={(item) => (
        <>
          <Button
            color={ButtonColors.Transparent}
            onClick={handleAssignTemplate(String(item.id))}
          >
            <ArrowSubmitSmallIcon />
          </Button>
          {hasGroupPermissions(employee, ORGANIZATION_CRUD_GROUP) ||
          item.created_by === employee?.id ? (
            <>
              <Button
                color={ButtonColors.Transparent}
                onClick={handleEditTemplate(String(item.id))}
              >
                <EditPencilSmallIcon />
              </Button>
              <Button
                onClick={handleDeleteTemplate(String(item.id))}
                color={ButtonColors.Transparent}
                tooltip="Delete Template"
              >
                <TrashIcon />
              </Button>
            </>
          ) : null}
        </>
      )}
      subtitle={(item) => <ListItemDate date={item.created_on} />}
      expandedContent={(item) => <ListContentPdfViewer file={item.file_url} />}
      generateHref={(item) => `/e-signatures/templates/${item.id}/edit`}
    />
  );
}
