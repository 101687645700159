import useVerticalStepperContent from "src/components/Steps/hooks/useVerticalStepperContent";
import { useAppDispatch } from "src/store";
import StepEditButton from "src/features/learningPaths/components/LPBuilder/Steps/StepEditButton";
import StepTwoYellowIcon from "src/components/Icons/StepTwoYellowIcon";
import CheckCircleYellowIcon from "src/components/Icons/CheckCircleYellowIcon";
import StepTwoGrayIcon from "src/components/Icons/StepTwoGrayIcon";
import StepContainer from "src/components/Steps/StepContainer";
import usePolicyFormState from "../../hooks/usePolicyFormState";
import { PolicyFormSteps, setPolicyFormStep } from "../../slices/policySlice";
import PolicyUploadPdfForm from "./PolicyUploadPdfForm";

export default function PolicyFormStepTwo() {
  const dispatch = useAppDispatch();
  const { step } = usePolicyFormState();

  const handleStepButtonClick = () =>
    dispatch(setPolicyFormStep(PolicyFormSteps.UploadPolicy));

  return useVerticalStepperContent({
    currentStep: step,
    activeStep: PolicyFormSteps.UploadPolicy,
    titleAction:
      step !== PolicyFormSteps.AddDetails &&
      step !== PolicyFormSteps.UploadPolicy ? (
        <StepEditButton onClick={handleStepButtonClick} />
      ) : null,
    activeIcon: <StepTwoYellowIcon />,
    inactiveIcon:
      step === PolicyFormSteps.AddDetails ? (
        <StepTwoGrayIcon />
      ) : (
        <CheckCircleYellowIcon />
      ),
    defaultContent: (
      <StepContainer>
        {step !== PolicyFormSteps.AddDetails &&
        step !== PolicyFormSteps.UploadPolicy ? (
          <span className="text-muted">This step is complete!</span>
        ) : (
          <span className="text-muted">
            Please upload a PDF file for your policy. The policy cannot be
            published without a PDF file.
          </span>
        )}
      </StepContainer>
    ),
    activeContent: <PolicyUploadPdfForm />,
  });
}
