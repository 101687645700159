import { useMemo } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import SiteHeader from "src/components/Headers/SiteHeader";
import SearchInput from "src/components/Inputs/SearchInput";
import PageLayout from "src/components/Layout/PageLayout";
import useAuth from "src/features/auth/hooks/useAuth";
import hasGroupPermissions from "src/features/auth/utils/hasGroupPermissions";
import useGetDepartmentQuery from "src/features/departments/hooks/useGetDepartmentQuery";
import { NON_EMPLOYEES_GROUP } from "src/features/groups/groups";
import useSetHeaderTitle from "src/features/header/hooks/useSetHeaderTitle";
import MyWeeklyScheduleSidebar from "../components/EmployeeWeeklyScheduleSidebar";
import SchedulesDepartmentsListSidebar from "../components/SchedulesDepartmentsListSidebar";
import useScheduleSetupStateQuery from "../hooks/useScheduleSetupStateQuery";
import { ScheduleSetupState } from "../services/scheduleSetupService";

export default function SchedulesRoute() {
  const { id } = useParams();
  const { employee } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: scheduleSetupStates, isLoading } = useScheduleSetupStateQuery();

  const isCalendarRoute = location.pathname.includes("schedules/departments");
  const { data: department } = useGetDepartmentQuery({
    id: isCalendarRoute ? id : undefined,
  });
  const isLaborBudgetRoute = location.pathname.includes("labor-budget");
  const isScheduleDashboardRoute = location.pathname.includes("schedules");
  const isMyScheduleRoute = location.pathname.includes("schedules/me");

  const isTimeOffRequestRoute = location.pathname.includes("time-off-requests");
  const isShiftTransferRoute = location.pathname.includes("shift-transfers");
  const isShiftConflictsRoute = location.pathname.includes("shift-conflicts");

  const rightContent = useMemo(() => {
    if (
      isCalendarRoute ||
      isLoading ||
      (hasGroupPermissions(employee, NON_EMPLOYEES_GROUP) &&
        scheduleSetupStates &&
        scheduleSetupStates[0].state !== ScheduleSetupState.Completed)
    ) {
      return null;
    } else if (
      !hasGroupPermissions(employee, NON_EMPLOYEES_GROUP) ||
      isMyScheduleRoute
    ) {
      return <MyWeeklyScheduleSidebar />;
    } else if (isLaborBudgetRoute) {
      return null;
    }

    return <SchedulesDepartmentsListSidebar />;
  }, [
    employee,
    isCalendarRoute,
    isLaborBudgetRoute,
    isLoading,
    isMyScheduleRoute,
    scheduleSetupStates,
  ]);

  const headerTitle = useMemo(() => {
    if (isShiftTransferRoute) {
      return "Shift Transfers";
    } else if (isTimeOffRequestRoute) {
      return "Time Off Requests";
    } else if (isShiftConflictsRoute) {
      return "Shift Conflicts";
    } else if (
      !hasGroupPermissions(employee, NON_EMPLOYEES_GROUP) ||
      isMyScheduleRoute
    ) {
      return "My Schedule";
    } else if (isCalendarRoute && department?.name) {
      return `${department?.name} Schedule`;
    } else if (isLaborBudgetRoute) {
      return "Labor Budget";
    } else {
      return "Schedules Dashboard";
    }
  }, [
    department?.name,
    employee,
    isCalendarRoute,
    isLaborBudgetRoute,
    isMyScheduleRoute,
    isShiftConflictsRoute,
    isShiftTransferRoute,
    isTimeOffRequestRoute,
  ]);

  useSetHeaderTitle(headerTitle);

  const handleNavigateToScheduleDashboard = () => navigate("/schedules");

  return (
    <PageLayout
      header={
        <SiteHeader
          id="schedules-header"
          actions={
            <>
              <SearchInput id="siteSearch" />
              {!isLoading &&
              scheduleSetupStates &&
              scheduleSetupStates[0].state === ScheduleSetupState.Completed ? (
                <>
                  {isCalendarRoute ||
                  isShiftTransferRoute ||
                  isTimeOffRequestRoute ||
                  isMyScheduleRoute ? (
                    <Button
                      id="schedule-button"
                      dataTestid="schedule-button"
                      title={"Schedules"}
                      color={ButtonColors.Yellow}
                      onClick={handleNavigateToScheduleDashboard}
                    />
                  ) : isScheduleDashboardRoute &&
                    hasGroupPermissions(employee, NON_EMPLOYEES_GROUP) ? (
                    <Button
                      id="schedule-button"
                      dataTestid="schedule-button"
                      title={"My Schedule"}
                      color={ButtonColors.Yellow}
                      onClick={() => navigate("/schedules/me")}
                    />
                  ) : null}
                </>
              ) : null}
            </>
          }
        />
      }
      mainContent={<Outlet />}
      rightContent={rightContent}
    />
  );
}
