import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import Button from "src/components/Buttons/Button";
import {
  ButtonColors,
  ButtonSizes,
} from "src/components/Buttons/buttons.types";
import Card from "src/components/Cards/Card";
import CardDetailHeader from "src/components/Cards/CardDetailHeader";
import ErrorFallback from "src/components/Errors/ErrorFallback";
import ListItemText from "src/components/Lists/ListItemText";
import useGetTimeOffRequestByIdQuery from "../hooks/useGetTimeOffRequestByIdQuery";
import TimeOffRequestCard from "./TimeOffRequestCard";
import TimeOffRequestDecisionDialog from "./TimeOffRequestDecisionDialog";
import TimeOffStatusBadge from "./TimeOffStatusBadge";

export default function TimeOffRequestDetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isDecisionDialogOpen, setIsDecisionDialogOpen] = useState(false);

  const {
    data: timeOffRequest,
    isLoading: isTimeOffRequestLoading,
    isError: isTimeOffRequestError,
    refetch: refetchTimeOffRequest,
  } = useGetTimeOffRequestByIdQuery({
    id,
  });

  const handleOpenDecisionDialog = () => {
    setIsDecisionDialogOpen(true);
  };

  const handleCloseDecisionDialog = () => refetchTimeOffRequest();

  return (
    <Card>
      <CardDetailHeader
        info={
          <ListItemText
            title="Time Off Request"
            subTitle={
              timeOffRequest?.status ? (
                <TimeOffStatusBadge status={timeOffRequest?.status} />
              ) : null
            }
          />
        }
        actions={
          <Button
            title={"Go back"}
            color={ButtonColors.Gray}
            size={ButtonSizes.SM}
            onClick={() => navigate(-1)}
          />
        }
      />
      <div className="flex flex-1 flex-col p-10">
        {isTimeOffRequestError ? (
          <ErrorFallback onReload={refetchTimeOffRequest} />
        ) : null}
        {isTimeOffRequestLoading ? <Skeleton height={400} /> : null}
        {!isTimeOffRequestLoading && timeOffRequest ? (
          <>
            <TimeOffRequestCard
              key={timeOffRequest.id}
              request={timeOffRequest}
              onOpenDecisionDialog={handleOpenDecisionDialog}
            />
            <TimeOffRequestDecisionDialog
              timeOffRequest={timeOffRequest}
              isOpen={isDecisionDialogOpen}
              setIsOpen={setIsDecisionDialogOpen}
              onClose={handleCloseDecisionDialog}
            />
          </>
        ) : null}
      </div>
    </Card>
  );
}
