const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#F4B90D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M4.29 1.667v16.666M4.29 3.333h9.332c2.25 0 2.75 1.25 1.167 2.834l-1 1c-.667.666-.667 1.75 0 2.333l1 1c1.583 1.583 1 2.833-1.167 2.833H4.29"
    />
  </svg>
)
export default SvgComponent
