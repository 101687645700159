import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m18.052 8.7-.817 3.483c-.7 3.009-2.083 4.225-4.683 3.975a8.752 8.752 0 0 1-1.35-.225l-1.4-.333c-3.476-.825-4.55-2.542-3.734-6.025l.817-3.492c.166-.708.366-1.325.616-1.833.975-2.017 2.634-2.558 5.417-1.9l1.392.325c3.492.817 4.558 2.542 3.742 6.025Z"
      stroke="#F4B90D"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.55 16.158c-.517.35-1.167.642-1.959.9l-1.317.434c-3.308 1.066-5.05.175-6.125-3.134l-1.066-3.291c-1.067-3.309-.184-5.059 3.125-6.125l1.316-.434c.342-.108.667-.2.975-.258-.25.508-.45 1.125-.616 1.833l-.817 3.492c-.817 3.483.258 5.2 3.733 6.025l1.4.333c.484.117.934.192 1.35.225ZM10.535 7.108l4.042 1.025M9.717 10.333l2.416.617"
      stroke="#F4B90D"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
