import useVerticalStepperContent from "src/components/Steps/hooks/useVerticalStepperContent";
import { MemoFormSteps, setMemoFormStep } from "../../slices/memoSlice";
import useMemoFormState from "../../hooks/useMemoFormState";
import { useAppDispatch } from "src/store";
import StepEditButton from "src/features/learningPaths/components/LPBuilder/Steps/StepEditButton";
import CheckCircleYellowIcon from "src/components/Icons/CheckCircleYellowIcon";
import StepThreeYellowIcon from "src/components/Icons/StepThreeYellowIcon";
import StepContainer from "src/components/Steps/StepContainer";
import StepThreeGrayIcon from "src/components/Icons/StepThreeGrayIcon";
import MemoAssignViewableByForm from "./MemoAssignViewableByForm";

export default function MemoFormStepThree() {
  const dispatch = useAppDispatch();
  const { step } = useMemoFormState();

  const handleStepButtonClick = () =>
    dispatch(setMemoFormStep(MemoFormSteps.AssignViewableBy));

  return useVerticalStepperContent({
    currentStep: step,
    activeStep: MemoFormSteps.AssignViewableBy,
    titleAction:
      step !== MemoFormSteps.UploadFile &&
      step !== MemoFormSteps.AddDetails &&
      step !== MemoFormSteps.AssignViewableBy ? (
        <StepEditButton onClick={handleStepButtonClick} />
      ) : null,
    activeIcon: <StepThreeYellowIcon />,
    inactiveIcon:
      step === MemoFormSteps.AddDetails || step === MemoFormSteps.UploadFile ? (
        <StepThreeGrayIcon />
      ) : (
        <CheckCircleYellowIcon />
      ),
    defaultContent: (
      <StepContainer>
        {step !== MemoFormSteps.UploadFile &&
        step !== MemoFormSteps.AddDetails &&
        step !== MemoFormSteps.AssignViewableBy ? (
          <span className="text-muted">This step is complete!</span>
        ) : (
          <span className="text-muted">
            Specify who can view this memo by assigning it to specific employee
            roles, departments, or the entire organization.
          </span>
        )}
      </StepContainer>
    ),
    activeContent: <MemoAssignViewableByForm />,
  });
}
