import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAlert } from "@blaumaus/react-alert";
import { AxiosError } from "axios";

import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import { useAppDispatch } from "src/store";
import {
  MemoFormSteps,
  setMemoFormStep,
  setSelectedMemoId,
} from "../slices/memoSlice";
import memosService from "../services/memosService";
import { MemoDetailsFormSchemaType } from "../zod/schemas";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

export default function useCreateMemoMutation() {
  const alert = useAlert();
  const handleErrors = useErrorHandling();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    (data: MemoDetailsFormSchemaType) => {
      return memosService.createMemo(data);
    },
    {
      onSuccess: (data) => {
        dispatch(setMemoFormStep(MemoFormSteps.UploadFile));
        dispatch(setSelectedMemoId(data.id));
        queryClient.invalidateQueries([QueryKeys.MemosList]);
        queryClient.setQueryData(["memo", String(data.id)], data);
        alert.success("Memo details saved successfully!");
      },
      onError: (err: AxiosError) => handleErrors(err),
    }
  );
}
