import axiosClient from "src/features/axios/client";
import { ShiftRequirementPartialUpdateTypeForm } from "../components/ShiftRequirementPlanningDialog";
import { CreateShiftRequirementVariables } from "../hooks/useCreateShiftRequirementMutation";

export enum ShiftRequirementStatus {
  Pending = "Pending",
  Published = "Published",
}

export type ShiftRequirementType = {
  id?: number;
  name: string;
  status: ShiftRequirementStatus;
  department: number;
  start_datetime: string;
  end_datetime: string;
  schedule_role: number;
  weekly_schedule: number;
  required_count: number;
  filled_count: number;
};

type GetShiftRequirementsParams = {
  weeklyScheduleId?: number;
  departmentId?: number;
  startDate?: string;
  endDate?: string;
};

class ShiftRequirementsService {
  async createShiftRequirement(
    data: CreateShiftRequirementVariables
  ): Promise<ShiftRequirementType> {
    return await axiosClient
      .post("/api/shift-requirements/", {
        name: data.name,
        department: data.department,
        weekly_schedule: data.weeklySchedule,
        schedule_role: data.scheduleRole,
        start_datetime: data.startDateTime,
        end_datetime: data.endDateTime,
        required_count: data.requiredCount,
        filled_count: data.filledCount,
      })
      .then((res) => res.data);
  }

  async deleteShiftRequirement(id: number): Promise<void> {
    return await axiosClient
      .delete(`/api/shift-requirements/${id}/`)
      .then((res) => res.data);
  }

  async partialUpdateShiftRequirement(
    data: ShiftRequirementPartialUpdateTypeForm
  ): Promise<ShiftRequirementType> {
    return await axiosClient
      .patch(`/api/shift-requirements/${data?.id}/`, data)
      .then((res) => res.data);
  }

  async getShiftRequirements({
    weeklyScheduleId,
    departmentId,
    startDate,
    endDate,
  }: GetShiftRequirementsParams): Promise<ShiftRequirementType[]> {
    const params = Object.fromEntries(
      Object.entries({
        weekly_schedule_id: weeklyScheduleId,
        department_id: departmentId,
        start_date: startDate,
        end_date: endDate,
      }).filter(([_, value]) => value !== undefined)
    ) as GetShiftRequirementsParams;
    return await axiosClient
      .get("/api/shift-requirements", { params })
      .then((res) => res.data);
  }
}

const shiftRequirementsService = new ShiftRequirementsService();

export default shiftRequirementsService;
