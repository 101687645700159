import { useMemo } from "react";
import {
  MdAttachMoney,
  MdOutlineWarningAmber,
  MdSchedule,
  MdSwapHoriz,
} from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import CardSectionHeader from "src/components/Cards/CardSectionHeader";
import ErrorFallback from "src/components/Errors/ErrorFallback";
import ProgressBar from "src/components/ProgressBar/ProgressBar";
import useAuth from "src/features/auth/hooks/useAuth";
import hasGroupPermissions from "src/features/auth/utils/hasGroupPermissions";
import { DepartmentDetail } from "src/features/departments/types/departments.type";
import { ORGANIZATION_CRUD_GROUP } from "src/features/groups/groups";
import useGetLaborBudgetsByStartAndEndDateQuery from "../hooks/useGetLaborBudgetsByStartAndEndDateQuery";
import useGetOrCreateLaborBudgetSettingsQuery from "../hooks/useGetOrCreateLaborBudgetSettingsQuery";
import useGetScheduleRolesQuery from "../hooks/useGetScheduleRolesQuery";
import useGetShiftConflictsByDateQuery from "../hooks/useGetShiftConflictsByDateQuery";
import useGetShiftRequirementsByDepartmentAndDatesQuery from "../hooks/useGetShiftRequirementsByDepartmentAndDatesQuery";
import useGetShiftTransfersByDatesQuery from "../hooks/useGetShiftTransfersByStartDate";
import useGetTimeOffRequestsByScheduleRoleQuery from "../hooks/useGetTimeOffRequestsByScheduleRoleQuery";
import useWeekFromUrl from "../hooks/useWeekFromUrl";
import { ShiftConflictResolutionStatus } from "../services/shiftConflictsService";
import { ShiftTransferStatus } from "../services/shiftTransferService";
import { TimeOffStatus } from "../services/timeOffRequestService";

type DepartmentSidebarCardProps = {
  department: DepartmentDetail;
};

export default function SchedulesDepartmentSidebarCard({
  department,
}: DepartmentSidebarCardProps) {
  const navigate = useNavigate();
  const { employee } = useAuth();
  const { startOfWeekDate, endOfWeekDate } = useWeekFromUrl();

  const {
    data: scheduleRoles,
    isLoading: isScheduleRolesLoading,
    isError: isScheduleRolesError,
    refetch: refetchScheduleRoles,
  } = useGetScheduleRolesQuery({
    departmentId: department.id,
  });

  const {
    data: shiftRequirements,
    isLoading: isShiftRequirementsLoading,
    isError: isShiftRequirementsError,
    refetch: refetchShiftRequirements,
  } = useGetShiftRequirementsByDepartmentAndDatesQuery({
    departmentId: department.id,
    startDate: startOfWeekDate.toISOString().slice(0, 10),
    endDate: endOfWeekDate.toISOString().slice(0, 10),
  });

  const {
    data: timeOffRequests,
    isInitialLoading: isTimeOffRequestsLoading,
    isError: isTimeOffRequestsError,
    refetch: refetchTimeOffRequests,
  } = useGetTimeOffRequestsByScheduleRoleQuery({
    scheduleRole:
      scheduleRoles && scheduleRoles.map((role) => role.id).join(","),
    startDate: startOfWeekDate.toISOString().slice(0, 10),
    endDate: endOfWeekDate.toISOString().slice(0, 10),
    status: TimeOffStatus.Pending,
  });

  const {
    data: shiftTransfers,
    isLoading: isShiftTransfersLoading,
    isError: isShiftTransfersError,
    refetch: refetchShiftTransfers,
  } = useGetShiftTransfersByDatesQuery({
    enabled: department?.id !== undefined,
    startDate: startOfWeekDate.toISOString().slice(0, 10),
    departmentId: department.id,
    status: `${ShiftTransferStatus.Pending}`,
  });

  const {
    data: laborBudgets,
    isLoading: isLaborBudgetsLoading,
    isError: isLaborBudgetsError,
    refetch: refetchLaborBudgets,
  } = useGetLaborBudgetsByStartAndEndDateQuery({
    startDate: startOfWeekDate.toISOString().slice(0, 10),
    endDate: endOfWeekDate.toISOString().slice(0, 10),
    departmentId: department.id,
  });

  const {
    data: shiftConflicts,
    isLoading: isShiftConflictsLoading,
    isError: isShiftConflictsError,
    refetch: refetchShiftConflicts,
  } = useGetShiftConflictsByDateQuery({
    startDate: startOfWeekDate.toISOString().slice(0, 10),
    resolutionStatus: ShiftConflictResolutionStatus.Unresolved,
    departmentId: department.id,
  });

  const { data: laborBudgetSettings } = useGetOrCreateLaborBudgetSettingsQuery(
    {}
  );

  const laborBudgetsTotal = useMemo(
    () =>
      laborBudgets?.reduce(
        (acc, budget) => acc + Number(budget.scheduled_amount),
        0
      ),
    [laborBudgets]
  );

  const filledShifts = useMemo(
    () =>
      shiftRequirements?.reduce((acc, shift) => acc + shift.filled_count, 0) ||
      0,
    [shiftRequirements]
  );

  const requiredShifts = useMemo(
    () =>
      shiftRequirements?.reduce(
        (acc, shift) => acc + shift.required_count,
        0
      ) || 0,
    [shiftRequirements]
  );

  const progress = useMemo(
    () => (requiredShifts > 0 ? (filledShifts / requiredShifts) * 100 : 0),
    [filledShifts, requiredShifts]
  );

  const isAnyLoading =
    isShiftRequirementsLoading ||
    isTimeOffRequestsLoading ||
    isScheduleRolesLoading ||
    isShiftTransfersLoading ||
    isLaborBudgetsLoading ||
    isShiftConflictsLoading;

  const isError =
    isShiftRequirementsError ||
    isTimeOffRequestsError ||
    isScheduleRolesError ||
    isShiftTransfersError ||
    isLaborBudgetsError ||
    isShiftConflictsError;

  const handleRefetchAll = async () => {
    await refetchScheduleRoles().then(() => {
      refetchShiftRequirements();
      refetchTimeOffRequests();
      refetchShiftTransfers();
      refetchLaborBudgets();
      refetchShiftConflicts();
    });
  };

  const handleClickDepartment = (id: number) =>
    navigate(
      `/schedules/departments/${id}?startOfWeekDate=${startOfWeekDate
        .toISOString()
        .slice(0, 10)}`
    );

  const handleOpenNoScheduleRolesDialog = (departmentId: number) => {};

  return (
    <>
      <CardSectionHeader title={department.name} key={department.id} />
      {isError ? (
        <div className="flex darkListItemContainer h-unset mh-200 cursor-pointer p-10 border-radius-10 gap-10">
          <ErrorFallback
            onReload={handleRefetchAll}
            error={
              "An error occurred loading this departments data. It has been reported and will be investigated."
            }
          />
        </div>
      ) : null}
      {!isError ? (
        <div
          className="flex darkListItemContainer h-unset cursor-pointer p-10 border-radius-10 gap-10"
          onClick={
            scheduleRoles && scheduleRoles.length > 0
              ? () => handleClickDepartment(department.id)
              : () => handleOpenNoScheduleRolesDialog(department.id)
          }
        >
          {isAnyLoading ? <Skeleton height={160} /> : null}
          {!isAnyLoading ? (
            <>
              <div className="flex flex-row gap-10 align-center">
                <div className="flex-1">
                  <div className="flex-1 flex-row">
                    <span className="flex-1 text-sm">Shifts</span>
                    <span className="justify-end align-center text-right text-sm font-bold">
                      {filledShifts}/{requiredShifts}
                    </span>
                  </div>
                  <div className="flex-1 py-10">
                    <ProgressBar
                      progress={progress > 100 ? 100 : progress}
                      progressClassName={
                        progress > 100
                          ? "bg-cp-danger"
                          : progress === 100
                          ? "bg-cp-green"
                          : ""
                      }
                      containerClassName="bg-cp-yellow-10"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-row gap-10 align-center">
                <div className="flex-1">
                  <div className="flex-1 flex-row">
                    <span className="flex-1 text-sm align-center gap-10">
                      <MdSwapHoriz size={20} color="#bbb" />
                      Time-Off Requests
                    </span>
                    <span className="justify-end align-center text-right text-sm font-bold">
                      {timeOffRequests?.length || 0}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-row gap-10 align-center ">
                <div className="flex-1">
                  <div className="flex-1 flex-row">
                    <span className="flex-1 text-sm align-center gap-10">
                      <MdSchedule size={20} color="#bbb" />
                      Shift Transfers
                    </span>
                    <span className="justify-end align-center text-right text-sm font-bold">
                      {shiftTransfers?.length || 0}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-row gap-10 align-center">
                <div className="flex-1">
                  <div className="flex-1 flex-row">
                    <span className="flex-1 text-sm align-center gap-10">
                      <MdOutlineWarningAmber size={20} color="#bbb" />
                      Shift Conflicts
                    </span>
                    <span className="justify-end align-center text-right text-sm font-bold">
                      {shiftConflicts?.length || 0}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-row gap-10 align-center">
                <div className="flex-1">
                  <div className="flex-1 flex-row">
                    <span className="flex-1 align-center text-sm gap-10">
                      <MdAttachMoney size={20} color="#bbb" />
                      Labor Budget
                    </span>
                    <span className="justify-end align-center text-right text-sm font-bold">
                      {(laborBudgetSettings &&
                        !laborBudgetSettings?.hide_labor_budget) ||
                      hasGroupPermissions(employee, ORGANIZATION_CRUD_GROUP) ? (
                        <>
                          ${Math.floor(laborBudgetsTotal || 0).toLocaleString()}
                        </>
                      ) : (
                        <span>-</span>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      ) : null}
    </>
  );
}
