import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import shiftRequirementsService from "../services/shiftRequirementsService";

type UseGetShiftRequirementsByDatesQueryProps = {
  startDate?: string;
  endDate?: string;
};

export default function useGetShiftRequirementsByDatesQuery({
  startDate,
  endDate,
}: UseGetShiftRequirementsByDatesQueryProps) {
  return useQuery({
    queryFn:
      startDate && endDate
        ? () =>
            shiftRequirementsService.getShiftRequirements({
              startDate,
              endDate,
            })
        : undefined,
    queryKey: [QueryKeys.ShiftRequirementsList, startDate, endDate],
    enabled: !!startDate && !!endDate,
  });
}
