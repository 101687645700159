import { useNavigate, useParams } from "react-router-dom";
import useGetDocumentSignatoryOrders from "../hooks/useGetDocumentSignatoryOrders";
import DocumentApprovalForm from "../components/Documents/DocumentApprovalForm";
import DocumentSignForm from "../components/Documents/DocumentSignForm";
import LoadingAnimation from "src/components/Animations/LoadingAnimation";
import useAuth from "src/features/auth/hooks/useAuth";

export default function DocumentSignRoute() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { employee } = useAuth();

  const { data: signatoryOrders, isLoading } = useGetDocumentSignatoryOrders({
    documentId: id,
  });

  if (isLoading || !signatoryOrders) {
    return <LoadingAnimation />;
  }

  const nextSignatory = signatoryOrders
    .filter(({ signed_on, approved_on }) => !signed_on && !approved_on)
    .sort((a, b) => a.order - b.order)[0];

  if (!nextSignatory || nextSignatory.employee?.id !== employee?.id) {
    const documentsTab = nextSignatory ? "Pending" : "Completed";
    navigate(`/e-signatures/documents/${id}?documentsTab=${documentsTab}`);
    return <LoadingAnimation />;
  }

  if (nextSignatory.approval_required) {
    return <DocumentApprovalForm />;
  }

  return <DocumentSignForm />;
}
