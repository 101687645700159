import formatDateTimeToTime from "src/features/datetime/utils/formatDateTimeToTime";
import {
  ShiftRequirementStatus,
  ShiftRequirementType,
} from "../services/shiftRequirementsService";

type ShiftRequirementBoxProps = {
  title?: string;
  shift: ShiftRequirementType;
  onClick: (shift: ShiftRequirementType) => void;
};

export default function ScheduleCalendarBox({
  title,
  shift,
  onClick,
}: ShiftRequirementBoxProps) {
  const isPastShift = new Date(shift.start_datetime) < new Date();
  return (
    <div
      className={
        shift?.filled_count === shift.required_count &&
        shift.status === ShiftRequirementStatus.Published
          ? "bg-cp-yellow"
          : shift?.filled_count > shift.required_count &&
            shift.status === ShiftRequirementStatus.Published
          ? "bg-cp-danger"
          : shift?.filled_count > shift.required_count &&
            shift.status === ShiftRequirementStatus.Pending
          ? "bg-pending-danger"
          : shift?.filled_count > 0 &&
            shift.status === ShiftRequirementStatus.Published
          ? "bg-cp-yellow-50"
          : shift?.filled_count > 0 &&
            shift?.filled_count < shift.required_count &&
            shift.status === ShiftRequirementStatus.Pending
          ? "bg-pending-yellow-50"
          : shift?.filled_count === 0 &&
            shift.status === ShiftRequirementStatus.Published
          ? "bg-cp-yellow-10"
          : shift?.filled_count === 0 &&
            shift.status === ShiftRequirementStatus.Pending
          ? "bg-pending-yellow-10"
          : "bg-pending-yellow"
      }
      style={{
        color:
          shift?.filled_count === shift.required_count
            ? "var(--clearpath-black)"
            : "var(--clearpath-white)",
        padding: "8px",
        borderRadius: "4px",
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
        cursor: "pointer",
        fontSize: "12px",
        textAlign: "center",
        opacity: isPastShift ? 0.5 : 1,
      }}
      onClick={() => onClick(shift)}
    >
      <div>{title}</div>
      <div className="font-bold">{shift.name}</div>
      <div className="text-xxs">
        {formatDateTimeToTime(shift.start_datetime)} -{" "}
        {formatDateTimeToTime(shift.end_datetime)}
      </div>
    </div>
  );
}
