import { useNavigate, useParams } from "react-router-dom";
import Button from "src/components/Buttons/Button";
import ArrowSubmitSmallIcon from "src/components/Icons/ArrowSubmitSmallIcon";
import TrashIcon from "src/components/Icons/TrashIcon";
import DocumentAnnotationsAssignmentStepper from "../Documents/DocumentAnnotationsAssignmentStepper";
import { setModalStateAction } from "src/features/modal/handlers";
import { DELETE_DOCUMENT } from "src/features/modal/titles";
import { useAppDispatch } from "src/store";
import ListItemDate from "./ListItemDate";
import useGetDocuments from "../../hooks/useGetDocuments";
import { Document } from "../../types/document.types";
import GenericExpandableBoxList from "src/components/Lists/GenericExpandableBoxList";
import hasGroupPermissions from "src/features/auth/utils/hasGroupPermissions";
import { ORGANIZATION_CRUD_GROUP } from "src/features/groups/groups";
import useAuth from "src/features/auth/hooks/useAuth";
import { ButtonColors } from "src/components/Buttons/buttons.types";

export default function DraftDocumentsList() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { employee } = useAuth();
  const { data: documents, isLoading } = useGetDocuments({ status: 0 });

  const handleDeleteDocument = (docId: string) => () =>
    dispatch(setModalStateAction(docId)(DELETE_DOCUMENT)(true));

  const handleAssignDocument = (docId: string) => () =>
    navigate(`/e-signatures/documents/${docId}/assign/`);

  return (
    <GenericExpandableBoxList<Document>
      items={documents || []}
      isLoading={isLoading}
      boxKey="id"
      boxTitleKey="title"
      placeholderText="No drafts yet..."
      defaultExpandedKey="id"
      defaultExpandedValue={id}
      isSelected={(item) => String(item.id) === id}
      titleActions={(item) => (
        <>
          <Button
            color={ButtonColors.Transparent}
            onClick={handleAssignDocument(String(item.id))}
          >
            <ArrowSubmitSmallIcon />
          </Button>
          {hasGroupPermissions(employee, ORGANIZATION_CRUD_GROUP) ||
          item.created_by === employee?.id ? (
            <Button
              onClick={handleDeleteDocument(String(item.id))}
              color={ButtonColors.Transparent}
              tooltip="Delete Document"
            >
              <TrashIcon />
            </Button>
          ) : null}
        </>
      )}
      subtitle={(item) => <ListItemDate date={item.created_on} />}
      expandedContent={() => <DocumentAnnotationsAssignmentStepper />}
      generateHref={(item) => `/e-signatures/documents/${item.id}/assign/`}
    />
  );
}
