import { useCallback, useMemo } from "react";

import { Page, Document } from "react-pdf";
import LoadingAnimation from "src/components/Animations/LoadingAnimation";
import PdfViewerPagesContainer from "./PdfViewerPagesContainer";
import PdfThumbnail from "./PdfThumbnail";
import PdfViewerContainer from "./PdfViewerContainer";
import PdfViewerThumbnailListContainer from "./PdfViewerThumbnailListContainer";
import useIntersectionObserver from "src/hooks/useIntersectionObserver";
import { ContainerSize } from "../../routes/DocumentDetailRoute";
import styles from "../ESignatures.module.css";
import { useAppDispatch } from "src/store";
import {
  setContainerSize,
  setNumPages,
  setPageNumber,
} from "../../slices/documentViewerSlice";
import useDocumentViewer from "../../hooks/useDocumentViewer";

interface BaseAnnotation {
  id?: string | number;
  page: number;
  uuid: string;
}

type VerticalDocumentViewerProps<T extends BaseAnnotation> = {
  fileUrl?: string | null;
  isLoading: boolean;
  pageRefs: React.MutableRefObject<(HTMLDivElement | null)[]>;
  annotations?: T[];
  loadingAnimation?: React.ReactNode;
  renderAnnotation?: (annotation: T, key: string) => React.ReactNode;
};

function VerticalDocumentViewer<T extends BaseAnnotation>({
  fileUrl,
  isLoading = false,
  pageRefs,
  annotations,
  loadingAnimation = <LoadingAnimation />,
  renderAnnotation,
}: VerticalDocumentViewerProps<T>) {
  const dispatch = useAppDispatch();
  const { numPages, pageNumber, containerSize } = useDocumentViewer();

  const opt = useMemo(() => {
    return {
      cMapUrl: "/bcmaps/",
      cMapPacked: true,
    };
  }, []);

  const handleLoadSuccess = useCallback(
    ({ numPages }: { numPages: number | null }) => {
      dispatch(setNumPages(numPages));
      // Only reset to page 1 if it's the first time loading the document.
      if (
        !pageNumber ||
        pageNumber < 1 ||
        (numPages && pageNumber > numPages)
      ) {
        setTimeout(() => dispatch(setPageNumber(1)), 200);
      }
    },
    [dispatch, pageNumber]
  );

  const handleRenderSuccess = useCallback(
    (pageSize: ContainerSize) => dispatch(setContainerSize(pageSize)),
    [dispatch]
  );

  const handleThumbnailClick = useCallback(
    (page: number) => {
      dispatch(setPageNumber(page));
      const pageRef = pageRefs.current[page - 1];
      if (pageRef) {
        pageRef.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
    [dispatch, pageRefs]
  );

  useIntersectionObserver({
    refs: pageRefs,
    onIntersect: (entry) => {
      const pageNumberString = entry.target.getAttribute("data-page-number");
      if (pageNumberString !== null) {
        dispatch(setPageNumber(parseInt(pageNumberString)));
      }
    },
  });

  return (
    <div className={styles.rowContainer}>
      <PdfViewerThumbnailListContainer>
        {numPages && fileUrl
          ? Array.from({ length: numPages }).map((_, index) => (
              <PdfThumbnail
                key={index + 1}
                fileSrc={fileUrl}
                pageNumber={index + 1}
                currentPage={pageNumber}
                onThumbnailClick={handleThumbnailClick}
              />
            ))
          : null}
      </PdfViewerThumbnailListContainer>
      <PdfViewerContainer>
        {!isLoading && fileUrl ? (
          <PdfViewerPagesContainer>
            <Document
              file={fileUrl}
              onLoadSuccess={handleLoadSuccess}
              loading={loadingAnimation}
              options={opt}
            >
              {numPages &&
                Array.from({ length: numPages }, (_, index) => (
                  <div
                    key={index + 1}
                    ref={(el) => (pageRefs.current[index] = el)}
                    data-page-number={index + 1}
                    style={{
                      marginBottom: "20px",
                      position: "relative",
                      width: containerSize.width
                        ? `${containerSize.width}px`
                        : "auto",
                      height: containerSize.height
                        ? `${containerSize.height}px`
                        : "auto",
                    }}
                  >
                    <Page
                      pageNumber={index + 1}
                      onRenderSuccess={handleRenderSuccess}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                    />
                    {annotations?.length && renderAnnotation
                      ? annotations
                          .filter((ann) => ann.page === index + 1)
                          .map((ann) => renderAnnotation(ann, ann.uuid))
                      : null}
                  </div>
                ))}
            </Document>
          </PdfViewerPagesContainer>
        ) : null}
      </PdfViewerContainer>
    </div>
  );
}

export default VerticalDocumentViewer;
