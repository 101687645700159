import { ALL_GROUPS } from "src/features/groups/groups";
import DocumentSignRoute from "./ESignaturesWithSidebarRoute";
import { UrlRoutes } from "src/features/site/types/routes.types";
import withProtectedRoute from "src/features/auth/components/withProtectedRoute";

export default withProtectedRoute(
  DocumentSignRoute,
  UrlRoutes.Dashboard,
  ALL_GROUPS
);
