import { useForm } from "react-hook-form";
import { RotatingLines } from "react-loader-spinner";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import Dialog from "src/components/Dialog/Dialog";
import DialogContent from "src/components/Dialog/DialogContent";
import DialogDescription from "src/components/Dialog/DialogDescription";
import DialogFooter from "src/components/Dialog/DialogFooter";
import DialogHeader from "src/components/Dialog/DialogHeader";
import InputContainerV2 from "src/components/Inputs/InputContainerV2";
import InputLabel from "src/components/Inputs/InputLabel";
import usePartialUpdateLaborBudgetMutation from "../hooks/usePartialUpdateLaborBudgetMutation";
import { LaborBudget } from "../services/laborBudgetService";

type LaborBudgetScheduleRoleEditDialogProps = {
  laborBudget: LaborBudget;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export default function LaborBudgetScheduleRoleFormDialog({
  laborBudget,
  isOpen,
  setIsOpen,
}: LaborBudgetScheduleRoleEditDialogProps) {
  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      budget_hours: laborBudget.budget_hours,
      budget_amount: laborBudget.budget_amount,
    },
  });

  const partialUpdateLaborBudgetMutation = usePartialUpdateLaborBudgetMutation({
    onSuccess: () => {
      setIsOpen(false);
    },
  });

  const onSubmitLaborBudget = (data: Partial<LaborBudget>) => {
    partialUpdateLaborBudgetMutation.mutate({
      id: laborBudget.id,
      ...data,
    });
  };

  return (
    <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
      <DialogHeader>Labor Budget</DialogHeader>
      <DialogDescription>Edit labor budget.</DialogDescription>
      <form
        onSubmit={handleSubmit(onSubmitLaborBudget)}
        className="dialog-form"
      >
        <DialogContent>
          <InputContainerV2>
            <InputLabel>Budget Hours</InputLabel>
            <input
              {...register("budget_hours")}
              className={
                formState.errors.budget_hours ? "inputBlackError" : "inputBlack"
              }
              type="number"
              min={0}
            />
            {formState.errors.budget_hours && (
              <span className={"inputError"}>
                {formState.errors.budget_hours.message}
              </span>
            )}
          </InputContainerV2>
          <InputContainerV2>
            <InputLabel>Budget Amount</InputLabel>
            <input
              {...register("budget_amount")}
              className={
                formState.errors.budget_amount
                  ? "inputBlackError"
                  : "inputBlack"
              }
              type="number"
              min={0}
            />
            {formState.errors.budget_amount && (
              <span className={"inputError"}>
                {formState.errors.budget_amount.message}
              </span>
            )}
          </InputContainerV2>
        </DialogContent>
        <DialogFooter>
          <Button
            color={ButtonColors.GrayAndYellow}
            onClick={() => setIsOpen(false)}
            disabled={partialUpdateLaborBudgetMutation.isLoading}
          >
            {partialUpdateLaborBudgetMutation.isLoading ? (
              <RotatingLines
                strokeColor="#f1b70c"
                strokeWidth="5"
                animationDuration="0.75"
                width="20"
                visible={true}
              />
            ) : (
              "Cancel"
            )}
          </Button>
          <Button
            color={ButtonColors.Yellow}
            type="submit"
            disabled={partialUpdateLaborBudgetMutation.isLoading}
          >
            {partialUpdateLaborBudgetMutation.isLoading ? (
              <RotatingLines
                strokeColor="#000"
                strokeWidth="5"
                animationDuration="0.75"
                width="20"
                visible={true}
              />
            ) : (
              "Save Labor Budget"
            )}
          </Button>
        </DialogFooter>
      </form>
    </Dialog>
  );
}
