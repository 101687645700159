const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={34}
    height={34}
    fill="none"
    {...props}
  >
    <circle cx={17} cy={17} r={16.5} fill="#232227" stroke="#4B4B4B" />
    <path
      fill="#fff"
      d="M16.983 24.24c-.92 0-1.753-.16-2.5-.48a4.159 4.159 0 0 1-1.8-1.52c-.453-.68-.693-1.533-.72-2.56h2.52c.013.68.233 1.253.66 1.72.44.453 1.053.68 1.84.68.747 0 1.32-.207 1.72-.62.4-.413.6-.933.6-1.56 0-.733-.267-1.287-.8-1.66-.52-.387-1.193-.58-2.02-.58h-1.04v-2.1h1.06c.68 0 1.247-.16 1.7-.48.453-.32.68-.793.68-1.42 0-.52-.173-.933-.52-1.24-.333-.32-.8-.48-1.4-.48-.653 0-1.167.193-1.54.58-.36.387-.56.86-.6 1.42h-2.5c.053-1.293.5-2.313 1.34-3.06.853-.747 1.953-1.12 3.3-1.12.96 0 1.767.173 2.42.52.667.333 1.167.78 1.5 1.34.347.56.52 1.18.52 1.86 0 .787-.22 1.453-.66 2a3.13 3.13 0 0 1-1.6 1.08 3.21 3.21 0 0 1 1.92 1.16c.493.6.74 1.36.74 2.28 0 .773-.187 1.48-.56 2.12-.373.64-.92 1.153-1.64 1.54-.707.387-1.58.58-2.62.58Z"
    />
  </svg>
)
export default SvgComponent
