import { useMutation } from "@tanstack/react-query";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import shiftTransferService, {
  ShiftTransfer,
} from "../services/shiftTransferService";

type CreateShiftTransferMutation = {
  onSuccess: (data: ShiftTransfer) => void;
};

export default function useCreateShiftTransferMutation({
  onSuccess,
}: CreateShiftTransferMutation) {
  const handleErrors = useErrorHandling();
  return useMutation({
    mutationFn: shiftTransferService.createShiftTransfer,
    onSuccess,
    onError: handleErrors,
  });
}
