import { AuthenticatedEmployee } from "src/features/employees/types/employees.type";
import { Role } from "src/features/forms/components/NestedCheckBoxesInput";

export type DocumentTemplate = {
  id: number;
  title: string;
  description: string;
  self_assignable: boolean;
  approval_required_by?: number;
  file_url?: string;
  name?: string;
  ext?: string;
  created_on: Date;
  updated_on: Date;
  created_by?: AuthenticatedEmployee | string | number | null;
  viewable_by: Role[];
};

export enum AnnotationType {
  Approval = "approval",
  Denial = "denial",
  Signature = "signature",
  TextField = "textField",
  Text = "text",
  Image = "image",
  Checkbox = "checkbox",
  Dropdown = "dropdown",
  OptionList = "optionList",
  RadioGroup = "radioGroup",
}

export type TemplateAnnotationOption = {
  id?: string;
  annotation: string;
  value: string;
  created_on?: string;
  updated_on?: string;
};

export type TemplateAnnotation = {
  id?: string;
  uuid: string;
  type: AnnotationType;
  template: number;
  options?: TemplateAnnotationOption[];
  page: number;
  x: number;
  y: number;
  width: number;
  height: number;
  created_on?: string;
  updated_on?: string;
};

export type TemplateTextAnnotation = {
  page: number;
  x: number;
  y: number;
  width: number;
  height: number;
};
