import Badge from "src/components/Badges/Badge";
import { TimeOffStatus } from "../services/timeOffRequestService";

type BadgeProps = {
  status: TimeOffStatus;
};

export default function TimeOffStatusBadge({ status }: BadgeProps) {
  const badgeColors = {
    Approved: "var(--clearpath-green)",
    Pending: "var(--clearpath-yellow)",
    Declined: "var(--clearpath-danger)",
  };

  return <Badge color={badgeColors[status]} text={status} />;
}
