import { createSlice } from "@reduxjs/toolkit";
import { GeneratedQuestion } from "../../questions/slices/questionFormSlice";

export enum AssessmentFormSteps {
  AddDetails = "Add Details",
  AddQuestions = "Add Questions",
  AssignViewableBy = "Assign Viewable By",
  ScheduleAssessment = "Schedule Assessment",
  SelectAssessment = "Select Assessment",
  PublishAssessment = "Publish Assessment",
}

export enum AssessmentFormBtns {
  AddNewQuestion = "Add New Question",
  GenerateAndBack = "Generate and Back",
  SaveQuestionAndBack = "Save Question and Back",
}

export type AssessmentFormState = {
  step: AssessmentFormSteps | null;
  buttons: AssessmentFormBtns | null;
  generatedQuestions: GeneratedQuestion[];
  selectedAssessmentId: string | null;
};

const initialState: AssessmentFormState = {
  step: null,
  buttons: null,
  generatedQuestions: [],
  selectedAssessmentId: null,
};

const assessmentFormSlice = createSlice({
  name: "assessment",
  initialState,
  reducers: {
    addOrUpdateQuestion(state, action) {
      const questionIndex = state.generatedQuestions.findIndex(
        (q: GeneratedQuestion) => q.id === action.payload.id
      );
      if (questionIndex !== undefined && questionIndex !== -1) {
        state.generatedQuestions[questionIndex] = action.payload;
      } else {
        state.generatedQuestions.push(action.payload);
      }
    },
    resetAssessmentFormState(state) {
      state.step = AssessmentFormSteps.AddDetails;
      state.buttons = null;
      state.generatedQuestions = [];
      state.selectedAssessmentId = null;
    },
    setAssessmentFormStep(state, action) {
      state.step = action.payload;
    },
    setAssessmentFormBtns(state, action) {
      state.buttons = action.payload;
    },
    setGeneratedQuestions(state, action) {
      state.generatedQuestions = action.payload;
    },
    setSelectedAssessmentId(state, action) {
      state.selectedAssessmentId = action.payload;
    },
  },
});

export default assessmentFormSlice.reducer;

export const {
  addOrUpdateQuestion,
  resetAssessmentFormState,
  setAssessmentFormStep,
  setAssessmentFormBtns,
  setGeneratedQuestions,
  setSelectedAssessmentId,
} = assessmentFormSlice.actions;
