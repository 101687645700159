import { useMutation } from "@tanstack/react-query";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import laborBudgetService, {
  LaborBudget,
} from "../services/laborBudgetService";

type PartialUpdateLaborBudgetMutation = {
  onSuccess?: (data: LaborBudget) => void;
};

export default function usePartialUpdateLaborBudgetMutation({
  onSuccess,
}: PartialUpdateLaborBudgetMutation) {
  const handleErrors = useErrorHandling();

  return useMutation({
    mutationFn: async (data: Partial<LaborBudget>) =>
      laborBudgetService.partialUpdateLaborBudget(data),
    onSuccess,
    onError: handleErrors,
  });
}
