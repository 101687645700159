import { useState, useEffect, useMemo, useCallback } from "react";
import { useAlert } from "@blaumaus/react-alert";

import formatTimeToHrMinSec from "src/features/datetime/utils/formatTimeToHrMinSec";
import calculateProgressBarPercentageFromTime from "src/features/progress/utils/calculateProgressBarPercentageFromTime";
import styles from "./ProgressBarTimer.module.css";

type ProgressBarTimerProps = {
  timeLimit: number;
  startTime: string;
  currentTime: string;
  currentQuestionNum?: number;
  totalQuestions?: number;
  onTimeOutCallback: () => void;
};

export default function ProgressBarTimer({
  timeLimit,
  startTime,
  currentTime,
  currentQuestionNum,
  totalQuestions,
  onTimeOutCallback,
}: ProgressBarTimerProps) {
  const alert = useAlert();
  const [hasAlerted, setHasAlerted] = useState(false);

  const timeLimitInMin = useMemo(() => timeLimit * 60 * 1000, [timeLimit]);

  const calculateTimeRemaining = useCallback(() => {
    const start = new Date(startTime).getTime();
    const current = new Date(currentTime).getTime();

    if (isNaN(start) || isNaN(current)) {
      return 0;
    }

    const timeLeft = timeLimitInMin - (current - start);

    return timeLeft > 0 ? timeLeft : 0;
  }, [startTime, currentTime, timeLimitInMin]);

  const [timeRemaining, setTimeRemaining] = useState<number>(
    calculateTimeRemaining()
  );

  useEffect(() => {
    const initialTimeRemaining = calculateTimeRemaining();

    setTimeRemaining(initialTimeRemaining);

    const interval = setInterval(() => {
      setTimeRemaining((prevTime) => {
        const newTime = prevTime - 1000;
        if (newTime <= 0) {
          clearInterval(interval);
          if (!hasAlerted) {
            alert.error(
              "Time's up! The rest of the questions will be auto-submitted as incorrect."
            );
            onTimeOutCallback?.();
            setHasAlerted(true);
          }
          return 0;
        }
        return newTime;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [
    startTime,
    currentTime,
    timeLimitInMin,
    hasAlerted,
    onTimeOutCallback,
    alert,
    calculateTimeRemaining,
  ]);

  const formattedTime = formatTimeToHrMinSec(timeRemaining);

  const progressBarPercentage = calculateProgressBarPercentageFromTime(
    timeRemaining,
    timeLimitInMin
  );

  return (
    <div className={styles.timerContainer}>
      <div className={styles.timerProgressBarContainer}>
        <div
          className={styles.timerProgressBar}
          style={{ width: `${progressBarPercentage}%` }}
        ></div>
      </div>
      <span className={styles.timerText}>Timer: {formattedTime}</span>
      <span className={styles.timerText}>
        Question: {currentQuestionNum}/{totalQuestions}
      </span>
    </div>
  );
}
