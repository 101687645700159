import { ShiftTransferStatus } from "../services/shiftTransferService";

export const getShiftTransferBadgeColor = (status: ShiftTransferStatus) => {
  switch (status) {
    case ShiftTransferStatus.Available:
      return "var(--clearpath-blue)";
    case ShiftTransferStatus.Approved:
      return "var(--clearpath-green)";
    case ShiftTransferStatus.Pending:
      return "var(--clearpath-yellow)";
    case ShiftTransferStatus.Declined:
      return "var(--clearpath-danger)";
    default:
      return "var(--clearpath-yellow)";
  }
};
