import React from "react";
import Skeleton from "react-loading-skeleton";
import { Document } from "../types/document.types";
import DocumentListItem from "./DocumentListItem";
import { DocumentTemplate } from "../types/template.types";

type DocumentTabProps = {
  documents: Document[] | DocumentTemplate[];
  isLoading: boolean;
  fileField: "file_url" | "newest_version";
  onDocumentClick?: (
    setIsFilterVisible: (value: boolean) => void,
    document: Document | DocumentTemplate
  ) => void;
  renderButton: (document: Document | DocumentTemplate) => React.ReactNode;
  dropDownItems?: (
    setIsFilterVisible: (value: boolean) => void,
    document: Document | DocumentTemplate
  ) => React.ReactNode;
  placeholderText?: string;
};

function DocumentsTab({
  documents = [],
  fileField,
  isLoading,
  onDocumentClick,
  renderButton,
  dropDownItems,
  placeholderText = "No documents found.",
}: DocumentTabProps) {
  const isDesktop = window.innerWidth >= 768;
  const columnsCount = isDesktop ? 4 : Math.floor(window.innerWidth / 310);
  const itemsCount = documents.length;
  const placeholdersCount = isDesktop
    ? Math.max(
        8 - itemsCount,
        columnsCount - (itemsCount % columnsCount || columnsCount)
      )
    : 0;

  const renderSkeleton = (key: React.Key) => (
    <div className="grid-item card" key={key}>
      <div className="flex">
        <Skeleton width={150} />
        <div className="py-10">
          <Skeleton height={30} className="document-description" />
        </div>
      </div>
      <Skeleton height={360} className="max-container" />
      <div className="pt-10">
        <Skeleton height={40} className="document-description" />
      </div>
    </div>
  );

  const renderDocumentItem = (document: Document | DocumentTemplate) => (
    <DocumentListItem
      key={document.id}
      document={document}
      fileField={fileField}
      onDocumentClick={onDocumentClick}
      renderButton={renderButton}
      dropDownItems={dropDownItems}
    />
  );

  const renderPlaceholders = () =>
    Array.from({ length: placeholdersCount }).map((_, index) => (
      <div
        className="grid-item card opacity-60 mh-500"
        key={`placeholder-${index}`}
      />
    ));

  const renderContent = () => {
    if (isLoading) {
      return Array.from({ length: 8 }).map((_, index) => renderSkeleton(index));
    }

    if (itemsCount > 0) {
      return (
        <>
          {documents.map(renderDocumentItem)}
          {renderPlaceholders()}
        </>
      );
    }

    return (
      <>
        <div className="flex flex-1 grid-item card mh-500">
          <span className="centered flex-1 cp-yellow">{placeholderText}</span>
        </div>
        {Array.from({ length: 7 }).map((_, index) => (
          <div
            className="grid-item card opacity-60 mh-500"
            key={`placeholder-${index}`}
          />
        ))}
      </>
    );
  };

  return <div className="grid-container">{renderContent()}</div>;
}

export default DocumentsTab;
