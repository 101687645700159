import React from "react";
import styles from "./FormRow.module.css";

type FormRowProps = {
  children: React.ReactNode;
};

export default function FormRow({ children }: FormRowProps) {
  return <div className={styles.formRow}>{children}</div>;
}
