import React, { memo } from "react";
import LoadingAnimation from "../Animations/LoadingAnimation";

type Props = {
  isLoading?: boolean;
  hideOnMobile?: boolean;
  id?: string;
  containerStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

const ListCard = ({
  children,
  isLoading,
  hideOnMobile,
  id,
  style,
  containerStyle,
}: Props) => {
  return (
    <div
      className={hideOnMobile ? `${"listCard"} hideMobile` : "listCard"}
      id={id}
      style={style}
    >
      <div className={"listCardContainer"} style={containerStyle}>
        {isLoading ? (
          <LoadingAnimation>
            <></>
          </LoadingAnimation>
        ) : (
          <>{children}</>
        )}
      </div>
    </div>
  );
};

export default memo(ListCard);
