import React from "react";
import styles from "../ESignatures.module.css";

type PdfViewerContainerProps = {
  children?: React.ReactNode;
};

export default function PdfViewerContainer({
  children,
}: PdfViewerContainerProps) {
  return <div className={styles.pdfViewerContainer}>{children}</div>;
}
