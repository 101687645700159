import { TimeOffStatus } from "../services/timeOffRequestService";

export const getTimeOffRequestsBadgeColor = (status: TimeOffStatus) => {
  switch (status) {
    case "Approved":
      return "var(--clearpath-green)";
    case "Pending":
      return "var(--clearpath-yellow)";
    case "Declined":
      return "var(--clearpath-danger)";
    default:
      return "var(--clearpath-yellow)";
  }
};
