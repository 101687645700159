import { useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { RotatingLines } from "react-loader-spinner";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import Dialog from "src/components/Dialog/Dialog";
import DialogContent from "src/components/Dialog/DialogContent";
import DialogDescription from "src/components/Dialog/DialogDescription";
import DialogFooter from "src/components/Dialog/DialogFooter";
import DialogHeader from "src/components/Dialog/DialogHeader";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import usePickUpEmployeeShiftMutation from "../hooks/usePickUpShiftMutation";
import { ShiftTransfer } from "../services/shiftTransferService";

type ShiftTransferPickupDialogProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClose?: () => void;
  transfer: ShiftTransfer;
};

export default function ShiftTransferPickupDialog({
  isOpen,
  setIsOpen,
  onClose,
  transfer,
}: ShiftTransferPickupDialogProps) {
  const queryClient = useQueryClient();

  const pickupShiftMutation = usePickUpEmployeeShiftMutation({
    onSuccess: async (data) => {
      setIsOpen(false);
      queryClient.setQueryData(
        [QueryKeys.ShiftTransfer, String(data.id)],
        data
      );
      await queryClient.invalidateQueries([
        QueryKeys.ShiftTransfer,
        String(data.id),
      ]);
      await queryClient.invalidateQueries([QueryKeys.ShiftTransfersList]);
      await queryClient.invalidateQueries([QueryKeys.EmployeeShiftsList]);
    },
  });

  const handlePickUpShift = () => pickupShiftMutation.mutate(transfer.id);

  return (
    <Dialog isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <DialogHeader>Pick up this shift?</DialogHeader>
      <DialogDescription>
        Are you sure you want to pick up this shift? After picking up this
        shift, approval is required from the manager. It is not guaranteed that
        you will be able to pick up this shift.
      </DialogDescription>
      <DialogContent>
        <div className="flex gap-10 p-20 border-radius-10 bg-cp-black-50">
          <span className="font-bold text-lg cp-yellow">
            {transfer.shift_from.shift_requirement.name}
          </span>
          <div className="flex flex-row gap-10 align-center">
            <span className="text-sm font-bold">
              {format(
                new Date(transfer.shift_from.shift_requirement.start_datetime),
                "PP"
              )}
            </span>
          </div>
          <div className="flex flex-row gap-10 align-center">
            <span className="text-sm font-bold">
              {format(
                new Date(transfer.shift_from.shift_requirement.start_datetime),
                "p"
              )}{" "}
              -{" "}
              {format(
                new Date(transfer.shift_from.shift_requirement.end_datetime),
                "p"
              )}
            </span>
          </div>
        </div>
      </DialogContent>
      <DialogFooter>
        <Button
          color={ButtonColors.GrayAndYellow}
          disabled={pickupShiftMutation.isLoading}
          onClick={() => setIsOpen(false)}
        >
          {pickupShiftMutation.isLoading ? (
            <RotatingLines
              strokeColor="#f1b70c"
              strokeWidth="5"
              animationDuration="0.75"
              width="20"
              visible={true}
            />
          ) : (
            "Cancel"
          )}
        </Button>
        <Button
          disabled={pickupShiftMutation.isLoading}
          color={ButtonColors.Yellow}
          onClick={handlePickUpShift}
        >
          {pickupShiftMutation.isLoading ? (
            <RotatingLines
              strokeColor="#000"
              strokeWidth="5"
              animationDuration="0.75"
              width="20"
              visible={true}
            />
          ) : (
            "Pick Up Shift"
          )}
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
