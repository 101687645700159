import { PermissionsGroup } from "./types/groups.types";

export const ALL_GROUPS: PermissionsGroup[] = [
  PermissionsGroup.Admin,
  PermissionsGroup.HumanResources,
  PermissionsGroup.Supervisor,
  PermissionsGroup.SupervisorReadOnly,
  PermissionsGroup.Employee,
];

export const ORGANIZATION_VIEW_GROUP: PermissionsGroup[] = [
  PermissionsGroup.Admin,
  PermissionsGroup.HumanResources,
];

export const ORGANIZATION_CRUD_GROUP: PermissionsGroup[] = [
  PermissionsGroup.Admin,
];

export const DEPARTMENT_ONLY_VIEW_GROUP: PermissionsGroup[] = [
  PermissionsGroup.Supervisor,
  PermissionsGroup.SupervisorReadOnly,
];

export const DEPARTMENT_ONLY_CRUD_GROUP: PermissionsGroup[] = [
  PermissionsGroup.Supervisor,
];

export const NON_EMPLOYEES_GROUP: PermissionsGroup[] = [
  PermissionsGroup.Admin,
  PermissionsGroup.Supervisor,
  PermissionsGroup.SupervisorReadOnly,
  PermissionsGroup.HumanResources,
];

export const EMPLOYEE_GROUP: PermissionsGroup[] = [PermissionsGroup.Employee];

export const CRUD_GROUP: PermissionsGroup[] = [
  ...ORGANIZATION_CRUD_GROUP,
  ...DEPARTMENT_ONLY_CRUD_GROUP,
];

export const CRUD_GROUP_WITH_HR: PermissionsGroup[] = [
  ...CRUD_GROUP,
  PermissionsGroup.HumanResources,
];
