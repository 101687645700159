import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { JSONContent } from "@tiptap/react";
import Skeleton from "react-loading-skeleton";
import { RotatingLines } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useAlert } from "@blaumaus/react-alert";

import DynamicFormInput from "src/components/DynamicForm/DynamicFormInput";
import FormRow from "src/components/Forms/FormRow";
import InputContainer from "src/components/Inputs/InputContainer";
import InputLabel from "src/components/Inputs/InputLabel";
import StepContainer from "src/components/Steps/StepContainer";
import { TEXT } from "src/features/forms/inputTypes";
import EditingContainer from "src/features/learningPaths/components/LPBuilder/Forms/EditingContainer";
import MemoEditor from "../MemoEditor";
import Button from "src/components/Buttons/Button";
import {
  MemoDetailsFormSchemaType,
  memoDetailsFormSchema,
} from "../../zod/schemas";
import inputStyles from "src/components/Inputs/Inputs.module.css";
import FlagIcon from "src/components/Icons/FlagIcon";
import SingleNoteIcon from "src/components/Icons/SingleNoteIcon";
import memosService from "../../services/memosService";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import ButtonsContainer from "src/components/Container/ButtonsContainer";
import useMemoFormState from "../../hooks/useMemoFormState";
import useGetMemoQuery from "../../hooks/useGetMemoQuery";
import useCreateMemoMutation from "../../hooks/useCreateMemoMutation";
import usePartialUpdateMemoMutation from "../../hooks/usePartialUpdateMemoMutation";
import {
  MemoFormSteps,
  setMemoFormStep,
  setSelectedMemoId,
} from "../../slices/memoSlice";
import { useAppDispatch } from "src/store";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import {
  ButtonColors,
  ButtonSizes,
} from "src/components/Buttons/buttons.types";

export default function MemoDetailsForm() {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const alert = useAlert();
  const { id } = useParams<{ id: string }>();
  const { selectedMemoId } = useMemoFormState();
  const handleErrors = useErrorHandling();

  const combinedId = id || selectedMemoId;

  const { data: memo, isInitialLoading: isMemoLoading } = useGetMemoQuery({
    id: combinedId ? String(combinedId) : undefined,
  });

  const createMemoMutation = useCreateMemoMutation();

  const partialUpdateMemoMutation = usePartialUpdateMemoMutation({
    onSuccess: (data) => {
      dispatch(setMemoFormStep(MemoFormSteps.UploadFile));
      dispatch(setSelectedMemoId(data.id));
      queryClient.invalidateQueries([QueryKeys.MemosList]);
      queryClient.setQueryData(["memo", String(data.id)], data);
      alert.success("Memo details saved successfully!");
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<MemoDetailsFormSchemaType>({
    resolver: zodResolver(memoDetailsFormSchema),
    defaultValues: {
      id: memo?.id,
      title: memo?.title || "",
      content: memo?.content || "",
    },
  });

  useEffect(() => {
    if (combinedId && memo) {
      setValue("id", memo?.id);
      setValue("title", memo?.title || "");
      setValue("content", memo?.content || "");
    }
  }, [memo, combinedId, setValue]);

  const handleSaveMemo = (data: MemoDetailsFormSchemaType) => {
    if (data?.id) {
      partialUpdateMemoMutation.mutate({ ...data });
    } else {
      createMemoMutation.mutate(data);
    }
  };

  const aiEnhancementMutation = useMutation(memosService.enhanceMemo, {
    onSuccess: (res) => setValue("content", res.content, { shouldDirty: true }),
    onError: (error: AxiosError) => handleErrors(error),
  });

  const handleAIEnhancement = () => {
    const data = { content: watch("content") as unknown as string };
    aiEnhancementMutation.mutate(data);
  };

  const isLoading =
    createMemoMutation.isLoading ||
    aiEnhancementMutation.isLoading ||
    partialUpdateMemoMutation.isLoading ||
    isMemoLoading;

  return (
    <StepContainer>
      <div className="mb-20">
        <span className="text-muted">
          Please enter a title and the content for your memo. Utilize our "Smart
          Enhance" feature to elevate the quality, layout, and professionalism
          of your memo. Simply type your content and select the "Smart Enhance"
          button to apply these enhancements automatically.
        </span>
      </div>
      <EditingContainer>
        <form
          onSubmit={handleSubmit(handleSaveMemo)}
          style={{ marginTop: "20px", overflowY: "auto" }}
        >
          <FormRow>
            <InputContainer>
              <InputLabel htmlFor={"title"} labelIcon={<FlagIcon />}>
                Memo Title
              </InputLabel>
              {!isLoading ? (
                <DynamicFormInput
                  register={register}
                  type={TEXT}
                  placeholder={"Add a title..."}
                  name={"title"}
                  defaultValue={""}
                  options={[]}
                  optionKey={""}
                  error={
                    errors["title" as keyof MemoDetailsFormSchemaType]?.message
                  }
                  inputClassName={inputStyles.darkInput}
                  containerClassName={inputStyles.inputContainer}
                  errorClassName={inputStyles.darkInputError}
                />
              ) : null}
              {isLoading ? <Skeleton height={40} /> : null}
            </InputContainer>
          </FormRow>
          <div
            style={{
              display: "flex",
              position: "relative",
              flexDirection: "column",
              padding: "5px 10px",
            }}
          >
            <InputLabel htmlFor={"content"} labelIcon={<SingleNoteIcon />}>
              Memo Content
            </InputLabel>
            {(!isLoading && !isMemoLoading && !combinedId) ||
            (!isLoading && !isMemoLoading && watch("content")) ? (
              <Controller
                render={({ field }) => (
                  <MemoEditor
                    content={field.value as unknown as JSONContent}
                    onChange={field.onChange}
                    error={
                      errors["content" as keyof MemoDetailsFormSchemaType]
                        ?.message
                    }
                  />
                )}
                name="content"
                control={control}
              />
            ) : (
              <Skeleton height={250} />
            )}
            <Button
              onClick={handleAIEnhancement}
              color={ButtonColors.Gray}
              size={ButtonSizes.MD}
              disabled={isLoading}
              style={{ marginTop: "10px" }}
            >
              {isLoading ? (
                <RotatingLines
                  strokeColor="#fff"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={true}
                />
              ) : (
                "Smart Enhance Memo"
              )}
            </Button>
            <ButtonsContainer>
              <Button
                color={ButtonColors.Yellow}
                size={ButtonSizes.MD}
                type="submit"
                disabled={isLoading}
              >
                {!isLoading ? (
                  "Save Memo Details"
                ) : (
                  <RotatingLines
                    strokeColor="#000"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="20"
                    visible={true}
                  />
                )}
              </Button>
            </ButtonsContainer>
          </div>
        </form>
      </EditingContainer>
    </StepContainer>
  );
}
