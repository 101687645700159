import { useMutation } from "@tanstack/react-query";
import scheduleRolesService from "../services/scheduleRolesService";
import { AxiosError } from "axios";

type DetailObject = {
  detail: string;
};

type SyncScheduleRolesMutation = {
  onSuccess?: (data: DetailObject) => void;
  onError?: (error: AxiosError) => void;
};

export default function useSyncScheduleRolesMutation({
  onSuccess,
  onError,
}: SyncScheduleRolesMutation) {
  return useMutation({
    mutationFn: scheduleRolesService.syncScheduleRoles,
    onSuccess,
    onError,
  });
}
