import React, { memo } from "react";
import styles from "./Header.module.css";

interface HeaderProps {
  children?: React.ReactNode;
  containerStyle?: React.CSSProperties;
  id: string;
  size?: string;
  title?: string;
  titleStyle?: React.CSSProperties;
}

function Header({
  children,
  containerStyle,
  id,
  size = "h3",
  title,
  titleStyle,
}: HeaderProps) {
  const HeadingTag = React.createElement(
    size,
    { className: styles.header, style: titleStyle, id },
    title
  );

  return (
    <div className={styles.headerContainer} style={containerStyle}>
      {HeadingTag}
      <div className={styles.close} id={id}>
        {children}
      </div>
    </div>
  );
}

export default memo(Header);
