import React, { memo, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styles from "./ExpandableListBox.module.css";
import ArrowCircleSmallYellowIcon from "../Icons/ArrowCircleSmallYellowIcon";

type ExpandableListBoxProps = {
  title: React.ReactNode;
  titleAction?: React.ReactNode;
  subtitle: React.ReactNode;
  listContent?: React.ReactNode;
  href: string;
  isSelected?: boolean | null;
  isLinkDisabled?: boolean;
  defaultExpanded?: boolean;
};

function ExpandableListBox({
  title,
  titleAction,
  subtitle,
  listContent,
  href,
  isSelected = false,
  isLinkDisabled = false,
  defaultExpanded = false,
}: ExpandableListBoxProps) {
  const [expanded, setExpanded] = useState(defaultExpanded);

  useEffect(() => {
    if (isSelected) {
      setExpanded(true);
    } else if (!defaultExpanded) {
      setExpanded(false);
    }
  }, [isSelected, defaultExpanded]);

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (isLinkDisabled) {
      e.preventDefault();
    }
  };

  return (
    <NavLink
      to={href}
      className={({ isActive }) =>
        isActive && isSelected ? styles.boxSelected : styles.box
      }
      onClick={isLinkDisabled ? handleLinkClick : undefined}
    >
      <div className={styles.boxMain}>
        <div className={styles.titleRow}>
          <span className={styles.title}>{title}</span>
          {titleAction ? (
            <div className={styles.titleAction}>{titleAction}</div>
          ) : null}
        </div>
        <div className={styles.subtitleRow}>
          <span className={styles.subtitle}>{subtitle}</span>
          <button
            className={styles.expandButton}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setExpanded(!expanded);
            }}
          >
            <ArrowCircleSmallYellowIcon
              className={expanded ? styles.rotate0 : styles.rotate180}
            />
          </button>
        </div>
      </div>
      {expanded && listContent ? (
        <div className={styles.cardBody}>{listContent}</div>
      ) : null}
    </NavLink>
  );
}

export default memo(ExpandableListBox);
