import React from "react";

type ContainerProps = {
  className?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
  dataTestId?: string;
  id?: string;
};

export default function Container({
  className,
  children,
  style,
  dataTestId,
  id,
}: ContainerProps) {
  return (
    <div
      className={`${"container"} ${className}`}
      style={style}
      data-testid={dataTestId}
      id={id}
    >
      {children}
    </div>
  );
}
