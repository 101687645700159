import { useQuery } from "@tanstack/react-query";

import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import employeeModuleContentsService from "../services/employeeModuleContentsService";

type EmployeeModuleContentsQuery = {
  employeeModuleId?: string;
};

export default function useGetEmployeeModuleContentsQuery({
  employeeModuleId,
}: EmployeeModuleContentsQuery) {
  return useQuery({
    queryKey: [QueryKeys.EmployeeModuleContents, employeeModuleId],
    queryFn: employeeModuleId
      ? () =>
          employeeModuleContentsService.getEmployeeModuleContentsByEmployeeModuleId(
            { employeeModuleId }
          )
      : undefined,
    enabled: !!employeeModuleId,
  });
}
