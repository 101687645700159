import { memo, useRef } from "react";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";
import Skeleton from "react-loading-skeleton";

import { Document, Page, pdfjs } from "react-pdf";
import Button from "src/components/Buttons/Button";

import styles from "./ResizingPDFViewer.module.css";
import calculate_a4_height_from_width from "../utils/calculate_a4_height_from_width";
import {
  ButtonColors,
  ButtonSizes,
} from "src/components/Buttons/buttons.types";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

type ResizingPDFViewerProps = {
  pageNumber: number;
  onPreviousPage: (e: React.MouseEvent<Element, MouseEvent>) => void;
  onNextPage: (e: React.MouseEvent<Element, MouseEvent>) => void;
  onLoadSuccess: ({ numPages }: { numPages: number }) => void;
  file: string;
  nextBtnDisabled: boolean;
  prevBtnDisabled: boolean;
  pageWidth: number;
  btnColor?: string;
  btnColorDisabled?: string;
};

function ResizingPDFViewer({
  pageNumber,
  onPreviousPage,
  onNextPage,
  onLoadSuccess,
  file,
  nextBtnDisabled,
  prevBtnDisabled,
  pageWidth,
  btnColor = "#f1b70c",
  btnColorDisabled = "#ffe088",
}: ResizingPDFViewerProps) {
  const containerRef = useRef(null);

  return (
    <div className={styles.pdfContainer} ref={containerRef}>
      <Button
        color={ButtonColors.Transparent}
        size={ButtonSizes.SM}
        disabled={prevBtnDisabled}
        onClick={onPreviousPage}
      >
        <BsArrowLeftCircleFill
          color={prevBtnDisabled ? btnColorDisabled : btnColor}
          size={24}
        />
      </Button>
      <Document
        file={file}
        onLoadSuccess={onLoadSuccess}
        loading={
          <Skeleton
            width={pageWidth}
            height={calculate_a4_height_from_width(pageWidth)}
          />
        }
      >
        <Page
          pageNumber={pageNumber}
          renderAnnotationLayer={false}
          renderTextLayer={false}
          width={pageWidth}
          height={calculate_a4_height_from_width(pageWidth)}
        />
      </Document>
      <Button
        color={ButtonColors.Transparent}
        size={ButtonSizes.SM}
        disabled={nextBtnDisabled}
        onClick={onNextPage}
      >
        <BsArrowRightCircleFill
          color={nextBtnDisabled ? btnColorDisabled : btnColor}
          size={24}
        />
      </Button>
    </div>
  );
}

export default memo(ResizingPDFViewer);
