import React, { memo, useEffect, useRef } from "react";
import lottie from "lottie-web";
import clearpathLoadingAnimation from "../../assets/animations/clearpathLoadingAnimationFull.json";
import styles from "./LoadingAnimation.module.css";

type LoadingAnimationProps = {
  children?: React.ReactNode;
  containerClassName?: string;
  animationClassName?: string;
  animation?: any;
};

function LoadingAnimation({
  children,
  containerClassName,
  animationClassName,
  animation = clearpathLoadingAnimation,
}: LoadingAnimationProps) {
  const anime = useRef(null);

  useEffect(() => {
    const animationInstance = lottie.loadAnimation({
      container: anime.current as any,
      animationData: animation,
      loop: true,
      autoplay: true,
    });

    return () => animationInstance.destroy();
  }, [animation]);

  return (
    <div
      className={`${styles.loadingAnimationContainer} ${containerClassName}`}
      data-testid="animationContainer"
    >
      <div
        data-testid="loading-animation"
        ref={anime}
        className={`${styles.loadingAnimation} ${animationClassName}`}
      />
      {children ? children : null}
    </div>
  );
}

export default memo(LoadingAnimation);
