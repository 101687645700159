import Skeleton from "react-loading-skeleton";
import CompletedStepContainer from "src/components/Steps/CompletedStepContainer";
import EmployeeSittingTable from "src/features/assessments/components/EmployeeSittingTable";
import useGetSittingReview from "src/features/assessments/hooks/useGetSittingReview";
import formatDateToString from "src/features/datetime/utils/formatDateToString";

type LPAssessmentResultProps = {
  sittingId: number;
};

export default function LPAssessmentResult({
  sittingId,
}: LPAssessmentResultProps) {
  const { data: sitting, isLoading } = useGetSittingReview({
    id: sittingId ? sittingId.toString() : undefined,
  });

  return (
    <div className="centered" style={{ flexDirection: "column" }}>
      {isLoading ? <Skeleton height={500} /> : null}
      {sitting && !isLoading ? (
        <CompletedStepContainer
          title={sitting?.assessment}
          description={`${
            sitting?.start
              ? formatDateToString(new Date(sitting?.start))
              : "Not started"
          } - ${
            sitting?.end
              ? formatDateToString(new Date(sitting?.end))
              : "Incomplete"
          }`}
          actionButtons={undefined}
          bottomRightActions={`${
            sitting?.status === 0
              ? "In progress"
              : sitting?.status === 1
              ? "Completed"
              : sitting?.status === 2
              ? "Time Expired"
              : null
          } - ${
            sitting?.status !== 0
              ? sitting?.passed
                ? "Passed"
                : "No Pass"
              : null
          }`}
          color={
            sitting?.status === 0 ? "White" : sitting?.passed ? "Green" : "Red"
          }
          content={<EmployeeSittingTable sittingId={sitting.id} />}
        />
      ) : null}
    </div>
  );
}
