import { useState } from "react";
import ResizingPDFViewer from "src/features/pdf/components/ResizingPDFViewer";

type ListContentPdfViewerProps = {
  file?: string;
  pageWidth?: number;
  btnColor?: string;
  btnColorDisabled?: string;
};

export default function ListContentPdfViewer({
  file,
  pageWidth = 200,
  btnColor,
  btnColorDisabled,
}: ListContentPdfViewerProps) {
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(0);

  const changePage = (offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    changePage(-1);
  };

  const nextPage = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    changePage(1);
  };

  const handleDocumentLoadSuccess = ({ numPages }: { numPages: number }) =>
    setNumPages(numPages);

  return (
    <>
      {file ? (
        <ResizingPDFViewer
          pageNumber={pageNumber}
          onPreviousPage={previousPage}
          onNextPage={nextPage}
          onLoadSuccess={handleDocumentLoadSuccess}
          file={file}
          nextBtnDisabled={pageNumber === numPages}
          prevBtnDisabled={pageNumber === 1}
          pageWidth={pageWidth}
          btnColor={btnColor}
          btnColorDisabled={btnColorDisabled}
        />
      ) : null}
    </>
  );
}
