import { memo } from "react";
import Button from "src/components/Buttons/Button";
import {
  ButtonColors,
  ButtonSizes,
} from "src/components/Buttons/buttons.types";
import EditPencilYellowSmallIcon from "src/components/Icons/EditPencilYellowSmallIcon";

type StepEditIconProps = {
  onClick: () => void;
};

function StepEditButton({ onClick }: StepEditIconProps) {
  return (
    <Button
      color={ButtonColors.Transparent}
      size={ButtonSizes.SM}
      onClick={onClick}
    >
      <EditPencilYellowSmallIcon />
    </Button>
  );
}

export default memo(StepEditButton);
