import { createSlice } from "@reduxjs/toolkit";

export enum ESignatureFormSteps {
  AddDetails = "Add Details",
  UploadFile = "Upload File",
  AddAnnotations = "Add Annotations",
  AssignViewableBy = "Assign Viewable By",
  SaveOrPublish = "Save or Publish",
}

export type ESignatureFormState = {
  step: ESignatureFormSteps | null;
  templateToEditId: string | null | undefined;
};

const initialState: ESignatureFormState = {
  step: null,
  templateToEditId: null,
};

export const eSignatureFormSlice = createSlice({
  name: "eSignatureForm",
  initialState,
  reducers: {
    resetESignatureFormState: (state) => {
      state.step = null;
      state.templateToEditId = null;
    },
    setESignatureFormStep: (state, action) => {
      state.step = action.payload;
    },
    setTemplateToEditId: (state, action) => {
      state.templateToEditId = action.payload;
    },
  },
});

export const {
  resetESignatureFormState,
  setESignatureFormStep,
  setTemplateToEditId,
} = eSignatureFormSlice.actions;

export default eSignatureFormSlice.reducer;
