import React, { memo } from "react";
import styles from "./Steps.module.css";

export type StepType = {
  icon: React.ReactNode;
  title: React.ReactNode;
  content: React.ReactNode;
};

type VerticalStepperProps = {
  steps: StepType[];
  className?: string;
};

function VerticalStepper({ steps, className }: VerticalStepperProps) {
  return (
    <>
      {steps.map((step, i) => (
        <div key={i} className={className || styles.step}>
          <div className={styles.iconContainer}>{step.icon}</div>
          <div className={styles.stepBody}>
            {step.title}
            {step.content}
          </div>
        </div>
      ))}
    </>
  );
}

export default memo(VerticalStepper);
