import React, { memo } from "react";
import DraggableSixDotsIcon from "../Icons/DraggableSixDotsIcon";
import styles from "./CompletedStepContainer.module.css";

type CompletedStepContainerProps = {
  title: React.ReactNode;
  description: React.ReactNode;
  color?: "Green" | "White" | "Yellow" | "Red";
  content?: React.ReactNode;
  actionButtons: React.ReactNode;
  bottomRightActions?: React.ReactNode;
  isDraggable?: boolean;
};

function CompletedStepContainer({
  title,
  description,
  color = "Green",
  content,
  actionButtons,
  bottomRightActions,
  isDraggable = false,
}: CompletedStepContainerProps) {
  return (
    <div className={styles.container + " " + styles["container" + color]}>
      <div className={styles.headerWrapper}>
        <div className={styles.headerContainer}>
          <span className={styles.title + " " + styles["title" + color]}>
            {isDraggable ? <DraggableSixDotsIcon /> : null}
            {title ? title : null}
          </span>
          {actionButtons ? actionButtons : null}
        </div>
      </div>
      {content ? content : null}
      <div className={styles.headerWrapper}>
        <div className={styles.headerContainer}>
          <div className={styles.descriptionWrapper}>
            <span className={styles.description}>{description}</span>
          </div>
          <span className={styles.saved + " " + styles["title" + color]}>
            {bottomRightActions ? bottomRightActions : null}
          </span>
        </div>
      </div>
    </div>
  );
}

export default memo(CompletedStepContainer);
