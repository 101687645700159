import { format } from "date-fns";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import { EmployeeListItem } from "src/features/employees/types/employees.type";
import getFullName from "src/features/employees/utils/getFullName";
import cn from "src/features/utils/cn";
import { EmployeeShift } from "../services/employeeShiftsService";

type ShiftTransferFlowCardsProps = {
  titleLeft: string;
  employeeLeft: EmployeeListItem;
  shiftLeft: EmployeeShift;
  titleRight: string;
  employeeRight?: EmployeeListItem;
  shiftRight?: EmployeeShift;
  onPickUpShift?: () => void;
  cardClassName?: string;
  icon?: React.ReactNode;
};

export default function ShiftTransferFlowCards({
  titleLeft,
  employeeLeft,
  shiftLeft,
  titleRight,
  employeeRight,
  shiftRight,
  onPickUpShift,
  cardClassName = "bg-dark-gray",
  icon,
}: ShiftTransferFlowCardsProps) {
  return (
    <>
      <div
        className={cn(
          "flex flex-col flex-1 justify-between border-light-gray p-10 border-radius-10",
          cardClassName
        )}
      >
        <div className="flex flex-col gap-20">
          <span className="cp-yellow font-bold">{titleLeft}</span>
          <div className="flex flex-row gap-10 align-center">
            <img
              src={employeeLeft.image}
              alt={getFullName(employeeLeft)}
              className="w-40 h-40 border-radius-half"
            />
            <div className="flex flex-col">
              <span className="font-bold">{getFullName(employeeLeft)}</span>
              <span className="text-sm text-muted">
                {employeeLeft.username}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-10 mt-20">
          <div className="flex flex-row gap-10 align-center">
            <span className="text-sm font-bold">Shift: </span>
            <span className="cp-yellow text-sm font-bold">
              {shiftLeft.shift_requirement.name}
            </span>
          </div>
          <div className="flex flex-row gap-10 align-center">
            <span className="text-sm font-bold">Date: </span>
            <span className="cp-yellow text-sm font-bold">
              {format(
                new Date(shiftLeft.shift_requirement.start_datetime),
                "PP"
              )}
            </span>
          </div>
          <div className="flex flex-row gap-10 align-center">
            <span className="text-sm font-bold">Times: </span>
            <span className="cp-yellow text-sm font-bold white-space-nowrap">
              {format(
                new Date(shiftLeft.shift_requirement.start_datetime),
                "p"
              )}{" "}
              -{" "}
              {format(new Date(shiftLeft.shift_requirement.end_datetime), "p")}
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center align-center p-20 rotate-90">
        {icon}
      </div>
      <div
        className={cn(
          "flex flex-col flex-1 justify-between border-light-gray p-10 border-radius-10",
          cardClassName
        )}
      >
        <div className="flex flex-col gap-20">
          <span className="cp-yellow font-bold">{titleRight}</span>
          <div className="flex flex-row gap-10 align-center">
            {employeeRight ? (
              <>
                <img
                  src={employeeRight.image}
                  alt={getFullName(employeeRight)}
                  className="w-40 h-40 border-radius-half"
                />
                <div className="flex flex-col">
                  <span className="font-bold">
                    {getFullName(employeeRight)}
                  </span>
                  <span className="text-sm text-muted">
                    {employeeRight.username}
                  </span>
                </div>
              </>
            ) : (
              <div className="flex flex-col gap-10">
                <span className="text-sm font-bold">
                  This shift has not been picked up yet.
                </span>
                {onPickUpShift && (
                  <div>
                    <Button
                      className="border-radius-5 mt-10"
                      color={ButtonColors.Yellow}
                      onClick={onPickUpShift}
                    >
                      Pick Up Shift
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-10 mt-20">
          {shiftRight?.shift_requirement ? (
            <>
              <div className="flex flex-row gap-10 align-center">
                <span className="text-sm font-bold">Shift: </span>
                <span className="cp-yellow text-sm font-bold">
                  {shiftRight.shift_requirement.name}
                </span>
              </div>
              <div className="flex flex-row gap-10 align-center">
                <span className="text-sm font-bold">Date: </span>
                <span className="cp-yellow text-sm font-bold">
                  {format(
                    new Date(shiftRight.shift_requirement.start_datetime),
                    "PP"
                  )}
                </span>
              </div>
              <div className="flex flex-row gap-10 align-center">
                <span className="text-sm font-bold">Times: </span>
                <span className="cp-yellow text-sm font-bold white-space-nowrap">
                  {format(
                    new Date(shiftRight.shift_requirement.start_datetime),
                    "p"
                  )}{" "}
                  -{" "}
                  {format(
                    new Date(shiftRight.shift_requirement.end_datetime),
                    "p"
                  )}
                </span>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}
