import { EditorContent, JSONContent, useEditor } from "@tiptap/react";
import Highlight from "@tiptap/extension-highlight";
import TextAlign from "@tiptap/extension-text-align";
import StarterKit from "@tiptap/starter-kit";

import MenuBar from "src/features/tiptap/components/MenuBar";
import InputError from "src/components/Errors/InputError";

type MemoEditorProps = {
  content?: JSONContent;
  onChange: (content: string) => void;
  error?: string;
};

export default function MemoEditor({
  content,
  error,
  onChange,
}: MemoEditorProps) {
  const editor = useEditor(
    {
      extensions: [
        StarterKit,
        TextAlign.configure({
          types: ["heading", "paragraph"],
          alignments: ["left", "center", "right"],
        }),
        Highlight,
      ],
      content,
      onUpdate({ editor }) {
        onChange(editor.getHTML());
      },
    },
    []
  );

  const editorStyle = {
    border: error ? "1px solid red" : "",
    borderRadius: "10px",
  };

  return (
    <>
      <EditorContent
        editor={editor}
        className="ProseMirrorWithResize"
        style={editorStyle}
      />
      {error ? <InputError error={error} /> : null}
      <MenuBar editor={editor} />
    </>
  );
}
