import DatePicker from "react-datepicker";
import Skeleton from "react-loading-skeleton";
import { useSearchParams } from "react-router-dom";
import FormRow from "src/components/Forms/FormRow";
import InputContainerV2 from "src/components/Inputs/InputContainerV2";
import InputLabel from "src/components/Inputs/InputLabel";
import Placeholder from "src/components/Placeholder/Placeholder";
import useDateRangeFromUrl from "src/features/datetime/hooks/useDateRangeFromUrl";
import useGetShiftTransfersByDatesQuery from "../hooks/useGetShiftTransfersByStartDate";
import {
  ShiftTransferStatus,
  ShiftTransferType,
} from "../services/shiftTransferService";
import ShiftTransferCard from "./ShiftTransferCard";

export default function ShiftTransfers() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [dateRange, setDateRange] = useDateRangeFromUrl();

  const status =
    (searchParams.get("status") as ShiftTransferStatus | "all") ||
    ShiftTransferStatus.Pending;
  const type = (searchParams.get("type") as ShiftTransferType | "all") || "all";

  const {
    data: shiftTransfers,
    isLoading: isShiftTransfersLoading,
    isError: isShiftTransfersError,
  } = useGetShiftTransfersByDatesQuery({
    enabled: true,
    startDate: dateRange[0]?.toISOString().slice(0, 10),
    endDate: dateRange[1]?.toISOString().slice(0, 10),
    status: status === "all" ? undefined : status,
    type: type === "all" ? undefined : type,
  });

  const handleDateRangeChange = (dates: [Date | null, Date | null]) =>
    setDateRange(dates);

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      status: e.target.value,
    });
  };

  const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      type: e.target.value,
    });
  };

  return (
    <div className="detail-card">
      <div className="px-10">
        <div className="border-bottom-light-gray pb-5">
          <FormRow>
            <InputContainerV2>
              <InputLabel>Status</InputLabel>
              <select
                className="inputBlack"
                defaultValue={status}
                onChange={handleStatusChange}
              >
                <option value="all">All</option>
                <option value={ShiftTransferStatus.Available}>
                  {ShiftTransferStatus.Available}
                </option>
                <option value={ShiftTransferStatus.Pending}>
                  {ShiftTransferStatus.Pending}
                </option>
                <option value={ShiftTransferStatus.Approved}>
                  {ShiftTransferStatus.Approved}
                </option>
                <option value={ShiftTransferStatus.Declined}>
                  {ShiftTransferStatus.Declined}
                </option>
              </select>
            </InputContainerV2>
            <InputContainerV2>
              <InputLabel>Type</InputLabel>
              <select
                className="inputBlack"
                defaultValue={type}
                onChange={handleTypeChange}
              >
                <option value="all">All</option>
                <option value={ShiftTransferType.ShiftDrop}>
                  {ShiftTransferType.ShiftDrop}
                </option>
                <option value={ShiftTransferType.ShiftSwap}>
                  {ShiftTransferType.ShiftSwap}
                </option>
              </select>
            </InputContainerV2>
            <InputContainerV2>
              <InputLabel>Shift Dates</InputLabel>
              <DatePicker
                selected={dateRange[0]}
                startDate={dateRange[0]}
                endDate={dateRange[1]}
                onChange={(dates) =>
                  handleDateRangeChange(dates as [Date | null, Date | null])
                }
                selectsRange
                className={"inputBlack"}
                monthsShown={2}
                portalId="datepicker-portal"
                popperPlacement="bottom"
                placeholderText="Select Date Range"
              />
            </InputContainerV2>
          </FormRow>
        </div>
        <div className="flex flex-1 gap-10 py-10 min-h-100vh-90 max-h-100vh-90 overflow-y-auto">
          {isShiftTransfersLoading &&
            Array.from({ length: 5 }).map((_, i) => (
              <Skeleton height={200} key={i} />
            ))}
          {isShiftTransfersError && <p>Error fetching shift transfers</p>}
          {shiftTransfers && shiftTransfers.length === 0 && (
            <div className="flex-1 centered">
              <Placeholder title="No shift transfers" />
            </div>
          )}
          {shiftTransfers &&
            shiftTransfers.length > 0 &&
            shiftTransfers.map((transfer) => (
              <ShiftTransferCard key={transfer.id} transfer={transfer} />
            ))}
        </div>
      </div>
    </div>
  );
}
