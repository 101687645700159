import { addDays, format, parseISO, startOfWeek } from "date-fns";
import { useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import Card from "src/components/Cards/Card";
import useAuth from "src/features/auth/hooks/useAuth";
import formatDateToString from "src/features/datetime/utils/formatDateToString";
import useGetEmployeeShiftsByEmployeeIdQuery from "../hooks/useGetEmployeeShiftsByEmployeeIdQuery";
import useGetTimeOffRequestsByEmployeeIdQuery from "../hooks/useGetTimeOffRequestsByEmployeeIdQuery";
import { EmployeeShift } from "../services/employeeShiftsService";
import {
  TimeOffRequest,
  TimeOffStatus,
} from "../services/timeOffRequestService";
import EmployeeWeeklyScheduleCard from "./EmployeeWeeklyScheduleCard";
import EmployeeWeeklyScheduleCardTimeOffRequestCard from "./EmployeeWeeklyScheduleCardTimeOffRequestCard";
import EmployeeWeeklyScheduleHeader from "./EmployeeWeeklyScheduleHeader";

const startOfCurrentWeek = startOfWeek(new Date(), { weekStartsOn: 0 });

const SHIFT_TRANSFERS_PATH = `/schedules/shift-transfers?status=all&startOfWeekDate=${startOfCurrentWeek
  .toISOString()
  .slice(0, 10)}`;

const TIME_OFF_REQUESTS_PATH = `/schedules/time-off-requests?status=all&startOfWeekDate=${startOfCurrentWeek
  .toISOString()
  .slice(0, 10)}`;

export default function MyWeeklySchedule() {
  const { employee } = useAuth();
  const navigate = useNavigate();

  const [currentWeek, setCurrentWeek] = useState(() => startOfCurrentWeek);

  const { data: employeeShifts, isLoading: isEmployeeShiftsLoading } =
    useGetEmployeeShiftsByEmployeeIdQuery({
      employeeid: employee?.id || undefined,
      weeklyScheduleStartDate: currentWeek.toISOString().slice(0, 10),
    });

  const { data: timeOffRequests, isLoading: isTimeOffRequestsLoading } =
    useGetTimeOffRequestsByEmployeeIdQuery({
      employeeId: employee?.id || undefined,
      startDate: currentWeek.toISOString().slice(0, 10),
      status: TimeOffStatus.Approved,
    });

  const scheduleByDay = useMemo(() => {
    const daysOfWeek: {
      day: string;
      date: Date;
      shifts: EmployeeShift[];
      timeOffRequests: TimeOffRequest[];
    }[] = Array.from({ length: 7 }, (_, i) => {
      const currentDate = addDays(currentWeek, i);
      return {
        day: format(currentDate, "EEEE - MMM d, yy"),
        date: currentDate,
        shifts: [],
        timeOffRequests: [],
      };
    });

    const dateToDayMap = new Map<string, (typeof daysOfWeek)[0]>();
    daysOfWeek.forEach((day) => {
      dateToDayMap.set(format(day.date, "yyyy-MM-dd"), day);
    });

    if (employeeShifts) {
      employeeShifts.forEach((shift: EmployeeShift) => {
        const shiftDate = new Date(shift.shift_requirement.start_datetime);
        const shiftDateString = format(shiftDate, "yyyy-MM-dd");
        const dayEntry = dateToDayMap.get(shiftDateString);
        if (dayEntry) {
          dayEntry.shifts.push(shift);
        }
      });
    }

    if (timeOffRequests) {
      timeOffRequests.forEach((timeOffRequest: TimeOffRequest) => {
        const startDate = parseISO(timeOffRequest.start_date);
        const endDate = parseISO(timeOffRequest.end_date);

        for (
          let date = new Date(startDate);
          date <= endDate;
          date.setDate(date.getDate() + 1)
        ) {
          const dateString = format(date, "yyyy-MM-dd");
          const dayEntry = dateToDayMap.get(dateString);
          if (dayEntry) {
            dayEntry.timeOffRequests.push(timeOffRequest);
          }
        }
      });
    }

    return daysOfWeek;
  }, [currentWeek, employeeShifts, timeOffRequests]);

  const weekDateTitle = useMemo(() => {
    return `${formatDateToString(
      currentWeek,
      false,
      false
    )} - ${formatDateToString(addDays(currentWeek, 6), false, false)}`;
  }, [currentWeek]);

  const handleClickWeekNavigation = (dir: "next" | "previous") => {
    setCurrentWeek((prevWeek) =>
      dir === "next" ? addDays(prevWeek, 7) : addDays(prevWeek, -7)
    );
  };

  const handleNavigateToShiftTransfers = () => navigate(SHIFT_TRANSFERS_PATH);

  const handleNavigateToTimeOffRequests = () =>
    navigate(TIME_OFF_REQUESTS_PATH);

  return (
    <Card>
      <EmployeeWeeklyScheduleHeader
        weekDateTitle={weekDateTitle}
        onClickWeekNavigation={handleClickWeekNavigation}
        onNavigateToShiftTransfers={handleNavigateToShiftTransfers}
        onNavigateToTimeOffRequests={handleNavigateToTimeOffRequests}
      />
      <div className="grid gap-10 p-10">
        {scheduleByDay.map((day) => {
          return (
            <div
              key={day.day}
              className="flex mh-150 bg-cp-black border-radius-5 p-10 gap-10"
            >
              <span className="font-bold text-xl cp-yellow">{day.day}</span>
              {isEmployeeShiftsLoading || isTimeOffRequestsLoading ? (
                <Skeleton height={100} />
              ) : null}
              {day.timeOffRequests.length > 0
                ? day.timeOffRequests.map((tor) => (
                    <EmployeeWeeklyScheduleCardTimeOffRequestCard
                      key={tor.id}
                      timeOffRequest={tor}
                    />
                  ))
                : null}
              {day.shifts.length > 0
                ? day.shifts.map((shift) => (
                    <EmployeeWeeklyScheduleCard key={shift.id} shift={shift} />
                  ))
                : null}
              {!isEmployeeShiftsLoading && !isTimeOffRequestsLoading
                ? day.shifts.length === 0 &&
                  day.timeOffRequests.length === 0 && (
                    <p className="text-sm text-muted">No shifts scheduled</p>
                  )
                : null}
            </div>
          );
        })}
      </div>
    </Card>
  );
}
