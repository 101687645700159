import { createSlice } from "@reduxjs/toolkit";
import { AuthenticatedEmployee } from "src/features/employees/types/employees.type";

export enum EmployeeLearningPathStatus {
  ASSIGNED = 0,
  IN_PROGRESS = 1,
  COMPLETED = 2,
  INCOMPLETE = 3,
}

export enum EmployeeLearningReportStatus {
  REQUESTED = "Requested",
  GENERATING = "Generating",
  READY_TO_DOWNLOAD = "Ready to Download",
  FAILED = "Failed",
}

export type EmployeeLearningPath = {
  employee: AuthenticatedEmployee | any;
  learningPath: any | string;
  assignedAt: Date;
  version: number;
  startedAt?: Date | null;
  completedAt?: Date | null;
  currentStatus: EmployeeLearningPathStatus;
  completionPercentage: number;
  deadline?: Date | null;
};

export type EmployeeLearningPathsState = {
  selectedLearningPath: EmployeeLearningPath | null;
};

const initialState: EmployeeLearningPathsState = {
  selectedLearningPath: null,
};

const employeeLearningPathsSlice = createSlice({
  name: "employeeLearningPaths",
  initialState,
  reducers: {
    setSelectedLearningPath(state, action) {
      state.selectedLearningPath = action.payload;
    },
    resetEmployeeLearningPathsState() {
      return initialState;
    },
  },
});

export const { resetEmployeeLearningPathsState, setSelectedLearningPath } =
  employeeLearningPathsSlice.actions;

export default employeeLearningPathsSlice.reducer;
