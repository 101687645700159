import { isValid, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function useDateRangeFromUrl() {
  const [searchParams, setSearchParams] = useSearchParams();

  const startDateParam = searchParams.get("startOfWeekDate");
  const endDateParam = searchParams.get("endOfWeekDate");

  const initialStartDate =
    startDateParam && isValid(parseISO(startDateParam))
      ? parseISO(startDateParam)
      : null;

  const initialEndDate =
    endDateParam && isValid(parseISO(endDateParam))
      ? parseISO(endDateParam)
      : null;

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    initialStartDate,
    initialEndDate,
  ]);

  useEffect(() => {
    const [startDate, endDate] = dateRange;
    const newSearchParams = new URLSearchParams(searchParams.toString());

    if (startDate) {
      newSearchParams.set(
        "startOfWeekDate",
        startDate.toISOString().slice(0, 10)
      );
    } else {
      newSearchParams.delete("startOfWeekDate");
    }

    if (endDate) {
      newSearchParams.set("endOfWeekDate", endDate.toISOString().slice(0, 10));
    } else {
      newSearchParams.delete("endOfWeekDate");
    }

    setSearchParams(newSearchParams);
  }, [dateRange, searchParams, setSearchParams]);

  return [dateRange, setDateRange] as const;
}
