import * as z from "zod";

export type MemoDetailsFormSchemaType = z.infer<typeof memoDetailsFormSchema>;

export const memoDetailsFormSchema = z.object({
  id: z.number().optional().or(z.string().optional()),
  title: z
    .string()
    .min(3, { message: "Title must be 3 characters min" })
    .max(50, { message: "Title must be 50 characters max" }),
  content: z
    .string()
    .min(3, { message: "Description must be at least 3 characters long" }),
});

export type MemoImageUpdateFormShemaType = z.infer<
  typeof memoImageUpdateSchema
>;

export const memoImageUpdateSchema = z.object({
  id: z.number().or(z.string()),
  file_url: z.string().nullable(),
  name: z.string().nullable(),
  ext: z.string().nullable(),
});

export type MemoUpdateViewableByFormSchemaType = z.infer<
  typeof memoUpdateViewableByFormSchema
>;

export const memoUpdateViewableByFormSchema = z.object({
  id: z.number().or(z.string()),
  viewable_by: z.array(z.string().or(z.number())),
});

export type MemoUpdateFormSchemaType = z.infer<typeof memoUpdateFormSchema>;

export const memoUpdateFormSchema = z.object({
  id: z.number().or(z.string()),
  title: z
    .string()
    .min(3, { message: "Title must be 3 characters min" })
    .max(50, { message: "Title must be 50 characters max" }),
  content: z
    .string()
    .min(3, { message: "Description must be at least 3 characters long" }),
  file_url: z.string().nullable(),
  name: z.string().nullable(),
  ext: z.string().nullable(),
  viewable_by: z.array(z.string().or(z.number())),
  draft: z.boolean(),
  important: z.boolean(),
});
