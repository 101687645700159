import useGetEmployeeModuleContentsQuery from "../../hooks/useGetEmployeeModuleContentsQuery";
import EmployeeModuleContentListItem from "./EmployeeModuleContentListItem";

type EmployeeModuleContentTitleProps = {
  employeeModuleId: string;
};

export default function EmployeeModuleContentsStatusList({
  employeeModuleId,
}: EmployeeModuleContentTitleProps) {
  const {
    data: employeeModuleContents,
    isLoading: isEmployeeModuleContentsLoading,
  } = useGetEmployeeModuleContentsQuery({
    employeeModuleId,
  });

  return (
    <>
      {!isEmployeeModuleContentsLoading &&
        employeeModuleContents?.map((employeeMc) => {
          return (
            <EmployeeModuleContentListItem
              key={employeeMc.id}
              employeeMc={employeeMc}
            />
          );
        })}
    </>
  );
}
