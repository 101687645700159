import StepOneYellowIcon from "src/components/Icons/StepOneYellowIcon";
import useVerticalStepperContent from "src/components/Steps/hooks/useVerticalStepperContent";
import StepEditButton from "src/features/learningPaths/components/LPBuilder/Steps/StepEditButton";
import CheckCircleYellowIcon from "src/components/Icons/CheckCircleYellowIcon";
import { useAppDispatch } from "src/store";
import { MemoFormSteps, setMemoFormStep } from "../../slices/memoSlice";
import useMemoFormState from "../../hooks/useMemoFormState";
import MemoDetailsForm from "./MemoDetailsForm";
import StepContainer from "src/components/Steps/StepContainer";

export default function MemoFormStepOne() {
  const dispatch = useAppDispatch();
  const { step } = useMemoFormState();

  const handleStepButtonClick = () =>
    dispatch(setMemoFormStep(MemoFormSteps.AddDetails));

  return useVerticalStepperContent({
    currentStep: step,
    activeStep: MemoFormSteps.AddDetails,
    titleAction:
      step !== MemoFormSteps.AddDetails ? (
        <StepEditButton onClick={handleStepButtonClick} />
      ) : null,
    activeIcon: <StepOneYellowIcon />,
    inactiveIcon: <CheckCircleYellowIcon />,
    defaultContent: (
      <StepContainer>
        {step !== MemoFormSteps.AddDetails ? (
          <span className="text-muted">This step is complete!</span>
        ) : (
          <span className="text-muted">
            Please enter a title and the content for your memo. Utilize our
            "Smart Enhance" feature to elevate the quality, layout, and
            professionalism of your memo. Simply type your content and select
            the "Smart Enhance" button to apply these enhancements
            automatically.
          </span>
        )}
      </StepContainer>
    ),
    activeContent: <MemoDetailsForm />,
  });
}
