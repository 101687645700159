import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import employeeScheduleRolesService from "../services/employeeScheduleRolesService";

type UseGetEmployeeScheduleRolesQuery = {
  scheduleRole?: number;
  departmentId?: number;
};

export default function useGetEmployeeScheduleRolesQuery({
  scheduleRole,
  departmentId,
}: UseGetEmployeeScheduleRolesQuery) {
  return useQuery({
    queryFn: () =>
      employeeScheduleRolesService.getEmployeeScheduleRoles(
        scheduleRole,
        departmentId
      ),
    queryKey: [QueryKeys.EmployeeScheduleRolesList, scheduleRole, departmentId],
    refetchOnWindowFocus: false,
  });
}
