import { memo } from "react";
import { useDispatch } from "react-redux";
import Button from "src/components/Buttons/Button";
import SectionHeader from "src/components/Headers/SectionHeader";
import MenuIcon from "src/components/Icons/MenuIcon";
import usePageHeader from "src/features/header/hooks/usePageHeader";
import { toggleMenu } from "src/features/navMenu/slices/navMenuSlice";
import globalStyles from "src/globalStyles.module.css";

type SiteHeaderProps = {
  actions?: React.ReactNode;
  id: string;
};

function SiteHeader({ actions, id }: SiteHeaderProps) {
  const dispatch = useDispatch();
  const { pageHeader } = usePageHeader();

  const handleToggleMenu = () => dispatch(toggleMenu());

  return (
    <SectionHeader
      id={id}
      title={pageHeader}
      leftAction={
        <Button
          className={globalStyles.navMenuButton}
          onClick={handleToggleMenu}
        >
          <MenuIcon />
        </Button>
      }
    >
      {actions}
    </SectionHeader>
  );
}

export default memo(SiteHeader);
