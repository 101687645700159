import { useEffect } from "react";
import { useAlert } from "@blaumaus/react-alert";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import LoadingAnimation from "src/components/Animations/LoadingAnimation";
import useSyncScheduleRolesMutation from "../hooks/useSyncScheduleRolesMutation";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import { useAppOutletContext } from "src/features/reactRouterDom/hooks/useAppOutletContext";
import DescriptionText from "src/components/Text/DescriptionText";
import Header from "src/components/Headers/Header";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";

export default function ScheduleRolesSyncRoute() {
  const { setCurrentStep } = useAppOutletContext() || {};
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const alert = useAlert();

  const syncScheduleRolesMutation = useSyncScheduleRolesMutation({
    onSuccess: (data) => {
      alert.success(data.detail);
      queryClient.invalidateQueries([QueryKeys.SchedulesSetupList]);
      navigate("/register/schedules/roles/verify");
    },
  });

  const handleSyncScheduleRoles = () => syncScheduleRolesMutation.mutate();

  useEffect(() => {
    setCurrentStep?.(1);
  }, [setCurrentStep]);

  return (
    <div className={"onboardingCard"}>
      <Header
        title={"Sync Schedule Roles"}
        containerStyle={{ maxHeight: "40px" }}
        id={"setUpRolesHeader"}
      />
      <DescriptionText>
        Sync your schedule roles with the roles in your organization
      </DescriptionText>
      <div className="centered flex-1">
        {syncScheduleRolesMutation.isLoading ? (
          <LoadingAnimation>
            <DescriptionText>Syncing schedule roles...</DescriptionText>
          </LoadingAnimation>
        ) : (
          <Button color={ButtonColors.Yellow} onClick={handleSyncScheduleRoles}>
            Sync Schedule Roles
          </Button>
        )}
      </div>
    </div>
  );
}
