import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import Dialog from "src/components/Dialog/Dialog";
import DialogContent from "src/components/Dialog/DialogContent";
import DialogDescription from "src/components/Dialog/DialogDescription";
import DialogFooter from "src/components/Dialog/DialogFooter";
import DialogHeader from "src/components/Dialog/DialogHeader";
import useWindowSize from "src/features/page/hooks/useWindowSize";
import PdfViewer from "src/features/pdf/components/PdfViewer";

type WeeklySchedulePrintDialogProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  scheduleUrl?: string;
  onClose?: () => void;
};

export default function WeeklySchedulePrintDialog({
  isOpen,
  setIsOpen,
  scheduleUrl,
  onClose,
}: WeeklySchedulePrintDialogProps) {
  const { windowWidth, windowHeight } = useWindowSize();

  const handleDownloadSchedule = () => {
    window.open(scheduleUrl, "_blank");
    setIsOpen(false);
  };

  return (
    <Dialog isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <DialogHeader>Download Weekly Schedule</DialogHeader>
      <DialogDescription>
        Would you like to download the weekly schedule?
      </DialogDescription>
      <DialogContent>
        <div style={{ overflowX: "auto" }}>
          <PdfViewer
            file={scheduleUrl}
            width={windowWidth < 576 ? windowWidth - 20 : windowHeight}
          />
        </div>
      </DialogContent>
      <DialogFooter>
        <Button
          color={ButtonColors.GrayAndYellow}
          onClick={() => setIsOpen(false)}
        >
          Close
        </Button>
        <Button color={ButtonColors.Yellow} onClick={handleDownloadSchedule}>
          Download Schedule
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
