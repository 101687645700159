import { useAlert } from "@blaumaus/react-alert";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MdDeleteForever } from "react-icons/md";
import { RotatingLines } from "react-loader-spinner";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import Dialog from "src/components/Dialog/Dialog";
import DialogContent from "src/components/Dialog/DialogContent";
import DialogDescription from "src/components/Dialog/DialogDescription";
import DialogFooter from "src/components/Dialog/DialogFooter";
import DialogHeader from "src/components/Dialog/DialogHeader";
import InputContainerV2 from "src/components/Inputs/InputContainerV2";
import InputLabel from "src/components/Inputs/InputLabel";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import { z } from "zod";
import useCreateShiftTypeMutation from "../hooks/useCreateShiftTypeMutation";
import usePartialUpdateShiftTypeMutation from "../hooks/usePartialUpdateShiftTypeMutation";
import { ShiftType } from "../services/shiftTypeService";
import ShiftTypeDeleteDialog from "./ShiftTypeDeleteDialog";

const shiftTypeSchema = z.object({
  id: z
    .union([z.string().optional(), z.number().optional()])
    .transform((val) => (typeof val === "string" ? parseInt(val, 10) : val))
    .optional(),
  name: z.string().min(3, "Name is required"),
  department: z.union([z.number(), z.string()]).refine((val) => val !== "", {
    message: "Please select a department.",
  }),
  start_time: z.string().min(3, "Start time is required"),
  end_time: z.string().min(3, "End time is required"),
});

type ShiftTypeForm = z.infer<typeof shiftTypeSchema>;

type ShiftTypeFormDialogProps = {
  shiftType: ShiftType | null;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  departmentId?: string;
};

export default function ShiftTypeFormDialog({
  shiftType,
  isOpen,
  setIsOpen,
  departmentId,
}: ShiftTypeFormDialogProps) {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const handleErrors = useErrorHandling();
  const queryClient = useQueryClient();
  const alert = useAlert();

  const { handleSubmit, register, formState, reset } = useForm<ShiftTypeForm>({
    resolver: zodResolver(shiftTypeSchema),
    defaultValues: {
      name: "",
      start_time: "",
      end_time: "",
      department: departmentId,
    },
  });

  const handleSuccessfulSave = () => {
    queryClient.invalidateQueries([QueryKeys.ShiftTypesList]);
    setIsOpen(false);
    reset({
      name: "",
      start_time: "",
      end_time: "",
      department: departmentId,
    });
    alert.success("Shift type saved successfully.");
  };

  const createShiftTypeMutation = useCreateShiftTypeMutation({
    onSuccess: () => handleSuccessfulSave(),
    onError: (error) => handleErrors(error),
  });

  const partialUpdateShiftTypeMutation = usePartialUpdateShiftTypeMutation({
    onSuccess: () => handleSuccessfulSave(),
  });

  const onSubmitShiftType = (data: ShiftTypeForm) => {
    if (shiftType) {
      partialUpdateShiftTypeMutation.mutate(data);
      return;
    }
    createShiftTypeMutation.mutate(data);
  };

  const handleOpenDeleteShiftTypeDialog = () => setIsDeleteDialogOpen(true);

  useEffect(() => {
    if (shiftType) {
      reset({
        id: shiftType.id,
        name: shiftType.name,
        start_time: shiftType.start_time,
        end_time: shiftType.end_time,
        department: departmentId,
      });
    }
  }, [shiftType, reset, departmentId]);

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={() => setIsOpen(false)}
      onClose={() =>
        reset({
          name: "",
          start_time: "",
          end_time: "",
          department: departmentId,
        })
      }
    >
      <DialogHeader>Shift Type</DialogHeader>
      <DialogDescription>
        {shiftType ? (
          <>
            Edit the shift type
            <MdDeleteForever
              className="cursor-pointer"
              color="var(--clearpath-danger)"
              size={24}
              onClick={handleOpenDeleteShiftTypeDialog}
            />
          </>
        ) : (
          "Create a new shift type"
        )}
      </DialogDescription>
      <form onSubmit={handleSubmit(onSubmitShiftType)} className="dialog-form">
        <DialogContent>
          <InputContainerV2>
            <InputLabel>Shift Name</InputLabel>
            <input
              type="text"
              disabled={createShiftTypeMutation.isLoading}
              className={
                formState.errors.name ? "inputBlackError" : "inputBlack"
              }
              placeholder="Shift Name"
              {...register("name")}
            />
            {formState.errors.name && (
              <span className={"inputError"}>
                {formState.errors.name.message}
              </span>
            )}
          </InputContainerV2>
          <InputContainerV2>
            <InputLabel>Shift Start</InputLabel>
            <input
              type="time"
              disabled={createShiftTypeMutation.isLoading}
              className={
                formState.errors.start_time ? "inputBlackError" : "inputBlack"
              }
              placeholder="Start Time"
              {...register("start_time")}
            />
            {formState.errors.start_time && (
              <span className={"inputError"}>
                {formState.errors.start_time.message}
              </span>
            )}
          </InputContainerV2>
          <InputContainerV2>
            <InputLabel>Shift End</InputLabel>
            <input
              type="time"
              disabled={createShiftTypeMutation.isLoading}
              className={
                formState.errors.end_time ? "inputBlackError" : "inputBlack"
              }
              placeholder="End Time"
              {...register("end_time")}
            />
            {formState.errors.end_time && (
              <span className={"inputError"}>
                {formState.errors.end_time.message}
              </span>
            )}
          </InputContainerV2>
        </DialogContent>
        <DialogFooter>
          <Button
            color={ButtonColors.GrayAndYellow}
            onClick={() => setIsOpen(false)}
            disabled={createShiftTypeMutation.isLoading}
          >
            {createShiftTypeMutation.isLoading ? (
              <RotatingLines
                strokeColor="#f1b70c"
                strokeWidth="5"
                animationDuration="0.75"
                width="20"
                visible={true}
              />
            ) : (
              "Cancel"
            )}
          </Button>
          <Button
            color={ButtonColors.Yellow}
            type="submit"
            disabled={createShiftTypeMutation.isLoading}
          >
            {createShiftTypeMutation.isLoading ? (
              <RotatingLines
                strokeColor="#000"
                strokeWidth="5"
                animationDuration="0.75"
                width="20"
                visible={true}
              />
            ) : (
              "Save Shift Type"
            )}
          </Button>
        </DialogFooter>
      </form>
      {shiftType ? (
        <ShiftTypeDeleteDialog
          isOpen={isDeleteDialogOpen}
          setIsOpen={setIsDeleteDialogOpen}
          shiftType={shiftType}
        />
      ) : null}
    </Dialog>
  );
}
