import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import timeOffRequestService, {
  TimeOffStatus,
} from "../services/timeOffRequestService";

type UseGetTimeOffRequestsQueryParams = {
  startDate?: string;
  endDate?: string;
  status?: TimeOffStatus;
};

export default function useGetTimeOffRequestsByStartDateQuery({
  startDate,
  endDate,
  status,
}: UseGetTimeOffRequestsQueryParams) {
  return useQuery({
    queryFn: startDate
      ? () =>
          timeOffRequestService.getTimeOffRequests({
            startDate,
            endDate,
            status,
          })
      : undefined,
    queryKey: [
      QueryKeys.TimeOffRequests,
      { startDate: startDate, endDate: endDate, status: status },
    ],
    enabled: !!startDate,
    retry: false,
  });
}
