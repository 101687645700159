import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAlert } from "@blaumaus/react-alert";

import departmentsService from "../services/departmentsService";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

export default function useDeleteDepartmentMutation() {
  const queryClient = useQueryClient();
  const alert = useAlert();

  return useMutation({
    mutationFn: departmentsService.deleteDepartment,
    onSuccess: async () => {
      alert.success("Department deleted successfully!");
      await queryClient.invalidateQueries([QueryKeys.DepartmentsList]);
    },
  });
}
