import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import shiftTransferService, {
  ShiftTransferStatus,
  ShiftTransferType,
} from "../services/shiftTransferService";

type UseGetShiftTransfersByEmployeeScheduleRolesQuery = {
  scheduleRoles?: string;
  startDate?: string;
  type?: ShiftTransferType;
  status?: ShiftTransferStatus;
  notFrom?: number;
  employeeId?: number;
};

export default function useGetShiftTransfersByEmployeeScheduleRolesQuery({
  scheduleRoles,
  startDate,
  type,
  status,
  notFrom,
  employeeId,
}: UseGetShiftTransfersByEmployeeScheduleRolesQuery) {
  return useQuery({
    queryFn:
      scheduleRoles && type
        ? () =>
            shiftTransferService.getShiftTransfersByEmployeeScheduleRoles(
              scheduleRoles,
              startDate,
              type,
              status,
              notFrom,
              employeeId
            )
        : undefined,
    queryKey: [
      QueryKeys.ShiftTransfersList,
      {
        scheduleRoles,
        startDate,
        type,
        status,
        notFrom,
        employeeId,
      },
    ],
    enabled: !!scheduleRoles && !!type,
    refetchOnWindowFocus: false,
  });
}
