import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosClient from "../../axios/client";
import { Group } from "../types/groups.types";

export const getGroups = createAsyncThunk<null, void, { rejectValue: any }>(
  "auth/getGroups",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("/api/groups");
      return response.data;
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      } else {
        return rejectWithValue({ detail: "An unknown error occured" });
      }
    }
  }
);

export interface GroupsState {
  data: Group[];
  status: "idle" | "loading" | "succeeded" | "failed";
  errors: string[] | null;
}

const initialState: GroupsState = {
  status: "idle",
  data: [],
  errors: null,
};

export const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGroups.pending, (state) => {
        state.status = "loading";
        state.errors = null;
      })
      .addCase(getGroups.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload || [];
      })
      .addCase(getGroups.rejected, (state, action) => {
        state.status = "failed";
        state.errors = action.payload;
      });
  },
});

export default groupsSlice.reducer;
