import React, { memo } from "react";
import ImageUploadIcon from "../Icons/ImageUploadIcon";
import styles from "./ImageUploadInput.module.css";
import LoadingAnimation from "../Animations/LoadingAnimation";
import InputError from "../Errors/InputError";

type ImageUploadInputProps = {
  error?: string | null;
  register?: any;
  name: string;
  accept?: string;
  containerClass?: string;
  containerStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  inputClass?: string;
  isUploading?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

function ImageUploadInput({
  error,
  register,
  name,
  containerClass,
  containerStyle,
  inputStyle,
  inputClass,
  accept = "image/jpeg,image/png",
  isUploading = false,
  onChange,
}: ImageUploadInputProps) {
  const ref = register ? register(name) : {};

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e);
    if (ref.onChange) ref.onChange(e);
  };

  return (
    <>
      <label
        className={containerClass ? containerClass : styles.imageUploadIcon}
        id="imageUpload"
        style={containerStyle}
      >
        {isUploading ? <LoadingAnimation /> : <ImageUploadIcon />}
        <input
          {...ref}
          type="file"
          name={name}
          accept={accept}
          id="imageUploadInput"
          className={inputClass ? inputClass : styles.imageUploadInput}
          style={inputStyle}
          onChange={handleChange}
        />
      </label>
      {error ? <InputError error={error} /> : null}
    </>
  );
}

export default memo(ImageUploadInput);
