import React from "react";
import styles from "./PageContent.module.css";

type PageContentProps = {
  children: React.ReactNode;
  id?: string;
  style?: React.CSSProperties;
  className?: string;
};

function PageContent({ children, id, style, className }: PageContentProps) {
  return (
    <div
      className={`${styles.contentContainer} ${className}`}
      id={id}
      style={style}
    >
      {children}
    </div>
  );
}

export default PageContent;
