import { createSlice } from "@reduxjs/toolkit";
import { DocumentAnnotation } from "../types/document.types";

export type DocumentAnnotationsState = {
  annotations: DocumentAnnotation[];
  selectedAnnotationUuid: string | null;
  signature: string | null;
  image: string | null;
};

const initialState: DocumentAnnotationsState = {
  annotations: [],
  selectedAnnotationUuid: null,
  signature: null,
  image: null,
};

export const documentAnnotationsSlice = createSlice({
  name: "documentAnnotations",
  initialState,
  reducers: {
    addAnnotation: (state, action) => {
      state.annotations.push(action.payload);
    },
    addOrUpdateAnnotation: (state, action) => {
      const incomingAnnotation = action.payload;

      const existingIndex = state.annotations.findIndex(
        (annotation) => annotation.uuid === incomingAnnotation.uuid
      );

      if (existingIndex !== -1) {
        state.annotations[existingIndex] = incomingAnnotation;
      } else {
        state.annotations.push(incomingAnnotation);
      }
    },
    addOrUpdateAnnotations: (
      state,
      action: { payload: DocumentAnnotation[] }
    ) => {
      const incomingAnnotations = action.payload;

      if (!incomingAnnotations) {
        return;
      }

      incomingAnnotations.forEach((newAnnotation) => {
        const existingIndex = state.annotations.findIndex(
          (annotation) => annotation.uuid === newAnnotation.uuid
        );

        if (existingIndex !== -1) {
          state.annotations[existingIndex] = newAnnotation;
        } else {
          state.annotations.push(newAnnotation);
        }
      });
    },
    deleteSelectedAnnotation: (state) => {
      const index = state.annotations.findIndex(
        (a) => a.uuid === state.selectedAnnotationUuid
      );
      if (index !== -1) {
        state.annotations.splice(index, 1);
      }
      state.selectedAnnotationUuid = null;
    },
    resetDocumentAnnotationState: (state) => {
      state.annotations = [];
      state.selectedAnnotationUuid = null;
      state.signature = null;
      state.image = null;
    },
    selectAnnotation: (state, action) => {
      state.selectedAnnotationUuid = action.payload;
    },
    setImage: (state, action) => {
      state.image = action.payload;
    },
    setSignature: (state, action) => {
      state.signature = action.payload;
    },
    moveAnnotation: (state, action) => {
      const { uuid, x, y, width, height } = action.payload;
      const index = state.annotations.findIndex((a) => a.uuid === uuid);
      if (index !== -1) {
        state.annotations[index] = {
          ...state.annotations[index],
          x,
          y,
          width,
          height,
        };
      }
    },
  },
});

export const {
  addAnnotation,
  addOrUpdateAnnotation,
  addOrUpdateAnnotations,
  deleteSelectedAnnotation,
  resetDocumentAnnotationState,
  selectAnnotation,
  setSignature,
  moveAnnotation,
} = documentAnnotationsSlice.actions;

export default documentAnnotationsSlice.reducer;
