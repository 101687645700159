import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import documentService from "../services/documentService";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

type UseGetDocumentProps = {
  enabled?: boolean;
  refetchOnWindowFocus?: boolean;
};

export default function useGetDocument({
  enabled = true,
  refetchOnWindowFocus = false,
}: UseGetDocumentProps = {}) {
  const { id } = useParams();

  return useQuery({
    queryKey: [QueryKeys.Document, id],
    queryFn: id ? () => documentService.getDocument(id) : undefined,
    enabled: !!id && enabled,
    refetchOnWindowFocus,
  });
}
