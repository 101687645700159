import axiosClient from "src/features/axios/client";
import { EmployeeListItem } from "src/features/employees/types/employees.type";
import { EmployeeScheduleRoleType } from "./employeeScheduleRolesService";
import { EmployeeShift } from "./employeeShiftsService";

export enum ShiftTransferStatus {
  Available = "Available",
  SwapRequested = "SwapRequested",
  Pending = "Pending",
  Approved = "Approved",
  Declined = "Declined",
  Expired = "Expired",
}

export enum ShiftTransferType {
  ShiftDrop = "ShiftDrop",
  ShiftSwap = "ShiftSwap",
}

export type ShiftTransfer = {
  id: number;
  type: ShiftTransferType;
  status: ShiftTransferStatus;
  shift_from: EmployeeShift;
  shift_to: EmployeeShift;
  employee_schedule_role_from: EmployeeScheduleRoleType;
  employee_schedule_role_to: EmployeeScheduleRoleType;
  requested_at: string;
  created_on: string;
  updated_on: string;
  decision_at: string;
  decision_by: EmployeeListItem | null;
};

export type CreateShiftTransferData = {
  type: ShiftTransferType;
  shift_from: number;
  shift_to: number;
  employee_schedule_role_from: number;
  employee_schedule_role_to: number;
};

class ShiftTransferService {
  async acceptShiftSwap(id: number): Promise<ShiftTransfer> {
    return await axiosClient
      .post(`/api/shift-transfers/${id}/accept-swap/`)
      .then((res) => res.data);
  }

  async declineShiftSwap(id: number): Promise<ShiftTransfer> {
    return await axiosClient
      .post(`/api/shift-transfers/${id}/decline-swap/`)
      .then((res) => res.data);
  }

  async createShiftTransfer(
    data: CreateShiftTransferData
  ): Promise<ShiftTransfer> {
    return await axiosClient
      .post(`/api/shift-transfers/`, data)
      .then((res) => res.data);
  }

  async getShiftTransfersByEmployeeScheduleRoles(
    scheduleRoles: string,
    startDate?: string,
    type?: ShiftTransferType,
    status?: ShiftTransferStatus,
    notFrom?: number,
    employeeId?: number
  ): Promise<ShiftTransfer[]> {
    return await axiosClient
      .get(`/api/shift-transfers`, {
        params: {
          schedule_role_from: scheduleRoles,
          type: type,
          status,
          start_date: startDate,
          not_from: notFrom,
          employee_id: employeeId,
        },
      })
      .then((res) => res.data);
  }

  async getShiftTransfersById(id: string | number): Promise<ShiftTransfer> {
    return await axiosClient
      .get(`/api/shift-transfers/${id}`)
      .then((res) => res.data);
  }

  async getShiftTransfersByStartDate(
    startDate: string,
    endDate?: string,
    type?: ShiftTransferType,
    status?: ShiftTransferStatus | string,
    notFrom?: number,
    departmentId?: number
  ): Promise<ShiftTransfer[]> {
    return await axiosClient
      .get(`/api/shift-transfers`, {
        params: {
          start_date: startDate,
          end_date: endDate,
          type: type,
          status,
          not_from: notFrom,
          department_id: departmentId,
        },
      })
      .then((res) => res.data);
  }

  async makeShiftTransferDecision(
    id: number,
    status: ShiftTransferStatus
  ): Promise<ShiftTransfer> {
    return await axiosClient
      .post(`/api/shift-transfers/${id}/decision/`, {
        status,
      })
      .then((res) => res.data);
  }

  async pickUpEmployeeShift(id: number): Promise<ShiftTransfer> {
    return await axiosClient
      .post(`/api/shift-transfers/${id}/pick-up-shift/`)
      .then((res) => res.data);
  }
}

const shiftTransferService = new ShiftTransferService();

export default shiftTransferService;
