import { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";

type ImageProps = {
  src: string;
  alt: string;
  id?: string;
  className?: string;
  style?: React.CSSProperties;
  height?: string | number;
  width?: string | number;
  circle?: boolean;
  loading?: "lazy" | "eager" | undefined;
  loadedCallback?: () => void;
};

export default function ImageV2({
  src,
  alt,
  id,
  className,
  style,
  height = "100%",
  width = "100%",
  circle = false,
  loading = "lazy",
  loadedCallback,
  ...props
}: ImageProps): JSX.Element {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (imgRef.current) {
      const handleLoad = () => {
        loadedCallback?.();
        setLoaded(true);
      };
      const imageElement = imgRef.current;
      imageElement.addEventListener("load", handleLoad);

      // If the image is already in cache, this will trigger the load event
      if (imageElement.complete) {
        setLoaded(true);
        loadedCallback?.();
      }

      return () => {
        imageElement.removeEventListener("load", handleLoad);
      };
    }
  }, [src, loadedCallback]);

  return (
    <>
      {!loaded && <Skeleton height={height} width={width} circle={circle} />}
      <img
        ref={imgRef}
        src={src}
        alt={alt}
        id={id}
        className={className}
        style={{ display: loaded ? "block" : "none", ...style }}
        {...props}
      />
    </>
  );
}
