import * as React from "react";

const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeOpacity={0.6}
      strokeWidth={1.5}
      d="m5.548 4.74 6.367-2.123c2.858-.952 4.41.608 3.465 3.465l-2.122 6.368c-1.425 4.283-3.765 4.283-5.19 0l-.63-1.89-1.89-.63c-4.283-1.425-4.283-3.758 0-5.19ZM7.582 10.238l2.685-2.693"
    />
  </svg>
);
export default SvgComponent;
