import { ShiftConflictResolutionStatus } from "../services/shiftConflictsService";

const getShiftConflictBadgeColor = (status: ShiftConflictResolutionStatus) => {
  switch (status) {
    case ShiftConflictResolutionStatus.Resolved:
      return "var(--clearpath-green)";
    case ShiftConflictResolutionStatus.Unresolved:
      return "var(--clearpath-yellow)";
    case ShiftConflictResolutionStatus.Dismissed:
      return "var(--clearpath-light-gray)";
    case ShiftConflictResolutionStatus.Expired:
      return "var(--clearpath-danger)";
    default:
      return "var(--clearpath-light-gray)";
  }
};

export default getShiftConflictBadgeColor;
