import { memo } from "react";
import styles from "./AssessmentTakeInfo.module.css";
import Badge from "src/components/Badges/Badge";

type LPTakeAssessmentInfoProps = {
  timeLimit: number | null;
  title: string;
  description: string;
  singleAttempt: boolean;
  graded: boolean;
  passingPercentage: number | null;
};

function AssessmentTakeInfo({
  timeLimit,
  title,
  description,
  singleAttempt,
  graded,
  passingPercentage,
}: LPTakeAssessmentInfoProps) {
  return (
    <div className={styles.assessmentInfoContainer}>
      <h1 className={styles.assessmentInfoText}>{title}</h1>
      <div className={styles.assessmentDescriptionsContainer}>
        <h4 className={styles.assessmentDescription}>
          {description ? description : null}
        </h4>
        <span className={styles.badgeContainer}>
          {graded ? <Badge color="#cd3c44" text="Graded" /> : null}
          {singleAttempt ? (
            <Badge color="#f1b70c" text="Single Attempt" />
          ) : null}
          {timeLimit ? <Badge color="#4f4f4f" text="Timed" /> : null}
        </span>
        <span className={styles.assessmentInfo}>
          This assessment is {graded ? "" : "not"} graded. You will be able to
          see your score at the end of the assessment. You will need to score at
          least {passingPercentage || "0"}% to pass.{" "}
          {singleAttempt
            ? "You will only be able to take this assessment once. "
            : "You can take this assessment multiple times. "}
          You have {timeLimit || "unlimited"} minutes to complete the
          assessment.
        </span>
      </div>
    </div>
  );
}

export default memo(AssessmentTakeInfo);
