import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";

export const useHandleOptimisticMutationError = <TData,>() => {
  const queryClient = useQueryClient();
  const handleErrors = useErrorHandling();

  const handleOptimisticMutationError = (
    error: AxiosError,
    queryKey: unknown[],
    prevData?: TData
  ) => {
    handleErrors(error);
    queryClient.setQueryData(queryKey, prevData); // Revert optimistic update
  };

  return handleOptimisticMutationError;
};
