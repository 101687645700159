import { useQuery } from "@tanstack/react-query";
import shiftRequirementsService from "../services/shiftRequirementsService";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

type UseGetShiftRequirementsQueryProps = {
  weeklyScheduleId?: number;
};

export default function useGetShiftRequirementsByScheduleIdQuery({
  weeklyScheduleId,
}: UseGetShiftRequirementsQueryProps) {
  return useQuery({
    queryFn: weeklyScheduleId
      ? () =>
          shiftRequirementsService.getShiftRequirements({
            weeklyScheduleId,
          })
      : undefined,
    queryKey: [QueryKeys.ShiftRequirementsList, weeklyScheduleId],
    enabled: !!weeklyScheduleId,
  });
}
