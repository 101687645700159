import { useAlert } from "@blaumaus/react-alert";
import { useMemo, useState } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { RotatingLines } from "react-loader-spinner";
import Button from "src/components/Buttons/Button";
import {
  ButtonColors,
  ButtonSizes,
} from "src/components/Buttons/buttons.types";
import ThreeDotsDropDownV2 from "src/components/DropDowns/ThreeDotsDropDownV2";
import ThreeDotsMenuItem from "src/components/DropDowns/ThreeDotsMenuItem";
import ThreeDotsIcon from "src/components/Icons/ThreeDotsIcon";
import formatDateToString from "src/features/datetime/utils/formatDateToString";
import TimeOffCreateDialog from "./TimeOffRequestCreateDialog";

type CalenderHeaderProps = {
  weekDates: Date[];
  scheduleUrl?: string;
  onNavigateWeek: (direction: "prev" | "next") => void;
  onPublishWeeklySchedule: () => void;
  onCopyLastWeeklySchedule: () => void;
  setIsFilterVisible: React.Dispatch<React.SetStateAction<boolean>>;
  publishButtonDisabled: boolean;
  isPublishButtonLoading: boolean;
  isFilterVisible: boolean;
};

export default function ScheduleCalenderHeader({
  weekDates,
  scheduleUrl,
  onNavigateWeek,
  onPublishWeeklySchedule,
  onCopyLastWeeklySchedule,
  setIsFilterVisible,
  publishButtonDisabled,
  isPublishButtonLoading,
  isFilterVisible,
}: CalenderHeaderProps) {
  const alert = useAlert();
  const [isTimeOffDialogOpen, setIsTimeOffDialogOpen] = useState(false);
  const weekDateTitle = useMemo(() => {
    return `${formatDateToString(
      weekDates[0],
      false,
      false
    )} - ${formatDateToString(weekDates[6], false, false)}`;
  }, [weekDates]);

  const handleCreateEmployeeTimeOff = () => {
    setIsTimeOffDialogOpen(true);
  };

  const handleDownloadSchedule = () => {
    if (!scheduleUrl) {
      alert.error("Please publish the schedule first.");
      return;
    }
    window.open(scheduleUrl, "_blank");
  };

  return (
    <div className="h-60 px-20 border-bottom-light-gray">
      <TimeOffCreateDialog
        isOpen={isTimeOffDialogOpen}
        setIsOpen={setIsTimeOffDialogOpen}
      />
      <div className="flex flex-1 flex-row align-center justify-between gap-20 h-60">
        <div className="flex">
          <Button
            color={ButtonColors.Yellow}
            size={ButtonSizes.MD}
            className="border-radius-5"
            disabled={publishButtonDisabled}
            onClick={onPublishWeeklySchedule}
          >
            {isPublishButtonLoading ? (
              <RotatingLines
                strokeColor="#000"
                strokeWidth="5"
                animationDuration="0.75"
                width="20"
                visible={true}
              />
            ) : !publishButtonDisabled ? (
              "Publish"
            ) : (
              "Published"
            )}
          </Button>
        </div>
        <div className="flex flex-1 flex-row gap-20 align-center justify-center absolute left-1/2 transform -translate-x-1/2">
          <Button
            color={ButtonColors.Yellow}
            className="border-radius-5 p-0"
            onClick={() => onNavigateWeek("prev")}
          >
            <MdChevronLeft className="cursor-pointer" size={28} />
          </Button>
          <span className="mobile-title">{weekDateTitle}</span>
          <Button
            color={ButtonColors.Yellow}
            className="border-radius-5 p-0"
            onClick={() => onNavigateWeek("next")}
          >
            <MdChevronRight className="cursor-pointer" size={28} />
          </Button>
        </div>

        <div className="flex w-40 align-end justify-end">
          <ThreeDotsDropDownV2
            setIsFilterVisible={setIsFilterVisible}
            isFilterVisible={isFilterVisible}
            icon={<ThreeDotsIcon />}
          >
            <ThreeDotsMenuItem onClick={handleCreateEmployeeTimeOff}>
              Create Employee Time-Off
            </ThreeDotsMenuItem>
            <ThreeDotsMenuItem onClick={onCopyLastWeeklySchedule}>
              Copy Last Week's Shifts
            </ThreeDotsMenuItem>
            <ThreeDotsMenuItem onClick={handleDownloadSchedule}>
              Download Schedule
            </ThreeDotsMenuItem>
          </ThreeDotsDropDownV2>
        </div>
      </div>
    </div>
  );
}
