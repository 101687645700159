import React from "react";
import styles from "./ModuleContentTitleAndDescription.module.css";

type ModuleContentTitleAndDescriptionProps = {
  title: React.ReactNode;
  description: React.ReactNode;
};

export default function ModuleContentTitleAndDescription({
  title,
  description,
}: ModuleContentTitleAndDescriptionProps) {
  return (
    <div className={styles.infoContainer}>
      <span className={styles.title}>{title}</span>
      <span className={styles.description}>{description}</span>
    </div>
  );
}
