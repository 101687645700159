import React from "react";
import styles from "./SquareContainer.module.css";

type SquareContainerProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
};

function SquareContainer({ children, style, className }: SquareContainerProps) {
  return (
    <div className={`${styles.container} ${className}`} style={style}>
      {children}
    </div>
  );
}

export default SquareContainer;
