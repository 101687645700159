import React from "react";

interface InputErrorProps {
  error?: string | null;
  style?: React.CSSProperties;
}

export default function InputError({ error, style }: InputErrorProps) {
  return (
    <div className={"inputError"} style={{ ...style }}>
      {error}
    </div>
  );
}
