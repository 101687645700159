function formatDurationToString(durationInMilliseconds: number): string {
  if (durationInMilliseconds <= 0) {
    return "0 hr 0 min 0 sec";
  }
  const seconds = Math.floor(durationInMilliseconds / 1000);
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return `${hours} hr ${minutes} min ${remainingSeconds} sec`;
}

export default formatDurationToString;
