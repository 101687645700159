import React, { useState, useEffect } from "react";
import Joyride from "react-joyride";
// Styles
import { joyrideStyles } from "./JoyRideStyles";

/**
 * Types for the Joyride steps
 */
interface IJoyrideSteps {
  title: string;
  target: string;
  content: string;
  disableBeacon?: boolean;
}

/**
 * Types for the JoyrideWrapper component props
 */
interface IProps {
  steps: IJoyrideSteps[];
  storageKey: string;
}

/**
 * JoyrideWrapper is a component which handles the logic for the Joyride component.
 * @param steps  The steps to be displayed in the joyride
 * @returns  A joyride component
 */
export default function JoyrideWrapper({
  steps,
  storageKey,
}: IProps): JSX.Element {
  const [runJoyRide, setRunJoyRide] = useState<boolean>(false);

  useEffect(() => {
    if (localStorage.getItem(storageKey)) return;
    setRunJoyRide(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hideJoyRide = (action: string, status: string) => {
    if (status === "finished" || action === "close") {
      localStorage.setItem(storageKey, "true");
      setRunJoyRide(false);
    }
  };
  return (
    <Joyride
      steps={steps}
      continuous={true}
      showProgress={true}
      callback={(data) => hideJoyRide(data.action, data.status)}
      run={runJoyRide}
      styles={{
        options: joyrideStyles.options,
        spotlight: joyrideStyles.spotlight,
        tooltipTitle: joyrideStyles.title,
        buttonNext: joyrideStyles.buttonNext,
      }}
    />
  );
}
