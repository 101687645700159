import { PDFDocument, rgb, StandardFonts } from "pdf-lib-incremental-save";
import { useCallback, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import SignatureCanvas from "react-signature-canvas";
import { useAlert } from "@blaumaus/react-alert";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { RotatingLines } from "react-loader-spinner";
import { v4 as uuidv4 } from "uuid";

import styles from "../ESignatures.module.css";
import Button from "src/components/Buttons/Button";
import {
  ButtonColors,
  ButtonSizes,
} from "src/components/Buttons/buttons.types";
import Card from "src/components/Cards/Card";
import DocumentCardHeader from "./DocumentCardHeader";
import DocumentSignToolbar from "./DocumentSignToolbar";
import VerticalDocumentApprovalViewer from "../Viewers/VerticalDocumentApprovalViewer";
import useScrollIntoView from "src/features/page/hooks/useScrollIntoView";
import useGetDocument from "../../hooks/useGetDocument";
import Dialog from "src/components/Dialog/Dialog";
import DialogHeader from "src/components/Dialog/DialogHeader";
import DialogDescription from "src/components/Dialog/DialogDescription";
import { AnnotationType } from "../../types/template.types";
import DialogContent from "src/components/Dialog/DialogContent";
import DialogFooter from "src/components/Dialog/DialogFooter";
import SquareCheckboxInput from "src/components/Inputs/SquareCheckboxInput";
import Container from "src/components/Container/Container";
import useAuth from "src/features/auth/hooks/useAuth";
import getFullName from "src/features/employees/utils/getFullName";
import { AuthenticatedEmployee } from "src/features/employees/types/employees.type";
import savePdfDocument from "src/features/pdf/utils/savePdfDocument";
import fetchPdfBytes from "src/features/pdf/utils/fetchPdfBytes";
import loadPdfDocument from "src/features/pdf/utils/loadPdfDocument";
import findAnnotationByUuid from "../../utils/findAnnotationByUuid";
import drawImageOnPage from "src/features/pdf/utils/drawImageOnPage";
import useCreateDocumentAnnotationMutation from "../../hooks/useCreateDocumentAnnotationMutation";
import fetchAndConvertToBlob from "src/features/pdf/utils/fetchAndConvertToBlob";
import useSignDocumentMutation from "../../hooks/useSignDocumentMutation";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import useUploadFileToS3Mutation from "src/features/s3/hooks/useUploadFileToS3Mutation";
import s3Service from "src/features/s3/services/s3Service";

export type DocumentApprovalAnnotationType = {
  document?: string | number;
  uuid: string;
  x: number;
  y: number;
  width: number;
  height: number;
  signature: string;
  page: number;
  type: AnnotationType;
  completed_at?: string;
  assigned_to: number | string | null;
};

const AGREE_TO_TERMS =
  "By checking this box, I understand and agree that I am signing this document digitally. I understand that my electronic signature is the equivalent of my handwritten signature.";

function getAnnotationApprovalDetails(
  annotation: DocumentApprovalAnnotationType
) {
  const annotationColor =
    annotation.type === AnnotationType.Approval
      ? rgb(0, 0.53, 0.18)
      : rgb(1, 0, 0);
  const approvalText =
    annotation.type === AnnotationType.Approval ? "Approved" : "Denied";

  return { annotationColor, approvalText };
}

async function drawApprovalAnnotation(
  pdfDoc: PDFDocument,
  page: any,
  annotation: DocumentApprovalAnnotationType,
  approvalText: string,
  annotationColor: any,
  employee: AuthenticatedEmployee
) {
  const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
  const dateTimeNow = new Date().toLocaleString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  page.drawRectangle({
    x: annotation.x + 7,
    y: page.getHeight() - annotation.y - annotation.height + 7,
    width: annotation.width - 14,
    height: annotation.height - 14,
    borderColor: annotationColor,
    borderWidth: 14,
    color: rgb(1, 1, 1),
  });

  page.drawText(approvalText, {
    x: annotation.x + 20,
    y: page.getHeight() - annotation.y - 36,
    size: 18,
    color: annotationColor,
    font: boldFont,
  });

  page.drawText(`By: ${getFullName(employee)}`, {
    x: annotation.x + 20,
    y: page.getHeight() - annotation.y - 105,
    size: 10,
    color: rgb(0, 0, 0),
  });

  page.drawText(`Date: ${dateTimeNow}`, {
    x: annotation.x + 20,
    y: page.getHeight() - annotation.y - 116,
    size: 10,
    color: rgb(0, 0, 0),
  });
}

function updateAnnotationsAsComplete(
  annotations: DocumentApprovalAnnotationType[],
  uuid: string
) {
  return annotations.map((a) =>
    a.uuid === uuid
      ? { ...a, complete: true, completed_at: new Date().toISOString() }
      : a
  );
}

export default function DocumentApprovalForm() {
  const alert = useAlert();
  const navigate = useNavigate();
  const { employee } = useAuth();
  const queryClient = useQueryClient();
  const pageRefs = useRef<(HTMLDivElement | null)[]>([]);
  const sigCanvasRef = useRef<SignatureCanvas | null>(null);
  const [numPages, setNumPages] = useState<number | undefined>(undefined);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [originalPdf, setOriginalPdf] = useState<string | null>(null);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [approvalType, setApprovalType] = useState<AnnotationType | null>(null);
  const [approvalAnnotations, setApprovalAnnotations] = useState<
    DocumentApprovalAnnotationType[]
  >([]);
  const { data: doc, isLoading } = useGetDocument();

  const areAllComplete = approvalAnnotations.every((a) => a.completed_at);
  const minOneExists = approvalAnnotations.length > 0;
  const scrollRef = useScrollIntoView(areAllComplete);

  const handleApprovalAnnotationClick = async (uuid: string) => {
    const annotation = findAnnotationByUuid(approvalAnnotations, uuid);

    if (!annotation) {
      alert.error("Annotation not found");
      return;
    } else if (!originalPdf) {
      alert.error("No PDF to update");
      return;
    } else if (!employee) {
      alert.error("No employee found");
      return;
    }

    try {
      const existingPdfBytes = await fetchPdfBytes(originalPdf);
      const pdfDoc = await loadPdfDocument(existingPdfBytes);
      const page = pdfDoc.getPage(annotation.page - 1);
      const { annotationColor, approvalText } =
        getAnnotationApprovalDetails(annotation);

      await drawApprovalAnnotation(
        pdfDoc,
        page,
        annotation,
        approvalText,
        annotationColor,
        employee
      );

      const coords = {
        x: annotation.x + 14,
        y: page.getHeight() - annotation.y - annotation.height + 14,
        width: annotation.width - 28,
        height: annotation.height - 28,
      };

      await drawImageOnPage(page, annotation.signature, coords, pdfDoc);

      const updatedPdfBlob = await savePdfDocument(pdfDoc);

      const updatedPdfUrl = URL.createObjectURL(updatedPdfBlob);

      setOriginalPdf(updatedPdfUrl);

      setApprovalAnnotations((annotations) =>
        updateAnnotationsAsComplete(annotations, uuid)
      );

      const nextPage =
        numPages && pageNumber < numPages ? pageNumber + 1 : pageNumber;

      if (nextPage) {
        setTimeout(() => {
          setPageNumber(nextPage);

          setTimeout(() => {
            const nextPageRef = pageRefs.current[Number(pageNumber)];
            if (nextPageRef) {
              nextPageRef.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "start",
              });
            } else {
              const lastPageRef = pageRefs.current[pageRefs.current.length - 1];
              if (lastPageRef) {
                lastPageRef.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "start",
                });
              }
            }
          }, 300);
        }, 600);
      }
    } catch (error) {
      alert.error("Failed to update PDF: " + error);
    }
  };

  const handleDocumentLoadSuccess = useCallback(
    ({ numPages }: { numPages: number | undefined }) => {
      setNumPages(numPages);
      setTimeout(() => setPageNumber(1), 200);
    },
    []
  );

  const handleResetPdf = () => {
    setOriginalPdf(doc?.newest_version || null);
    setApprovalAnnotations([]);
  };

  const createDocumentAnnotationMutation = useCreateDocumentAnnotationMutation(
    {}
  );

  const signDocumentMutation = useSignDocumentMutation({
    onSuccess: (data) => {
      queryClient.setQueryData([QueryKeys.Document, String(data.id)], data);
      queryClient.invalidateQueries([QueryKeys.DocumentsList]);
      alert.success("Document is complete! All employees will be notified.");
      navigate(`/e-signatures/documents/${data.id}?documentsTab=Completed`);
    },
  });

  const uploadFileToS3Mutation = useUploadFileToS3Mutation({
    onSuccess: (_data, variables) => {
      const formData = new FormData();
      const firstApprovalAnnotationType = approvalAnnotations[0].type;
      formData.append("approved_annotation_type", firstApprovalAnnotationType);
      formData.append("name", variables.name);
      formData.append("ext", variables.ext);

      if (!doc?.id) return;

      const createDocumentAnnotations = approvalAnnotations.map(
        (annotation) => {
          return createDocumentAnnotationMutation.mutateAsync({
            ...annotation,
          });
        }
      );

      Promise.all(createDocumentAnnotations)
        .then(() => queryClient.invalidateQueries([QueryKeys.DocumentsList]))
        .catch((error) => alert.error("Failed to submit document: " + error));

      signDocumentMutation.mutate({
        id: doc.id,
        data: formData,
      });
    },
  });

  async function handleFileUploadToS3(file: File) {
    const uuid = uuidv4();
    const extension = file.name.split(".").pop();
    const key = `private/e-signatures/documents/versions/${uuid}.${extension}`;

    const response = await s3Service.getPresignedUrl(key);

    if (response?.presigned_url && uuid && extension) {
      uploadFileToS3Mutation.mutate({
        file,
        presigned_url: response.presigned_url,
        get_url: response.get_url,
        name: uuid,
        ext: extension,
      });
    }
  }

  const handleSubmitDocument = async () => {
    if (!doc?.id || !originalPdf) {
      alert.error("Document not found");
      return;
    }

    if (!employee) {
      alert.error("Employee not found");
      return;
    }

    const blob = await fetchAndConvertToBlob(originalPdf);
    const file = new File([blob], "1.pdf", { type: "application/pdf" });
    await handleFileUploadToS3(file);
  };

  const handleSelectApprovalType = (type: AnnotationType) => {
    const existingApprovalAnnotation = approvalAnnotations.find(
      (a) => a.type !== type
    );
    if (existingApprovalAnnotation) {
      alert.error("Only one approval annotation type is allowed per document.");
      return;
    }
    setApprovalType(type);
    setIsDialogOpen(true);
  };

  const handleAgreeToTerms = () => setAgreeToTerms(!agreeToTerms);

  const handleApproveOrDenyDocument = () => {
    if (!agreeToTerms) {
      alert.error("Please agree to the terms.");
      return;
    }
    if (sigCanvasRef.current?.isEmpty()) {
      alert.error("Please add your signature.");
      return;
    }
    if (agreeToTerms) {
      const signature = sigCanvasRef.current?.toDataURL();
      if (signature && approvalType && doc && employee) {
        const newApprovalAnnotation = {
          document: doc.id,
          assigned_to: employee?.id,
          uuid: uuidv4(),
          x: 0,
          y: 0,
          width: 190,
          height: 138,
          signature,
          page: pageNumber,
          type: approvalType,
          completed_at: undefined,
        };
        const existingApprovalAnnotation = approvalAnnotations.find(
          (a) => a.page === pageNumber
        );
        if (existingApprovalAnnotation) {
          alert.error("Only one approval annotation is allowed per page.");
          return;
        }
        setApprovalAnnotations([...approvalAnnotations, newApprovalAnnotation]);
        setIsDialogOpen(false);
        setAgreeToTerms(false);
      }
    }
  };

  const handleClearSignature = () => sigCanvasRef.current?.clear();

  const handleCancelSignature = () => {
    sigCanvasRef.current?.clear();
    setIsDialogOpen(false);
    setAgreeToTerms(false);
  };

  useEffect(() => {
    if (doc?.newest_version && !originalPdf) {
      setOriginalPdf(doc.newest_version);
    }
  }, [doc, originalPdf]);

  return (
    <Card>
      <Dialog isOpen={isDialogOpen} setIsOpen={setIsDialogOpen}>
        <DialogHeader>
          {approvalType === AnnotationType.Approval
            ? "Approve Document"
            : "Deny Document"}
        </DialogHeader>
        <DialogDescription>
          {approvalType === AnnotationType.Approval
            ? "Please add your signature to approve this document."
            : "Please add your signature to deny this document."}
        </DialogDescription>
        <DialogContent>
          <SignatureCanvas
            ref={sigCanvasRef}
            penColor="black"
            canvasProps={{
              width: window.innerWidth < 768 ? window.innerWidth - 20 : 370,
              height: 100,
              className: styles.sigCanvas,
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "10px 0",
            }}
          >
            <Button
              color={ButtonColors.Gray}
              size={ButtonSizes.SM}
              onClick={handleClearSignature}
              title="Clear Signature"
            />
          </div>
          <Container style={{ maxWidth: "370px" }}>
            <SquareCheckboxInput
              id={"agreeToTerms"}
              label={AGREE_TO_TERMS}
              name={"agreeToTerms"}
              checked={agreeToTerms}
              onChange={handleAgreeToTerms}
            />
          </Container>
        </DialogContent>
        <DialogFooter>
          <Button
            color={ButtonColors.GrayAndYellow}
            onClick={handleCancelSignature}
          >
            Cancel
          </Button>
          <Button
            color={ButtonColors.Yellow}
            onClick={handleApproveOrDenyDocument}
          >
            {approvalType === AnnotationType.Approval ? "Approve" : "Deny"}
          </Button>
        </DialogFooter>
      </Dialog>
      <DocumentCardHeader />
      {isLoading ? (
        <div className="p-10">
          <Skeleton height={792} />
        </div>
      ) : null}
      {!isLoading && originalPdf ? (
        <div>
          <DocumentSignToolbar onResetPdf={handleResetPdf}>
            <Button
              onClick={() => handleSelectApprovalType(AnnotationType.Approval)}
              color={ButtonColors.Green}
              tooltip="Approve Document"
              size={ButtonSizes.SM}
              title="Approve"
            ></Button>
            <Button
              onClick={() => handleSelectApprovalType(AnnotationType.Denial)}
              color={ButtonColors.Red}
              tooltip="Deny Document"
              size={ButtonSizes.SM}
              title="Deny"
            ></Button>
          </DocumentSignToolbar>
          <VerticalDocumentApprovalViewer
            fileUrl={originalPdf}
            pageRefs={pageRefs}
            isLoading={false}
            approvalAnnotations={approvalAnnotations}
            numPages={numPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setApprovalAnnotations={setApprovalAnnotations}
            onApprovalAnnotationClick={handleApprovalAnnotationClick}
            onDocumentLoadSuccess={handleDocumentLoadSuccess}
          />
          <div className={styles.buttonsContainer} ref={scrollRef}>
            <Button
              color={ButtonColors.Yellow}
              onClick={handleSubmitDocument}
              disabled={
                !minOneExists ||
                !areAllComplete ||
                isLoading ||
                uploadFileToS3Mutation.isLoading ||
                createDocumentAnnotationMutation.isLoading ||
                signDocumentMutation.isLoading
              }
            >
              {isLoading ||
              uploadFileToS3Mutation.isLoading ||
              createDocumentAnnotationMutation.isLoading ||
              signDocumentMutation.isLoading ? (
                <RotatingLines
                  strokeColor="#000"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={true}
                />
              ) : (
                "Submit Document"
              )}
            </Button>
          </div>
        </div>
      ) : null}
    </Card>
  );
}
