import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { RotatingLines } from "react-loader-spinner";
import { zodResolver } from "@hookform/resolvers/zod";
import { UseMutationResult } from "@tanstack/react-query";

import NestedCheckBoxInput, {
  Department,
  Role,
} from "src/features/forms/components/NestedCheckBoxesInput";
import { getDepartmentsWithRoles } from "src/features/departments/apis";
import Button from "src/components/Buttons/Button";
import ButtonsContainer from "src/components/Container/ButtonsContainer";
import useScrollIntoView from "src/features/page/hooks/useScrollIntoView";

import {
  lpViewableByFormDefs,
  LPViewableByFormSchemaType,
} from "src/features/learningPaths/components/LPBuilder/Forms/viewableByFormDefs";
import {
  AssignViewableByStepFormSchemaType,
  assignViewableByStepFormSchema,
} from "./zod/assignViewableByStepFormSchema";
import { ButtonColors, ButtonSizes } from "../Buttons/buttons.types";

interface GenericUpdateFormReturnType {
  id: number | string;
  viewable_by: Role[];
}

type GenericUpdateFormSchemaType = {
  id: number | string;
  viewable_by: (string | number)[];
};

type AssignViewableByStepFormProps<
  T extends GenericUpdateFormReturnType | FormData
> = {
  objectDetail: T;
  partialUpdateMutation: UseMutationResult<
    T,
    unknown,
    Partial<GenericUpdateFormSchemaType>,
    unknown
  >;
  onGoBack: () => void;
};

function AssignViewableByStepForm<T extends GenericUpdateFormReturnType>({
  objectDetail,
  partialUpdateMutation,
  onGoBack,
}: AssignViewableByStepFormProps<T>) {
  const scrollRef = useScrollIntoView();

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors },
  } = useForm<AssignViewableByStepFormSchemaType>({
    resolver: zodResolver(assignViewableByStepFormSchema),
  });

  const handleSaveViewableBy = handleSubmit((data) => {
    if (objectDetail?.id) {
      partialUpdateMutation.mutate({
        ...data,
        id: objectDetail.id,
      });
    }
  });

  useEffect(() => {
    if (objectDetail) {
      const ids =
        objectDetail?.viewable_by?.map((role) => role?.id?.toString()) || [];
      reset({
        id: objectDetail?.id,
        viewable_by: ids || [],
      });
    }
  }, [objectDetail, reset]);

  return (
    <div style={{ marginTop: "1rem" }}>
      {objectDetail?.viewable_by ? (
        <NestedCheckBoxInput<Department, Role>
          key={lpViewableByFormDefs.fields[0].name}
          register={register}
          setValue={setValue}
          queryFn={getDepartmentsWithRoles}
          inputName={lpViewableByFormDefs.fields[0].name}
          itemName="name"
          subItemName="name"
          subItemKey="roles"
          defaultSelectedSubIds={objectDetail.viewable_by.map(
            (role) => role.id
          )}
          error={
            errors[
              lpViewableByFormDefs.fields[0]
                .name as keyof LPViewableByFormSchemaType
            ]?.message
          }
        />
      ) : null}
      <ButtonsContainer>
        <Button
          color={ButtonColors.GrayAndYellow}
          size={ButtonSizes.MD}
          onClick={onGoBack}
        >
          {!partialUpdateMutation.isLoading ? (
            "Go Back"
          ) : (
            <RotatingLines
              strokeColor="#f1b70c"
              strokeWidth="5"
              animationDuration="0.75"
              width="20"
              visible={true}
            />
          )}
        </Button>
        <Button
          color={ButtonColors.Yellow}
          size={ButtonSizes.MD}
          onClick={handleSaveViewableBy}
          disabled={partialUpdateMutation.isLoading}
        >
          {!partialUpdateMutation.isLoading ? (
            "Save And Next"
          ) : (
            <RotatingLines
              strokeColor="#000"
              strokeWidth="5"
              animationDuration="0.75"
              width="20"
              visible={true}
            />
          )}
        </Button>
      </ButtonsContainer>
      <div ref={scrollRef} />
    </div>
  );
}

export default AssignViewableByStepForm;
