import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import rolesService from "../services/rolesService";
import { EmployeeTypeDetail } from "../types/employeeTypes.types";

type UpdateEmployeeTypeMutation = {
  onSuccess?: (data: EmployeeTypeDetail) => void;
  onError?: (error: AxiosError) => void;
};

export default function useUpdateEmployeeTypeMutation({
  onSuccess,
  onError,
}: UpdateEmployeeTypeMutation) {
  return useMutation(rolesService.updateEmployeeType, {
    onSuccess,
    onError: (error: AxiosError) => onError?.(error),
  });
}
