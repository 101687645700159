import { createSlice } from "@reduxjs/toolkit";

export enum MemoFormSteps {
  AddDetails = "Add Details",
  UploadFile = "Upload File (optional)",
  AssignViewableBy = "Assign Viewable By",
  ScheduleMemo = "Schedule Memo",
  PublishMemo = "Publish Memo",
}

export type MemoFormState = {
  step: MemoFormSteps | null;
  selectedMemoId: string | null;
};

const initialState: MemoFormState = {
  step: null,
  selectedMemoId: null,
};

const memoSlice = createSlice({
  name: "memo",
  initialState,
  reducers: {
    resetMemoFormState(state) {
      state.step = MemoFormSteps.AddDetails;
      state.selectedMemoId = null;
    },
    setMemoFormStep(state, action) {
      state.step = action.payload;
    },
    setSelectedMemoId(state, action) {
      state.selectedMemoId = action.payload;
    },
  },
});

export const { resetMemoFormState, setMemoFormStep, setSelectedMemoId } =
  memoSlice.actions;

export default memoSlice.reducer;
