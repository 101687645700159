import React, { useEffect, useRef } from "react";
import { DocumentAnnotation } from "../../types/document.types";
import styles from "../ESignatures.module.css";
import { TemplateAnnotation } from "../../types/template.types";

type EditableTextFieldAnnotationProps = {
  annotation: DocumentAnnotation | TemplateAnnotation;
  handleSaveText: (
    e: React.FocusEvent<HTMLDivElement>,
    annotation: DocumentAnnotation | TemplateAnnotation
  ) => void;
};

export default function EditableTextFieldAnnotation({
  annotation,
  handleSaveText,
}: EditableTextFieldAnnotationProps) {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Prevent the user from typing more characters than the annotation width
    const handleInput = (e: Event) => {
      const div = divRef.current;
      if (!div) return;

      const selection = window.getSelection();
      const range = selection?.getRangeAt(0);
      const startOffset = range?.startOffset || 0;

      if (
        div.scrollHeight > div.clientHeight ||
        div.scrollWidth > div.clientWidth
      ) {
        div.innerText = div.innerText.slice(0, -1);

        if (range) {
          const newRange = document.createRange();
          newRange.setStart(div.childNodes[0], Math.max(startOffset - 1, 0));
          newRange.setEnd(div.childNodes[0], Math.max(startOffset - 1, 0));
          selection?.removeAllRanges();
          selection?.addRange(newRange);
        }
      }
    };

    const div = divRef.current;
    if (div) {
      div.addEventListener("input", handleInput);
      return () => div.removeEventListener("input", handleInput);
    }
  }, []);

  return (
    <div
      ref={divRef}
      inputMode="text"
      contentEditable
      onBlur={(e) => handleSaveText(e, annotation)}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === "Tab") {
          e.preventDefault();
          e.currentTarget.blur();
        }
      }}
      id={`editable-${annotation.id}`}
      className={styles.editableTextField}
      style={{
        top: annotation.y,
        left: annotation.x,
        width: annotation.width,
        height: annotation.height,
      }}
    ></div>
  );
}
