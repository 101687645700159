import StepsProgressBarItem from "./StepsProgressBarItem";
import styles from "./Steps.module.css";

type StepsProgressBarProps = {
  current: number;
  totalSteps?: number;
};

export default function RegistrationStepsProgressBar({
  totalSteps = 4,
  current,
}: StepsProgressBarProps) {
  const progressWidth = ((current - 1) / (totalSteps - 1)) * 100 + "%";

  return (
    <div className={styles.stepBarWrapper}>
      <div className={styles.stepBarContainer}>
        {Array.from({ length: totalSteps }).map((_, index) => (
          <StepsProgressBarItem
            key={index}
            step={index + 1}
            current={current}
          />
        ))}
        <div className={styles.stepBar} />
        <div
          className={styles.stepBarProgress}
          style={{ width: `calc(${progressWidth} - 30px)` }}
        />
      </div>
    </div>
  );
}
