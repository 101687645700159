import { useAlert } from "@blaumaus/react-alert";
import { useQueryClient } from "@tanstack/react-query";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import Dialog from "src/components/Dialog/Dialog";
import DialogDescription from "src/components/Dialog/DialogDescription";
import DialogFooter from "src/components/Dialog/DialogFooter";
import DialogHeader from "src/components/Dialog/DialogHeader";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import useDismissShiftConflictMutation from "../hooks/useDismissShiftConflictMutation";
import { ShiftConflict } from "../services/shiftConflictsService";

type ShiftConflictDismissDialogProps = {
  conflict: ShiftConflict;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export default function ShiftConflictDismissDialog({
  conflict,
  isOpen,
  setIsOpen,
}: ShiftConflictDismissDialogProps) {
  const alert = useAlert();
  const queryClient = useQueryClient();

  const dismissShiftConflictMutation = useDismissShiftConflictMutation({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.ShiftConflictsList]);
      alert.success("Shift conflict successfully dismissed.");
      setIsOpen(false);
    },
  });

  const handleDismissConflict = () =>
    dismissShiftConflictMutation.mutate(conflict.id);

  return (
    <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
      <DialogHeader>Dismiss Shift Conflict</DialogHeader>
      <DialogDescription>
        Are you sure you want to dismiss this shift conflict?
      </DialogDescription>
      <DialogFooter>
        <Button
          color={ButtonColors.GrayAndYellow}
          onClick={() => setIsOpen(false)}
        >
          Cancel
        </Button>
        <Button color={ButtonColors.Yellow} onClick={handleDismissConflict}>
          Dismiss Conflict
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
