import React, { ComponentType } from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "src/features/auth/hooks/useAuth";
import { UrlRoutes } from "src/features/site/types/routes.types";

const withProtectedRoute = <P extends object>(
  Component: ComponentType<P>,
  redirectTo: string,
  allowedGroups: string[]
) => {
  const ProtectedComponent: React.FC<P> = (props) => {
    const { employee, isAuthenticated } = useAuth();
    const location = useLocation();

    if (!isAuthenticated) {
      return (
        <Navigate
          to={{
            pathname: `${UrlRoutes.Login}`,
            search: `?redirect=${encodeURIComponent(
              location.pathname + location.search
            )}`,
          }}
          replace={true}
        />
      );
    }

    if (!allowedGroups.includes(employee?.role?.group?.name || "")) {
      return <Navigate to={{ pathname: redirectTo }} replace={true} />;
    }

    return <Component {...props} />;
  };

  return ProtectedComponent;
};

export default withProtectedRoute;
