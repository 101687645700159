import { createSlice } from "@reduxjs/toolkit";
import { AuthenticatedEmployee } from "../types/employees.type";

export interface IEmployeesState {
  status: "idle" | "loading" | "succeeded" | "failed";
  data: AuthenticatedEmployee[];
  errors: string[];
}

const initialState: IEmployeesState = {
  status: "idle",
  data: [],
  errors: [],
};

export const employeesSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {
    setEmployees: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export default employeesSlice.reducer;

export const { setEmployees } = employeesSlice.actions;
