import styles from "../ESignatures.module.css";

type PdfViewerThumbnailListContainerProps = {
  children?: React.ReactNode;
};

export default function PdfViewerThumbnailListContainer({
  children,
}: PdfViewerThumbnailListContainerProps) {
  return (
    <div className={styles.pdfViewerThumbnailListContainer}>{children}</div>
  );
}
