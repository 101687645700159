import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useQueryClient } from "@tanstack/react-query";

import AssignViewableByStepForm from "src/components/Steps/AssignViewableByStepForm";
import { useAppDispatch } from "src/store";
import { PolicyFormSteps, setPolicyFormStep } from "../../slices/policySlice";
import usePolicyFormState from "../../hooks/usePolicyFormState";
import useGetPolicyQuery from "../../hooks/useGetPolicyQuery";
import StepContainer from "src/components/Steps/StepContainer";
import EditingContainer from "src/features/learningPaths/components/LPBuilder/Forms/EditingContainer";
import usePartialUpdatePolicyMutation from "../../hooks/usePartialUpdatePolicyMutation";

export default function PolicyAssignViewableByForm() {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const { selectedPolicyId } = usePolicyFormState();
  const combinedId = id || selectedPolicyId;

  const { data: policy, isInitialLoading: isPolicyLoading } = useGetPolicyQuery(
    { id: String(combinedId) }
  );

  const partialUpdatePolicyMutation = usePartialUpdatePolicyMutation({
    onSuccess: (data) => {
      queryClient.setQueryData(["policy", String(data.id)], data);
      dispatch(setPolicyFormStep(PolicyFormSteps.SchedulePolicy));
    },
  });

  const handleGoBack = () =>
    dispatch(setPolicyFormStep(PolicyFormSteps.UploadPolicy));

  return (
    <StepContainer>
      <div className="mb-20">
        <span className="text-muted">
          Specify who can view this policy by assigning it to specific employee
          roles, departments, or the entire organization.
        </span>
      </div>
      <EditingContainer>
        {!isPolicyLoading && policy ? (
          <AssignViewableByStepForm
            objectDetail={policy}
            partialUpdateMutation={partialUpdatePolicyMutation}
            onGoBack={handleGoBack}
          />
        ) : null}
        {isPolicyLoading ? <Skeleton height={200} /> : null}
      </EditingContainer>
    </StepContainer>
  );
}
