import { useMutation } from "@tanstack/react-query";
import departmentsService from "../services/departmentsService";
import { SingleDepartmentFormType } from "../components/DepartmentsForm";

export default function useUpdateDepartmentMutation() {
  return useMutation({
    mutationFn: (variables: { id: number; data: SingleDepartmentFormType }) =>
      departmentsService.updateDepartment(variables.id, variables.data),
  });
}
