import * as React from "react";
const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <circle cx={10} cy={10} r={10} fill="url(#a)" />
    <path
      fill="#000"
      d="M8.64 13.348c-.11.099-.264.181-.4.181-.137 0-.291-.086-.405-.185l-2.542-2.311.808-.735 2.143 1.948 5.666-5.187.795.747-6.066 5.542Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={10}
        x2={10}
        y1={0}
        y2={20}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FEC110" />
        <stop offset={0} stopColor="#FEC110" />
        <stop offset={1} stopColor="#CC9800" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgComponent;
