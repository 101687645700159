import React, { memo } from "react";
import styles from "./CardDetailHeader.module.css";

type CardDetailHeaderProps = {
  display?: React.ReactNode;
  info: React.ReactNode;
  actions: React.ReactNode;
};

function CardDetailHeader({ display, info, actions }: CardDetailHeaderProps) {
  return (
    <div className={styles.headerContainer}>
      {display ? (
        <div
          className={styles.headerDisplayContainer}
          data-testid="display-container"
        >
          {display}
        </div>
      ) : null}
      <div className={styles.headerInfoContainer}>{info}</div>
      {actions ? (
        <div className={styles.headerActionsContainer}>{actions}</div>
      ) : null}
    </div>
  );
}

export default memo(CardDetailHeader);
