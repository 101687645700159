import * as React from "react";

const AssessmentsIcon = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.666 6.875V15c0 2.5-1.491 3.333-3.333 3.333H6.666c-1.841 0-3.333-.833-3.333-3.333V6.875c0-2.708 1.492-3.333 3.333-3.333 0 .516.209.983.55 1.325.342.341.809.55 1.325.55h2.917a1.878 1.878 0 0 0 1.875-1.875c1.842 0 3.333.625 3.333 3.333Z"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.334 3.542a1.878 1.878 0 0 1-1.875 1.875H8.542c-.517 0-.983-.209-1.325-.55a1.864 1.864 0 0 1-.55-1.325c0-1.034.842-1.875 1.875-1.875h2.917c.516 0 .983.208 1.325.55.341.341.55.808.55 1.325ZM6.667 10.833H10M6.667 14.167h6.667"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AssessmentsIcon;
