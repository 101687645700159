import { AuthenticatedEmployee } from "src/features/employees/types/employees.type";
import { AnnotationType, DocumentTemplate } from "./template.types";

export enum DocumentStatus {
  Draft = 0,
  InProgress = 1,
  AwaitingReview = 2,
  Completed = 3,
  Rejected = 4,
}

export type DocumentListItem = {
  id: number;
  title: string;
  created_on: string;
  updated_on: string;
};

export type Document = {
  id?: number;
  title?: string;
  description?: string;
  template: DocumentTemplate;
  self_assigned: boolean;
  approval_required_by?: number;
  file_url?: string;
  name?: string;
  ext?: string;
  newest_version: string;
  status: DocumentStatus;
  created_by?: number;
  created_on: Date;
  updated_on: Date;
};

export enum DocumentAnnotationStatus {
  NotAssigned = 0,
  Assigned = 1,
  AwaitingCompletion = 2,
  Completed = 3,
  Rejected = 4,
}

export type DocumentAnnotationOption = {
  id: number;
  value: string;
  annotation: number;
  created_on: string;
  updated_on: string;
};

export type DocumentAnnotation = {
  id: string;
  uuid: string;
  document: string;
  assigned_to: AuthenticatedEmployee | null;
  status: DocumentAnnotationStatus;
  type: AnnotationType;
  options?: DocumentAnnotationOption[];
  page: number;
  x: number;
  y: number;
  width: number;
  height: number;
  completed_at: Date | string | null;
  completed_version: number | null;
  created_on: string;
  updated_on: string;
};

export type DocumentAnnotationForm = {
  id?: string;
  uuid: string;
  document?: string | number;
  assigned_to: number | string | null;
  type: AnnotationType;
  page: number;
  x: number;
  y: number;
  width: number;
  height: number;
  completed_at?: string | null;
};

export type DocumentSignatoryOrder = {
  id: number;
  document: number;
  employee: AuthenticatedEmployee;
  order: number;
  signed_on?: string;
  approval_required: boolean;
  approved_on?: string;
  denied_on?: string;
  created_on?: string;
  updated_on?: string;
};
