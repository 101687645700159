import { useCallback } from "react";
import * as Sentry from "@sentry/react";

import styles from "./EmployeeImageGroup.module.css";
import EmployeeImage from "./EmployeeImage";
import getFullName from "src/features/employees/utils/getFullName";
import {
  AuthenticatedEmployee,
  EmployeeListItem,
} from "src/features/employees/types/employees.type";
import ErrorFallback from "../Errors/ErrorFallback";

type EmployeeImageGroupProps = {
  employees: AuthenticatedEmployee[] | EmployeeListItem[];
};

export default function EmployeeImageGroup({
  employees,
}: EmployeeImageGroupProps) {
  const getEmployeeCount = useCallback(
    () => employees?.length || 2,
    [employees]
  );

  return (
    <div className={styles.employeeImageGroupContainer}>
      <Sentry.ErrorBoundary
        fallback={({ error, resetError }) => (
          <ErrorFallback error={error} onReload={resetError} />
        )}
      >
        {getEmployeeCount() === 1 ? (
          <>
            <EmployeeImage
              allowUpload={false}
              alt={getFullName(employees[0])}
              src={employees[0]?.image || ""}
              containerClassName={styles.employeeImageContainerSingle}
            />
          </>
        ) : null}
        {getEmployeeCount() === 2 ? (
          <div className={styles.employeeImageContainerDouble}>
            <EmployeeImage
              allowUpload={false}
              alt={getFullName(employees[0])}
              src={employees[0]?.image || ""}
              containerClassName={styles.employeeImageContainerDoubleFirst}
            />
            <EmployeeImage
              allowUpload={false}
              alt={getFullName(employees[1])}
              src={employees[1]?.image || ""}
              containerClassName={styles.employeeImageContainerDoubleSecond}
            />
          </div>
        ) : null}
        {getEmployeeCount() > 2 ? (
          <>
            <div className={styles.employeeImageContainerTriple}>
              <EmployeeImage
                allowUpload={false}
                alt={getFullName(employees[0])}
                src={employees[0]?.image || ""}
                containerClassName={styles.employeeImageContainerTripleFirst}
                imgClassName={styles.employeeImageTripleFirst}
              />
              <EmployeeImage
                allowUpload={false}
                alt={getFullName(employees[1])}
                src={employees[1]?.image || ""}
                containerClassName={styles.employeeImageContainerTripleSecond}
                imgClassName={styles.employeeImageTripleSecond}
              />
            </div>
            <div className={styles.employeeImageCount}>
              +{getEmployeeCount() - 2}
            </div>
          </>
        ) : null}
      </Sentry.ErrorBoundary>
    </div>
  );
}
