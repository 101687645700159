import axiosClient from "src/features/axios/client";
import { ModuleContentType } from "../learningPaths.types";

class ModuleContentService {
  async deleteModuleContent({ id }: { id: string }) {
    return await axiosClient
      .delete(`/api/module-contents/${id}/`)
      .then((res) => res.data);
  }

  async getModuleContent(id: string) {
    return await axiosClient
      .get(`/api/module-contents/${id}/`)
      .then((res) => res.data);
  }

  async getModuleContentsByModuleId(
    moduleId: string
  ): Promise<ModuleContentType[]> {
    return await axiosClient
      .get(`/api/module-contents/`, {
        params: {
          module_id: moduleId,
        },
      })
      .then((res) => res.data);
  }
}

const moduleContentService = new ModuleContentService();

export default moduleContentService;
