import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../axios/client";
import { Department } from "../departments.types";

export const getDepartments = createAsyncThunk<
  Department[],
  void,
  { rejectValue: any }
>("departments/getDepartments", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosClient.get("/api/departments/");
    return response.data;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue(err.response.data);
    } else if (err.request) {
      return rejectWithValue({ detail: "An unknown error occured" });
    } else {
      return rejectWithValue({ detail: "An unknown error occured" });
    }
  }
});

export const createDepartment = createAsyncThunk<
  Department,
  Department,
  { rejectValue: any }
>("departments/createDepartment", async (data, { rejectWithValue }) => {
  try {
    const response = await axiosClient.post("/api/departments/", data);
    return response.data;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue(err.response.data);
    } else if (err.request) {
      return rejectWithValue({ detail: "An unknown error occured" });
    } else {
      return rejectWithValue({ detail: "An unknown error occured" });
    }
  }
});

export const updateDepartment = createAsyncThunk<
  Department,
  Department,
  { rejectValue: any }
>("departments/updateDepartment", async (department, { rejectWithValue }) => {
  try {
    const { id, name, roles } = department;
    const response = await axiosClient.put(`/api/departments/${id}/`, {
      name,
      roles,
    });
    return response.data;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue(err.response.data);
    } else if (err.request) {
      return rejectWithValue({ detail: "An unknown error occured" });
    } else {
      return rejectWithValue({ detail: "An unknown error occured" });
    }
  }
});

export const deleteDepartment = createAsyncThunk<
  Department,
  number,
  { rejectValue: any }
>("departments/deleteDepartment", async (id, { rejectWithValue }) => {
  try {
    const response = await axiosClient.delete(`/api/departments/${id}/`);
    return response.data;
  } catch (err: any) {
    if (err.response) {
      return rejectWithValue(err.response.data);
    } else if (err.request) {
      return rejectWithValue({ detail: "An unknown error occured" });
    } else {
      return rejectWithValue({ detail: "An unknown error occured" });
    }
  }
});

export interface DepartmentsState {
  status: "idle" | "loading" | "succeeded" | "failed";
  data: Department[];
  error: string[] | null;
}

const initialState: DepartmentsState = {
  status: "idle",
  data: [],
  error: null,
};

const departmentsSlice = createSlice({
  name: "departments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDepartments.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getDepartments.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "idle";
        state.error = null;
      })
      .addCase(getDepartments.rejected, (state, action) => {
        state.error = action.payload;
        state.status = "failed";
      })
      .addCase(createDepartment.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createDepartment.fulfilled, (state, action) => {
        state.data.push(action.payload);
        state.status = "idle";
        state.error = null;
      })
      .addCase(createDepartment.rejected, (state, action) => {
        state.error = action.payload;
        state.status = "failed";
      })
      .addCase(updateDepartment.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateDepartment.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        // Replace the department with the updated one
        state.data = state.data.map((department) =>
          department.id === action.payload.id ? action.payload : department
        );
      })
      .addCase(updateDepartment.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(deleteDepartment.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteDepartment.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
      })
      .addCase(deleteDepartment.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

// export const {} = departmentsSlice.actions;

export default departmentsSlice.reducer;
