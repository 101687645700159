import * as React from "react"

const LogoutIcon = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.675 5.67c.232-2.7 1.62-3.803 4.657-3.803h.098c3.352 0 4.695 1.343 4.695 4.696v4.89c0 3.352-1.343 4.695-4.695 4.695h-.098c-3.015 0-4.402-1.088-4.65-3.743M1.5 9h9.66"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.487 6.487 12 9l-2.513 2.512"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default LogoutIcon
