import { PDFDocument } from "pdf-lib-incremental-save";
import { DocumentAnnotation } from "src/features/e-signatures/types/document.types";
import { TemplateAnnotation } from "src/features/e-signatures/types/template.types";

async function embedImageOnPdf(
  pdfUrl: string,
  signature: Blob,
  annotation: TemplateAnnotation | DocumentAnnotation
) {
  const existingPdfBytes = await (await fetch(pdfUrl)).arrayBuffer();
  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const pageIndex = annotation.page - 1;
  const page = pdfDoc.getPages()[pageIndex];

  // Determine the image format from the Blob's type
  let signatureImageEmbed;
  if (signature.type === "image/png") {
    signatureImageEmbed = await pdfDoc.embedPng(await signature.arrayBuffer());
  } else if (signature.type === "image/jpeg") {
    signatureImageEmbed = await pdfDoc.embedJpg(await signature.arrayBuffer());
  } else {
    throw new Error("Unsupported image format: " + signature.type);
  }

  page.drawImage(signatureImageEmbed, {
    x: annotation.x,
    y: page.getHeight() - annotation.y - annotation.height,
    width: annotation.width,
    height: annotation.height,
  });

  return pdfDoc.save();
}

export default embedImageOnPdf;
