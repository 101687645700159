import React, { useEffect, useImperativeHandle, useRef } from "react";
import InputError from "../Errors/InputError";
import { FieldError } from "react-hook-form";

interface TextInputProps {
  autoComplete?: string;
  autoFocus?: boolean;
  containerClassName: string;
  errorClassName: string;
  inputClassName: string;
  labelClassName?: string;
  defaultValue?: string;
  id?: string;
  label?: string | null;
  labelIcon?: React.ReactNode;
  maxLength?: number;
  name: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  required?: boolean;
  type?: string;
  value: string;
  min?: number;
  max?: number;
  error?: string | FieldError | null;
  step?: string | number;
}

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      autoComplete,
      autoFocus,
      containerClassName,
      errorClassName,
      inputClassName,
      labelClassName,
      id,
      label,
      labelIcon,
      maxLength,
      name,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      placeholder,
      required = false,
      type = "text",
      value,
      min,
      max,
      error,
      step,
      ...rest
    },
    ref
  ) => {
    const localRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => localRef.current as HTMLInputElement);

    useEffect(() => {
      const element = localRef.current;

      const handleWheel = (event: WheelEvent) => {
        if (type === "number") {
          event.preventDefault();
        }
      };

      if (element) {
        element.addEventListener("wheel", handleWheel, { passive: false });
      }

      return () => {
        if (element) {
          element.removeEventListener("wheel", handleWheel);
        }
      };
    }, [type]);

    return (
      <>
        <div className={containerClassName} id={id}>
          <input
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            className={error ? errorClassName : inputClassName}
            id={id}
            maxLength={maxLength}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            required={required}
            type={type}
            value={value}
            min={min}
            max={max}
            ref={localRef}
            step={step}
            aria-describedby={error ? `${id}-error` : undefined}
            {...rest}
          />
        </div>
        {error ? <InputError error={error as string} /> : null}
      </>
    );
  }
);

export default TextInput;
