import Skeleton from "react-loading-skeleton";
import Card from "src/components/Cards/Card";
import ErrorFallback from "src/components/Errors/ErrorFallback";
import ListCard from "src/components/Lists/ListCard";
import ListCardHeader from "src/components/Lists/ListCardHeader";
import useGetDepartmentsListQuery from "src/features/departments/hooks/useGetDepartmentsListQuery";
import SchedulesDepartmentSidebarCard from "./SchedulesDepartmentSidebarCard";

export default function SchedulesDepartmentsListSidebar() {
  const {
    data: departments,
    isLoading: isDepartmentsLoading,
    refetch,
    isError,
  } = useGetDepartmentsListQuery();

  if (isError) {
    return (
      <Card className="centered min-h-100vh-90">
        <ErrorFallback
          error={
            "Oops! An error occurred! It has been reported and is being investigated."
          }
          onReload={refetch}
        />
      </Card>
    );
  }

  return (
    <ListCard
      id="dashboardOrgCard"
      isLoading={false}
      style={{
        minHeight: "100%",
        minWidth: "250px",
        flexDirection: "column",
      }}
    >
      <ListCardHeader title="Department Calendars" />
      <div className="flex gap-5 p-10">
        {isDepartmentsLoading &&
          Array.from({ length: 5 }).map((_, index) => (
            <div key={index} className="flex w-full gap-10">
              <Skeleton height={20} width={100} />
              <Skeleton key={index} height={180} />
            </div>
          ))}
        {!isDepartmentsLoading &&
          !isError &&
          departments?.map((department) => (
            <SchedulesDepartmentSidebarCard
              key={department.id}
              department={department}
            />
          ))}
      </div>
    </ListCard>
  );
}
