import React from "react";
import { useDispatch } from "react-redux";
import { setSearchTerm } from "../../features/search/slices/searchSlice";
import useSearch from "../../features/search/hooks/useSearch";
import SearchIcon from "../Icons/SearchIcon";
import TextInput from "./TextInput";
import inputStyles from "./Inputs.module.css";

interface SearchInputProps {
  id: string;
  placeholder?: string;
  inputClassName?: string;
  inputWithSearchTermClassName?: string;
  containerClassName?: string;
}

export default function SearchInput({
  id,
  placeholder,
  inputClassName,
  inputWithSearchTermClassName,
  containerClassName,
}: SearchInputProps) {
  const dispatch = useDispatch();
  const { searchTerm } = useSearch();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(setSearchTerm(e.target.value));

  const className = searchTerm
    ? inputWithSearchTermClassName || inputStyles.roundedInputHighlighted
    : inputClassName || inputStyles.roundedInput;

  return (
    <div className={containerClassName || "searchContainer"} id={id}>
      <TextInput
        id="searchInput"
        name="searchInput"
        type="search"
        placeholder={placeholder || "Search"}
        inputClassName={className}
        onChange={handleSearch}
        value={searchTerm}
        errorClassName={""}
        containerClassName={"searchContainer"}
      />
      <div className={"searchIconContainer"}>
        <SearchIcon />
      </div>
    </div>
  );
}
