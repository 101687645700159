const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={34}
    height={34}
    fill="none"
    {...props}
  >
    <circle cx={17} cy={17} r={17} fill="url(#a)" />
    <path
      fill="#000"
      d="M12.307 24v-1.86c.853-.707 1.68-1.407 2.48-2.1a24.912 24.912 0 0 0 2.16-2.06c.64-.68 1.147-1.34 1.52-1.98.387-.653.58-1.287.58-1.9 0-.573-.16-1.08-.48-1.52-.307-.44-.82-.66-1.54-.66-.733 0-1.287.24-1.66.72s-.56 1.06-.56 1.74h-2.48c.027-1.027.253-1.88.68-2.56a3.941 3.941 0 0 1 1.7-1.54c.707-.347 1.5-.52 2.38-.52 1.427 0 2.533.393 3.32 1.18.8.773 1.2 1.78 1.2 3.02 0 .773-.18 1.527-.54 2.26a9.776 9.776 0 0 1-1.38 2.12c-.573.68-1.193 1.32-1.86 1.92a60.957 60.957 0 0 1-1.94 1.64h6.06V24h-9.64Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={17}
        x2={17}
        y1={0}
        y2={34}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FEC110" />
        <stop offset={0} stopColor="#FEC110" />
        <stop offset={1} stopColor="#CC9800" />
      </linearGradient>
    </defs>
  </svg>
)
export default SvgComponent
