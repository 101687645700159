import React from "react";
import Container from "../Container/Container";
import styles from "./Form.module.css";

type FormProps = {
  id?: string;
  children?: React.ReactNode;
  className?: string;
  description?: string;
  actions?: React.ReactNode;
  onSubmit?: any;
  dataTestId?: string;
  style?: React.CSSProperties;
};

export default function Form({
  id,
  children,
  description,
  className,
  actions,
  onSubmit,
  dataTestId,
  style,
}: FormProps) {
  return (
    <form
      id={id}
      onSubmit={onSubmit}
      className={className}
      style={{ overflow: "hidden", position: "relative", ...style }}
      data-testid={dataTestId}
      noValidate={true}
    >
      {description ? (
        <div className={styles.formDescriptionContainer}>{description}</div>
      ) : null}
      <Container
        style={{ display: "flex", minWidth: "300px", flexDirection: "column" }}
      >
        {children}
        {actions ? (
          <Container
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              padding: "20px 10px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {actions}
          </Container>
        ) : null}
      </Container>
    </form>
  );
}
