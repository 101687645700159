import Badge from "src/components/Badges/Badge";
import { ShiftTransferStatus } from "../services/shiftTransferService";

type BadgeProps = {
  status: ShiftTransferStatus;
};

export default function ShiftTransferBadge({ status }: BadgeProps) {
  const badgeColors: Record<ShiftTransferStatus, string> = {
    [ShiftTransferStatus.Available]: "var(--clearpath-green-light)",
    [ShiftTransferStatus.SwapRequested]: "var(--clearpath-blue)",
    [ShiftTransferStatus.Pending]: "var(--clearpath-yellow)",
    [ShiftTransferStatus.Approved]: "var(--clearpath-green)",
    [ShiftTransferStatus.Declined]: "var(--clearpath-danger)",
    [ShiftTransferStatus.Expired]: "var(--clearpath-danger)",
  };

  return <Badge color={badgeColors[status]} text={status} />;
}
