import { useEffect, useState } from "react";
import { MdDelete, MdEdit, MdGroup, MdPerson } from "react-icons/md";
import { RotatingLines } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import Dialog from "src/components/Dialog/Dialog";
import DialogContent from "src/components/Dialog/DialogContent";
import DialogHeader from "src/components/Dialog/DialogHeader";
import DropDownV2 from "src/components/DropDowns/DropDownV2";
import ThreeDotsMenuItem from "src/components/DropDowns/ThreeDotsMenuItem";
import Tab from "src/components/Tabs/Tab";
import TabNavigation from "src/components/Tabs/TabNavigation";
import useAuth from "src/features/auth/hooks/useAuth";
import hasGroupPermissions from "src/features/auth/utils/hasGroupPermissions";
import { CRUD_GROUP_WITH_HR } from "src/features/groups/groups";
import { setModalStateAction } from "src/features/modal/handlers";
import { DELETE_DOCUMENT, DELETE_TEMPLATE } from "src/features/modal/titles";
import useWindowSize from "src/features/page/hooks/useWindowSize";
import PdfViewer from "src/features/pdf/components/PdfViewer";
import useGetMyTodosListQuery from "src/features/todos/hooks/queries/useGetMyTodosListQuery";
import {
  TodoObjectTypes,
  TodoStatus,
} from "src/features/todos/types/todosEnums.types";
import { useAppDispatch } from "src/store";
import DocumentBulkAssignDialog from "../components/Documents/DocumentBulkAssignDialog";
import useCreateDocumentMutation from "../hooks/useCreateDocumentMutation";
import useGetDocuments from "../hooks/useGetDocuments";
import useGetTemplates from "../hooks/useGetTemplates";
import { resetDocumentAnnotationState } from "../slices/documentAnnotationsSlice";
import { resetDocumentAssignFormState } from "../slices/documentAssignFormSlice";
import { resetESignatureFormState } from "../slices/eSignatureFormSlice";
import { resetTemplateAnnotationsState } from "../slices/templateAnnotationsSlice";
import { Document, DocumentStatus } from "../types/document.types";
import { DocumentTemplate } from "../types/template.types";
import isDocument from "../utils/isDocument";
import DocumentsTab from "./DocumentsTab";

export default function ESignaturesDashboard() {
  const { employee } = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [previewDocument, setPreviewDocument] = useState<string | null>(null);
  const [isBulkAssignDialogOpen, setIsBulkAssignDialogOpen] = useState(false);
  const [bulkAssignTemplate, setBulkAssignTemplate] =
    useState<DocumentTemplate | null>(null);
  const [dropdownVisibility, setDropdownVisibility] = useState<{
    [key: string]: boolean;
  }>({});
  const [isOpen, setIsOpen] = useState(false);
  const { windowWidth, windowHeight } = useWindowSize();

  useEffect(() => {
    dispatch(resetDocumentAnnotationState());
    dispatch(resetDocumentAssignFormState());
    dispatch(resetESignatureFormState());
    dispatch(resetTemplateAnnotationsState());
  }, [dispatch]);

  const { data: templates, isLoading: isTemplatesLoading } = useGetTemplates();
  const { data: draftDocuments, isLoading: isDraftDocumentsLoading } =
    useGetDocuments({ status: 0 });
  const { data: pendingDocuments, isLoading: isPendingDocumentsLoading } =
    useGetDocuments({ status: 1 });
  const { data: completedDocuments, isLoading: isCompletedDocumentsLoading } =
    useGetDocuments({ status: 2 });

  const { data: todos, isLoading: isTodosLoading } = useGetMyTodosListQuery({
    objectType: TodoObjectTypes.Document,
    status: TodoStatus.Assigned,
  });

  const createDocumentMutation = useCreateDocumentMutation();

  const handleOpenBulkAssignTemplateDialog = (template: DocumentTemplate) => {
    setBulkAssignTemplate(template);
    setIsBulkAssignDialogOpen(true);
  };

  const handleDocumentTemplateButtonClick = (
    template: Document | DocumentTemplate
  ) => {
    if (!template?.id) return;
    const data = {
      template: template.id,
      status: DocumentStatus.Draft,
    };
    createDocumentMutation.mutate(data);
  };

  const handleDraftDocumentButtonClick = (
    document: Document | DocumentTemplate
  ) =>
    navigate(
      `/e-signatures/documents/${document.id}/assign?documentsTab=Drafts`
    );

  const handlePendingDocumentButtonClick = (
    document: Document | DocumentTemplate
  ) => {
    if (!todos?.some((t) => t?.document?.id === document.id)) {
      navigate(`/e-signatures/documents/${document.id}?documentsTab=Pending`);
    } else {
      navigate(
        `/e-signatures/documents/${document.id}/sign?documentsTab=Pending`
      );
    }
  };

  const handleCompletedDocumentButtonClick = (
    document: Document | DocumentTemplate
  ) => {
    if (isDocument(document)) {
      navigate(
        `/e-signatures/documents/${document?.id}?documentsTab=Completed`
      );
    }
  };

  const handleEditAnnotationsClick = (
    setIsFilterVisible: any,
    document: Document | DocumentTemplate
  ) => {
    setIsFilterVisible(false);
    if (isDocument(document)) {
      navigate(`/e-signatures/templates/${document?.template?.id}/edit`);
    } else {
      navigate(`/e-signatures/templates/${document.id}/edit`);
    }
  };
  const handleDeleteTemplateClick = (
    setIsFilterVisible: any,
    document: Document | DocumentTemplate
  ) => {
    setIsFilterVisible(false);
    dispatch(setModalStateAction(document.id)(DELETE_TEMPLATE)(true));
  };

  const handleDeleteDocumentClick = (
    setIsFilterVisible: any,
    document: Document | DocumentTemplate
  ) => {
    setIsFilterVisible(false);
    dispatch(setModalStateAction(document.id)(DELETE_DOCUMENT)(true));
  };

  const setIsFilterVisibleForId = (
    id: string | number | undefined,
    value: React.SetStateAction<boolean>
  ) => {
    if (id === undefined) return;

    setDropdownVisibility((prevState) => ({
      ...prevState,
      [id]: typeof value === "function" ? value(prevState[id] || false) : value,
    }));
  };

  return (
    <div>
      {isBulkAssignDialogOpen && bulkAssignTemplate ? (
        <DocumentBulkAssignDialog
          isOpen={isBulkAssignDialogOpen}
          setIsOpen={setIsBulkAssignDialogOpen}
          template={bulkAssignTemplate}
        />
      ) : null}
      <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
        <DialogHeader>Document Preview</DialogHeader>
        <DialogContent>
          {previewDocument && (
            <div style={{ overflowX: "auto" }}>
              <PdfViewer
                file={previewDocument}
                width={
                  windowWidth < 576 ? windowWidth - 20 : windowHeight / 1.5
                }
              />
            </div>
          )}
        </DialogContent>
      </Dialog>
      <TabNavigation defaultActiveTab={0} id="documents">
        <Tab title={"Templates"}>
          <DocumentsTab
            fileField="file_url"
            placeholderText="No templates found."
            documents={templates || []}
            isLoading={isTemplatesLoading}
            onDocumentClick={(_setIsFilterVisible, template) => {
              if (template?.file_url) {
                setPreviewDocument(template.file_url);
                setIsOpen(true);
              }
            }}
            renderButton={(template: any) => {
              const templateId = template.id;
              if (!templateId) return null;

              const isFilterVisible = dropdownVisibility[templateId] || false;
              const setIsFilterVisible = (
                value: React.SetStateAction<boolean>
              ) => {
                setIsFilterVisibleForId(templateId, value);
              };

              return (
                <>
                  {!template.self_assignable ? (
                    <DropDownV2
                      title={"Assign Document"}
                      icon={undefined}
                      size={undefined}
                      btnClassName="flex-1 border-radius-5"
                      color={ButtonColors.Yellow}
                      isFilterVisible={isFilterVisible}
                      setIsFilterVisible={setIsFilterVisible}
                    >
                      <ThreeDotsMenuItem
                        onClick={() =>
                          handleDocumentTemplateButtonClick(template)
                        }
                      >
                        <MdPerson /> {"Assign Individually"}
                      </ThreeDotsMenuItem>
                      <ThreeDotsMenuItem
                        onClick={() =>
                          handleOpenBulkAssignTemplateDialog(template)
                        }
                      >
                        <MdGroup /> {"Assign in Bulk"}
                      </ThreeDotsMenuItem>
                    </DropDownV2>
                  ) : (
                    <Button
                      className="border-radius-5 flex-1"
                      color={ButtonColors.Yellow}
                      onClick={() =>
                        handleDocumentTemplateButtonClick(template)
                      }
                      disabled={createDocumentMutation.isLoading}
                    >
                      {createDocumentMutation.isLoading ? (
                        <RotatingLines
                          strokeColor="#000"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="20"
                          visible={true}
                        />
                      ) : (
                        "Fill out Document"
                      )}
                    </Button>
                  )}
                </>
              );
            }}
            dropDownItems={
              hasGroupPermissions(employee, CRUD_GROUP_WITH_HR)
                ? (setIsFilterVisible, template) => {
                    return (
                      <>
                        <ThreeDotsMenuItem
                          onClick={() =>
                            handleEditAnnotationsClick(
                              setIsFilterVisible,
                              template
                            )
                          }
                        >
                          <MdEdit /> {"Edit Template"}
                        </ThreeDotsMenuItem>
                        <ThreeDotsMenuItem
                          onClick={() =>
                            handleDeleteTemplateClick(
                              setIsFilterVisible,
                              template
                            )
                          }
                        >
                          <MdDelete /> {"Delete Template"}
                        </ThreeDotsMenuItem>
                      </>
                    );
                  }
                : undefined
            }
          />
        </Tab>
        {hasGroupPermissions(employee, CRUD_GROUP_WITH_HR) ? (
          <Tab title={"Drafts"}>
            <DocumentsTab
              fileField="file_url"
              placeholderText="No drafts found."
              documents={draftDocuments || []}
              isLoading={isDraftDocumentsLoading}
              onDocumentClick={(_setIsFilterVisible, document) => {
                if (document?.file_url) {
                  setPreviewDocument(document.file_url);
                  setIsOpen(true);
                }
              }}
              renderButton={(document) => {
                return (
                  <Button
                    style={{ borderRadius: "5px", flex: 1 }}
                    color={ButtonColors.Yellow}
                    onClick={() => handleDraftDocumentButtonClick(document)}
                    disabled={false}
                  >
                    Finish Assigning Document
                  </Button>
                );
              }}
              dropDownItems={(setIsFilterVisible, document) => {
                return (
                  <>
                    {hasGroupPermissions(employee, CRUD_GROUP_WITH_HR) ? (
                      <ThreeDotsMenuItem
                        onClick={() =>
                          handleEditAnnotationsClick(
                            setIsFilterVisible,
                            document
                          )
                        }
                      >
                        <MdEdit /> {"Edit Template"}
                      </ThreeDotsMenuItem>
                    ) : null}
                    {hasGroupPermissions(employee, CRUD_GROUP_WITH_HR) ? (
                      <ThreeDotsMenuItem
                        onClick={() =>
                          handleDeleteDocumentClick(
                            setIsFilterVisible,
                            document
                          )
                        }
                      >
                        <MdDelete /> {"Delete Document"}
                      </ThreeDotsMenuItem>
                    ) : null}
                  </>
                );
              }}
            />
          </Tab>
        ) : (
          <></>
        )}
        <Tab title={"Pending"}>
          <DocumentsTab
            fileField="newest_version"
            placeholderText="You're all caught up!"
            documents={pendingDocuments || []}
            isLoading={isPendingDocumentsLoading}
            onDocumentClick={(_setIsFilterVisible, document) => {
              if (isDocument(document) && document?.newest_version) {
                setPreviewDocument(document.newest_version);
                setIsOpen(true);
              }
            }}
            renderButton={(document) => {
              return (
                <Button
                  style={{ borderRadius: "5px", flex: 1 }}
                  color={ButtonColors.Yellow}
                  onClick={() => handlePendingDocumentButtonClick(document)}
                  disabled={
                    isTodosLoading ||
                    !todos?.some((todo) => todo?.document?.id === document.id)
                  }
                >
                  {!todos?.some((todo) => todo?.document?.id === document.id)
                    ? "Awaiting Other Signers..."
                    : "Sign Document Now"}
                </Button>
              );
            }}
          />
        </Tab>
        <Tab title={"Completed"}>
          <DocumentsTab
            fileField="newest_version"
            placeholderText="No completed documents found."
            documents={completedDocuments || []}
            isLoading={isCompletedDocumentsLoading}
            onDocumentClick={(_setIsFilterVisible, document) => {
              if (isDocument(document) && document?.newest_version) {
                setPreviewDocument(document.newest_version);
                setIsOpen(true);
              }
            }}
            renderButton={(document) => {
              return (
                <Button
                  style={{ borderRadius: "5px", flex: 1 }}
                  color={ButtonColors.Yellow}
                  onClick={() => handleCompletedDocumentButtonClick(document)}
                  disabled={false}
                >
                  View Document
                </Button>
              );
            }}
          />
        </Tab>
      </TabNavigation>
    </div>
  );
}
