import * as React from "react"

const MemosIcon = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m6.166 5.267 7.075-2.359c3.175-1.058 4.9.675 3.85 3.85l-2.358 7.075c-1.583 4.759-4.183 4.759-5.767 0l-.7-2.1-2.1-.7c-4.758-1.583-4.758-4.175 0-5.766ZM8.425 11.375l2.983-2.992"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default MemosIcon
