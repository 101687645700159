import React from "react";
import styles from "./Button.module.css";
import { ButtonColors, ButtonSizes } from "./buttons.types";

const colorStyles: Record<string, string> = {
  black: styles.btnBlack,
  blue: styles.btnBlue,
  gray: styles.btnGray,
  "gray-and-yellow": styles.btnGrayAndYellow,
  green: styles.btnGreen,
  red: styles.btnRed,
  "white-outline": styles.btnWhiteOutline,
  yellow: styles.btnYellow,
  transparent: styles.btnTransparent,
};

const sizeStyles: Record<string, string> = {
  xs: styles.btnXs,
  sm: styles.btnSm,
  md: styles.btnMd,
  lg: styles.btnLg,
  xl: styles.btnXl,
};

const textSizes: Record<string, string> = {
  xs: styles.btnXsText,
  sm: styles.btnSmText,
  md: styles.btnMdText,
  lg: styles.btnLgText,
  xl: styles.btnXlText,
};

interface ButtonProps {
  buttonRef?: React.RefObject<HTMLButtonElement>;
  className?: string;
  color?: ButtonColors;
  disabled?: boolean;
  form?: string;
  id?: number | string | null;
  dataTestid?: string;
  onClick?: (e: React.MouseEvent<Element, MouseEvent>) => void;
  size?: ButtonSizes;
  style?: React.CSSProperties;
  title?: string;
  tooltip?: string;
  type?: "button" | "submit" | "reset";
  children?: React.ReactNode;
}

export default function Button({
  buttonRef,
  children,
  className,
  color,
  disabled = false,
  form,
  onClick,
  style,
  size = ButtonSizes.MD,
  title,
  tooltip,
  type = "button",
  id = 0,
  dataTestid = "",
}: ButtonProps) {
  const classNames = [
    className,
    colorStyles[color || ""],
    sizeStyles[size],
  ].filter(Boolean);

  return (
    <button
      ref={buttonRef}
      title={tooltip}
      className={classNames.join(" ")}
      disabled={disabled}
      form={form}
      onClick={onClick}
      style={style}
      type={type}
      id={id?.toString()}
      data-testid={dataTestid}
    >
      {children}
      {title ? <span className={textSizes[size] || ""}>{title}</span> : null}
    </button>
  );
}
