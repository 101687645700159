import { useCallback, useMemo } from "react";
import { setModalStateAction } from "src/features/modal/handlers";
import { SELECT_EMPLOYEE } from "src/features/modal/titles";
import { useAppDispatch } from "src/store";
import useDocumentAnnotations from "../../hooks/useDocumentAnnotations";
import { DocumentAnnotationStatus } from "../../types/document.types";
import Button from "src/components/Buttons/Button";
import Container from "src/components/Container/Container";
import CheckIconSmall from "src/components/Icons/CheckIconSmall";
import YellowCircle24Icon from "src/components/Icons/YellowCircle24Icon";
import VerticalStepper from "src/components/Steps/VerticalStepper";
import { AnnotationType } from "../../types/template.types";
import styles from "../ESignatures.module.css";
import EmployeeImage from "src/components/Images/EmployeeImage";
import getFullName from "src/features/employees/utils/getFullName";
import useDocumentAssignForm from "../../hooks/useDocumentAssignForm";
import { DocumentAssignFormSteps } from "../../slices/documentAssignFormSlice";
import { ButtonColors } from "src/components/Buttons/buttons.types";

export default function DocumentAnnotationsAssignmentStepper() {
  const dispatch = useAppDispatch();
  const { annotations } = useDocumentAnnotations();
  const { step } = useDocumentAssignForm();

  const handleAssignAnnotation = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      const { id } = e.currentTarget;
      dispatch(setModalStateAction(id)(SELECT_EMPLOYEE)(true));
    },
    [dispatch]
  );

  const steps = useMemo(() => {
    return annotations.map((annotation) => {
      return {
        icon:
          annotation.status !== DocumentAnnotationStatus.NotAssigned ? (
            <CheckIconSmall />
          ) : (
            <YellowCircle24Icon />
          ),
        title: (
          <span className={styles.stepTitleSmall}>
            {annotation.type === AnnotationType.Signature
              ? "Signature"
              : annotation.type === AnnotationType.TextField
              ? "Text"
              : annotation.type === AnnotationType.Image
              ? "Image"
              : annotation.type === AnnotationType.Dropdown
              ? "Dropdown"
              : annotation.type === AnnotationType.Checkbox
              ? "Checkbox"
              : null}
          </span>
        ),
        content: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "0.5rem",
            }}
          >
            {annotation.status !== DocumentAnnotationStatus.NotAssigned &&
            annotation?.assigned_to ? (
              <div
                id={annotation.id}
                onClick={handleAssignAnnotation}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  fontSize: "10px",
                  fontWeight: 500,
                }}
              >
                <EmployeeImage
                  src={annotation.assigned_to.image}
                  alt={"Employee Image"}
                  containerStyle={{ height: 20, width: 20 }}
                />
                <span>{getFullName(annotation.assigned_to)}</span>
              </div>
            ) : (
              <Container>
                {step === DocumentAssignFormSteps.AssignAnnotations ? (
                  <Button
                    color={ButtonColors.Yellow}
                    onClick={handleAssignAnnotation}
                    id={annotation.id}
                  >
                    Assign
                  </Button>
                ) : (
                  <span style={{ fontSize: "12px", color: "#4f4f4f" }}>
                    Not assigned
                  </span>
                )}
              </Container>
            )}
          </div>
        ),
      };
    });
  }, [annotations, handleAssignAnnotation, step]);

  return (
    <div style={{ padding: "1rem" }}>
      <VerticalStepper steps={steps} />
    </div>
  );
}
