import { useQuery } from "@tanstack/react-query";
import employeeScheduleRolesService from "../services/employeeScheduleRolesService";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

type UseGetEmployeeScheduleRolesByEmployeeIdProps = {
  employeeId?: number;
};

export default function useGetEmployeeScheduleRolesByEmployeeId({
  employeeId,
}: UseGetEmployeeScheduleRolesByEmployeeIdProps) {
  return useQuery({
    queryFn: employeeId
      ? () =>
          employeeScheduleRolesService.getEmployeeScheduleRolesByEmployeeId(
            employeeId
          )
      : undefined,
    queryKey: [QueryKeys.EmployeeScheduleRolesList, employeeId],
    enabled: !!employeeId,
  });
}
