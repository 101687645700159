import * as React from "react"

const SvgComponent = (props: any) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.667 12.667C6.667 13.4 7.267 14 8 14s1.333-.6 1.333-1.333c0-.734-.6-1.334-1.333-1.334s-1.333.6-1.333 1.334ZM6.667 3.333c0 .734.6 1.334 1.333 1.334s1.333-.6 1.333-1.334C9.333 2.6 8.733 2 8 2s-1.333.6-1.333 1.333ZM6.667 8c0 .733.6 1.333 1.333 1.333S9.333 8.733 9.333 8 8.733 6.667 8 6.667 6.667 7.267 6.667 8Z"
      stroke="#fff"
      strokeWidth={1.5}
    />
  </svg>
)

export default SvgComponent
