import { useState } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import ThreeDotsDropDownV2 from "src/components/DropDowns/ThreeDotsDropDownV2";
import ThreeDotsMenuItem from "src/components/DropDowns/ThreeDotsMenuItem";
import ThreeDotsIcon from "src/components/Icons/ThreeDotsIcon";

type EmployeeWeeklyScheduleHeaderProps = {
  weekDateTitle: string;
  onClickWeekNavigation: (direction: "next" | "previous") => void;
  onNavigateToShiftTransfers: () => void;
  onNavigateToTimeOffRequests: () => void;
};

export default function EmployeeWeeklyScheduleHeader({
  weekDateTitle,
  onClickWeekNavigation,
  onNavigateToShiftTransfers,
  onNavigateToTimeOffRequests,
}: EmployeeWeeklyScheduleHeaderProps) {
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  return (
    <div className="h-60 px-20 border-bottom-light-gray flex flex-1 flex-row align-center justify-between">
      <div className="flex flex-1 flex-row align-center justify-center gap-20">
        <div className="flex w-20" />
        <div className="flex flex-1 flex-row align-center justify-center gap-20">
          <Button
            color={ButtonColors.Yellow}
            className="border-radius-5 p-0"
            onClick={() => onClickWeekNavigation("previous")}
          >
            <MdChevronLeft className="cursor-pointer" size={28} />
          </Button>
          <span className="font-bold text-lg">{weekDateTitle}</span>
          <Button
            color={ButtonColors.Yellow}
            className="border-radius-5 p-0"
            onClick={() => onClickWeekNavigation("next")}
          >
            <MdChevronRight className="cursor-pointer" size={28} />
          </Button>
        </div>
        <div className="flex w-20 align-end justify-end">
          <ThreeDotsDropDownV2
            setIsFilterVisible={setIsFilterVisible}
            isFilterVisible={isFilterVisible}
            icon={<ThreeDotsIcon />}
          >
            <ThreeDotsMenuItem onClick={onNavigateToShiftTransfers}>
              View All Shift Transfers
            </ThreeDotsMenuItem>
            <ThreeDotsMenuItem onClick={onNavigateToTimeOffRequests}>
              View All Time Off Requests
            </ThreeDotsMenuItem>
          </ThreeDotsDropDownV2>
        </div>
      </div>
    </div>
  );
}
