import { useAlert } from "@blaumaus/react-alert";
import { useQueryClient } from "@tanstack/react-query";
import { RotatingLines } from "react-loader-spinner";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import Dialog from "src/components/Dialog/Dialog";
import DialogDescription from "src/components/Dialog/DialogDescription";
import DialogFooter from "src/components/Dialog/DialogFooter";
import DialogHeader from "src/components/Dialog/DialogHeader";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import useCancelShiftTransferMutation from "../hooks/useCancelShiftTransferMutation";
import { EmployeeShift } from "../services/employeeShiftsService";

type ShiftTransferCancelDialogProps = {
  shift: EmployeeShift;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClose?: () => void;
};

export default function ShiftTransferCancelDialog({
  shift,
  isOpen,
  setIsOpen,
  onClose,
}: ShiftTransferCancelDialogProps) {
  const alert = useAlert();
  const queryClient = useQueryClient();

  const cancelShiftTransferMutation = useCancelShiftTransferMutation({
    onSuccess: (data) => {
      alert.success("Shift transfer cancelled successfully!");
      setIsOpen(false);
      queryClient.invalidateQueries([QueryKeys.ShiftTransfersList]);
      queryClient.invalidateQueries([QueryKeys.EmployeeShiftsList]);
    },
  });

  const handleCancelShiftTransfer = () =>
    cancelShiftTransferMutation.mutate(shift.id);

  return (
    <Dialog isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <DialogHeader>Cancel this shift transfer?</DialogHeader>
      <DialogDescription>
        Are you sure you want to cancel this shift transfer?
      </DialogDescription>
      <DialogFooter>
        <Button
          color={ButtonColors.GrayAndYellow}
          disabled={cancelShiftTransferMutation.isLoading}
          onClick={() => setIsOpen(false)}
        >
          {cancelShiftTransferMutation.isLoading ? (
            <RotatingLines
              strokeColor="#f1b70c"
              strokeWidth="5"
              animationDuration="0.75"
              width="20"
              visible={true}
            />
          ) : (
            "Close"
          )}
        </Button>
        <Button
          color={ButtonColors.Yellow}
          disabled={cancelShiftTransferMutation.isLoading}
          onClick={handleCancelShiftTransfer}
        >
          {cancelShiftTransferMutation.isLoading ? (
            <RotatingLines
              strokeColor="#f1b70c"
              strokeWidth="5"
              animationDuration="0.75"
              width="20"
              visible={true}
            />
          ) : (
            "Yes, Cancel"
          )}
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
