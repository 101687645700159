import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "src/store";
import {
  addOrUpdateAnnotations,
  resetDocumentAnnotationState,
} from "../slices/documentAnnotationsSlice";
import documentService from "../services/documentService";

type UseGetAnnotations = {
  enabled?: boolean;
  refetchOnWindowFocus?: boolean;
};

export default function useGetMyDocumentAnnotations({
  enabled = true,
  refetchOnWindowFocus = false,
}: UseGetAnnotations) {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  return useQuery({
    queryKey: ["myDocumentAnnotations", id],
    queryFn: id ? () => documentService.getMyAnnotations(id) : () => [],
    enabled: enabled && !!id,
    refetchOnWindowFocus,
    onSuccess: (data) => {
      dispatch(resetDocumentAnnotationState());
      dispatch(addOrUpdateAnnotations(data));
    },
  });
}
