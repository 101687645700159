import styles from "./Steps.module.css";
import CheckIcon from "../Icons/CheckIcon";

type StepsProgressBarItemProps = {
  current: number;
  step: number;
};

export default function StepsProgressBarItem({
  current,
  step,
}: StepsProgressBarItemProps) {
  return (
    <div className={styles.stepItemContainer}>
      <div
        className={current >= step ? styles.stepDotComplete : styles.stepDot}
      >
        {current >= step ? (
          <CheckIcon />
        ) : (
          <div className={styles.stepNumber}>{step}</div>
        )}
      </div>
      <span
        className={current >= step ? styles.stepTextComplete : styles.stepText}
      >
        Step {step}
      </span>
    </div>
  );
}
