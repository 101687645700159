import { DATE_FORMAT, EN_US } from "../dateOptions";

/**
 * Formats a date range into a string representation using the provided locale and format options.
 *
 * @param {Date | null} startDate - The start date of the range. Must not be null.
 * @param {Date | null} endDate - The end date of the range. Must not be null.
 * @param {string} [locale=EN_US] - The locale to use for formatting the dates. Defaults to 'EN_US'.
 * @param {Intl.DateTimeFormatOptions} [format=DATE_FORMAT] - The format options for the dates. Defaults to 'DATE_FORMAT'.
 * @returns {string} A string representing the formatted date range.
 * @throws {Error} Throws an error if either startDate or endDate is null.
 */
function formatDateRange(
  startDate: Date | string | null,
  endDate: Date | string | null,
  locale = EN_US,
  format: Intl.DateTimeFormatOptions = DATE_FORMAT
) {
  if (!startDate || !endDate) {
    throw new Error("Both startDate and endDate are required");
  }

  if (typeof startDate === "string") {
    startDate = new Date(startDate);
  }

  if (typeof endDate === "string") {
    endDate = new Date(endDate);
  }

  return `${startDate.toLocaleDateString(
    locale,
    format
  )} - ${endDate.toLocaleDateString(locale, format)}`;
}

export default formatDateRange;
