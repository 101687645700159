import { useMutation } from "@tanstack/react-query";
import scheduleRolesService from "../services/scheduleRolesService";
import { ScheduleRoleFormDataType } from "../components/VerifyScheduleRolesForm";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";

export default function usePartialUpdateScheduleRolesMutation() {
  const handleErrors = useErrorHandling();
  return useMutation({
    mutationFn: (data: ScheduleRoleFormDataType) =>
      scheduleRolesService.partialUpdateScheduleRole(data.id, data),
    onError: handleErrors,
  });
}
