import { AuthenticatedEmployee } from "src/features/employees/types/employees.type";

function hasGroupPermissions(
  employee: AuthenticatedEmployee | null,
  group: string[]
) {
  if (!employee?.role?.group?.name) return false;
  const employeeGroupName = employee.role.group.name.toLowerCase();
  return group.map((g) => g.toLowerCase()).includes(employeeGroupName);
}

export default hasGroupPermissions;
