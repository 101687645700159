import * as z from "zod";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { RotatingLines } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useAlert } from "@blaumaus/react-alert";
import { ScheduleRoleType } from "../services/scheduleRolesService";
import useGetDepartmentsListQuery from "src/features/departments/hooks/useGetDepartmentsListQuery";
import inputStyles from "src/components/Inputs/Inputs.module.css";
import Button from "src/components/Buttons/Button";
import ButtonsContainer from "src/components/Container/ButtonsContainer";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import usePartialUpdateScheduleRolesMutation from "../hooks/usePartialUpdateScheduleRolesMutation";
import useSyncEmployeeScheduleRolesMutation from "../hooks/useSyncEmployeeScheduleRolesMutation";

const scheduleRoleSchema = z.object({
  id: z.number().or(z.string()),
  role: z.number().or(z.string()),
  department: z.number().or(z.string()),
  hourly_rate: z.number().or(z.string()),
});

export type ScheduleRoleFormDataType = z.infer<typeof scheduleRoleSchema>;

const scheduleRolesFormSchema = z.object({
  scheduleRoles: z.array(scheduleRoleSchema),
});

type ScheduleRoleFormType = z.infer<typeof scheduleRolesFormSchema>;

type ScheduleRolesFormProps = {
  scheduleRoles: ScheduleRoleType[];
};

export default function ScheduleRolesForm({
  scheduleRoles,
}: ScheduleRolesFormProps) {
  const alert = useAlert();
  const navigate = useNavigate();

  const { data: departments } = useGetDepartmentsListQuery();
  const partialUpdateScheduleRolesMutation =
    usePartialUpdateScheduleRolesMutation();
  const syncEmployeeScheduleRolesMutation =
    useSyncEmployeeScheduleRolesMutation({
      onSuccess: () => {
        navigate("/register/schedules/employees/verify");
      },
    });

  const { control, handleSubmit } = useForm<ScheduleRoleFormType>({
    resolver: zodResolver(scheduleRolesFormSchema),
    defaultValues: {
      scheduleRoles: scheduleRoles.map((scheduleRole) => ({
        id: scheduleRole.id,
        role: scheduleRole.role.id,
        department: scheduleRole.department.id,
        hourly_rate: scheduleRole.hourly_rate,
      })),
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "scheduleRoles",
    keyName: "uuid",
  });

  const handleSaveScheduleRoles = (data: ScheduleRoleFormType) => {
    const mutations = data.scheduleRoles.map((scheduleRole) =>
      partialUpdateScheduleRolesMutation.mutateAsync(scheduleRole)
    );

    Promise.all(mutations)
      .then(() => {
        syncEmployeeScheduleRolesMutation.mutate();
      })
      .catch(() => {
        alert.error(
          "An error occurred while saving. It has been reported and is being investigated."
        );
      });
  };

  const isLoading =
    partialUpdateScheduleRolesMutation.isLoading ||
    syncEmployeeScheduleRolesMutation.isLoading;

  return (
    <form
      onSubmit={handleSubmit(handleSaveScheduleRoles)}
      style={{ padding: "10px 10px 0 10px", flex: 1 }}
    >
      <table className="table" style={{ backgroundColor: "white" }}>
        <thead>
          <tr>
            <th>Role</th>
            <th>Department</th>
            <th>Hourly Rate</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((field, index) => (
            <tr key={field.uuid}>
              <td>
                <div>{scheduleRoles[index].role.name}</div>
              </td>
              <td>
                <Controller
                  name={`scheduleRoles.${index}.department`}
                  control={control}
                  render={({ field }) => (
                    <select
                      {...field}
                      disabled={isLoading}
                      className={inputStyles.darkInput}
                      id={`department${index}`}
                    >
                      {departments?.length &&
                        departments.map((dep) => (
                          <option key={dep.name} value={dep.id}>
                            {dep.name}
                          </option>
                        ))}
                    </select>
                  )}
                />
              </td>
              <td>
                <Controller
                  name={`scheduleRoles.${index}.hourly_rate`}
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      disabled={isLoading}
                      type="number"
                      min={0}
                      className={inputStyles.darkInput}
                      id={`hourlyRate${index}`}
                    />
                  )}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ButtonsContainer>
        <Button color={ButtonColors.Yellow} disabled={isLoading} type="submit">
          {isLoading ? (
            <RotatingLines
              strokeColor="#000"
              strokeWidth="5"
              animationDuration="0.75"
              width="20"
              visible={true}
            />
          ) : (
            "Save Changes"
          )}
        </Button>
      </ButtonsContainer>
    </form>
  );
}
