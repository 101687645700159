export const joyrideStyles = {
  options: {
    arrowColor: "#F1B70C",
    backgroundColor: "#F1B70C",
    beaconSize: 36,
    overlayColor: "rgba(0, 0, 0, 0.4)",
    primaryColor: "#38373F",
    textColor: "#333",
    width: undefined,
    zIndex: 100,
  },
  spotlight: {
    backgroundColor: "rgba(255, 255, 255, 0.3)",
  },
  title: {
    fontSize: "20px",
    paddingTop: "1.5rem",
  },
  buttonNext: {
    backgroundColor: "var(--clearpath-mid-gray)",
    borderRadius: "20px",
    color: "#fff",
    fontSize: "14px",
    padding: "0.5rem 1rem",
  },
};
