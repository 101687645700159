import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { postSittingAnswers } from "../apis";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

export default function useLpMcQuestionMutation() {
  const queryClient = useQueryClient();
  const handleErrors = useErrorHandling();

  return useMutation(postSittingAnswers, {
    onSuccess: (response: any) => {
      queryClient.invalidateQueries([QueryKeys.MyLearningPathsDetails]);
      queryClient.setQueryData(
        [QueryKeys.MyLearningPathAssessmentTake],
        response
      );
    },
    onError: (error: AxiosError) => handleErrors(error),
  });
}
