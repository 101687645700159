import { addDays, isValid, parseISO, startOfWeek } from "date-fns";
import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function useWeekFromUrl() {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const startOfWeekDateParam = queryParams.get("startOfWeekDate");

  const currentWeek = useMemo(() => {
    return startOfWeekDateParam && isValid(parseISO(startOfWeekDateParam))
      ? startOfWeek(parseISO(startOfWeekDateParam), { weekStartsOn: 0 })
      : startOfWeek(new Date(), { weekStartsOn: 0 });
  }, [startOfWeekDateParam]);

  const handleNavigateWeek = useCallback(
    (direction: "prev" | "next") => {
      const newDate = addDays(currentWeek, direction === "next" ? 7 : -7);
      const formattedDate = newDate.toISOString().slice(0, 10);

      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.set("startOfWeekDate", formattedDate);

      navigate(
        {
          pathname: location.pathname,
          search: newSearchParams.toString(),
        },
        { replace: true }
      );
    },
    [currentWeek, navigate, location.pathname, location.search]
  );

  const weekDates = useMemo(
    () => Array.from({ length: 7 }, (_, i) => addDays(currentWeek, i)),
    [currentWeek]
  );

  const weekDateTitle = useMemo(() => {
    return `${weekDates[0].toLocaleDateString()} - ${weekDates[6].toLocaleDateString()}`;
  }, [weekDates]);

  return {
    currentWeek,
    startOfWeekDate: currentWeek,
    endOfWeekDate: addDays(currentWeek, 6),
    handleNavigateWeek,
    weekDates,
    weekDateTitle,
  };
}
