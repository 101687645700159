import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useAlert } from "@blaumaus/react-alert";

import assessmentService from "../services/assessmentService";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

export default function useToggleEmployeeAnswerMutation() {
  const handleErrors = useErrorHandling();
  const alert = useAlert();
  const queryClient = useQueryClient();

  return useMutation(assessmentService.toggleEmployeeAnswer, {
    onSuccess: (data) => {
      alert.success("Answer correctness changed successfully!");
      queryClient.setQueryData([QueryKeys.GetSitting, String(data.id)], data);
    },
    onError: (error: AxiosError) => handleErrors(error),
  });
}
