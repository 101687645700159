import * as React from "react"
const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="red"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M17.5 4.983a84.752 84.752 0 0 0-8.35-.416c-1.65 0-3.3.083-4.95.25l-1.7.166M7.082 4.141l.183-1.091c.134-.792.234-1.383 1.642-1.383h2.183c1.409 0 1.517.625 1.642 1.391l.183 1.083M15.708 7.616l-.542 8.392c-.092 1.309-.167 2.325-2.492 2.325h-5.35c-2.325 0-2.4-1.016-2.491-2.325L4.29 7.616M8.607 13.75h2.775M7.918 10.416h4.167"
    />
  </svg>
)
export default SvgComponent

