import { useQuery } from "@tanstack/react-query";
import assessmentService from "../services/assessmentService";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

type UseGetSittingProps = {
  id?: string;
  refetchOnWindowFocus?: boolean;
  enabled?: boolean;
};

export default function useGetSittingReview({
  id,
  refetchOnWindowFocus = false,
  enabled = true,
}: UseGetSittingProps) {
  return useQuery({
    queryKey: [QueryKeys.GetSitting, String(id)],
    queryFn: id ? () => assessmentService.getSitting(id) : undefined,
    refetchOnWindowFocus,
    enabled,
  });
}
