import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import rolesService from "../services/rolesService";

type DeleteEmployeeTypeMutation = {
  onSuccess?: () => void;
  onError?: (data: AxiosError) => void;
};

export default function useDeleteEmployeeTypeMutation({
  onSuccess,
  onError,
}: DeleteEmployeeTypeMutation) {
  return useMutation(rolesService.deleteEmployeeType, {
    onSuccess,
    onError,
  });
}
