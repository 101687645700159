// Represents the shape of an imported React component module.
interface ReactComponentModule {
  default: React.ComponentType;
}

// A function that dynamically imports a React component module.
type ComponentImportFunction = () => Promise<ReactComponentModule>;

const lazyRetry = (
  componentImport: ComponentImportFunction
): Promise<ReactComponentModule> =>
  new Promise((resolve, reject) => {
    const storageKey = `retry-lazy-refreshed${btoa(
      componentImport.toString()
    )}`;
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(storageKey) || "false"
    );
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(storageKey, "false");
        if (component === undefined) {
          window.sessionStorage.setItem(storageKey, "true");
          return window.location.reload(); // refresh the page
        }
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(storageKey, "true");
          window.location.reload();
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });

export default lazyRetry;
