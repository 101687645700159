import React, { useEffect } from "react";
import { getDepartments } from "../../features/departments/slices/departmentsSlice";
import { getRoles } from "../../features/employeeTypes/slices/rolesSlice";
import PageContent from "./PageContent";
import NavigationMenu from "src/features/navMenu/components/NavMenu";
import useAuth from "src/features/auth/hooks/useAuth";
import { useAppDispatch } from "src/store";

type FeatureContentProps = {
  header?: React.ReactNode;
  children: React.ReactNode;
};

export default function FeatureContent({
  header,
  children,
}: FeatureContentProps) {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getRoles());
      dispatch(getDepartments());
    }
  }, [isAuthenticated, dispatch]);

  return (
    <>
      <NavigationMenu />
      <PageContent>
        {header}
        {children}
      </PageContent>
    </>
  );
}
