import { Link } from "react-router-dom";
import { useAppDispatch } from "src/store";
import { closeMenu } from "../slices/navMenuSlice";
import ImageV2 from "src/components/Images/ImageV2";
import Button from "src/components/Buttons/Button";
import CloseIcon from "src/components/Icons/CloseIcon";
import logo from "src/assets/images/clearpath-logo.png";
import styles from "./NavMenu.module.css";

export default function NavMenuLogo() {
  const dispatch = useAppDispatch();
  const handleCloseMenu = () => dispatch(closeMenu());

  return (
    <div className={styles.logoContainer}>
      <Link onClick={handleCloseMenu} to="/dashboard" style={{ width: "80%" }}>
        <ImageV2
          loading={"lazy"}
          src={logo}
          alt="clearpath logo"
          height={40}
          width={112}
        />
      </Link>
      <Button
        className={styles.closeButton}
        onClick={handleCloseMenu}
        title={undefined}
      >
        <CloseIcon />
      </Button>
    </div>
  );
}
