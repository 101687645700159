import axiosClient from "src/features/axios/client";

export type LaborBudgetSettings = {
  id: number;
  hide_labor_budget: boolean;
};

class LaborBudgetSettingsService {
  async getOrCreateLaborBudgetSettings(): Promise<LaborBudgetSettings> {
    return await axiosClient
      .get("/api/labor-budget-settings/get-or-create/")
      .then((res) => res.data);
  }

  async partialUpdateLaborBudgetSettings(
    data: Partial<LaborBudgetSettings>
  ): Promise<LaborBudgetSettings> {
    return await axiosClient
      .patch(`/api/labor-budget-settings/${data.id}/`, data)
      .then((res) => res.data);
  }
}

const laborBudgetSettingsService = new LaborBudgetSettingsService();

export default laborBudgetSettingsService;
