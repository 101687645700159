import axiosClient from "src/features/axios/client";
import {
  EmployeeLearningPathDetails,
  EmployeeLearningPathRank,
} from "../learningPaths.types";

class EmployeeLearningPathsService {
  async deleteEmployeeLP(id: number) {
    return await axiosClient.delete(`/api/employee-learning-paths/${id}`);
  }

  async getEmployeeLearningPaths(
    { id, page = 1, sortBy, sortDirection, ...filters } = {} as any
  ) {
    return await axiosClient
      .get(`api/employee-learning-paths/`, {
        params: {
          id,
          page,
          sortBy,
          sortDirection,
          ...filters,
        },
      })
      .then((res) => res.data);
  }

  async getEmployeeLearningPathAndModules(
    id: number | string
  ): Promise<EmployeeLearningPathDetails> {
    return await axiosClient
      .get(`/api/employee-learning-paths-and-modules/${id}`)
      .then((res) => res.data);
  }

  async getEmployeeLearningPathRank(
    id: string | number
  ): Promise<EmployeeLearningPathRank> {
    return await axiosClient
      .get(`/api/employee-learning-path-rank/${id}/`)
      .then((res) => res.data);
  }

  async getEmployeeOverallProgress(id: number) {
    return await axiosClient
      .get(`/api/employee-learning-paths/employee-stats/${id}/`)
      .then((res) => res.data);
  }
}

const employeeLearningPathsService = new EmployeeLearningPathsService();

export default employeeLearningPathsService;
