import { useQuery } from "@tanstack/react-query";

import rolesService from "../services/rolesService";

export default function useGetEmployeeTypesQuery() {
  return useQuery({
    queryKey: ["employeeTypes"],
    queryFn: rolesService.getEmployeeTypes,
  });
}
