import { AxiosError } from "axios";
import { useAlert } from "@blaumaus/react-alert";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import todosService from "src/features/todos//services/todosService";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

export default function useDeleteScheduledTodoMutation() {
  const alert = useAlert();
  const handleErrors = useErrorHandling();
  const queryClient = useQueryClient();

  return useMutation(todosService.deleteScheduledTodo, {
    onSuccess: (_data) => {
      alert.success("Successfully deleted scheduled todo");
      queryClient.invalidateQueries([QueryKeys.ScheduledTodosList]);
    },
    onError: (error: AxiosError) => handleErrors(error),
  });
}
