import { ForwardedRef, RefObject, useEffect } from "react";

interface VideoActions {
  [key: string]: () => void;
}

const useVideoActions = (
  videoRef: RefObject<HTMLVideoElement> | ForwardedRef<HTMLVideoElement>,
  actions: VideoActions
) => {
  useEffect(() => {
    const video = (videoRef as RefObject<HTMLVideoElement>).current;
    if (!video) return;

    const eventListeners = Object.keys(actions).map((event) => {
      video.addEventListener(event, actions[event]);
      return { event, action: actions[event] };
    });

    return () => {
      eventListeners.forEach(({ event, action }) => {
        video.removeEventListener(event, action);
      });
    };
  }, [videoRef, actions]);
};

export default useVideoActions;
