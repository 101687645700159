import { useOutletContext } from "react-router-dom";
import { Department } from "src/features/departments/departments.types";

type AppOutletContext = {
  setCurrentStep: (step: number) => void;
  department: Department | null;
};

export function useAppOutletContext() {
  return useOutletContext<AppOutletContext>();
}
