import axiosClient from "../../axios/client";

class MyLearningPathsService {
  async getMyLearningPathDetails() {
    return await axiosClient
      .get(`/api/my-learning-paths-details/`)
      .then((res) => res.data);
  }

  async getMyLearningPathDetailsById(id: number | string) {
    return await axiosClient
      .get(`/api/my-learning-paths-details/${id}/`)
      .then((res) => res.data);
  }

  async getMyLearningPathTake(id: number | string) {
    return await axiosClient
      .get(`/api/my-learning-paths/${id}/take/`)
      .then((res) => res.data);
  }

  async postMyLearningPathTake({ id, emcId, answers }: any) {
    return await axiosClient
      .post(`/api/my-learning-paths/${id}/take/`, {
        emc_id: emcId,
        answers: answers,
      })
      .then((res) => res.data);
  }
}

const myLearningPathsService = new MyLearningPathsService();

export default myLearningPathsService;
