import * as React from "react";
const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={34}
    height={34}
    fill="none"
    {...props}
  >
    <circle cx={17} cy={17} r={16.5} fill="#232227" stroke="#4B4B4B" />
    <path
      fill="#fff"
      d="M18.854 24v-2.6h-6.9v-2.04l6.56-9.36h2.9v9.18h1.84v2.22h-1.84V24h-2.56Zm-4.16-4.82h4.32v-6.36l-4.32 6.36Z"
    />
  </svg>
);
export default SvgComponent;
