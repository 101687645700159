import { MdOutlineFileDownload } from "react-icons/md";
import { useParams } from "react-router-dom";
import { useAlert } from "@blaumaus/react-alert";
import Button from "src/components/Buttons/Button";
import ListItemDate from "./ListItemDate";
import useGetDocuments from "../../hooks/useGetDocuments";
import { Document } from "../../types/document.types";
import GenericExpandableBoxList from "src/components/Lists/GenericExpandableBoxList";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import DocumentSignatureProgressStepper from "../Documents/DocumentSignatureProgressStepper";

export default function CompletedDocumentsList() {
  const alert = useAlert();
  const { id } = useParams();

  const { data: documents, isLoading } = useGetDocuments({ status: 2 });

  const handleDownloadDocument = async (doc: Document) => {
    if (doc && doc.newest_version) {
      try {
        const response = await fetch(doc.newest_version);
        if (!response.ok) {
          alert.error("Error downloading the document. Please try again.");
          return;
        }
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "Document.pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
      } catch (err) {
        console.error("Error downloading the document:", err);
      }
    }
  };

  return (
    <GenericExpandableBoxList<Document>
      items={documents || []}
      isLoading={isLoading}
      boxKey="id"
      boxTitleKey="title"
      placeholderText="No completed documents yet..."
      defaultExpandedKey="id"
      defaultExpandedValue={id}
      isSelected={(item) => String(item.id) === id}
      titleActions={(item) => (
        <Button
          id={String(item.id)}
          onClick={() => handleDownloadDocument(item)}
          color={ButtonColors.Transparent}
          tooltip="Download Document"
        >
          <MdOutlineFileDownload color={"#bbb"} />
        </Button>
      )}
      subtitle={(item) => <ListItemDate date={item.created_on} />}
      expandedContent={(document) => (
        <DocumentSignatureProgressStepper documentId={String(document.id)} />
      )}
      generateHref={(item) => `/e-signatures/documents/${item.id}`}
    />
  );
}
