import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useAlert } from "@blaumaus/react-alert";
import documentService from "../services/documentService";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import { useAppDispatch } from "src/store";
import { setModalStateAction } from "src/features/modal/handlers";
import { Document } from "../types/document.types";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

export default function useCreateDocumentMutation() {
  const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleErrors = useErrorHandling();
  const queryClient = useQueryClient();

  return useMutation(documentService.createDocument, {
    onSuccess: (data) => {
      alert.success("Document created successfully!");
      dispatch(setModalStateAction(null)(null)(false));
      if (!data?.self_assigned) {
        navigate(
          `/e-signatures/documents/${data.id}/assign/?documentsTab=Drafts`
        );
      } else {
        navigate(
          `/e-signatures/documents/${data.id}/sign?documentsTab=Pending`
        );
      }
      queryClient.setQueryData(
        [QueryKeys.DocumentsList, data.status],
        (prevData: Document[] | undefined) => {
          if (prevData) {
            return [data, ...prevData];
          }
          return [data];
        }
      );
    },
    onError: (error: AxiosError) => handleErrors(error),
  });
}
