import axiosClient from "src/features/axios/client";
import { AllocateEmployeeShift } from "../components/SelectEmployeeScheduleRoleDialog";
import { EmployeeScheduleRoleType } from "./employeeScheduleRolesService";
import { ShiftRequirementType } from "./shiftRequirementsService";

export enum EmployeeShiftStatus {
  Pending = "Pending",
  PendingCancellation = "PendingCancellation",
  PendingPickupApproval = "PendingPickupApproval",
  PendingSwapApproval = "PendingSwapApproval",
  Published = "Published",
  Cancelled = "Cancelled",
  PendingDrop = "PendingDrop",
  Dropped = "Dropped",
  PickedUp = "PickedUp",
  SwapRequested = "SwapRequested",
  SwapApproved = "SwapApproved",
  SwapDeclined = "SwapDeclined",
}

export type EmployeeShift = {
  id: number;
  status: EmployeeShiftStatus;
  employee_schedule_role: EmployeeScheduleRoleType;
  shift_requirement: ShiftRequirementType;
  created_by: number;
  created_on: string;
  updated_on: number;
  decision_at: string;
  decision_by: number;
};

type CreateEmployeeShift = {
  employee_schedule_role: number;
  shift_requirement: number;
  department: number;
};
class EmployeeShiftsService {
  async allocateEmployeeShift(
    data: AllocateEmployeeShift
  ): Promise<EmployeeShift> {
    return await axiosClient
      .post("/api/employee-shifts/allocate-shift/", {
        name: data.name,
        employee_id: data.employeeId,
        schedule_role_id: data.scheduleRoleId,
        department_id: data.departmentId,
        weekly_schedule_id: data.weeklyScheduleId,
        start_datetime: data.startDateTime,
        end_datetime: data.endDateTime,
      })
      .then((res) => res.data);
  }

  async cancelShiftTransfer(id: number): Promise<EmployeeShift> {
    return await axiosClient
      .post(`/api/employee-shifts/${id}/cancel-transfer/`)
      .then((res) => res.data);
  }

  async createEmployeeShift(data: CreateEmployeeShift): Promise<EmployeeShift> {
    return await axiosClient
      .post("/api/employee-shifts/", data)
      .then((res) => res.data);
  }

  async deleteEmployeeShift(id: number): Promise<void> {
    return await axiosClient
      .delete(`/api/employee-shifts/${id}`)
      .then((res) => res.data);
  }

  async dropEmployeeShift(id: number): Promise<EmployeeShift> {
    return await axiosClient
      .post(`/api/employee-shifts/${id}/drop-shift/`)
      .then((res) => res.data);
  }

  async getEmployeeShifts(
    scheduleRoleId: number,
    startDate?: string,
    status?: EmployeeShiftStatus,
    notEmployeeId?: number
  ): Promise<EmployeeShift[]> {
    return await axiosClient
      .get("/api/employee-shifts", {
        params: {
          schedule_role_id: scheduleRoleId,
          start_date: startDate,
          status: status,
          not_employee_id: notEmployeeId,
        },
      })
      .then((res) => res.data);
  }

  async getEmployeeShiftsByDepartmentId(
    departmentId: number,
    weeklyScheduleId: number,
    status?: EmployeeShiftStatus
  ): Promise<EmployeeShift[]> {
    return await axiosClient
      .get("/api/employee-shifts", {
        params: {
          department_id: departmentId,
          weekly_schedule_id: weeklyScheduleId,
          status: status,
        },
      })
      .then((res) => res.data);
  }

  async getEmployeeShiftsByEmployeeId(
    employeeId: number,
    weeklyScheduleStartDate: string
  ): Promise<EmployeeShift[]> {
    return await axiosClient
      .get("/api/employee-shifts", {
        params: {
          employee_id: employeeId,
          weekly_schedule_start_date: weeklyScheduleStartDate,
        },
      })
      .then((res) => res.data);
  }

  async getOpenShiftsByEmployeeScheduleRoles(
    employeeScheduleRoles: string
  ): Promise<EmployeeShift[]> {
    return await axiosClient
      .get("/api/employee-shifts/", {
        params: {
          employee_schedule_roles: employeeScheduleRoles,
          status: EmployeeShiftStatus.Dropped,
        },
      })
      .then((res) => res.data);
  }

  async partialUpdateEmployeeShift(
    data: Partial<EmployeeShift>
  ): Promise<EmployeeShift> {
    return await axiosClient
      .patch(`/api/employee-shifts/${data.id}/`, data)
      .then((res) => res.data);
  }
}

const employeeShiftsService = new EmployeeShiftsService();

export default employeeShiftsService;
