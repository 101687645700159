import axiosClient from "../axios/client";
import { IOrganization } from "./types/organization.types";

const createCompany = (company: IOrganization) =>
  axiosClient.post("/api/companies/", company);

// TODO: move into companies viewset
const getCompany = () => axiosClient.get(`/api/get-company/`);

// TODO: create action route
const getTenant = () => axiosClient.get("/api/get-tenant/");

const updateCompany = (id: string, company: IOrganization) =>
  axiosClient.put(`/api/companies/${id}/`, company);

// TODO: move to action route
const getRegistrationAccessible = () =>
  axiosClient.get("api/registration-accessible/");

const getOrganizationFeatures = async () =>
  await axiosClient.get("/api/features/").then((res) => res.data);

export {
  createCompany,
  getCompany,
  getOrganizationFeatures,
  getTenant,
  getRegistrationAccessible,
  updateCompany,
};
