import { DocumentAnnotation } from "../types/document.types";
import { AnnotationType } from "../types/template.types";

/**
 * Generates initial options for annotations based on their types.
 *
 * Iterates over a list of document annotations and creates an initial options
 * object where the keys are annotation IDs and the values depend on the annotation
 * type: `null` for Dropdown types, and an empty array `[]` for Checkbox types.
 *
 * @param {DocumentAnnotation[]} annotations - An array of document annotations.
 * @returns {Object} The initial options object with keys set to annotation IDs and
 *                   values initialized based on the annotation type.
 */
function generateInitialAnnotationOptions(
  annotations: DocumentAnnotation[]
): Record<string, any> {
  return annotations.reduce((acc, curr) => {
    if (curr.type === AnnotationType.Dropdown) {
      return { ...acc, [curr.id]: null };
    } else if (curr.type === AnnotationType.Checkbox) {
      return { ...acc, [curr.id]: [] };
    }
    return acc;
  }, {});
}

export default generateInitialAnnotationOptions;
