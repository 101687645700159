import { ForwardedRef, RefObject } from "react";
import { MutateFunction } from "@tanstack/react-query";

function createTimeTriggerAction(
  videoRef: RefObject<HTMLVideoElement> | ForwardedRef<HTMLVideoElement>,
  triggerFunction: MutateFunction<void, unknown, void, unknown> | any,
  videoLengthTriggered: boolean,
  setVideoLengthTriggered: (value: boolean) => void
) {
  let lastTime = 0;
  let watchedTime = 0;

  return () => {
    const currentVideo = (videoRef as RefObject<HTMLVideoElement>).current;
    if (currentVideo) {
      const currentTime = currentVideo.currentTime;
      const deltaTime = currentTime - lastTime;

      // The deltaTime condition is to prevent jumps in video to be counted
      if (deltaTime > 0 && deltaTime < 2) {
        watchedTime += deltaTime;
      }
      lastTime = currentTime;

      if (watchedTime / currentVideo.duration >= 0.9 && !videoLengthTriggered) {
        triggerFunction();
        setVideoLengthTriggered(true);
        lastTime = 0;
        watchedTime = 0;
      }
    }
  };
}

export default createTimeTriggerAction;
