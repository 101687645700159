import { memo } from "react";
import { Link } from "react-router-dom";

import EmployeeCardText from "src/components/Cards/EmployeeCardText";
import getFullName from "src/features/employees/utils/getFullName";
import styles from "./NavMenu.module.css";
import ImageV2 from "src/components/Images/ImageV2";
import { AuthenticatedEmployee } from "src/features/employees/types/employees.type";

type NavMenuEmployeeProfileProps = {
  employee: AuthenticatedEmployee;
};

function NavMenuEmployeeProfile({ employee }: NavMenuEmployeeProfileProps) {
  return (
    <Link to={`/employees/${employee.id}`}>
      <div className={styles.userContainer}>
        <div className={styles.userImageContainer}>
          <ImageV2
            loading={"lazy"}
            src={employee.image}
            alt="user"
            height={90}
            width={90}
            circle={true}
          />
        </div>
        {employee ? (
          <EmployeeCardText
            title={getFullName(employee)}
            subtitle={employee.role?.name}
          />
        ) : null}
      </div>
    </Link>
  );
}

export default memo(NavMenuEmployeeProfile);
