// import { useRef, useState } from "react";
// import { NavLink } from "react-router-dom";
// import { formatDistanceToNow } from "date-fns";
// import { truncate } from "lodash";
// import { useQuery } from "@tanstack/react-query";

// import styles from "./UnreadDiscussionsIndicator.module.css";
// import useOutsideClick from "src/features/page/hooks/useOutsideClick";
// import { getUnreadDiscussions } from "src/features/discussions/apis";
// import DiscussionsIcon from "src/components/Icons/DiscussionsIcon";
// import { IDiscussion } from "src/types/Discussions.types";
// import DropDownV2 from "src/components/DropDowns/DropDownV2";
// import DescriptionText from "src/components/Text/DescriptionText";
// import DropDownSection from "src/components/DropDowns/DropDownSection";
// import DropDownContainer from "src/components/DropDowns/DropDownContainer";
// import {
//   COMMENTS_UNVIEWED,
//   QueryKeys.UnreadDiscussions,
// } from "src/features/reactQuery/queryKeys";
// import ListItemText from "src/components/Lists/ListItemText";
// import {
//   ROUTE_DISCUSSIONS,
//   ROUTE_MEMOS,
//   ROUTE_VIDEOS,
// } from "src/features/site/routes";
// import commentsService from "src/features/comments/services/commentsService";

export default function UnreadDiscussionsIndicator() {
  // const [isComponentVisible, setIsComponentVisible] = useState(false);
  // const ref = useRef<HTMLButtonElement>(null);

  // const { data: unreadDiscussions } = useQuery({
  //   queryKey: [QueryKeys.UnreadDiscussions],
  //   queryFn: getUnreadDiscussions,
  // });

  // const { data: unviewedComments } = useQuery({
  //   queryKey: [COMMENTS_UNVIEWED],
  //   queryFn: commentsService.getUnviewedComments,
  // });

  // useOutsideClick(ref, () => {
  //   if (isComponentVisible) handleCloseDropDown();
  // });

  // const handleCloseDropDown = () => setIsComponentVisible(false);

  return (
    <>
      {/* {(unreadDiscussions && unreadDiscussions?.length > 0) ||
      unviewedComments?.length > 0 ? (
        <DropDownV2
          icon={<FlashingIndicatorIcon />}
          btnClassName={styles.indicatorBtn}
          isFilterVisible={isComponentVisible}
          setIsFilterVisible={setIsComponentVisible}
        >
          <DropDownContainer>
            <DropDownSection>
              {unreadDiscussions?.length === 0 &&
              unviewedComments?.length === 0 ? (
                <DescriptionText>
                  You have no unread discussions or comments.
                </DescriptionText>
              ) : null}
              {unviewedComments?.length > 0
                ? unviewedComments.map(
                    ({
                      id,
                      object_id,
                      comment,
                      created_on,
                      content_type,
                    }: any) => {
                      return (
                        <NavLink
                          to={`${
                            content_type === "memo" ? ROUTE_MEMOS : ROUTE_VIDEOS
                          }/${object_id}/`}
                          state={{ scrollToId: id }}
                          key={object_id}
                          className={styles.notificationLink}
                          onClick={handleCloseDropDown}
                        >
                          <ListItemText
                            title={comment}
                            subTitle={truncate(created_on, {
                              length: 20,
                            })}
                          />
                        </NavLink>
                      );
                    }
                  )
                : null}
              {unreadDiscussions?.length > 0
                ? unreadDiscussions.map(
                    ({ title, updated_on, uuid, id }: IDiscussion) => {
                      return (
                        <NavLink
                          to={`${ROUTE_DISCUSSIONS}/${uuid}/`}
                          key={id}
                          className={styles.notificationLink}
                          onClick={handleCloseDropDown}
                        >
                          <ListItemText
                            title={title}
                            subTitle={truncate(
                              formatDistanceToNow(new Date(updated_on), {
                                addSuffix: true,
                              }),
                              {
                                length: 20,
                              }
                            )}
                          />
                        </NavLink>
                      );
                    }
                  )
                : null}
            </DropDownSection>
          </DropDownContainer>
        </DropDownV2>
      ) : null} */}
    </>
  );
}

// function FlashingIndicatorIcon() {
//   return (
//     <>
//       <DiscussionsIcon />
//       <div className={styles.count} />
//     </>
//   );
// }
