import { useAlert } from "@blaumaus/react-alert";
import { format } from "date-fns";
import { useMemo } from "react";
import AnimatedNumbers from "react-animated-numbers";
import {
  MdAttachMoney,
  MdSchedule,
  MdSwapHoriz,
  MdWarning,
} from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import Badge from "src/components/Badges/Badge";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import Card from "src/components/Cards/Card";
import CardHeader from "src/components/Cards/CardHeader";
import CardHeaderAction from "src/components/Cards/CardHeaderAction";
import SquareContainer from "src/components/Container/SquareContainer";
import ErrorFallback from "src/components/Errors/ErrorFallback";
import Header from "src/components/Headers/Header";
import Placeholder from "src/components/Placeholder/Placeholder";
import ProgressBar from "src/components/ProgressBar/ProgressBar";
import { config1 } from "src/features/animatedNumbers/configs";
import useAuth from "src/features/auth/hooks/useAuth";
import hasGroupPermissions from "src/features/auth/utils/hasGroupPermissions";
import DiscussionContainer from "src/features/discussions/components/DiscussionContainer";
import { ORGANIZATION_CRUD_GROUP } from "src/features/groups/groups";
import useGetLaborBudgetsByStartAndEndDateQuery from "../hooks/useGetLaborBudgetsByStartAndEndDateQuery";
import useGetOrCreateLaborBudgetSettingsQuery from "../hooks/useGetOrCreateLaborBudgetSettingsQuery";
import useGetShiftConflictsByDateQuery from "../hooks/useGetShiftConflictsByDateQuery";
import useGetShiftRequirementsByDatesQuery from "../hooks/useGetShiftRequirementsByDatesQuery";
import useGetShiftTransfersByDatesQuery from "../hooks/useGetShiftTransfersByStartDate";
import useGetTimeOffRequestsByStartDateQuery from "../hooks/useGetTimeOffRequestsByStartDateQuery";
import useScheduleSetupStateQuery from "../hooks/useScheduleSetupStateQuery";
import useWeekFromUrl from "../hooks/useWeekFromUrl";
import { ScheduleSetupState } from "../services/scheduleSetupService";
import { ShiftConflictResolutionStatus } from "../services/shiftConflictsService";
import {
  ShiftTransferStatus,
  ShiftTransferType,
} from "../services/shiftTransferService";
import { TimeOffStatus } from "../services/timeOffRequestService";
import { getShiftTransferBadgeColor } from "../utils/getShiftTransferColorBadge";
import SchedulesWeekNavigation from "./SchedulesWeekNavigation";

const cardStyle = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  aspectRatio: 1,
} as const;

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

type ShiftDay = {
  filled: number;
  required: number;
};

export default function SchedulesSupervisorDashboard() {
  const alert = useAlert();
  const navigate = useNavigate();
  const { employee } = useAuth();
  const { startOfWeekDate, endOfWeekDate, handleNavigateWeek, weekDateTitle } =
    useWeekFromUrl();

  const {
    data: scheduleSetupStates,
    isLoading: isScheduleSetupStatesLoading,
    isError: isScheduleSetupStatesError,
    refetch: refetchScheduleSetupStates,
  } = useScheduleSetupStateQuery();

  const {
    data: timeOffRequests,
    isLoading: isTimeOffRequestsLoading,
    isError: isTimeOffRequestsError,
    refetch: refetchTimeOffRequests,
  } = useGetTimeOffRequestsByStartDateQuery({
    startDate: startOfWeekDate.toISOString().slice(0, 10),
    status: TimeOffStatus.Pending,
  });

  const {
    data: shiftRequirements,
    isLoading: isShiftRequirementsLoading,
    isError: isShiftRequirementsError,
    refetch: refetchShiftRequirements,
  } = useGetShiftRequirementsByDatesQuery({
    startDate: startOfWeekDate.toISOString().slice(0, 10),
    endDate: endOfWeekDate.toISOString().slice(0, 10),
  });

  const {
    data: laborBudgets,
    isLoading: isLaborBudgetsLoading,
    isError: isLaborBudgetsError,
    refetch: refetchLaborBudgets,
  } = useGetLaborBudgetsByStartAndEndDateQuery({
    startDate: startOfWeekDate.toISOString().slice(0, 10),
    endDate: endOfWeekDate.toISOString().slice(0, 10),
  });

  const {
    data: shiftTransfers,
    isLoading: isShiftTransfersLoading,
    isError: isShiftTransfersError,
    refetch: refetchShiftTransfers,
  } = useGetShiftTransfersByDatesQuery({
    startDate: startOfWeekDate.toISOString().slice(0, 10),
    status: ShiftTransferStatus.Pending,
  });

  const {
    data: shiftConflicts,
    isLoading: isShiftConflictsLoading,
    isError: isShiftConflictsError,
    refetch: refetchShiftConflicts,
  } = useGetShiftConflictsByDateQuery({
    startDate: startOfWeekDate.toISOString().slice(0, 10),
    resolutionStatus: ShiftConflictResolutionStatus.Unresolved,
  });

  const {
    data: openShifts,
    isLoading: isOpenShiftsLoading,
    isError: isOpenShiftsError,
    refetch: refetchOpenShifts,
  } = useGetShiftTransfersByDatesQuery({
    startDate: startOfWeekDate.toISOString().slice(0, 10),
    type: ShiftTransferType.ShiftDrop,
    status: ShiftTransferStatus.Available,
  });

  const { data: laborBudgetSettings, isLoading: isLaborBudgetSettingsLoading } =
    useGetOrCreateLaborBudgetSettingsQuery({});

  const combinedLaborBudgetAmounts = useMemo(() => {
    return laborBudgets?.reduce(
      (acc, curr) => acc + Number(curr.scheduled_amount),
      0
    );
  }, [laborBudgets]);

  const groupShiftsByDay = (): Record<string, ShiftDay> => {
    const grouped: Record<string, ShiftDay> = {};

    if (shiftRequirements) {
      shiftRequirements.forEach((shift) => {
        const dayOfWeek: string = format(
          new Date(shift.start_datetime),
          "EEEE"
        );

        if (!grouped[dayOfWeek]) {
          grouped[dayOfWeek] = { filled: 0, required: 0 };
        }

        grouped[dayOfWeek].filled += shift.filled_count;
        grouped[dayOfWeek].required += shift.required_count;
      });
    }

    return grouped;
  };

  const shiftsByDay = groupShiftsByDay();

  const handleOpenShiftsFilledDialog = (day: string) => {
    return;
  };

  const handleNavigateToTimeOffRequests = () => {
    navigate(
      `/schedules/time-off-requests?status=${
        TimeOffStatus.Pending
      }&startOfWeekDate=${startOfWeekDate.toISOString().slice(0, 10)}`
    );
  };

  const handleNavigateToShiftTransfers = () => {
    navigate(
      `/schedules/shift-transfers?status=${
        ShiftTransferStatus.Pending
      }&startOfWeekDate=${startOfWeekDate.toISOString().slice(0, 10)}`
    );
  };

  const handleNavigateToShiftConflicts = () => {
    navigate(
      `/schedules/shift-conflicts?startOfWeekDate=${startOfWeekDate
        .toISOString()
        .slice(0, 10)}`
    );
  };

  const handleNavigateToLaborBudget = () => {
    if (
      (laborBudgetSettings && !laborBudgetSettings?.hide_labor_budget) ||
      hasGroupPermissions(employee, ORGANIZATION_CRUD_GROUP)
    ) {
      navigate(
        `/schedules/labor-budget?startOfWeekDate=${startOfWeekDate
          .toISOString()
          .slice(0, 10)}`
      );
    } else {
      alert.error("You do not have permission to view labor budgets.");
    }
  };

  const isLoading =
    isScheduleSetupStatesLoading ||
    isShiftRequirementsLoading ||
    isLaborBudgetsLoading ||
    isShiftTransfersLoading ||
    isShiftConflictsLoading ||
    isTimeOffRequestsLoading ||
    isOpenShiftsLoading ||
    isLaborBudgetSettingsLoading;

  if (isLoading) {
    return (
      <div className="grid-two-column">
        {isLoading &&
          Array.from({ length: 6 }).map((_, index) => (
            <Card key={index} className="grid-item-square p-10">
              <Skeleton height={160} />
            </Card>
          ))}
      </div>
    );
  }

  if (
    !isLoading &&
    scheduleSetupStates &&
    scheduleSetupStates[0].state !== ScheduleSetupState.Completed
  ) {
    return (
      <DiscussionContainer>
        <div className="centered gap-20">
          <Placeholder title="To use the scheduler, please sync roles and employees." />
          <Button
            color={ButtonColors.Yellow}
            onClick={() => navigate("/register/schedules")}
          >
            Sync Roles and Employees
          </Button>
        </div>
      </DiscussionContainer>
    );
  }

  if (isScheduleSetupStatesError) {
    return (
      <Card className="centered min-h-100vh-90">
        <ErrorFallback
          error={
            "Oops! An error occurred! It has been reported and is being investigated."
          }
          onReload={refetchScheduleSetupStates}
        />
      </Card>
    );
  }

  return (
    <div>
      {scheduleSetupStates &&
      scheduleSetupStates[0].state !== ScheduleSetupState.Completed ? (
        <Button
          color={ButtonColors.Yellow}
          onClick={() => navigate("/register/schedules")}
        >
          Set Up Schedules Now
        </Button>
      ) : (
        <div className="flex gap-10">
          <Card>
            <SchedulesWeekNavigation
              onNavigateWeek={handleNavigateWeek}
              weekDateTitle={weekDateTitle}
            />
          </Card>
          <div className="grid-2x1">
            <div className="grid-2x1-rows">
              {isShiftTransfersError ? (
                <Card className="h-full centered">
                  <ErrorFallback
                    error={
                      "Oops! An error occurred! It has been reported and is being investigated."
                    }
                    onReload={refetchShiftTransfers}
                  />
                </Card>
              ) : null}
              {!isShiftTransfersError ? (
                <Card
                  className="h-full hover-bg-white-20"
                  onClick={handleNavigateToShiftTransfers}
                >
                  <Header id="shifts-header" title="Shift Transfers">
                    <MdSchedule size={24} color="#bbb" />
                  </Header>
                  <div className="flex p-10">
                    <CardHeaderAction style={{ fontSize: "3rem" }}>
                      <AnimatedNumbers
                        animateToNumber={shiftTransfers?.length || 0}
                        configs={config1}
                      />
                    </CardHeaderAction>
                    <span className="my-10 text-muted">Need Approval</span>
                  </div>
                </Card>
              ) : null}
              {isShiftConflictsError ? (
                <Card className="h-full centered">
                  <ErrorFallback
                    error={
                      "Oops! An error occurred! It has been reported and is being investigated."
                    }
                    onReload={refetchShiftConflicts}
                  />
                </Card>
              ) : null}
              {!isShiftConflictsError ? (
                <Card
                  className="h-full hover-bg-white-20"
                  onClick={handleNavigateToShiftConflicts}
                >
                  <Header id="shifts-header" title="Conflicts">
                    <MdWarning size={24} color="#bbb" />
                  </Header>
                  <div className="flex p-10">
                    <CardHeaderAction style={{ fontSize: "3rem" }}>
                      <AnimatedNumbers
                        animateToNumber={
                          isShiftConflictsLoading
                            ? 0
                            : shiftConflicts
                            ? shiftConflicts?.length
                            : 0
                        }
                        configs={config1}
                      />
                    </CardHeaderAction>
                    <span className="my-10 text-muted">Require Attention</span>
                  </div>
                </Card>
              ) : null}
            </div>
            <div className="grid-2x1-rows">
              {isTimeOffRequestsError && (
                <Card className="h-full centered">
                  <ErrorFallback
                    error={
                      "Oops! An error occurred! It has been reported and is being investigated."
                    }
                    onReload={refetchTimeOffRequests}
                  />
                </Card>
              )}
              {!isTimeOffRequestsError ? (
                <Card
                  className="h-full hover-bg-white-20"
                  onClick={handleNavigateToTimeOffRequests}
                >
                  <Header id="shifts-header" title="Time-Off Requests">
                    <MdSwapHoriz size={24} color="#bbb" />
                  </Header>
                  <div className="flex p-10">
                    <CardHeaderAction style={{ fontSize: "3rem" }}>
                      <AnimatedNumbers
                        animateToNumber={
                          isTimeOffRequestsLoading
                            ? 0
                            : timeOffRequests
                            ? timeOffRequests?.length
                            : 0
                        }
                        configs={config1}
                      />
                    </CardHeaderAction>
                    <span className="my-10 text-muted">Need Approval</span>
                  </div>
                </Card>
              ) : null}
              {isLaborBudgetsError ? (
                <Card className="h-full centered">
                  <ErrorFallback
                    error={
                      "Oops! An error occurred! It has been reported and is being investigated."
                    }
                    onReload={refetchLaborBudgets}
                  />
                </Card>
              ) : null}
              {!isLaborBudgetsError ? (
                <Card
                  className="h-full hover-bg-white-20"
                  onClick={handleNavigateToLaborBudget}
                >
                  <Header id="shifts-header" title="Labor Budget">
                    <MdAttachMoney size={24} color="#bbb" />
                  </Header>
                  <div className="flex p-10">
                    <CardHeaderAction
                      style={{
                        fontSize: "3rem",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {(laborBudgetSettings &&
                        !laborBudgetSettings?.hide_labor_budget) ||
                      hasGroupPermissions(employee, ORGANIZATION_CRUD_GROUP) ? (
                        <>
                          $
                          <AnimatedNumbers
                            animateToNumber={combinedLaborBudgetAmounts || 0}
                            configs={config1}
                          />
                        </>
                      ) : (
                        <span>-</span>
                      )}
                    </CardHeaderAction>
                    <span className="my-10 text-muted">This Week</span>
                  </div>
                </Card>
              ) : null}
            </div>
          </div>
          <div className="grid-2x1">
            <Card style={cardStyle}>
              <SquareContainer>
                <CardHeader title="Shifts Filled" />
                <div className="flex flex-col p-10 justify-between flex-1 py-20 overflow-y-auto">
                  {isShiftRequirementsError ? (
                    <ErrorFallback
                      error={
                        "Oops! An error occurred! It has been reported and is being investigated."
                      }
                      onReload={refetchShiftRequirements}
                    />
                  ) : null}
                  {daysOfWeek.map((day) => {
                    // Calculate progress. if days are overfilled, progress will be 100%
                    const progress =
                      shiftsByDay[day] && shiftsByDay[day].required > 0
                        ? (shiftsByDay[day].filled /
                            shiftsByDay[day].required) *
                          100
                        : 0;
                    const count = shiftsByDay[day]
                      ? `${shiftsByDay[day].filled}/${shiftsByDay[day].required}`
                      : "0/0";
                    return (
                      <div
                        key={day}
                        className="flex flex-row gap-10 align-center px-10 p-5 border-radius-5"
                        onClick={() => handleOpenShiftsFilledDialog(day)}
                      >
                        <div className="w-80">
                          <span className="text-muted">{day}</span>
                        </div>
                        <div className="flex-1">
                          {isShiftRequirementsLoading
                            ? Array.from({ length: 7 }).map((_, i) => (
                                <Skeleton height={20} key={i} />
                              ))
                            : null}
                          {!isShiftRequirementsLoading ? (
                            <ProgressBar
                              progress={progress > 100 ? 100 : progress}
                              progressClassName={
                                progress > 100
                                  ? "bg-cp-danger"
                                  : progress === 100
                                  ? "bg-cp-green"
                                  : ""
                              }
                            />
                          ) : null}
                        </div>
                        <div className="w-40 text-right">
                          <span className="text-muted">
                            {!isShiftRequirementsLoading && count}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </SquareContainer>
            </Card>
            <Card style={cardStyle}>
              <SquareContainer>
                <CardHeader title="Open Shifts" />
                <div className="flex flex-1 gap-10 overflow-y-auto p-10">
                  {isOpenShiftsError ? (
                    <ErrorFallback
                      error={
                        "Oops! An error occurred! It has been reported and is being investigated."
                      }
                      onReload={refetchOpenShifts}
                    />
                  ) : null}
                  {isOpenShiftsLoading
                    ? Array.from({ length: 2 }).map((_, i) => (
                        <Skeleton height={100} key={i} />
                      ))
                    : null}
                  {!isOpenShiftsLoading &&
                    openShifts &&
                    openShifts.length === 0 && (
                      <div className="flex flex-1 centered">
                        <Placeholder title="No Open Shifts" />
                      </div>
                    )}
                  {!isOpenShiftsLoading &&
                    openShifts &&
                    openShifts.length > 0 &&
                    openShifts.map((shift) => (
                      <div
                        key={shift.id}
                        className="flex darkListItemContainer mh-unset h-unset cursor-pointer p-10 border-radius-10 gap-10"
                        onClick={() =>
                          navigate(`/schedules/shift-transfers/${shift.id}`)
                        }
                      >
                        <div className="flex flex-row align-center justify-between">
                          <Badge
                            color={getShiftTransferBadgeColor(shift.status)}
                            text={shift.status}
                          />
                          <span className="text-xxs text-muted">
                            {new Date(shift.updated_on).toLocaleDateString()}
                          </span>
                        </div>
                        <div className="flex flex-col gap-5">
                          <div className="flex flex-row gap-10 align-center">
                            <span className="text-sm">
                              {shift.shift_from.shift_requirement.name}
                            </span>
                          </div>
                          <div className="flex flex-row gap-10 align-center">
                            <span className="text-sm">
                              {format(
                                new Date(
                                  shift.shift_from.shift_requirement.start_datetime
                                ),
                                "PP"
                              )}
                            </span>
                          </div>
                          <div className="flex flex-row gap-10 align-center">
                            <span className="text-xs">
                              {format(
                                new Date(
                                  shift.shift_from.shift_requirement.start_datetime
                                ),
                                "p"
                              )}{" "}
                              -{" "}
                              {format(
                                new Date(
                                  shift.shift_from.shift_requirement.end_datetime
                                ),
                                "p"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </SquareContainer>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
}
