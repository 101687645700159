import { useEffect } from "react";
import { useAppDispatch } from "src/store";
import { setPageHeader } from "../headerSlice";

function useSetHeaderTitle(title: string) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setPageHeader(title));
  }, [dispatch, title]);
}

export default useSetHeaderTitle;
