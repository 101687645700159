import { useMutation } from "@tanstack/react-query";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import employeeShiftsService, {
  EmployeeShift,
} from "../services/employeeShiftsService";

type CancelShiftTransferMutationProps = {
  onSuccess: (data: EmployeeShift) => void;
};

export default function useCancelShiftTransferMutation({
  onSuccess,
}: CancelShiftTransferMutationProps) {
  const handleErrors = useErrorHandling();

  return useMutation({
    mutationFn: employeeShiftsService.cancelShiftTransfer,
    onSuccess,
    onError: handleErrors,
  });
}
