import { useEffect } from "react";

import usePolicyFormState from "../../hooks/usePolicyFormState";
import { useAppDispatch } from "src/store";
import { PolicyFormSteps, setPolicyFormStep } from "../../slices/policySlice";
import {
  ScheduledTodosFormSteps,
  setScheduledTodosFormStep,
} from "src/features/todos/todosSlice";
import useVerticalStepperContent from "src/components/Steps/hooks/useVerticalStepperContent";
import StepEditButton from "src/features/learningPaths/components/LPBuilder/Steps/StepEditButton";
import StepFourYellowIcon from "src/components/Icons/StepFourYellowIcon";
import CheckCircleYellowIcon from "src/components/Icons/CheckCircleYellowIcon";
import StepFourGrayIcon from "src/components/Icons/StepFourGrayIcon";
import StepContainer from "src/components/Steps/StepContainer";
import PolicyScheduleForm from "./PolicyScheduleForm";

export default function PolicyFormStepFour() {
  const { step } = usePolicyFormState();
  const dispatch = useAppDispatch();

  const handleStepButtonClick = () =>
    dispatch(setPolicyFormStep(PolicyFormSteps.SchedulePolicy));

  useEffect(() => {
    dispatch(
      setScheduledTodosFormStep(ScheduledTodosFormSteps.ListScheduledTodos)
    );
  }, [dispatch]);

  return useVerticalStepperContent({
    currentStep: step,
    activeStep: PolicyFormSteps.SchedulePolicy,
    titleAction:
      step !== PolicyFormSteps.AddDetails &&
      step !== PolicyFormSteps.UploadPolicy &&
      step !== PolicyFormSteps.AssignViewableBy &&
      step !== PolicyFormSteps.SchedulePolicy ? (
        <StepEditButton onClick={handleStepButtonClick} />
      ) : null,
    activeIcon: <StepFourYellowIcon />,
    inactiveIcon:
      step === PolicyFormSteps.PublishPolicy ? (
        <CheckCircleYellowIcon />
      ) : (
        <StepFourGrayIcon />
      ),
    defaultContent: (
      <StepContainer>
        {step !== PolicyFormSteps.AddDetails &&
        step !== PolicyFormSteps.UploadPolicy &&
        step !== PolicyFormSteps.AssignViewableBy &&
        step !== PolicyFormSteps.SchedulePolicy ? (
          <span className="text-muted">This step is complete!</span>
        ) : (
          <span className="text-muted">
            Assign "To-Do" tasks related to this policy to specific employees,
            roles, or departments. These tasks must be completed by the
            designated recipients. Please note that scheduled tasks will only be
            dispatched once the policy is published.
          </span>
        )}
      </StepContainer>
    ),
    activeContent: <PolicyScheduleForm />,
  });
}
