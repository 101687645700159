import React from "react";

type DialogDescriptionProps = {
  children?: React.ReactNode;
};

export default function DialogDescription({
  children,
}: DialogDescriptionProps) {
  return <p className="dialog-description">{children}</p>;
}
