import React from "react";
import { TemplateAnnotation } from "../../types/template.types";
import { DocumentAnnotation } from "../../types/document.types";
import { selectAnnotation } from "../../slices/templateAnnotationsSlice";
import { useAppDispatch } from "src/store";

type AnnotationCheckboxesProps = {
  annotation: TemplateAnnotation | DocumentAnnotation;
  selectedValues?: string | string[];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
};

const AnnotationCheckboxes: React.FC<AnnotationCheckboxesProps> = ({
  annotation,
  selectedValues = [],
  onChange,
  className,
}) => {
  const dispatch = useAppDispatch();
  // Guard clause for missing or empty options
  if (!annotation.options || annotation.options.length === 0) {
    return null; // or some fallback UI
  }

  const handleClickCheckboxes = (e: React.MouseEvent) => {
    dispatch(selectAnnotation(annotation.uuid));
  };

  return (
    <div
      className={className || "document-annotation-checkboxes"}
      onClick={handleClickCheckboxes}
      style={{
        top: annotation.y,
        left: annotation.x,
        width: annotation.width,
        height: annotation.height,
      }}
    >
      {annotation.options.map((option) => (
        <div
          key={option.value}
          style={{ display: "flex", alignItems: "center" }}
        >
          <input
            id={`checkbox-${option.value}`}
            type="checkbox"
            value={option.value}
            checked={selectedValues?.includes(option.value)}
            onChange={onChange}
            aria-label={option.value}
          />
          <label
            htmlFor={`checkbox-${option.value}`}
            style={{ marginLeft: "2px" }}
          >
            {option.value}
          </label>
        </div>
      ))}
    </div>
  );
};

export default AnnotationCheckboxes;
