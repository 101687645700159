import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={39}
    height={39}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={1.086}
      y={1}
      width={36.914}
      height={36.914}
      rx={18.457}
      fill="#F4B90D"
    />
    <path
      d="M16.486 25.29H22.6c1.61 0 2.252-.986 2.328-2.187l.303-4.819a2.19 2.19 0 0 0-2.187-2.327.958.958 0 0 1-.846-.52l-.42-.845c-.268-.531-.968-.969-1.563-.969h-1.336c-.601 0-1.301.438-1.57.969l-.42.846a.959.959 0 0 1-.845.519 2.19 2.19 0 0 0-2.188 2.327l.304 4.819c.07 1.201.717 2.187 2.327 2.187ZM18.668 17.123h1.75"
      stroke="#292D32"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.543 22.957a1.9 1.9 0 0 0 1.896-1.896 1.9 1.9 0 0 0-1.896-1.896 1.9 1.9 0 0 0-1.895 1.896 1.9 1.9 0 0 0 1.895 1.896Z"
      stroke="#292D32"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={1.086}
      y={1}
      width={36.914}
      height={36.914}
      rx={18.457}
      stroke="#232227"
      strokeWidth={2}
    />
  </svg>
)

export default SvgComponent
