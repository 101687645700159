import { useAlert } from "@blaumaus/react-alert";
import { useQueryClient } from "@tanstack/react-query";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib-incremental-save";
import React, { useEffect, useRef } from "react";
import { BsCloudUpload } from "react-icons/bs";
import { RotatingLines } from "react-loader-spinner";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import { v4 as uuidv4 } from "uuid";

import Button from "src/components/Buttons/Button";
import {
  ButtonColors,
  ButtonSizes,
} from "src/components/Buttons/buttons.types";
import Card from "src/components/Cards/Card";
import Container from "src/components/Container/Container";
import Dialog from "src/components/Dialog/Dialog";
import DialogContent from "src/components/Dialog/DialogContent";
import DialogDescription from "src/components/Dialog/DialogDescription";
import DialogFooter from "src/components/Dialog/DialogFooter";
import DialogHeader from "src/components/Dialog/DialogHeader";
import SquareCheckboxInput from "src/components/Inputs/SquareCheckboxInput";
import embedImageOnPdf from "src/features/pdf/utils/embedImageOnPdf";
import fetchAndConvertToBlob from "src/features/pdf/utils/fetchAndConvertToBlob";
import fetchPdfBytes from "src/features/pdf/utils/fetchPdfBytes";
import loadPdfDocument from "src/features/pdf/utils/loadPdfDocument";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import useUploadFileToS3Mutation from "src/features/s3/hooks/useUploadFileToS3Mutation";
import s3Service from "src/features/s3/services/s3Service";
import { useAppDispatch } from "src/store";
import useDocumentSignForm from "../../hooks/useDocumentSignForm";
import useDocumentViewer from "../../hooks/useDocumentViewer";
import useGetDocument from "../../hooks/useGetDocument";
import useSignDocumentMutation from "../../hooks/useSignDocumentMutation";
import { setSignature } from "../../slices/documentAnnotationsSlice";
import { setPageNumber } from "../../slices/documentViewerSlice";
import { DocumentAnnotation } from "../../types/document.types";
import { AnnotationType, TemplateAnnotation } from "../../types/template.types";
import checkAllOptionsHaveValues from "../../utils/checkAllAnnotationOptionsHaveValues";
import generateInitialAnnotationOptions from "../../utils/generateInitialAnnoationOptions";
import updateCheckboxAnnotationOptions from "../../utils/updateCheckboxAnnotationOptions";
import AnnotationCheckboxes from "../Annotations/AnnotationCheckboxes";
import AnnotationDropdown from "../Annotations/AnnotationDropdown";
import ClickableAnnotation from "../Annotations/ClickableAnnotation";
import EditableTextFieldAnnotation from "../Annotations/EditableTextFieldAnnotation";
import styles from "../ESignatures.module.css";
import VerticalDocumentViewer from "../Viewers/VerticalDocumentViewer";
import DocumentCardHeader from "./DocumentCardHeader";
import DocumentSignToolbar from "./DocumentSignToolbar";

const getClickableAnnoationText = (annotation: DocumentAnnotation) => {
  switch (annotation.type) {
    case AnnotationType.Signature:
      return "Click to add Signature";
    case AnnotationType.TextField:
      return "Click to add Text";
    case AnnotationType.Image:
      return "Click to add Image";
    default:
      return "";
  }
};

const AGREE_TO_TERMS =
  "By checking this box, I understand and agree that I am signing this document digitally. I understand that my electronic signature is the equivalent of my handwritten signature.";

export type OptionsType = Record<string, string | string[]>;

export default function DocumentSignForm() {
  const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const pageRefs = useRef<(HTMLDivElement | null)[]>([]);
  const { containerSize } = useDocumentViewer();
  const imageRef = useRef<HTMLInputElement>(null);

  const {
    agreeToTerms,
    annotationOptions,
    annotations,
    areAllComplete,
    completed,
    image,
    isImageDialogOpen,
    isSignatureDialogOpen,
    isSignatureRequired,
    selectedAnnotation,
    sigCanvasRef,
    signature,
    scrollRef,
    pdfUrl,
    unsignedAnns,
    setAgreeToTerms,
    setAnnotationOptions,
    setCompleted,
    setImage,
    setIsImageDialogOpen,
    setIsSignatureDialogOpen,
    setPdfUrl,
    setSelectedAnnotation,
  } = useDocumentSignForm();

  const { data: document, isLoading: isDocumentLoading } = useGetDocument();

  const signDocumentMutation = useSignDocumentMutation({
    onSuccess: (data) => {
      queryClient.setQueryData([QueryKeys.Document, String(data.id)], data);
      queryClient.invalidateQueries([QueryKeys.DocumentsList]);
      alert.success("Document saved successfully!");
      navigate(
        `/e-signatures?documentsTab=${
          data.approval_required_by || data.status === 1
            ? "Pending"
            : "Completed"
        }`
      );
    },
  });

  const scrollToNextAnnotation = (annotationId: string) => {
    const currentAnnotation = annotations.find((a) => a.id === annotationId);
    if (!currentAnnotation) return;

    const { width: containerWidth, height: ContainerHeight } = containerSize;
    if (!containerWidth || !ContainerHeight) return;

    const scrollToPosition = (
      annotation: DocumentAnnotation,
      pageRef: HTMLDivElement | null
    ) => {
      if (!annotation || !pageRef) return;

      const isOnLeftThird = annotation.x < containerWidth / 3;
      const isOnRightThird =
        annotation.x + annotation.width > (containerWidth / 3) * 2;

      const isOnTopThird = annotation.y < ContainerHeight / 3;
      const isOnBottomThird =
        annotation.y + annotation.height > (ContainerHeight / 3) * 2;

      pageRef.scrollIntoView({
        behavior: "smooth",
        block: isOnTopThird ? "start" : isOnBottomThird ? "end" : "center",
        inline: isOnLeftThird ? "start" : isOnRightThird ? "end" : "center",
      });
    };

    // Find the next incomplete annotation, ordered by page, then by y, then by x
    const nextAnnotation = annotations
      .filter((a) => !completed.includes(a.id) && a.id !== annotationId)
      .sort((a, b) => {
        if (a.page !== b.page) return a.page - b.page;
        if (a.y !== b.y) return a.y - b.y;
        return a.x - b.x;
      })[0];

    if (nextAnnotation) {
      setTimeout(() => {
        dispatch(setPageNumber(nextAnnotation.page));

        setTimeout(() => {
          const nextPageRef = pageRefs.current[nextAnnotation.page - 1];
          scrollToPosition(nextAnnotation, nextPageRef);
        }, 300);
      }, 600);
    }
  };

  const handleClickAnnotation = async (e: React.MouseEvent<HTMLDivElement>) => {
    const { id } = e.currentTarget;
    const annotation = annotations.find((a) => String(a.id) === id);
    if (!annotation || !pdfUrl) return;

    switch (annotation.type) {
      case AnnotationType.TextField:
        setSelectedAnnotation(annotation);
        break;
      case AnnotationType.Image:
        setIsImageDialogOpen(true);
        setSelectedAnnotation(annotation);
        break;
      case AnnotationType.Signature:
        if (!signature && isSignatureRequired) {
          setIsSignatureDialogOpen(true);
          setSelectedAnnotation(annotation);
          return;
        } else {
          handleAddSignatureToDocument(annotation, signature);
        }
    }
  };

  const handleAddSignatureToDocument = async (
    annotation: DocumentAnnotation,
    signature: string | null
  ) => {
    if (!pdfUrl || !signature) return;
    try {
      const signatureBlob = await fetchAndConvertToBlob(signature);
      const pdfBytes = await embedImageOnPdf(pdfUrl, signatureBlob, annotation);
      const updatedPdfBlob = new Blob([pdfBytes], {
        type: "application/pdf",
      });
      setPdfUrl(URL.createObjectURL(updatedPdfBlob));
      dispatch(setPageNumber(annotation.page));
      setCompleted((prev) => [...prev, annotation.id]);
      scrollToNextAnnotation(annotation.id);
    } catch (error) {
      alert.error("Error updating the PDF with signature");
    }
  };

  const handleSaveSignature = () => {
    if (sigCanvasRef.current) {
      dispatch(setSignature(sigCanvasRef.current.toDataURL()));
      setIsSignatureDialogOpen(false);
      handleAddSignatureToDocument(
        selectedAnnotation!!,
        sigCanvasRef.current.toDataURL()
      );
    }
  };

  const handleAgreeToTerms = () => {
    if (sigCanvasRef.current?.isEmpty()) {
      alert.error("Please add your signature before agreeing to the terms.");
      return;
    }
    setAgreeToTerms(!agreeToTerms);
  };

  const handleCloseSignatureDialog = () => {
    setIsSignatureDialogOpen(false);
    setAgreeToTerms(false);
    sigCanvasRef.current?.clear();
  };

  const handleSaveText = async (
    e: React.FocusEvent<HTMLDivElement>,
    annotation: DocumentAnnotation | TemplateAnnotation
  ) => {
    const text = e.target.innerText;
    if (!pdfUrl || !text.trim()) return;

    try {
      const existingPdfBytes = await fetchPdfBytes(pdfUrl);
      const pdfDoc = await loadPdfDocument(existingPdfBytes);

      const page = pdfDoc.getPages()[annotation.page - 1];
      const { height } = page.getSize();

      const maxWidth = annotation.width;
      const fontSize = 10;
      const lineHeight = fontSize * 1.2;
      const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
      const charSpacing = 0.3;

      // Split text into words
      const words = text.split(" ");

      let wrappedText = "";
      let line = "";

      words.forEach((word) => {
        const wordWidth =
          font.widthOfTextAtSize(word + " ", fontSize) +
          word.length * charSpacing;
        const lineWidth =
          font.widthOfTextAtSize(line, fontSize) + line.length * charSpacing;

        if (lineWidth + wordWidth > maxWidth) {
          wrappedText += line + "\n";
          line = word + " ";
        } else {
          line += word + " ";
        }
      });

      wrappedText += line;

      const lines = wrappedText.trim().split("\n");
      let currentY = height - annotation.y - 15;

      page.drawRectangle({
        x: annotation.x,
        y: height - annotation.y - annotation.height,
        width: annotation.width,
        height: annotation.height,
        color: rgb(1, 1, 1),
      });

      lines.forEach((line, index) => {
        let currentX = annotation.x + 3;

        line.split("").forEach((char) => {
          page.drawText(char, {
            x: currentX,
            y: currentY - index * lineHeight,
            size: fontSize,
            font,
          });
          currentX += font.widthOfTextAtSize(char, fontSize) + charSpacing;
        });
      });

      const pdfBytes = await pdfDoc.save();
      const updatedPdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
      const updatedPdfUrl = URL.createObjectURL(updatedPdfBlob);
      setCompleted((prev) => [...prev, annotation.id!!]);
      setPdfUrl(updatedPdfUrl);
      scrollToNextAnnotation(annotation.id!!);
    } catch (error) {
      alert.error("Error updating the PDF with text");
    }
  };

  const handleSelectedOption = (id: string, value: string) => {
    setAnnotationOptions((prev) => ({ ...prev, [id]: value }));

    const annotation = annotations.find((a) => String(a.id) === String(id));

    if (!annotation) return;

    const text = value;
    if (!pdfUrl || !text.trim()) return;

    const embedText = async () => {
      try {
        const existingPdfBytes = await fetchPdfBytes(pdfUrl);
        const pdfDoc = await loadPdfDocument(existingPdfBytes);

        const page = pdfDoc.getPages()[annotation.page - 1];
        const { height } = page.getSize();

        const maxWidth = annotation.width;
        const fontSize = 10;
        const lineHeight = fontSize * 1.2;
        const charWidth = fontSize * 0.6;
        const words = text.split(" ");

        let wrappedText = "";
        let lineWidth = 0;

        words.forEach((word: string) => {
          const wordWidth = word.length * charWidth;
          if (lineWidth + wordWidth > maxWidth) {
            wrappedText += "\n";
            lineWidth = 0;
          }
          wrappedText += word + " ";
          lineWidth += wordWidth + charWidth;
        });

        const lines = wrappedText.trim().split("\n");
        let currentY = height - annotation.y - 15;

        page.drawRectangle({
          x: annotation.x,
          y: height - annotation.y - 24,
          width: annotation.width,
          height: annotation.height,
          color: rgb(1, 1, 1),
        });

        lines.forEach((line, index) => {
          page.drawText(line, {
            x: annotation.x + 15,
            y: currentY - index * lineHeight,
            size: fontSize,
            maxWidth: maxWidth,
          });
        });

        const pdfBytes = await pdfDoc.save();
        const updatedPdfBlob = new Blob([pdfBytes], {
          type: "application/pdf",
        });
        const updatedPdfUrl = URL.createObjectURL(updatedPdfBlob);
        setCompleted((prev) => [...prev, annotation.id!!]);
        setPdfUrl(updatedPdfUrl); // Update the state to reflect the new PDF in the viewer
        scrollToNextAnnotation(annotation.id!!);
      } catch (error) {
        alert.error("Error updating the PDF with text");
      }
    };
    embedText();
  };

  const handleSubmitCheckbox = async (
    e: React.MouseEvent<Element, MouseEvent>
  ) => {
    if (!pdfUrl) return;
    const { id } = e.currentTarget;
    setCompleted((prev) => [...prev, Number(id) as any]);

    // Find the annotation related to the clicked checkbox
    const annotation = annotations.find((a) => String(a.id) === id);
    if (!annotation || !annotation.options) return;

    const checkboxValues = annotationOptions[annotation.id];
    if (!checkboxValues) return;

    const fontSize = 10;

    try {
      const res = await fetch(pdfUrl);
      const existingPdfBytes = await res.arrayBuffer();
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      const form = pdfDoc.getForm();
      const page = pdfDoc.getPage(annotation.page - 1);
      const { height } = page.getSize();

      page.drawRectangle({
        x: annotation.x,
        y: height - annotation.y - annotation.height,
        width: annotation.width,
        height: annotation.height,
        color: rgb(1, 1, 1),
      });

      annotation.options.forEach((option, index) => {
        let checkboxField;
        try {
          checkboxField = form.createCheckBox(option.value);
        } catch (error) {
          checkboxField = form.getCheckBox(option.value);
        }

        checkboxField.addToPage(page, {
          x: annotation.x + 6,
          y: height - annotation.y - 20 - index * 20,
          width: 12,
          height: 12,
        });

        page.drawText(option.value, {
          x: annotation.x + 25,
          y: height - annotation.y - 18 - index * 20,
          size: fontSize,
        });

        if (checkboxValues.includes(option.value)) {
          checkboxField.check();
        } else {
          checkboxField.uncheck();
        }
      });

      form.flatten();

      const pdfBytes = await pdfDoc.save();
      const updatedPdfBlob = new Blob([pdfBytes], {
        type: "application/pdf",
      });
      const updatedPdfUrl = URL.createObjectURL(updatedPdfBlob);
      setPdfUrl(updatedPdfUrl); // Update the state to reflect the new PDF in the viewer
      scrollToNextAnnotation(annotation.id);
    } catch (error) {
      alert.error("Error updating the PDF with checkboxes");
    }
  };

  const handleCheckboxChange = (
    id: string,
    value: string,
    checked: boolean
  ) => {
    setAnnotationOptions((prev) =>
      updateCheckboxAnnotationOptions(prev, id, value, checked)
    );
  };

  const handleFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files?.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => imageRef?.current?.click();

  const handleSaveImageToPdf = async () => {
    if (image && pdfUrl && selectedAnnotation?.id) {
      const blob = await fetchAndConvertToBlob(image);
      const annotation = annotations.find(
        (a) => a.id === selectedAnnotation?.id
      );
      if (!annotation) return;
      const pdfBytes = await embedImageOnPdf(pdfUrl, blob, annotation);
      const updatedPdfBlob = new Blob([pdfBytes], {
        type: "application/pdf",
      });
      setPdfUrl(URL.createObjectURL(updatedPdfBlob));
      setCompleted((prev) => [...prev, annotation.id]);
      setIsImageDialogOpen(false);
      setImage(null);
      setSelectedAnnotation(null);
      scrollToNextAnnotation(annotation.id);
    }
  };

  const uploadFileToS3Mutation = useUploadFileToS3Mutation({
    onSuccess: (data, variables) => {
      const formData = new FormData();
      formData.append("name", variables.name);
      formData.append("ext", variables.ext);

      if (!document?.id) return;

      signDocumentMutation.mutate({
        id: document.id,
        data: formData,
      });
    },
  });

  async function handleFileUploadToS3(file: File) {
    const uuid = uuidv4();
    const extension = file.name.split(".").pop();
    const key = `private/e-signatures/documents/versions/${uuid}.${extension}`;

    const response = await s3Service.getPresignedUrl(key);

    if (response?.presigned_url && uuid && extension) {
      uploadFileToS3Mutation.mutate({
        file,
        presigned_url: response.presigned_url,
        get_url: response.get_url,
        name: uuid,
        ext: extension,
      });
    }
  }

  const handleSubmitDocument = async () => {
    const filledOut = checkAllOptionsHaveValues(annotations, annotationOptions);

    if (!filledOut) {
      alert.error("Please select a value for each option before submitting");
      return;
    }

    if (document?.id && pdfUrl) {
      const blob = await fetchAndConvertToBlob(pdfUrl);
      const file = new File([blob], "1.pdf", { type: "application/pdf" });
      await handleFileUploadToS3(file);
    }
  };

  const handleResetDocument = () => {
    if (document?.newest_version) {
      dispatch(setPageNumber(1));
      setPdfUrl(document.newest_version);
      setSelectedAnnotation(null);
      setCompleted([]);
      if (annotations.length > 0) {
        const options = generateInitialAnnotationOptions(annotations);
        setAnnotationOptions(options);
      }
    }
  };

  const handleClearSignature = () => sigCanvasRef.current?.clear();

  useEffect(() => {
    if (annotations.length > 0) {
      const options = generateInitialAnnotationOptions(annotations);
      setAnnotationOptions(options);
    }
  }, [annotations, setAnnotationOptions]);

  useEffect(() => {
    if (document?.newest_version) {
      setPdfUrl(document.newest_version);
    }
  }, [document?.newest_version, setPdfUrl]);

  const renderAnnotation = (annotation: DocumentAnnotation) => {
    return (
      <>
        {selectedAnnotation?.id === annotation.id &&
        annotation.type === AnnotationType.TextField ? (
          <EditableTextFieldAnnotation
            key={annotation.id}
            annotation={annotation}
            handleSaveText={handleSaveText}
          />
        ) : annotation.type === AnnotationType.Dropdown &&
          !annotationOptions[annotation.id] ? (
          <AnnotationDropdown
            key={annotation.id}
            className="document-sign-annotation-dropdown"
            annotation={annotation}
            selectedValue={annotationOptions[annotation.id]}
            onSelect={(value) => handleSelectedOption(annotation.id, value)}
          />
        ) : annotation.type === AnnotationType.Checkbox &&
          !completed.includes(annotation.id) ? (
          <>
            <AnnotationCheckboxes
              key={annotation.id}
              annotation={annotation}
              selectedValues={annotationOptions[annotation.id]}
              className="document-sign-annotation-checkboxes"
              onChange={(e) =>
                handleCheckboxChange(
                  annotation.id,
                  e.target.value,
                  e.target.checked
                )
              }
            />
            <div
              style={{
                position: "absolute",
                top: annotation.y + annotation.height + 1,
                left: annotation.x,
              }}
            >
              <Button
                color={ButtonColors.Gray}
                id={annotation.id}
                style={{ width: annotation.width - 61, borderRadius: "5px" }}
                onClick={handleSubmitCheckbox}
                title="Save"
              />
            </div>
          </>
        ) : annotation.type !== AnnotationType.Dropdown &&
          annotation.type !== AnnotationType.Checkbox ? (
          <ClickableAnnotation
            key={annotation.id}
            id={annotation.id}
            x={annotation.x}
            y={annotation.y}
            width={annotation.width}
            height={annotation.height}
            onClick={handleClickAnnotation}
            className={
              annotation.assigned_to
                ? "document-sign-annotation"
                : styles.placeholderSelected
            }
          >
            {getClickableAnnoationText(annotation)}
          </ClickableAnnotation>
        ) : null}
      </>
    );
  };

  return (
    <>
      <Dialog
        isOpen={isSignatureDialogOpen}
        setIsOpen={setIsSignatureDialogOpen}
      >
        <DialogHeader>Add Signature</DialogHeader>
        <DialogDescription>
          Please sign below and agree to the terms.
        </DialogDescription>
        <DialogContent>
          <SignatureCanvas
            ref={sigCanvasRef}
            penColor="black"
            canvasProps={{
              width: window.innerWidth < 768 ? window.innerWidth - 20 : 370,
              height: 100,
              className: styles.sigCanvas,
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "10px 0",
            }}
          >
            <Button
              color={ButtonColors.Gray}
              size={ButtonSizes.SM}
              onClick={handleClearSignature}
              title="Clear Signature"
            />
          </div>
          <Container style={{ maxWidth: "370px" }}>
            <SquareCheckboxInput
              id={"agreeToTerms"}
              label={AGREE_TO_TERMS}
              name={"agreeToTerms"}
              checked={agreeToTerms}
              onChange={handleAgreeToTerms}
            />
          </Container>
        </DialogContent>
        <DialogFooter>
          <Button
            color={ButtonColors.GrayAndYellow}
            onClick={handleCloseSignatureDialog}
          >
            Cancel
          </Button>
          <Button color={ButtonColors.Yellow} onClick={handleSaveSignature}>
            Save Signature
          </Button>
        </DialogFooter>
      </Dialog>
      <Dialog isOpen={isImageDialogOpen} setIsOpen={setIsImageDialogOpen}>
        <DialogHeader>Add Image</DialogHeader>
        <DialogDescription>
          Please add an image to the document.
        </DialogDescription>
        <DialogContent>
          <div
            className={"drag-and-drop-input-container"}
            style={{ width: "unset", margin: 0 }}
            onDragOver={handleDragOver}
            onDrop={handleFileDrop}
            onClick={handleClick}
          >
            <input
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              ref={imageRef}
              style={{ display: "none" }}
              onChange={handleFileUpload}
              multiple={false}
            />
            {!image ? (
              <div className="centered gap-10 p-20">
                <BsCloudUpload color={"#f1b70c"} size={24} />
                <p className={styles.dragText}>
                  Drag files here or click to upload.
                </p>
              </div>
            ) : null}
            {image ? (
              <img
                src={image}
                alt={"Uploaded"}
                style={{ width: "100px", height: "100px" }}
              />
            ) : null}
          </div>
        </DialogContent>
        <DialogFooter>
          <Button
            color={ButtonColors.GrayAndYellow}
            onClick={handleCloseSignatureDialog}
          >
            Cancel
          </Button>
          <Button color={ButtonColors.Yellow} onClick={handleSaveImageToPdf}>
            Insert Image
          </Button>
        </DialogFooter>
      </Dialog>
      <Card>
        <DocumentCardHeader />
        {isDocumentLoading ? (
          <div className="p-10">
            <Skeleton height={792} />
          </div>
        ) : null}
        <DocumentSignToolbar onResetPdf={handleResetDocument} />
        <VerticalDocumentViewer
          fileUrl={pdfUrl}
          pageRefs={pageRefs}
          isLoading={isDocumentLoading}
          annotations={unsignedAnns}
          loadingAnimation={<Skeleton height={792} width={612} />}
          renderAnnotation={(annotation) => renderAnnotation(annotation)}
        />
        <div className={styles.buttonsContainer} ref={scrollRef}>
          <Button
            color={ButtonColors.Yellow}
            onClick={handleSubmitDocument}
            disabled={
              !areAllComplete ||
              signDocumentMutation.isLoading ||
              uploadFileToS3Mutation.isLoading
            }
          >
            {signDocumentMutation.isLoading ||
            uploadFileToS3Mutation.isLoading ? (
              <RotatingLines
                strokeColor="#000"
                strokeWidth="5"
                animationDuration="0.75"
                width="20"
                visible={true}
              />
            ) : (
              "Submit Document"
            )}
          </Button>
        </div>
      </Card>
    </>
  );
}
