import * as React from "react";

const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <circle cx={12} cy={12} r={12} fill="url(#a)" />
    <path
      fill="#000"
      d="M10.266 16.747c-.139.138-.335.253-.51.253-.173 0-.37-.12-.514-.258L6 13.527l1.03-1.021 2.733 2.709L16.987 8 18 9.039l-7.734 7.708Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={12}
        x2={12}
        y1={0}
        y2={24}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FEC110" />
        <stop offset={0} stopColor="#FEC110" />
        <stop offset={1} stopColor="#CC9800" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgComponent;
