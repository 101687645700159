import { FormDefs } from "src/features/forms/formDefs.types";

const assignScheduledTodosFormDefs = {
  id: "assignScheduledTodosForm",
  description: "",
  fields: [
    {
      name: "employees",
      description: "Select the employees the Todo will be assigned to.",
      type: "checkbox",
      placeholder: "Assigned To",
      required: false,
      label: "Assigned To",
      labelIcon: null,
      defaultValue: "",
    },
    {
      name: "roles",
      description: "Select the employee types the Todo will be issued to.",
      type: "checkbox",
      placeholder: "Recipients",
      required: true,
      label: "Recipients",
      labelIcon: null,
      defaultValue: "",
    },
  ],
} as FormDefs;

export { assignScheduledTodosFormDefs };
