import { OptionsType } from "../components/Documents/DocumentSignForm";

/**
 * Updates the annotation options state for a checkbox change.
 *
 * This function adjusts the array of selected values for a given checkbox
 * annotation based on whether the checkbox was checked or unchecked. If checked,
 * the value is added to the array. If unchecked, the value is removed.
 *
 * @param {OptionsType} prevOptions - The previous state of annotation options.
 * @param {string} id - The ID of the annotation (checkbox) being updated.
 * @param {string} value - The value of the checkbox.
 * @param {boolean} checked - Whether the checkbox was checked (true) or unchecked (false).
 * @returns {OptionsType} The updated state of annotation options.
 */
function updateCheckboxAnnotationOptions(
  prevOptions: OptionsType,
  id: string,
  value: string,
  checked: boolean
): OptionsType {
  const currentValues = Array.isArray(prevOptions[id])
    ? (prevOptions[id] as string[])
    : [];
  let updatedValues: string[] = [];

  if (checked) {
    updatedValues = [...currentValues, value];
  } else {
    updatedValues = currentValues.filter((val) => val !== value);
  }

  return { ...prevOptions, [id]: updatedValues };
}

export default updateCheckboxAnnotationOptions;
