import React, { memo } from "react";

import styles from "./InputLabel.module.css";

type InputLabelProps = {
  action?: React.ReactNode;
  className?: string;
  children: React.ReactNode;
  labelIcon?: React.ReactNode;
  htmlFor?: string;
  style?: React.CSSProperties;
};

function InputLabel({
  action,
  className,
  children,
  labelIcon,
  htmlFor,
  style,
}: InputLabelProps) {
  return (
    <>
      <label
        htmlFor={htmlFor}
        className={className || styles.label}
        style={style}
      >
        <span className={styles.labelText}>
          {labelIcon ? labelIcon : null} {children}
        </span>
        {action ? <span className={styles.action}>{action}</span> : null}
      </label>
    </>
  );
}

export default memo(InputLabel);
