import { createSlice } from "@reduxjs/toolkit";
import { AuthenticatedEmployee } from "../employees/types/employees.type";

export type AuthState = {
  isAuthenticated: boolean;
  employee: AuthenticatedEmployee | null;
  schema: string | null;
  access: string | null;
  refresh: string | null;
};

const initialState: AuthState = {
  isAuthenticated: false,
  employee: null,
  schema: null,
  access: null,
  refresh: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.isAuthenticated = true;
      state.employee = action.payload.employee;
      state.schema = action.payload.schema;
      state.access = action.payload.access;
      state.refresh = action.payload.refresh;
    },
    setEmployee: (state, action) => {
      state.employee = action.payload;
    },
    setSchema: (state, action) => {
      state.schema = action.payload;
    },
    setAccess: (state, action) => {
      state.access = action.payload;
    },
    setRefresh: (state, action) => {
      state.refresh = action.payload;
    },
    resetAuthState: (state) => {
      state.isAuthenticated = false;
      state.employee = null;
      state.schema = null;
      state.access = null;
      state.refresh = null;
    },
  },
});

export const {
  setAuth,
  setEmployee,
  setSchema,
  setAccess,
  setRefresh,
  resetAuthState,
} = authSlice.actions;
export default authSlice.reducer;
