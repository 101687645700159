import { useQuery } from "@tanstack/react-query";

import policiesService from "../services/policiesService";
import { Policy } from "../types/policies.types";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

type UseGetPolicyById = {
  id?: string;
  refetchOnWindowFocus?: boolean;
  enabled?: boolean;
};

export default function useGetPolicyQuery({
  id,
  refetchOnWindowFocus = false,
  enabled = true,
}: UseGetPolicyById) {
  return useQuery<Policy>({
    queryKey: [QueryKeys.Policy, String(id)],
    queryFn: id ? () => policiesService.getPolicyById(id) : undefined,
    refetchOnWindowFocus,
    enabled: enabled && !!id,
  });
}
