import axiosClient from "src/features/axios/client";
import { CancelShiftMutationVariables } from "../hooks/useCancelEmployeeShiftMutation";
import { EmployeeShift } from "./employeeShiftsService";

export enum ShiftConflictResolutionStatus {
  Unresolved = "Unresolved",
  Resolved = "Resolved",
  Dismissed = "Dismissed",
  Expired = "Expired",
}

export enum ShiftConflictType {
  OverlappingShifts = "OverlappingShifts",
  ConsecutiveShifts = "ConsecutiveShifts",
  ExceedsDailyHours = "ExceedsDailyHours",
  ExceedsWeeklyHours = "ExceedsWeeklyHours",
  ExceedsWeeklyDays = "ExceedsWeeklyDays",
  MultipleConflicts = "MultipleConflicts",
}

export interface ShiftConflict {
  id: number;
  shifts: EmployeeShift[];
  conflict_type: ShiftConflictType;
  resolution_status: ShiftConflictResolutionStatus;
  resolved_at: string | null;
  notes: string[];
  created_on: string;
  updated_on: string;
}

export type ShiftConflictListItem = {
  id: number;
  shifts: number[];
  conflict_type: ShiftConflictType;
  resolution_status: ShiftConflictResolutionStatus;
  resolved_at: string | null;
  created_on: string;
  updated_on: string;
  notes: string[];
};

class ShiftConflictsService {
  async cancelEmployeeShift(
    data: CancelShiftMutationVariables
  ): Promise<ShiftConflict> {
    return await axiosClient
      .post(`/api/shift-conflicts/${data.id}/cancel-shift/`, {
        employee_shift_id: data.employeeShiftId,
      })
      .then((res) => res.data);
  }

  async dismissShiftConflict(id: number): Promise<ShiftConflict> {
    return axiosClient
      .post(`/api/shift-conflicts/${id}/dismiss/`)
      .then((response) => response.data);
  }

  async getShiftConflicts(
    departmentId?: number,
    weeklyScheduleId?: number,
    resolutionStatus?: ShiftConflictResolutionStatus,
    conflictType?: ShiftConflictType,
    startDate?: string,
    endDate?: string
  ): Promise<ShiftConflict[]> {
    return axiosClient
      .get("/api/shift-conflicts", {
        params: {
          department_id: departmentId,
          weekly_schedule_id: weeklyScheduleId,
          resolution_status: resolutionStatus,
          conflict_type: conflictType,
          start_date: startDate,
          end_date: endDate,
        },
      })
      .then((response) => response.data);
  }
}

const shiftConflictsService = new ShiftConflictsService();

export default shiftConflictsService;
