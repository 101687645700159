import React from "react";
import Skeleton from "react-loading-skeleton";
import ListContainer from "src/components/Lists/ListContainer";
import ExpandableListBox from "src/components/ListBox/ExpandableListBox";
import Placeholder from "src/components/Placeholder/Placeholder";

type GenericExpandableBoxListProps<T> = {
  items: T[];
  isLoading: boolean;
  boxKey: keyof T;
  boxTitleKey: keyof T;
  placeholderText: string;
  defaultExpandedKey: keyof T;
  defaultExpandedValue?: string;
  titleActions?: (item: T) => React.ReactNode;
  isSelected?: (item: T) => boolean;
  subtitle?: (item: T) => React.ReactNode;
  expandedContent?: (item: T) => React.ReactNode;
  generateHref?: (item: T) => string;
};

export default function GenericExpandableBoxList<T>({
  items,
  isLoading,
  boxKey,
  boxTitleKey,
  placeholderText,
  defaultExpandedKey,
  defaultExpandedValue,
  titleActions,
  isSelected,
  subtitle,
  expandedContent,
  generateHref,
}: GenericExpandableBoxListProps<T>) {
  return (
    <ListContainer style={{ padding: "0" }}>
      {isLoading ? (
        <Skeleton height={70} count={5} style={{ margin: "3px 0" }} />
      ) : null}
      {!isLoading &&
        items.map((item, index) => (
          <ExpandableListBox
            key={String(item[boxKey]) || index}
            title={String(item[boxTitleKey])}
            titleAction={titleActions ? titleActions(item) : undefined}
            subtitle={subtitle ? subtitle(item) : undefined}
            listContent={expandedContent ? expandedContent(item) : undefined}
            href={generateHref ? generateHref(item) : ""}
            isSelected={isSelected ? isSelected(item) : false}
            isLinkDisabled={false}
            defaultExpanded={
              String(item[defaultExpandedKey]) === String(defaultExpandedValue)
            }
          />
        ))}
      {!isLoading && items.length === 0 && (
        <Placeholder title={placeholderText} />
      )}
    </ListContainer>
  );
}
