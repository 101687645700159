import { useMemo } from "react";

export const useCompletionStatus = <T extends { [key: string]: any }>(
  data: T,
  statusKey: keyof T = "status",
  completedStatus: T[keyof T] = "completed" as T[keyof T]
): boolean =>
  useMemo(() => {
    return data?.[statusKey] === completedStatus;
  }, [data, statusKey, completedStatus]);
