import axiosClient from "src/features/axios/client";

export type ShiftType = {
  id: number;
  name: string;
  start_time: string;
  end_time: string;
};

export type GetShiftTypeFilters = {
  department_id?: number | string;
  name?: string;
  start_time?: string;
  end_time?: string;
};

class ShiftTypeService {
  async createShiftType(data: Omit<ShiftType, "id">): Promise<ShiftType> {
    return axiosClient
      .post("/api/shift-types/", data)
      .then((response) => response.data);
  }

  async deleteShiftType(id: number): Promise<void> {
    return axiosClient
      .delete(`/api/shift-types/${id}/`)
      .then((res) => res.data);
  }

  async getShiftTypes(filters: GetShiftTypeFilters): Promise<ShiftType[]> {
    return axiosClient
      .get("/api/shift-types", { params: filters })
      .then((response) => response.data);
  }

  async partialUpdateShiftType(data: Partial<ShiftType>): Promise<ShiftType> {
    return axiosClient
      .patch(`/api/shift-types/${data.id}/`, data)
      .then((response) => response.data);
  }
}

const shiftTypeService = new ShiftTypeService();

export default shiftTypeService;
