const isAnswerCorrect = (
  incorrectQuestions: string,
  questionId?: string | number
) => {
  if (!incorrectQuestions) return true;
  if (!questionId) return false;

  const incorrectQuestionsArray = incorrectQuestions.split(",");

  const isIncorrect = incorrectQuestionsArray.includes(questionId.toString());

  return !isIncorrect;
};

export default isAnswerCorrect;
