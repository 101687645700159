import { useParams } from "react-router-dom";
import Button from "src/components/Buttons/Button";
import TrashIcon from "src/components/Icons/TrashIcon";
import { setModalStateAction } from "src/features/modal/handlers";
import { DELETE_DOCUMENT } from "src/features/modal/titles";
import { useAppDispatch } from "src/store";
import useGetDocuments from "../../hooks/useGetDocuments";
import ListItemDate from "./ListItemDate";
import { Document } from "../../types/document.types";
import GenericExpandableBoxList from "src/components/Lists/GenericExpandableBoxList";
import DocumentSignatureProgressStepper from "../Documents/DocumentSignatureProgressStepper";
import hasGroupPermissions from "src/features/auth/utils/hasGroupPermissions";
import { ORGANIZATION_CRUD_GROUP } from "src/features/groups/groups";
import useAuth from "src/features/auth/hooks/useAuth";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import useGetMyTodosListQuery from "src/features/todos/hooks/queries/useGetMyTodosListQuery";
import {
  TodoObjectTypes,
  TodoStatus,
} from "src/features/todos/types/todosEnums.types";

export default function InProgressDocumentsList() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { employee } = useAuth();
  const { data: documents, isLoading } = useGetDocuments({ status: 1 });

  const { data: todos } = useGetMyTodosListQuery({
    objectType: TodoObjectTypes.Document,
    status: TodoStatus.Assigned,
  });

  const handleDeleteDocument = (docId: string) => () =>
    dispatch(setModalStateAction(docId)(DELETE_DOCUMENT)(true));

  return (
    <GenericExpandableBoxList<Document>
      items={documents || []}
      isLoading={isLoading}
      boxKey="id"
      boxTitleKey="title"
      placeholderText="No pending documents..."
      defaultExpandedKey="id"
      defaultExpandedValue={id}
      isSelected={(item) => String(item.id) === id}
      titleActions={(item) => (
        <>
          {hasGroupPermissions(employee, ORGANIZATION_CRUD_GROUP) ||
          item.created_by === employee?.id ? (
            <>
              <Button
                id={String(item.id)}
                onClick={handleDeleteDocument(String(item.id))}
                color={ButtonColors.Transparent}
                tooltip="Delete Document"
              >
                <TrashIcon />
              </Button>
            </>
          ) : null}
        </>
      )}
      subtitle={(item) => <ListItemDate date={item.created_on} />}
      expandedContent={(document) => (
        <DocumentSignatureProgressStepper documentId={String(document.id)} />
      )}
      generateHref={(item) => {
        const baseHref = `/e-signatures/documents/${item.id}`;
        const suffix = todos?.some((todo) => todo?.document?.id === item.id)
          ? "/sign"
          : "";
        return `${baseHref}${suffix}`;
      }}
    />
  );
}
