import { createSlice } from "@reduxjs/toolkit";

export interface TableGridState {
  refetch: boolean;
}

const initialState: TableGridState = {
  refetch: false,
};

export const tableGridSlice = createSlice({
  name: "tableGrid",
  initialState,
  reducers: {
    setRefetchTableGrid: (state, action) => {
      state.refetch = action.payload;
    },
  },
});

export const { setRefetchTableGrid } = tableGridSlice.actions;

export default tableGridSlice.reducer;
