import { RefObject, useEffect } from "react";

type OnIntersect = (entry: IntersectionObserverEntry) => void;

interface UseIntersectionObserverArgs {
  refs: RefObject<(Element | null)[]>;
  onIntersect: OnIntersect;
  options?: IntersectionObserverInit;
}

function useIntersectionObserver({
  refs,
  onIntersect,
  options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
  },
}: UseIntersectionObserverArgs): void {
  useEffect(() => {
    if (!("IntersectionObserver" in window)) {
      console.warn("IntersectionObserver is not supported by your browser.");
      return;
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          onIntersect(entry);
        }
      });
    }, options);

    const currentRefs = refs?.current?.filter((ref) => ref !== null);

    if (!currentRefs) return;

    currentRefs.forEach((ref) => ref && observer.observe(ref));

    return () => currentRefs.forEach((ref) => ref && observer.unobserve(ref));
  }, [refs, onIntersect, options]);
}

export default useIntersectionObserver;
