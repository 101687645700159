import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import DynamicFormInput from "src/components/DynamicForm/DynamicFormInput";
import { useAlert } from "@blaumaus/react-alert";

import styles from "./ScheduledTodosForm.module.css";
import inputStyles from "src/components/Inputs/Inputs.module.css";
import FormRow from "src/components/Forms/FormRow";
import CalendarIcon from "src/components/Icons/CalendarIcon";
import InputContainer from "src/components/Inputs/InputContainer";
import InputLabel from "src/components/Inputs/InputLabel";
import { SELECT, TEXT } from "src/features/forms/inputTypes";
import mapChoices from "src/features/learningPaths/utils/mapChoices";
import useGetScheduledTodosRecurrenceChoicesQuery from "../hooks/queries/useGetScheduledTodosRecurrenceChoicesQuery";
import useCreateScheduledTodoMutation from "../hooks/mutations/useCreateScheduledTodoMutation";
import {
  ScheduledTodoStatus,
  TodoObjectTypes,
} from "../types/todosEnums.types";
import FlagIcon from "src/components/Icons/FlagIcon";
import EmployeeImage from "src/components/Images/EmployeeImage";
import SearchInput from "src/components/Inputs/SearchInput";
import SquareCheckboxInput from "src/components/Inputs/SquareCheckboxInput";
import Tab from "src/components/Tabs/Tab";
import TabNavigation from "src/components/Tabs/TabNavigation";
import { getDepartmentsWithRoles } from "src/features/departments/apis";
import useEmployees from "src/features/employees/hooks/useEmployees";
import NestedCheckBoxInput, {
  Department,
  Role,
} from "src/features/forms/components/NestedCheckBoxesInput";
import ButtonsContainer from "src/components/Container/ButtonsContainer";
import {
  SAVE,
  CANCEL,
  PUBLISH,
  SAVE_AS_DRAFT,
} from "src/features/buttons/titles";
import {
  ScheduledTodosFormSteps,
  setScheduledTodoToUpdate,
  setScheduledTodosFormStep,
} from "../todosSlice";
import { useAppDispatch } from "src/store";
import Button from "src/components/Buttons/Button";
import DropDownV2 from "src/components/DropDowns/DropDownV2";
import DropDownV2Button from "src/components/DropDowns/DropDownV2Button";
import useScheduledTodosFormState from "../hooks/redux/useScheduledTodosFormState";
import useUpdateScheduledTodosMutation from "../hooks/mutations/useUpdateScheduledTodoMutation";
import Container from "src/components/Container/Container";
import EditingContainer from "src/features/learningPaths/components/LPBuilder/Forms/EditingContainer";
import "react-datepicker/dist/react-datepicker.css";
import { AuthenticatedEmployee } from "src/features/employees/types/employees.type";
import { IRole } from "src/features/employeeTypes/types/employeeTypes.types";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import useGetScheduledTodoQuery from "../hooks/queries/useGetScheduledTodoQuery";
import useGetScheduledTodosDeadlineChoicesQuery from "../hooks/queries/useGetScheduledTodosDeadlineChoicesQuery";
import { assignScheduledTodosFormDefs } from "../formDefs/scheduledTodosFormDefs";
import {
  CreateScheduledTodosFormValues,
  createScheduledTodosFormSchema,
} from "../zod/createScheduledTodosFormSchema";
import { ButtonColors } from "src/components/Buttons/buttons.types";

const today = new Date();

type CreateScheduledTodosProps = {
  objectId: number | string;
  objectType: TodoObjectTypes;
  onSuccess?: () => void;
};

const TAB_DEPARTMENTS = "Roles";
const TAB_EMPLOYEES = "Employees";

export default function ScheduledTodosForm({
  objectId,
  objectType,
  onSuccess,
}: CreateScheduledTodosProps) {
  const alert = useAlert();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const { data: employees } = useEmployees({});

  const { data: recurrenceChoices } =
    useGetScheduledTodosRecurrenceChoicesQuery();

  const { data: deadlineChoices } = useGetScheduledTodosDeadlineChoicesQuery(
    {}
  );
  const { scheduledTodoToUpdate } = useScheduledTodosFormState();

  const { data: scheduledTodo, isInitialLoading } = useGetScheduledTodoQuery({
    id: scheduledTodoToUpdate ? String(scheduledTodoToUpdate) : undefined,
  });

  const onSuccessCallback = useCallback(() => {
    queryClient.invalidateQueries([QueryKeys.ScheduledTodosList]);
    dispatch(
      setScheduledTodosFormStep(ScheduledTodosFormSteps.ListScheduledTodos)
    );
    dispatch(setScheduledTodoToUpdate(null));
    onSuccess?.();
  }, [dispatch, onSuccess, queryClient]);

  const createScheduledTodoMutation = useCreateScheduledTodoMutation({
    onSuccess: () => onSuccessCallback(),
  });
  const updateScheduledTodoMutation = useUpdateScheduledTodosMutation({
    onSuccess: () => onSuccessCallback(),
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm<CreateScheduledTodosFormValues>({
    resolver: zodResolver(createScheduledTodosFormSchema),
  });

  useEffect(() => {
    if (errors?.employees?.message) {
      alert.error(errors?.employees?.message);
    }
  }, [errors, alert]);

  useEffect(() => {
    if (scheduledTodo) {
      reset({
        title: scheduledTodo.title,
        recurrence: scheduledTodo.recurrence,
        deadline: scheduledTodo.deadline,
        send_notifications: scheduledTodo.send_notifications,
        allow_duplicate_todos: scheduledTodo.allow_duplicate_todos,
        start_date: new Date(scheduledTodo.start_date),
        employees: scheduledTodo.employees.map(
          (e: AuthenticatedEmployee) => e.id
        ),
        roles: scheduledTodo.roles.map((role: IRole) => role.id),
      });
    }
  }, [scheduledTodo, reset]);

  const handleCancelScheduledTodos = () => {
    dispatch(
      setScheduledTodosFormStep(ScheduledTodosFormSteps.ListScheduledTodos)
    );
    dispatch(setScheduledTodoToUpdate(null));
  };

  const mappedRecurrenceChoices = useMemo(
    () => mapChoices(recurrenceChoices),
    [recurrenceChoices]
  );

  const mappedDeadlineChoices = useMemo(
    () => mapChoices(deadlineChoices),
    [deadlineChoices]
  );

  const onSubmit =
    (status: ScheduledTodoStatus) =>
    ({ start_date, ...data }: any) => {
      if (scheduledTodoToUpdate) {
        updateScheduledTodoMutation.mutate({
          ...data,
          id: scheduledTodoToUpdate,
          start_date,
          objectId: Number(objectId),
          objectType,
          status,
        });
      } else {
        createScheduledTodoMutation.mutate({
          ...data,
          start_date,
          objectId: Number(objectId),
          objectType,
          status,
        });
      }
    };

  const dropdownOptions = [
    {
      title: SAVE_AS_DRAFT,
      action: handleSubmit(onSubmit(ScheduledTodoStatus.Draft)),
      icon: undefined,
    },
    {
      title: PUBLISH,
      action: handleSubmit(onSubmit(ScheduledTodoStatus.Published)),
      icon: undefined,
    },
  ];

  return (
    <EditingContainer>
      <form>
        <FormRow>
          <InputContainer style={{ padding: "5px 0" }}>
            <InputLabel htmlFor={"title"} labelIcon={<FlagIcon />}>
              Schedule Title
            </InputLabel>
            <DynamicFormInput
              register={register}
              type={TEXT}
              placeholder={"Add a schedule title..."}
              name={"title"}
              defaultValue={""}
              options={[]}
              optionKey={""}
              error={
                errors["title" as keyof CreateScheduledTodosFormValues]?.message
              }
              inputClassName={inputStyles.darkInput}
              containerClassName={inputStyles.inputContainer}
              errorClassName={inputStyles.darkInputError}
            />
          </InputContainer>
        </FormRow>
        <FormRow>
          <InputContainer style={{ padding: "5px 0" }}>
            <InputLabel htmlFor={"recurrence"} labelIcon={<CalendarIcon />}>
              How often?
            </InputLabel>
            <DynamicFormInput
              register={register}
              type={SELECT}
              placeholder={"recurrence"}
              name={"recurrence"}
              defaultValue={""}
              options={mappedRecurrenceChoices}
              optionKey={"label"}
              error={
                errors["recurrence" as keyof CreateScheduledTodosFormValues]
                  ?.message
              }
              inputClassName={inputStyles.darkInput}
              containerClassName={inputStyles.inputContainer}
              errorClassName={inputStyles.darkInputError}
            />
          </InputContainer>
        </FormRow>
        <FormRow>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              padding: "5px 0",
            }}
          >
            <InputLabel htmlFor={"start_date"} labelIcon={<CalendarIcon />}>
              Start Date
            </InputLabel>
            <Controller
              control={control}
              defaultValue={today}
              name="start_date"
              render={({ field }) => (
                <DatePicker
                  selected={field.value}
                  onChange={(date) => field.onChange(date)}
                  minDate={new Date()}
                  startDate={field.value}
                  className={inputStyles.darkInputWidth100}
                />
              )}
            />
          </div>
          <InputContainer style={{ padding: "5px 0" }}>
            <InputLabel htmlFor={"deadline"} labelIcon={<CalendarIcon />}>
              Deadline
            </InputLabel>
            <DynamicFormInput
              register={register}
              type={SELECT}
              placeholder={"deadline"}
              name={"deadline"}
              options={mappedDeadlineChoices}
              defaultValue={""}
              optionKey={"label"}
              error={
                errors["deadline" as keyof CreateScheduledTodosFormValues]
                  ?.message
              }
              inputClassName={inputStyles.darkInput}
              containerClassName={inputStyles.inputContainer}
              errorClassName={inputStyles.darkInputError}
            />
          </InputContainer>
        </FormRow>
        <FormRow>
          <Container style={{ margin: "1rem 0" }}>
            <SquareCheckboxInput
              id={"send_notifications"}
              label={"Send Notifications"}
              description={
                "Send notifications to employees when assigning todos?"
              }
              {...register("send_notifications", { required: false })}
            />
          </Container>
        </FormRow>
        <FormRow>
          <Container style={{ margin: "1rem 0" }}>
            <SquareCheckboxInput
              id={"allow_duplicate_todos"}
              label={"Allow Duplicate Todos"}
              description={
                "If checked, employees with existing todos which are assigned or in-progress will be issued a duplicate todo."
              }
              {...register("allow_duplicate_todos", { required: false })}
            />
          </Container>
        </FormRow>
        <TabNavigation id="departments">
          <Tab title={TAB_DEPARTMENTS}>
            {!isInitialLoading ? (
              <NestedCheckBoxInput<Department, Role>
                key={assignScheduledTodosFormDefs.fields[1].name}
                register={register}
                setValue={setValue}
                queryFn={getDepartmentsWithRoles}
                inputName={assignScheduledTodosFormDefs.fields[1].name}
                itemName="name"
                subItemName="name"
                subItemKey="roles"
                defaultSelectedSubIds={
                  scheduledTodo?.roles.map((role: any) => role.id.toString()) ||
                  []
                }
                error={
                  errors[
                    assignScheduledTodosFormDefs.fields[1]
                      .name as keyof CreateScheduledTodosFormValues
                  ]?.message
                }
              />
            ) : null}
          </Tab>
          <Tab title={TAB_EMPLOYEES}>
            <SearchInput
              id={"employeeSearch"}
              inputClassName={inputStyles.blackInput}
              containerClassName={inputStyles.inputContainer}
            />
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              <div className={styles.employeeCheckboxesContainer}>
                {employees?.map((employee: any) => (
                  <div key={employee.id} className={styles.employeeCheckbox}>
                    <Controller
                      control={control}
                      name="employees"
                      defaultValue={[]}
                      render={({ field }) => (
                        <SquareCheckboxInput
                          id={employee.id.toString()}
                          name={employee.id.toString()}
                          label={""}
                          checked={field.value?.includes(employee.id) ?? false}
                          onChange={(e) => {
                            const currentValues = field.value ?? [];
                            const updatedValues = e.target.checked
                              ? [...currentValues, employee.id]
                              : currentValues.filter(
                                  (id) => id !== employee.id
                                );

                            field.onChange(updatedValues);
                          }}
                          value={employee.id}
                        />
                      )}
                    />

                    <div className={styles.employeeAvatar}>
                      <EmployeeImage
                        containerStyle={{ width: "30px", height: "30px" }}
                        alt={employee.username}
                        src={employee.image}
                      />
                    </div>
                    <div className={styles.employeeTextContainer}>
                      <div className={styles.employeeName}>
                        {employee.first_name} {employee.last_name}
                      </div>
                      <div className={styles.employeeRole}>
                        {employee.role.name}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Tab>
        </TabNavigation>
        <ButtonsContainer>
          <Button
            color={ButtonColors.GrayAndYellow}
            onClick={handleCancelScheduledTodos}
            title={CANCEL}
          />
          <DropDownV2
            title={SAVE}
            color={ButtonColors.Yellow}
            isFilterVisible={isFilterVisible}
            setIsFilterVisible={setIsFilterVisible}
          >
            {dropdownOptions.map((option, index) => (
              <DropDownV2Button
                key={index}
                icon={option.icon}
                title={option.title}
                onClick={option.action}
              />
            ))}
          </DropDownV2>
        </ButtonsContainer>
      </form>
    </EditingContainer>
  );
}
