import Button from "src/components/Buttons/Button";
import ImageV2 from "src/components/Images/ImageV2";
import { Question } from "../../learningPaths.types";
import styles from "./PreviousQuestion.module.css";
import { ButtonColors } from "src/components/Buttons/buttons.types";

type PreviousQuestionProps = {
  question: Question;
  outcome: boolean;
  answers: number[];
};

export default function PreviousQuestion({
  question,
  outcome,
  answers,
}: PreviousQuestionProps) {
  const handleOpenImageTab = (e: React.MouseEvent<Element, MouseEvent>) => {
    const imageSrc = e.currentTarget.id;
    window.open(imageSrc, "_blank");
  };

  return (
    <div className={styles.previousQuestionContainer}>
      <div className={styles.previousQuestion}>
        <span className={styles.sectionTitle}>Previous Question:</span>
        {question.figure ? (
          <Button
            color={ButtonColors.Transparent}
            onClick={handleOpenImageTab}
            id={question.figure}
          >
            <ImageV2
              src={question.figure}
              alt={question.content}
              className={styles.questionImage}
              height={40}
              width={40}
              circle={true}
            />
          </Button>
        ) : null}
        <div className={styles.content}>{question.content}</div>
      </div>
      <div className={styles.previousQuestion}>
        <span className={styles.sectionTitle}>
          Your answer was {outcome ? "correct" : "incorrect"}:
        </span>
        {question?.answers
          ? question.answers.map((answer) =>
              answer.correct ? (
                <div
                  className={
                    Array.isArray(answers) &&
                    answers.includes(Number(answer.id))
                      ? styles.correctAnswer
                      : styles.answer
                  }
                  key={answer.id}
                >
                  {`- ${answer.content} (Correct Answer)`}{" "}
                  {answer?.id &&
                  Array.isArray(answers) &&
                  answers.includes(Number(answer.id)) ? (
                    <span className={styles.yourAnswer}>(Your Answer)</span>
                  ) : null}
                </div>
              ) : (
                <div
                  className={
                    Array.isArray(answers) &&
                    answers.includes(Number(answer.id))
                      ? styles.incorrectAnswer
                      : styles.answer
                  }
                  key={answer.id}
                >
                  {`- ${answer.content} `}
                  {answer?.id &&
                  Array.isArray(answers) &&
                  answers.includes(Number(answer.id)) ? (
                    <span className={styles.yourAnswer}>(Your Answer)</span>
                  ) : null}
                </div>
              )
            )
          : null}
        {question?.answer ? (
          outcome ? (
            <div className={styles.correctAnswer}>
              <span className={styles.yourAnswer}>Your Answer: {answers}</span>
              <br />
              <br />
              Correct Answer: {question.answer}
            </div>
          ) : (
            <div className={styles.incorrectAnswer}>
              <span className={styles.yourAnswer}>Your Answer: {answers}</span>
              <br />
              <br />
              Correct Answer: {question.answer}
            </div>
          )
        ) : null}
      </div>
      {question?.explanation ? (
        <div className={styles.previousQuestion}>
          <span className={styles.sectionTitle}>Explanation:</span>
          <div className={styles.content}>{question.explanation}</div>
        </div>
      ) : null}
    </div>
  );
}
