import { createSlice } from "@reduxjs/toolkit";

export interface NavigationState {
  isVisible: boolean;
}

const initialState: NavigationState = {
  isVisible: false,
};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    closeMenu: (state) => {
      state.isVisible = false;
    },
    openMenu: (state) => {
      state.isVisible = true;
    },
    toggleMenu: (state) => {
      state.isVisible = !state.isVisible;
    },
  },
});

export const { closeMenu, openMenu, toggleMenu } = navigationSlice.actions;

export default navigationSlice.reducer;
