import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import laborBudgetService from "../services/laborBudgetService";

type UseGetLaborBudgetsByStartAndEndDateQuery = {
  weeklyScheduleId?: number;
  departmentId?: number;
  scheduleRoleId?: number;
  startDate: string;
  endDate: string;
};

export default function useGetLaborBudgetsByStartAndEndDateQuery({
  weeklyScheduleId,
  departmentId,
  scheduleRoleId,
  startDate,
  endDate,
}: UseGetLaborBudgetsByStartAndEndDateQuery) {
  return useQuery({
    queryFn:
      startDate && endDate
        ? () =>
            laborBudgetService.getLaborBudgets(
              startDate,
              endDate,
              weeklyScheduleId,
              departmentId,
              scheduleRoleId
            )
        : undefined,
    queryKey: [
      QueryKeys.LaborBudgets,
      startDate,
      endDate,
      weeklyScheduleId,
      departmentId,
      scheduleRoleId,
    ],
    enabled: !!startDate && !!endDate,
    refetchOnWindowFocus: false,
    retry: false,
  });
}
