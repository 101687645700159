import { useMutation } from "@tanstack/react-query";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import shiftTransferService, {
  ShiftTransfer,
  ShiftTransferStatus,
} from "../services/shiftTransferService";

type MakeShiftTransferDecisionMutationProps = {
  onSuccess?: (data: ShiftTransfer) => void;
};

type ShiftTransferDecisionData = {
  id: number;
  status: ShiftTransferStatus;
};

export default function useMakeShiftTransferDecisionMutation({
  onSuccess,
}: MakeShiftTransferDecisionMutationProps) {
  const handleErrors = useErrorHandling();

  return useMutation({
    mutationFn: async (data: ShiftTransferDecisionData) =>
      shiftTransferService.makeShiftTransferDecision(data.id, data.status),
    onSuccess,
    onError: handleErrors,
  });
}
