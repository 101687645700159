import CheckCircleYellowIcon from "src/components/Icons/CheckCircleYellowIcon";
import StepThreeGrayIcon from "src/components/Icons/StepThreeGrayIcon";
import StepThreeYellowIcon from "src/components/Icons/StepThreeYellowIcon";
import StepContainer from "src/components/Steps/StepContainer";
import useVerticalStepperContent from "src/components/Steps/hooks/useVerticalStepperContent";
import StepEditButton from "src/features/learningPaths/components/LPBuilder/Steps/StepEditButton";
import usePolicyFormState from "../../hooks/usePolicyFormState";
import { useAppDispatch } from "src/store";
import { setPolicyFormStep, PolicyFormSteps } from "../../slices/policySlice";
import PolicyAssignViewableByForm from "./PolicyAssignViewableByForm";

export default function PolicyFormStepThree() {
  const dispatch = useAppDispatch();
  const { step } = usePolicyFormState();

  const handleStepButtonClick = () =>
    dispatch(setPolicyFormStep(PolicyFormSteps.AssignViewableBy));

  return useVerticalStepperContent({
    currentStep: step,
    activeStep: PolicyFormSteps.AssignViewableBy,
    titleAction:
      step !== PolicyFormSteps.AddDetails &&
      step !== PolicyFormSteps.UploadPolicy &&
      step !== PolicyFormSteps.AssignViewableBy ? (
        <StepEditButton onClick={handleStepButtonClick} />
      ) : null,
    activeIcon: <StepThreeYellowIcon />,
    inactiveIcon:
      step === PolicyFormSteps.AddDetails ||
      step === PolicyFormSteps.UploadPolicy ? (
        <StepThreeGrayIcon />
      ) : (
        <CheckCircleYellowIcon />
      ),
    defaultContent: (
      <StepContainer>
        {step !== PolicyFormSteps.AddDetails &&
        step !== PolicyFormSteps.UploadPolicy &&
        step !== PolicyFormSteps.AssignViewableBy ? (
          <span className="text-muted">This step is complete!</span>
        ) : (
          <span className="text-muted">
            Specify who can view this policy by assigning it to specific
            employee roles, departments, or the entire organization.
          </span>
        )}
      </StepContainer>
    ),
    activeContent: <PolicyAssignViewableByForm />,
  });
}
