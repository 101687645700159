import { memo } from "react";
import styles from "../styles/RegisterHeader.module.css";
import Image from "../../../components/Images/Image";
import logo from "../../../assets/images/clearpath-logo.png";

function RegisterHeader() {
  return (
    <div className={styles.registerHeader}>
      <Image src={logo} alt="Clearpath Logo" className={styles.logo} />
    </div>
  );
}

export default memo(RegisterHeader);
