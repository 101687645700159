import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import s3Service from "../services/s3Service";

type UploadFileToS3Mutation = {
  onSuccess?: (data: any, variables: MutationData) => void;
  uploadProgress?: (percentage: number) => void;
};

export type MutationData = {
  presigned_url: string;
  get_url: string;
  file: File;
  name: string;
  ext: string;
};

export default function useUploadFileToS3Mutation({
  onSuccess,
  uploadProgress,
}: UploadFileToS3Mutation) {
  const handleErrors = useErrorHandling();

  return useMutation(
    ({ presigned_url, file }: MutationData) =>
      s3Service.uploadFileToS3(presigned_url, file, uploadProgress),
    {
      onSuccess,
      onError: (error: AxiosError) => handleErrors(error),
    }
  );
}
