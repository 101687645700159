import { useMutation } from "@tanstack/react-query";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import { AllocateEmployeeShift } from "../components/SelectEmployeeScheduleRoleDialog";
import employeeShiftsService, {
  EmployeeShift,
} from "../services/employeeShiftsService";

type AllocateEmployeeShiftMutation = {
  onSuccess?: (data: EmployeeShift) => void;
};

export default function useAllocateEmployeeShiftMutation({
  onSuccess,
}: AllocateEmployeeShiftMutation) {
  const handleErrors = useErrorHandling();

  return useMutation({
    mutationFn: async (data: AllocateEmployeeShift) =>
      employeeShiftsService.allocateEmployeeShift(data),
    onError: handleErrors,
    onSuccess,
  });
}
