import { memo } from "react";
import { TRY_AGAIN } from "src/features/buttons/titles";
import Button from "../Buttons/Button";
import { ButtonColors } from "../Buttons/buttons.types";
import DescriptionText from "../Text/DescriptionText";

const DEFAULT_ERROR_MESSAGE =
  "Oops! An error occurred! It has been reported and is being investigated.";
const BUTTON_DATA_TEST_ID = "reload-button";

type ErrorFallbackProps = {
  error?: string | Error;
  onReload?: () => void;
  buttonColor?: ButtonColors;
};

function ErrorFallback({
  error,
  onReload,
  buttonColor = ButtonColors.Gray,
}: ErrorFallbackProps) {
  const errorMessage =
    error instanceof Error ? error.message : error || DEFAULT_ERROR_MESSAGE;

  return (
    <div className={"flex flex-1 centered"}>
      <DescriptionText>{errorMessage}</DescriptionText>
      {onReload && (
        <Button
          title={TRY_AGAIN}
          color={buttonColor}
          onClick={onReload}
          dataTestid={BUTTON_DATA_TEST_ID}
        />
      )}
    </div>
  );
}

export default memo(ErrorFallback);
