import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import rolesService from "../services/rolesService";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import { EmployeeTypeDetail } from "../types/employeeTypes.types";

type CreateEmployeeTypeMutation = {
  onSuccess?: (data: EmployeeTypeDetail) => void;
  onError?: (error: AxiosError) => void;
};

export default function useCreateEmployeeTypeMutation({
  onSuccess,
  onError,
}: CreateEmployeeTypeMutation) {
  const handleErrors = useErrorHandling();
  return useMutation(rolesService.createEmployeeType, {
    onSuccess,
    onError: (error: AxiosError) => {
      handleErrors(error);
      onError?.(error);
    },
  });
}
