import * as React from "react";
const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={34}
    height={34}
    fill="none"
    {...props}
  >
    <circle cx={17} cy={17} r={17} fill="url(#a)" />
    <path
      fill="#000"
      d="M14.688 22.691c-.185.169-.447.309-.679.309-.231 0-.494-.147-.687-.316L9 18.756l1.374-1.249 3.643 3.311L23.649 12 25 13.27 14.688 22.69Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={17}
        x2={17}
        y1={0}
        y2={34}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FEC110" />
        <stop offset={0} stopColor="#FEC110" />
        <stop offset={1} stopColor="#CC9800" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgComponent;
