import { useQuery } from "@tanstack/react-query";
import todosService from "../../services/todosService";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

type UseGetScheduledTodosRecurrenceChoices = {
  enabled?: boolean;
  refetchOnWindowFocus?: boolean;
};

export default function useGetScheduledTodosRecurrenceChoicesQuery({
  enabled = true,
  refetchOnWindowFocus = false,
}: UseGetScheduledTodosRecurrenceChoices = {}) {
  return useQuery({
    queryKey: [QueryKeys.ScheduledTodoRecurrenceChoices],
    queryFn: todosService.getScheduledTodosRecurrenceChoices,
    enabled,
    refetchOnWindowFocus,
  });
}
