import { createSlice } from "@reduxjs/toolkit";

export enum VideoFormSteps {
  SelectVideo = "Select Video",
  SelectVideoTimes = "Select Video Times",
  AddDetails = "Add Details",
  ProcessVideo = "Show And Process Video",
}

export type VideoFormState = {
  step: VideoFormSteps | null;
  selectedVideoId: string | number | null;
};

const initialState: VideoFormState = {
  step: null,
  selectedVideoId: null,
};

const videoFormSlice = createSlice({
  name: "videoForm",
  initialState,
  reducers: {
    setVideoFormStep(state, action) {
      state.step = action.payload;
    },
    setSelectedVideoId(state, action) {
      state.selectedVideoId = action.payload;
    },
    resetVideoFormState() {
      return initialState;
    },
  },
});

export default videoFormSlice.reducer;

export const { resetVideoFormState, setVideoFormStep, setSelectedVideoId } =
  videoFormSlice.actions;
