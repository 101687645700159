type ClickableAnnotationProps = {
  id?: string;
  children?: React.ReactNode;
  x: number;
  y: number;
  width: number;
  height: number;
  className: string;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export default function ClickableAnnotation({
  id,
  children,
  x,
  y,
  width,
  height,
  className,
  onClick,
}: ClickableAnnotationProps) {
  return (
    <>
      <div
        key={id}
        id={id}
        className={className}
        onClick={onClick}
        style={{
          transform: `translate(${x}px, ${y}px)`,
          width: `${width}px`,
          height: `${height}px`,
        }}
      >
        {children ? children : null}
      </div>
    </>
  );
}
