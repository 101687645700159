import { capitalize } from "lodash";

interface DrfErrorData {
  [key: string]: string[];
}

/**
 * Convert DRF error response to a readable string.
 *
 * @param {DrfErrorData} errorData - The error data from Django Rest Framework
 * @return {string} - A formatted error message
 */
export const formatDrfErrors = (errorData: DrfErrorData): string[] => {
  return Object.keys(errorData).map(
    (field) => `${capitalize(field)}: ${errorData[field].join(", ")}`
  );
};
