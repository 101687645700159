import { PDFDocument, PDFPage } from "pdf-lib-incremental-save";

type Coords = {
  x: number;
  y: number;
  width: number;
  height: number;
};

async function drawImageOnPage(
  page: PDFPage,
  imageSrc: string,
  coords: Coords,
  pdfDoc: PDFDocument
) {
  if (imageSrc) {
    const image = await pdfDoc.embedPng(imageSrc);

    const imageAspectRatio = image.width / image.height;
    const availableWidth = coords.width;
    const availableHeight = coords.height;

    let imageWidth, imageHeight;
    if (availableWidth / availableHeight > imageAspectRatio) {
      imageHeight = availableHeight;
      imageWidth = imageHeight * imageAspectRatio;
    } else {
      imageWidth = availableWidth;
      imageHeight = imageWidth / imageAspectRatio;
    }

    const imageX = coords.x + (availableWidth - imageWidth) / 2;
    const imageY = coords.y + (availableHeight - imageHeight) / 2;

    page.drawImage(image, {
      x: imageX,
      y: imageY,
      width: imageWidth,
      height: imageHeight,
    });
  }
}

export default drawImageOnPage;
