import { useMemo } from "react";

interface ModuleContent {
  content_type?: string;
}

export const useContentType = (
  moduleContent: ModuleContent,
  contentType: string
): boolean =>
  useMemo(() => {
    return moduleContent?.content_type === contentType;
  }, [moduleContent, contentType]);
