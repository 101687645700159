import { z } from "zod";

export type PolicyDetailsFormSchemaType = z.infer<
  typeof policyDetailsFormSchema
>;

export const policyDetailsFormSchema = z.object({
  id: z.number().optional().or(z.string().optional()),
  title: z
    .string()
    .min(2, { message: "Title must be at least 2 characters long." }),
  category: z.string().or(z.number()),
});

export type PolicyUploadFormSchemaType = z.infer<typeof policyUploadFormSchema>;

export const policyUploadFormSchema = z.object({
  id: z.number().or(z.string()),
});

export type PolicyViewableByFormSchemaType = z.infer<
  typeof policyViewableByFormSchema
>;

export const policyViewableByFormSchema = z.object({
  id: z.number().or(z.string()),
  viewable_by: z.array(z.string().or(z.number())),
});

export type PolicyUpdateFormSchemaType = z.infer<typeof policyUpdateFormSchema>;

export const policyUpdateFormSchema = z.object({
  id: z.number().or(z.string()),
  title: z
    .string()
    .min(2, { message: "Title must be at least 2 characters long." }),
  category: z.number().or(z.string()),
  name: z.string().optional().nullable(),
  ext: z.string().optional().nullable(),
  viewable_by: z.array(z.string().or(z.number())),
  draft: z.boolean(),
  important: z.boolean(),
});
