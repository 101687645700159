const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      stroke="#FEC110"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M9.945 2.7 3.788 9.217c-.233.248-.458.735-.503 1.073l-.277 2.43c-.098.877.532 1.477 1.402 1.327l2.415-.412c.338-.06.81-.308 1.043-.563l6.157-6.517c1.065-1.125 1.545-2.408-.113-3.975-1.65-1.553-2.902-1.005-3.967.12Z"
    />
    <path
      stroke="#FEC110"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M8.917 3.788a4.595 4.595 0 0 0 4.088 3.862M2.25 16.5h13.5"
    />
  </svg>
)
export default SvgComponent
