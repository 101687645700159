import { useMemo } from "react";
import useGetDocumentSignatoryOrders from "../../hooks/useGetDocumentSignatoryOrders";
import VerticalStepper from "src/components/Steps/VerticalStepper";
import CheckIconSmall from "src/components/Icons/CheckIconSmall";
import YellowCircle24Icon from "src/components/Icons/YellowCircle24Icon";
import formatDateToString from "src/features/datetime/utils/formatDateToString";
import styles from "../ESignatures.module.css";
import getFullName from "src/features/employees/utils/getFullName";
import EmployeeImage from "src/components/EmployeeImage/EmployeeImage";

type DocumentSignatureProgressStepperProps = {
  documentId: string;
};

export default function DocumentSignatureProgressStepper({
  documentId,
}: DocumentSignatureProgressStepperProps) {
  const { data: signatoryOrders } = useGetDocumentSignatoryOrders({
    documentId,
  });

  const steps = useMemo(() => {
    return signatoryOrders
      ? signatoryOrders?.map((order) => {
          return {
            icon:
              order.signed_on || order.approved_on || order.denied_on ? (
                <CheckIconSmall />
              ) : (
                <YellowCircle24Icon />
              ),
            title: (
              <span className={styles.stepTitleSmall}>
                {!order.signed_on &&
                !order.approval_required &&
                !order.approved_on &&
                !order.denied_on
                  ? "Awaiting Signature"
                  : order.approval_required &&
                    !order.approved_on &&
                    !order.denied_on
                  ? "Awaiting Approval"
                  : order.approval_required &&
                    order.approved_on &&
                    !order.denied_on
                  ? `Approved ${formatDateToString(order.approved_on)}`
                  : order.approval_required && order.denied_on
                  ? `Denied ${formatDateToString(order.denied_on)}`
                  : `Signed ${formatDateToString(order.signed_on)}`}
              </span>
            ),
            content: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "0.5rem",
                }}
              >
                <div
                  id={String(order.id)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  <EmployeeImage
                    src={order.employee?.image}
                    alt={"Employee Image"}
                    containerStyle={{ height: 20, width: 20 }}
                  />
                  <span>{getFullName(order.employee)}</span>
                </div>
              </div>
            ),
          };
        })
      : [];
  }, [signatoryOrders]);

  return (
    <div style={{ padding: "1rem" }}>
      <VerticalStepper steps={steps} />
    </div>
  );
}
