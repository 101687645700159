import { useNavigate } from "react-router-dom";
import EmployeeTypesForm from "./EmployeeTypesForm";
import Card from "src/components/Cards/Card";
import Button from "src/components/Buttons/Button";
import ListCardHeader from "src/components/Lists/ListCardHeader";
import {
  ButtonColors,
  ButtonSizes,
} from "src/components/Buttons/buttons.types";

export default function UpdateEmployeeTypesForm() {
  const navigate = useNavigate();

  const handleSuccessfulSave = () => navigate("/departments/update");
  const handleCancel = () => navigate("/organization");

  return (
    <Card
      style={{
        minHeight: "20vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ListCardHeader title={"Update Roles"}>
        <Button
          title={"Go back"}
          color={ButtonColors.Gray}
          size={ButtonSizes.SM}
          onClick={() => navigate(-1)}
        />
      </ListCardHeader>
      <EmployeeTypesForm
        onSuccessfulSaveCallback={handleSuccessfulSave}
        onCancelCallback={handleCancel}
      />
    </Card>
  );
}
