import SiteHeader from "../../../components/Headers/SiteHeader";
import SearchInput from "src/components/Inputs/SearchInput";
import UnreadDiscussionsIndicator from "src/features/discussions/components/UnreadDiscussionsIndicator";
import useSetHeaderTitle from "src/features/header/hooks/useSetHeaderTitle";
import PageLayout from "src/components/Layout/PageLayout";
import MyLearningPathDetailsListCard from "../components/LPTake/MyLearningPathDetailsListCard";
import LearningPathTake from "../components/LPTake/LearningPathTake";

export default function LearningPathsTakeRoute() {
  useSetHeaderTitle("Learning Paths");

  return (
    <PageLayout
      header={
        <SiteHeader
          id="learning-paths-take-header"
          actions={
            <>
              <SearchInput id="lpSearch" />
              <UnreadDiscussionsIndicator />
            </>
          }
        />
      }
      mainContent={<LearningPathTake />}
      rightContent={<MyLearningPathDetailsListCard />}
    />
  );
}
