import getFullName from "src/features/employees/utils/getFullName"; 
import { EmployeeShiftStatus } from "../services/employeeShiftsService";
import { ShiftRequirementType } from "../services/shiftRequirementsService";
import useGetEmployeeShiftsListQuery from "./useGetEmployeeShiftsListQuery";

type UseGetFulfilledShiftsListQuery = {
  scheduleRoleId?: number;
  shift: ShiftRequirementType | null;
  search?: string;
};

export default function useGetFulfilledShiftsListQuery({
  scheduleRoleId,
  shift,
  search,
}: UseGetFulfilledShiftsListQuery) {
  return useGetEmployeeShiftsListQuery({
    scheduleRoleId,
    select: (data) => {
      let filteredData = data;

      if (search) {
        filteredData = filteredData?.filter((employeeShift) => {
          const fullName = getFullName(
            employeeShift.employee_schedule_role.employee
          );
          return fullName.toLowerCase().includes(search.toLowerCase());
        });
      }

      if (shift !== null) {
        filteredData = filteredData?.filter((employeeShift) => {
          return (
            employeeShift.shift_requirement.id === shift.id &&
            employeeShift.status !== EmployeeShiftStatus.PendingCancellation &&
            employeeShift.status !== EmployeeShiftStatus.Cancelled &&
            employeeShift.status !== EmployeeShiftStatus.Dropped
          );
        });
      }

      return filteredData;
    },
  });
}
