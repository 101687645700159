import { createSlice } from "@reduxjs/toolkit";

export enum DocumentAssignFormSteps {
  AddDetails = "Add Details",
  AssignAnnotations = "Assign Annotations",
  SelectSigningOrder = "Select Signing Order",
  ScheduleDocument = "Schedule Document",
  PublishDocument = "Publish Document",
}

export type DocumentAssignFormState = {
  step: DocumentAssignFormSteps | null;
  documentToEditId: string | null;
};

const initialState: DocumentAssignFormState = {
  step: null,
  documentToEditId: null,
};

export const documentAssignFormSlice = createSlice({
  name: "documentAssignForm",
  initialState,
  reducers: {
    resetDocumentAssignFormState: (state) => {
      state.step = null;
      state.documentToEditId = null;
    },
    setDocumentAssignFormStep: (state, action) => {
      state.step = action.payload;
    },
    setDocumentToEditId: (state, action) => {
      state.documentToEditId = action.payload;
    },
  },
});

export const {
  resetDocumentAssignFormState,
  setDocumentAssignFormStep,
  setDocumentToEditId,
} = documentAssignFormSlice.actions;

export default documentAssignFormSlice.reducer;
