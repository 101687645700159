import ScheduleKey from "./ScheduleKey";

export default function ScheduleKeyRow() {
  return (
    <div
      className="overflow-x-auto"
      style={{
        borderTop: "1px solid var(--clearpath-light-gray)",
        backgroundColor: "var(--clearpath-dark-gray)",
        borderRadius: "0 0 15px 15px",
      }}
    >
      <div className="schedule-key-row">
        <ScheduleKey
          className="bg-pending-yellow-10"
          color=""
          text="Unpublished"
        />
        <ScheduleKey color="var(--clearpath-yellow-10)" text="Empty" />
        <ScheduleKey color="var(--clearpath-yellow-50)" text="Partial" />
        <ScheduleKey color="var(--clearpath-yellow)" text="Filled" />
        <ScheduleKey color="var(--clearpath-danger)" text="Overfilled" />
      </div>
    </div>
  );
}
