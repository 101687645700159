import { OptionsType } from "../components/Documents/DocumentSignForm";
import { DocumentAnnotation } from "../types/document.types";
import { AnnotationType } from "../types/template.types";

/**
 * Checks if all dropdown and checkbox annotations have values.
 *
 * For checkboxes, a value means the associated array in `annotationOptions` is not empty.
 * For dropdowns, a value means the associated value in `annotationOptions` is not null.
 *
 * @param {DocumentAnnotation[]} annotations - An array of document annotations.
 * @param {OptionsType} annotationOptions - An object mapping annotation IDs to their current values.
 * @returns {boolean} True if all dropdown and checkbox annotations have values, false otherwise.
 */
function checkAllOptionsHaveValues(
  annotations: DocumentAnnotation[],
  annotationOptions: OptionsType
): boolean {
  const dropdowns = annotations.filter(
    (annotation) => annotation.type === AnnotationType.Dropdown
  );
  const checkboxes = annotations.filter(
    (annotation) => annotation.type === AnnotationType.Checkbox
  );
  const allDropdownsAndCheckboxes = [...dropdowns, ...checkboxes];

  return allDropdownsAndCheckboxes.every((annotation) => {
    if (annotation.type === AnnotationType.Checkbox) {
      return annotationOptions[annotation.id].length > 0;
    } else {
      return annotationOptions[annotation.id] !== null;
    }
  });
}

export default checkAllOptionsHaveValues;
