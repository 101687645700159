type TimeOffRequestService = {
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function LocalSearchInput({
  placeholder,
  value,
  onChange,
}: TimeOffRequestService) {
  return (
    <input
      type="text"
      className="inputBlack"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
}
