import { memo } from "react";
import ContentBody from "./ContentBody";
import FeatureContent from "./FeatureContent";
import MainContent from "./MainContent";
import RightContent from "./RightContent";

type PageLayoutProps = {
  header: React.ReactNode;
  mainContent: React.ReactNode;
  rightContent?: React.ReactNode;
};

function PageLayout({ header, mainContent, rightContent }: PageLayoutProps) {
  return (
    <FeatureContent header={header}>
      {rightContent ? (
        <ContentBody>
          <MainContent>{mainContent}</MainContent>
          <RightContent>{rightContent}</RightContent>
        </ContentBody>
      ) : (
        mainContent
      )}
    </FeatureContent>
  );
}

export default memo(PageLayout);
