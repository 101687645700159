import React, { memo } from "react";
import styles from "./PageContainer.module.css";

interface Props {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

function PageContainer({ children, style }: Props) {
  return (
    <div className={styles.pageContainer} style={style}>
      {children}
    </div>
  );
}

export default memo(PageContainer);
