import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useAlert } from "@blaumaus/react-alert";
import todosService from "../../services/todosService";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import { ScheduledTodoDetail } from "../../types/todos.types";

type Props = {
  onSuccess?: (data: ScheduledTodoDetail) => void;
};

export default function useCreateScheduledTodoMutation({ onSuccess }: Props) {
  const alert = useAlert();
  const handleErrors = useErrorHandling();

  return useMutation(todosService.createScheduledTodo, {
    onSuccess: (data) => {
      alert.success("Successfully created scheduled todo!");
      onSuccess?.(data);
    },
    onError: (error: AxiosError) => handleErrors(error),
  });
}
