import { useMutation } from "@tanstack/react-query";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import shiftTransferService, {
  ShiftTransfer,
} from "../services/shiftTransferService";

type AcceptShiftSwapMutation = {
  onSuccess?: (data: ShiftTransfer) => void;
};

export default function useDeclineShiftSwapMutation({
  onSuccess,
}: AcceptShiftSwapMutation) {
  const handleErrors = useErrorHandling();
  return useMutation({
    mutationFn: shiftTransferService.declineShiftSwap,
    onSuccess,
    onError: handleErrors,
  });
}
