import { useMutation } from "@tanstack/react-query";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import { ShiftRequirementPartialUpdateTypeForm } from "../components/ShiftRequirementPlanningDialog";
import shiftRequirementsService, {
  ShiftRequirementType,
} from "../services/shiftRequirementsService";

type PartialUpdateShiftRequirementVariables = {
  data: ShiftRequirementPartialUpdateTypeForm;
};

type UsePartialUpdateShiftRequirement = {
  onSuccess?: (data: ShiftRequirementType) => void;
};

export default function usePartialUpdateShiftRequirementMutation({
  onSuccess,
}: UsePartialUpdateShiftRequirement) {
  const handleErrors = useErrorHandling();
  return useMutation({
    mutationFn: async (variables: PartialUpdateShiftRequirementVariables) =>
      shiftRequirementsService.partialUpdateShiftRequirement(variables.data),
    onSuccess,
    onError: handleErrors,
  });
}
