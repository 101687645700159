import React, { memo } from "react";

type PlaceholderProps = {
  title: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

function Placeholder({ title, className, style }: PlaceholderProps) {
  return (
    <div
      className={`${className} ${"placeholder"}`}
      style={style}
      data-testid="placeholder"
    >
      {title}
    </div>
  );
}

export default memo(Placeholder);
