import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import shiftRequirementsService from "../services/shiftRequirementsService";

type UseGetShiftRequirementsQueryProps = {
  departmentId?: number;
  startDate?: string;
  endDate?: string;
};

export default function useGetShiftRequirementsByDepartmentAndDatesQuery({
  departmentId,
  startDate,
  endDate,
}: UseGetShiftRequirementsQueryProps) {
  return useQuery({
    queryFn:
      departmentId && startDate && endDate
        ? () =>
            shiftRequirementsService.getShiftRequirements({
              departmentId,
              startDate,
              endDate,
            })
        : undefined,
    queryKey: [
      QueryKeys.ShiftRequirementsList,
      departmentId,
      startDate,
      endDate,
    ],
    enabled: !!departmentId && !!startDate && !!endDate,
    retry: false,
  });
}
