export const EN_US = "en-US";
export const NUMERIC = "numeric";
export const SHORT = "short";
export const TWO_DIGIT = "2-digit";

export const DATE_FORMAT = {
  day: NUMERIC,
  month: SHORT,
  year: TWO_DIGIT,
} as const;

export const DATETIME_FORMAT: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  hour12: true,
};
