import React from "react";

import styles from "./ThreeDotsMenuItem.module.css";

type ThreeDotsMenuItemProps = {
  children: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  id?: string;
};

export default function ThreeDotsMenuItem({
  children,
  onClick,
  id,
}: ThreeDotsMenuItemProps) {
  return (
    <div className={styles.dropdownMenuItem}>
      <button className={styles.dropdownBtn} onClick={onClick} id={id}>
        {children}
      </button>
    </div>
  );
}
