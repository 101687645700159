import React from "react";
import styles from "./InputContainer.module.css";

type InputContainerProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
};

function InputContainer({ children, style }: InputContainerProps) {
  return (
    <div className={styles.inputContainer} style={style}>
      {children}
    </div>
  );
}

export default InputContainer;
