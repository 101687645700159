import { createSlice } from "@reduxjs/toolkit";

export enum LearningPathPolicyFormSteps {
  SelectPolicy = "Select Policy",
  SelectPages = "Select Pages",
  AddDetails = "Add Details",
  SelectPage = "Select Page To Extract",
}

export type LearningPathPolicyFormState = {
  step: LearningPathPolicyFormSteps | null;
  selectedPolicyId: number | null;
};

const initialState: LearningPathPolicyFormState = {
  step: null,
  selectedPolicyId: null,
};

const learningPathsPolicyFormSlice = createSlice({
  name: "learningPathsPolicyForm",
  initialState,
  reducers: {
    setLearningPathPolicyFormStep(state, action) {
      state.step = action.payload;
    },
    setLearningPathsSelectedPolicyId(state, action) {
      state.selectedPolicyId = action.payload;
    },
    resetLearningPathsPolicyFormState(state) {
      state.step = LearningPathPolicyFormSteps.SelectPolicy;
      state.selectedPolicyId = null;
    },
  },
});

export default learningPathsPolicyFormSlice.reducer;

export const {
  resetLearningPathsPolicyFormState,
  setLearningPathPolicyFormStep,
  setLearningPathsSelectedPolicyId,
} = learningPathsPolicyFormSlice.actions;
