import { useQueryClient } from "@tanstack/react-query";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import Dialog from "src/components/Dialog/Dialog";
import DialogDescription from "src/components/Dialog/DialogDescription";
import DialogFooter from "src/components/Dialog/DialogFooter";
import DialogHeader from "src/components/Dialog/DialogHeader";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import useDeleteShiftRequirementMutation from "../hooks/useDeleteShiftRequirementMutation";
import { ShiftRequirementType } from "../services/shiftRequirementsService";

type DeleteShiftDialogProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClose?: () => void;
  shiftRequirement: ShiftRequirementType;
};

export default function ShiftRequirementDeleteDialog({
  isOpen,
  setIsOpen,
  onClose,
  shiftRequirement,
}: DeleteShiftDialogProps) {
  const queryClient = useQueryClient();

  const deleteShiftRequirementMutation = useDeleteShiftRequirementMutation({
    onSuccess: async () => {
      setIsOpen(false);
      await queryClient.invalidateQueries([QueryKeys.ShiftRequirementsList]);
      await queryClient.invalidateQueries([QueryKeys.EmployeeShiftsList]);
    },
  });

  const handleDeleteShift = () => {
    if (!shiftRequirement?.id) return;
    deleteShiftRequirementMutation.mutate(shiftRequirement?.id);
  };

  return (
    <Dialog isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <DialogHeader>Delete Shift</DialogHeader>
      <DialogDescription>
        Are you sure you want to delete this shift? It will delete all employee
        shifts associated with this shift. This action cannot be undone.
      </DialogDescription>
      <DialogFooter>
        <Button
          color={ButtonColors.GrayAndYellow}
          onClick={() => setIsOpen(false)}
        >
          Cancel
        </Button>
        <Button color={ButtonColors.Red} onClick={handleDeleteShift}>
          Delete
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
