import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import policiesService from "../services/policiesService";
import { Policy } from "../types/policies.types";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";

type PartialUpdatePolicyMutation = {
  onSuccess?: (data: Policy) => void;
};

export default function usePartialUpdatePolicyMutation({
  onSuccess,
}: PartialUpdatePolicyMutation) {
  const handleErrors = useErrorHandling();
  return useMutation(policiesService.partialUpdatePolicy, {
    onSuccess,
    onError: (error: AxiosError) => handleErrors(error),
  });
}
