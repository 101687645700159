import axiosClient from "src/features/axios/client";
import { Group } from "../types/groups.types";

class GroupsService {
  async getGroups(): Promise<Group[]> {
    return await axiosClient.get("/api/groups").then((res) => res.data);
  }
}

const groupsService = new GroupsService();

export default groupsService;
