import { UploadProgressCallback } from "src/features/files/hooks/useFileUpload";
import axiosClient from "../../axios/client";
import {
  Policy,
  PolicyCategory,
  PolicyListItem,
} from "../types/policies.types";
import {
  PolicyDetailsFormSchemaType,
  PolicyUpdateFormSchemaType,
} from "../zod/policyStepFormSchema";
import { handleUploadProgress } from "src/features/files/utils/handleUploadProgress";
import { PaginatedApiResponse } from "src/features/axios/axios.types";
import { InfiniteQueryFilters } from "src/components/Lists/FilterableListSidebar";

class PoliciesService {
  async createPolicy(data: FormData) {
    return await axiosClient
      .post("/api/policies/", data)
      .then((res) => res.data);
  }

  async createPolicyV2(data: PolicyDetailsFormSchemaType): Promise<Policy> {
    return await axiosClient
      .post("/api/policies/", data)
      .then((res) => res.data);
  }

  async createPolicyCategory({ title }: { title: string }) {
    return await axiosClient
      .post("/api/policies-categories/", { title })
      .then((res) => res.data);
  }

  async deletePolicy(id: string) {
    return await axiosClient
      .delete(`/api/policies/${id}`)
      .then((res) => res.data);
  }

  async getPolicies() {
    return await axiosClient.get("/api/policies/").then((res) => res.data);
  }

  async getPoliciesPaginated({
    pageParam,
    startDate,
    endDate,
    ...filters
  }: InfiniteQueryFilters): Promise<PaginatedApiResponse<PolicyListItem>> {
    return await axiosClient.get("/api/policies/", {
      params: {
        page: pageParam,
        start_date: startDate
          ? new Date(startDate).toISOString().split("T")[0]
          : undefined,
        end_date: endDate
          ? new Date(endDate).toISOString().split("T")[0]
          : undefined,
        categories: Array.isArray(filters.categories)
          ? filters.categories.join(",")
          : "",
      },
    });
  }

  async getPolicyById(id: string): Promise<Policy> {
    return await axiosClient.get(`/api/policies/${id}`).then((res) => res.data);
  }

  async getPolicyCategories(): Promise<PolicyCategory[]> {
    return await axiosClient
      .get("/api/policies-categories/")
      .then((res) => res.data);
  }

  async getPolicyReadUnreadStatus(id: string | number) {
    return await axiosClient
      .get(`/api/policies/${id}/read-status/`)
      .then((res) => res.data);
  }

  async getPoliciesReadUnreadPieChartData() {
    return await axiosClient
      .get("/api/policies-read-unread-pie-chart/")
      .then((res) => res.data);
  }

  async getPoliciesCountByRecipientTypeBarChart() {
    return await axiosClient
      .get("/api/policies-count-by-employee-type-bar-chart/")
      .then((res) => res.data);
  }

  async partialUpdatePolicy(
    data: Partial<PolicyUpdateFormSchemaType>
  ): Promise<Policy> {
    return await axiosClient
      .patch(`/api/policies/${data.id}/`, data)
      .then((res) => res.data);
  }

  async partialUpdatePolicyUsingFormData(
    id: string,
    formData: FormData
  ): Promise<Policy> {
    return await axiosClient
      .patch(`/api/policies/${id}/`, formData)
      .then((res) => res.data);
  }

  async updatePolicy(id: string | number, data: FormData) {
    return await axiosClient
      .patch(`/api/policies/${id}/`, data)
      .then((res) => res.data);
  }

  async uploadPolicyToS3(
    url: string,
    file: File,
    uploadProgressCallback?: UploadProgressCallback
  ): Promise<{ presigned_url: string; status: number }> {
    return await axiosClient.put(url, file, {
      headers: {
        "Content-Type": file.type,
      },
      onUploadProgress: uploadProgressCallback
        ? (progressEvent) =>
            handleUploadProgress(progressEvent, uploadProgressCallback)
        : undefined,
    });
  }
}

const policiesService = new PoliciesService();

export default policiesService;
