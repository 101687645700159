import { format } from "date-fns";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import Button from "src/components/Buttons/Button";
import {
  ButtonColors,
  ButtonSizes,
} from "src/components/Buttons/buttons.types";
import Card from "src/components/Cards/Card";
import CardDetailHeader from "src/components/Cards/CardDetailHeader";
import ErrorFallback from "src/components/Errors/ErrorFallback";
import ListItemText from "src/components/Lists/ListItemText";
import useGetShiftTransferById from "../hooks/useGetShiftTransferById";
import ShiftTransferCard from "./ShiftTransferCard";

export default function ShiftTransferDetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: shiftTransfer,
    refetch: refetchShiftTransfer,
    isError: isShiftTransferError,
    isLoading: isShiftTransferLoading,
  } = useGetShiftTransferById({ id });

  return (
    <Card>
      <CardDetailHeader
        info={
          <ListItemText
            title={shiftTransfer?.type || "Shift Transfer"}
            subTitle={
              shiftTransfer?.created_on ? (
                <span>
                  {`Created on ${format(
                    new Date(shiftTransfer.created_on),
                    "MM/dd/yy"
                  )}`}
                </span>
              ) : null
            }
          />
        }
        actions={
          <Button
            title={"Go back"}
            color={ButtonColors.Gray}
            size={ButtonSizes.SM}
            onClick={() => navigate(-1)}
          />
        }
      />
      <div className="flex flex-1 flex-col p-10">
        {isShiftTransferError ? (
          <ErrorFallback onReload={refetchShiftTransfer} />
        ) : null}
        {isShiftTransferLoading ? <Skeleton height={400} /> : null}
        {!isShiftTransferLoading && shiftTransfer ? (
          <ShiftTransferCard key={shiftTransfer.id} transfer={shiftTransfer} />
        ) : null}
      </div>
    </Card>
  );
}
