const mapChoices = (choices?: [number, string][] | undefined) => {
  if (!choices) {
    return [];
  }

  return choices.map(([value, label]) => ({
    value,
    label,
    name: label,
  }));
};

export default mapChoices;
