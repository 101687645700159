import React, { forwardRef, memo } from "react";

interface TabProps {
  title: React.ReactNode;
  badge?: React.ReactNode;
  isActive?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  isVisible?: boolean;
}

const Tab = forwardRef<HTMLDivElement, TabProps>(
  ({ title, badge, isActive, onClick, children, style, className }, ref) => {
    return (
      <div
        ref={ref}
        className={`${"tab relative"} ${isActive ? "active" : ""} ${
          className || ""
        }`}
        style={style}
        onClick={onClick}
      >
        {title}
        {badge}
      </div>
    );
  }
);

export default memo(Tab);
