import React, { memo, useMemo } from "react";

type CardHeaderProps = {
  title?: React.ReactNode;
  action?: React.ReactNode;
  children?: React.ReactNode;
  containerClassName?: string;
};

function CardHeader({
  title,
  action,
  children,
  containerClassName,
}: CardHeaderProps) {
  const containerClass = useMemo(() => {
    return `${"cardHeader"} ${containerClassName}`;
  }, [containerClassName]);

  return (
    <div className={containerClass}>
      {title ? <div className={"cardHeaderTitle"}>{title}</div> : null}
      {action ? <div className={"cardHeaderAction"}>{action}</div> : null}
      {children}
    </div>
  );
}

export default memo(CardHeader);
