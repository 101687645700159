import axiosClient from "../axios/client";
import { EmployeeUpdateFormData } from "src/features/employees/components/EmployeeDetailForm";
import { AuthenticatedEmployee } from "./types/employees.type";

const activateEmployee = (employeeId: string) =>
  axiosClient.post(`/api/activate/${employeeId}/`);

const createAdmin = (employee: AuthenticatedEmployee) =>
  axiosClient.post("/api/create-admin/", employee);

const getLoginsGraph = () =>
  axiosClient.get("/api/logins-graph/").then((res) => res.data);

const getLogins = () => axiosClient.get("/api/logins/").then((res) => res.data);

const getSignupsGraph = () =>
  axiosClient.get("/api/signups-graph/").then((res) => res.data);

const getSignups = () =>
  axiosClient.get("/api/signups/").then((res) => res.data);

// TODO: These types ???
const updateEmployee = async (employee: EmployeeUpdateFormData) =>
  axiosClient.patch(`/api/employees/${employee.id}/`, employee);

export {
  activateEmployee,
  createAdmin,
  getSignupsGraph,
  getSignups,
  getLoginsGraph,
  getLogins,
  updateEmployee,
};
