import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import employeeShiftsService, {
  EmployeeShift,
  EmployeeShiftStatus,
} from "../services/employeeShiftsService";

type UseGetEmployeeShiftsListQuery = {
  scheduleRoleId?: number;
  startDate?: string;
  status?: EmployeeShiftStatus;
  notEmployeeId?: number;
  select: (data: EmployeeShift[]) => EmployeeShift[] | undefined;
};

export default function useGetEmployeeShiftsListQuery({
  scheduleRoleId,
  startDate,
  status,
  notEmployeeId,
  select,
}: UseGetEmployeeShiftsListQuery) {
  return useQuery({
    queryFn: scheduleRoleId
      ? () =>
          employeeShiftsService.getEmployeeShifts(
            scheduleRoleId,
            startDate,
            status,
            notEmployeeId
          )
      : undefined,
    queryKey: [
      QueryKeys.EmployeeShiftsList,
      scheduleRoleId,
      startDate,
      status,
      notEmployeeId,
    ],
    enabled: !!scheduleRoleId,
    select,
  });
}
