import React from "react";
import DropDownV2 from "./DropDownV2";
import styles from "./ThreeDotsDropDownV2.module.css";

type ThreeDotsDropDownV2Props = {
  children: React.ReactNode;
  setIsFilterVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isFilterVisible: boolean;
  icon?: React.ReactNode;
};

export default function ThreeDotsDropDownV2({
  children,
  icon,
  setIsFilterVisible,
  isFilterVisible,
}: ThreeDotsDropDownV2Props) {
  return (
    <DropDownV2
      btnClassName={styles.optionsButton}
      icon={icon}
      setIsFilterVisible={setIsFilterVisible}
      isFilterVisible={isFilterVisible}
    >
      {children}
    </DropDownV2>
  );
}
