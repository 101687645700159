import { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Document, Page } from "react-pdf";
import calculate_a4_landscape_from_width from "src/features/pdf/utils/calculate_a4_landscape_from_height";
import styles from "./LearningPathTakeCompleted.module.css";

type LearningPathTakeCompletedProps = {
  certificate_url?: string;
};

export default function LearningPathTakeCompleted({
  certificate_url,
}: LearningPathTakeCompletedProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);

      const handleResize = () => {
        if (containerRef.current) {
          setContainerWidth(containerRef.current.offsetWidth);
        }
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  const onDocumentLoadSuccess = () => {};

  return (
    <div
      ref={containerRef}
      className={styles.completedContainer}
      style={{ height: certificate_url ? "auto" : "400px" }}
    >
      <h1 className="cp-yellow">
        Congratulations! <br />
        <span className="text-xl font-bold">
          You have completed this Learning Path!
        </span>
      </h1>
      {certificate_url ? (
        <div className="flex gap-20 p-10">
          <div
            className="border-yellow cursor-pointer"
            onClick={() => window.open(certificate_url, "_blank")}
          >
            <Document
              file={certificate_url}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={
                <Skeleton
                  height={calculate_a4_landscape_from_width(
                    containerWidth || 350
                  )}
                  width={containerWidth || 350}
                />
              }
            >
              <Page
                pageNumber={1}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                width={containerWidth || 350}
              />
            </Document>
          </div>
        </div>
      ) : null}
    </div>
  );
}
