import axiosClient from "src/features/axios/client";
import { InfiniteQueryFilters } from "src/components/Lists/FilterableListSidebar";
import {
  AssessmentDetail,
  AssessmentListItem,
} from "../types/assessments.types";
import { PaginatedApiResponse } from "src/features/axios/axios.types";
import { AssessmentUpdateFormSchemaType } from "../zod/assessmentSchema";
import { Question } from "src/features/learningPaths/learningPaths.types";

export type GetAssessmentsSelectListResponse = {
  id: number;
  title: string;
  category: string;
};

class AssessmentService {
  async addQuestionToAssessment({
    id,
    questionId,
  }: {
    id: string | number;
    questionId: string | number;
  }): Promise<any> {
    return await axiosClient
      .post(`/api/assessments/${id}/add-question/`, {
        question_id: questionId,
      })
      .then((res) => res.data);
  }

  async createAssessment(data: any): Promise<any> {
    return await axiosClient
      .post("/api/assessments/", data)
      .then((res) => res.data);
  }

  async createAssessmentCopy(id: string): Promise<any> {
    return await axiosClient
      .post(`/api/assessments/${id}/copy/`)
      .then((res) => res.data);
  }

  async createQuestion(data: any): Promise<Question> {
    return await axiosClient
      .post(`/api/questions/`, data)
      .then((res) => res.data);
  }

  async deleteAssessment(id: string): Promise<any> {
    return await axiosClient
      .delete(`/api/assessments/${id}/`)
      .then((res) => res.data);
  }

  async deleteAssessmentQuestion({
    id,
    questionId,
  }: {
    id: string | number;
    questionId: string | number;
  }): Promise<any> {
    return await axiosClient
      .post(`/api/assessments/${id}/remove-question/`, {
        question_id: questionId,
      })
      .then((res) => res.data);
  }

  async getAssessment(id: string): Promise<any> {
    return await axiosClient
      .get(`/api/assessments/${id}`)
      .then((res) => res.data);
  }

  async getAssessments(): Promise<AssessmentListItem[]> {
    return await axiosClient.get("/api/assessments/").then((res) => res.data);
  }

  async getAssessmentsPaginated({
    pageParam,
    startDate,
    endDate,
    ...filters
  }: InfiniteQueryFilters): Promise<PaginatedApiResponse<AssessmentListItem>> {
    return await axiosClient.get("/api/assessments/", {
      params: {
        page: pageParam,
        start_date: startDate
          ? new Date(startDate).toISOString().split("T")[0]
          : undefined,
        end_date: endDate
          ? new Date(endDate).toISOString().split("T")[0]
          : undefined,
        ...filters,
      },
    });
  }

  async getAssessmentTodos(id: string): Promise<any> {
    return await axiosClient
      .get(`/api/assessments/${id}/todos/`)
      .then((res) => res.data);
  }

  async getSitting(id: string): Promise<any> {
    return await axiosClient
      .get(`/api/sittings/${id}/`)
      .then((res) => res.data);
  }

  async getSittings({
    assessmentId,
    page = 1,
    sortBy,
    sortDirection,
    ...filters
  }: any): Promise<any> {
    return await axiosClient
      .get("/api/sittings/", {
        params: {
          assessment_id: assessmentId,
          page,
          sortBy,
          sortDirection,
          ...filters,
        },
      })
      .then((res) => res.data);
  }

  async findAndTakeSitting(assessmentId: string): Promise<any> {
    return await axiosClient
      .post(`/api/assessments/${assessmentId}/find-and-take/`)
      .then((res) => res.data);
  }

  async getSittingTakeData(id: string): Promise<any> {
    return await axiosClient
      .get(`/api/assessments/${id}/take`)
      .then((res) => res.data);
  }

  async partialUpdateAssessment(
    data: Partial<AssessmentUpdateFormSchemaType>
  ): Promise<AssessmentDetail> {
    return await axiosClient
      .patch(`/api/assessments/${data.id}/`, data)
      .then((res) => res.data);
  }

  async postSittingMcAnswers({ id, answers }: any): Promise<any> {
    return await axiosClient
      .post(`/api/assessments/${id}/take/`, { answers })
      .then((res) => res.data);
  }

  async postSittingNumericAnswer({ id, answer }: any): Promise<any> {
    return await axiosClient
      .post(`/api/assessments/${id}/take/`, { answer })
      .then((res) => res.data);
  }

  async reissueAssessment({
    id,
    employeeId,
  }: {
    id: string | number;
    employeeId: string | number;
  }): Promise<any> {
    return await axiosClient
      .post(`/api/assessments/${id}/reissue/`, { employee_id: employeeId })
      .then((res) => res.data);
  }

  async toggleEmployeeAnswer({ id, questionId }: any): Promise<any> {
    return await axiosClient
      .post(`/api/sittings/${id}/toggle-answer/`, {
        question_id: Number(questionId),
      })
      .then((res) => res.data);
  }

  async partialUpdateQuestion(data: FormData, id: string): Promise<Question> {
    return await axiosClient
      .patch(`/api/questions/${id}/`, data)
      .then((res) => res.data);
  }

  async updateAssessment(id: string | number, data: any): Promise<any> {
    return await axiosClient
      .put(`/api/assessments/${id}/`, data)
      .then((res) => res.data);
  }

  async updateQuestion(data: any) {
    return await axiosClient
      .put(`/api/questions/${data.id}/`, data)
      .then((res) => res.data);
  }

  async updateQuestionPosition(
    questionId: number,
    assessmentId: number,
    newPosition: number
  ) {
    return await axiosClient
      .patch(`/api/questions/${questionId}/update-position/`, {
        assessment_id: assessmentId,
        new_position: newPosition,
      })
      .then((res) => res.data);
  }
}

const assessmentService = new AssessmentService();

export default assessmentService;
