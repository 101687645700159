import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

import ScheduledTodosForm from "src/features/todos/components/ScheduledTodosForm";
import { TodoObjectTypes } from "src/features/todos/types/todosEnums.types";
import { ScheduledTodosFormSteps } from "src/features/todos/todosSlice";
import ScheduledTodosTable from "src/features/todos/components/ScheduledTodosTable";
import { useAppDispatch } from "src/store";
import useGetPolicyQuery from "../../hooks/useGetPolicyQuery";
import usePolicyFormState from "../../hooks/usePolicyFormState";
import { PolicyFormSteps, setPolicyFormStep } from "../../slices/policySlice";
import StepContainer from "src/components/Steps/StepContainer";
import useGetScheduledTodosListQuery from "src/features/todos/hooks/queries/useGetScheduledTodosListQuery";
import useScheduledTodosFormState from "src/features/todos/hooks/redux/useScheduledTodosFormState";

export default function PolicyScheduleForm() {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const { selectedPolicyId, step } = usePolicyFormState();
  const { scheduledTodosFormStep } = useScheduledTodosFormState();

  const combinedId = id || selectedPolicyId;

  const { data: policy, isLoading: isPolicyLoading } = useGetPolicyQuery({
    id: String(combinedId),
  });

  const { data: scheduledTodos, isLoading: isLoadingScheduledTodos } =
    useGetScheduledTodosListQuery({
      objectId: combinedId,
      objectType: TodoObjectTypes.Policy,
    });

  const isLoading = isPolicyLoading || isLoadingScheduledTodos;

  const handleGoToPublishPolicyStep = () =>
    dispatch(setPolicyFormStep(PolicyFormSteps.PublishPolicy));

  return (
    <StepContainer>
      <div className="mb-20">
        <span className="text-muted">
          Assign "To-Do" tasks related to this policy to specific employees,
          roles, or departments. These tasks must be completed by the designated
          recipients. Please note that scheduled tasks will only be dispatched
          once the policy is published.
        </span>
      </div>
      {isLoading ? <Skeleton height={200} /> : null}
      {!isLoading &&
      scheduledTodosFormStep === ScheduledTodosFormSteps.ListScheduledTodos ? (
        <ScheduledTodosTable
          scheduledTodos={scheduledTodos}
          isScheduleStep={step === PolicyFormSteps.SchedulePolicy}
          onNextStep={handleGoToPublishPolicyStep}
        />
      ) : null}
      {!isLoading &&
      policy &&
      scheduledTodosFormStep ===
        ScheduledTodosFormSteps.CreateUpdateScheduledTodos &&
      policy?.id ? (
        <ScheduledTodosForm
          objectId={policy.id}
          objectType={TodoObjectTypes.Policy}
          onSuccess={handleGoToPublishPolicyStep}
        />
      ) : null}
    </StepContainer>
  );
}
