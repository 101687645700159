import React from "react";
import { useAlert } from "@blaumaus/react-alert";

import styles from "./UploadAndDisplayImage.module.css";
import CloseSmallIcon from "../Icons/CloseSmallIcon";
import Image from "../Images/Image";
import ImageUploadIcon from "../Icons/ImageUploadIcon";
import UserImageIcon from "../Icons/UserImageIcon";

type UploadAndDisplayImageProps = {
  formValues: any;
  setFormValues: any;
  containerClassName?: string;
};

const UploadAndDisplayImage = ({
  formValues,
  setFormValues,
  containerClassName,
}: UploadAndDisplayImageProps) => {
  const alert = useAlert();

  const handleRemoveImage = () => {
    setFormValues({ ...formValues, image: null });
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target?.files[0].size > 3000000) {
      handleRemoveImage();
      alert.error("Image size should be less than 3MB.");
    } else if (e.target.files) {
      setFormValues({ ...formValues, image: e.target.files[0] });
    }
  };

  return (
    <div className={`${styles.imageContainer} ${containerClassName}`}>
      {formValues.image ? (
        <>
          <div className={styles.imageRemoveIcon}>
            <button style={{ all: "unset" }} onClick={handleRemoveImage}>
              <CloseSmallIcon />
            </button>
          </div>
          <div>
            <Image
              src={formValues.image}
              alt={"Editable Image"}
              className={styles.image}
            />
          </div>
        </>
      ) : (
        <div>
          <UserImageIcon />
        </div>
      )}
      <label className={styles.imageUploadIcon} id="imageUpload">
        <ImageUploadIcon />
        <input
          type="file"
          name="image"
          accept="image/jpeg,image/png"
          id="imageUploadInput"
          onChange={handleImageChange}
        />
      </label>
    </div>
  );
};

export default UploadAndDisplayImage;
