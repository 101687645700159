import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAlert } from "@blaumaus/react-alert";
import { AxiosError } from "axios";
import employeeShiftsService from "../services/employeeShiftsService";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import {
  ShiftRequirementStatus,
  ShiftRequirementType,
} from "../services/shiftRequirementsService";

type UseDeleteEmployeeShiftMutation = {
  scheduleRoleId?: number;
  departmentId?: number;
};

type DeleteEmployeeShiftVariables = {
  id: number;
  weeklyScheduleId: number;
  shiftRequirementId: number;
};

export default function useDeleteEmployeeShiftMutation({
  scheduleRoleId,
  departmentId,
}: UseDeleteEmployeeShiftMutation) {
  const alert = useAlert();
  const queryClient = useQueryClient();
  const handleErrors = useErrorHandling();

  return useMutation({
    mutationFn: async ({ id }: DeleteEmployeeShiftVariables) =>
      employeeShiftsService.deleteEmployeeShift(id),
    onSuccess: async (_data, variables, _context) => {
      alert.success("Employee shift removed successfully");
      await queryClient.invalidateQueries([
        QueryKeys.EmployeeShiftsList,
        scheduleRoleId,
      ]);
      await queryClient.invalidateQueries([
        QueryKeys.EmployeeShiftsList,
        {
          department: departmentId,
          weeklySchedule: variables.weeklyScheduleId,
        },
      ]);
      queryClient.setQueryData<ShiftRequirementType[]>(
        [QueryKeys.ShiftRequirementsList, variables.weeklyScheduleId],
        (prevData) => {
          if (!prevData) return prevData;
          return prevData.map((shiftRequirement) => {
            if (shiftRequirement.id === variables.shiftRequirementId) {
              return {
                ...shiftRequirement,
                filled_count: shiftRequirement.filled_count - 1,
                status: ShiftRequirementStatus.Pending,
              };
            }
            return shiftRequirement;
          });
        }
      );
    },
    onError: (error: AxiosError) => handleErrors(error),
  });
}
