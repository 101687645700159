import * as React from "react";

const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <g
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      opacity={0.6}
    >
      <path d="M9.945 2.7 3.788 9.218c-.233.247-.458.735-.503 1.072l-.277 2.43c-.098.877.532 1.477 1.402 1.327l2.415-.412c.338-.06.81-.307 1.043-.563l6.157-6.517c1.065-1.125 1.545-2.407-.113-3.975-1.65-1.552-2.902-1.005-3.967.12Z" />
      <path d="M8.918 3.787a4.595 4.595 0 0 0 4.087 3.863M2.25 16.5h13.5" />
    </g>
  </svg>
);
export default SvgComponent;
