import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useCallback } from "react";
import { RotatingLines } from "react-loader-spinner";

import StepContainer from "src/components/Steps/StepContainer";
import EditingContainer from "src/features/learningPaths/components/LPBuilder/Forms/EditingContainer";
import useMemoFormState from "../../hooks/useMemoFormState";
import useGetMemoQuery from "../../hooks/useGetMemoQuery";
import {
  MemoImageUpdateFormShemaType,
  memoImageUpdateSchema,
} from "../../zod/schemas";
import FormRow from "src/components/Forms/FormRow";
import Button from "src/components/Buttons/Button";
import ButtonsContainer from "src/components/Container/ButtonsContainer";
import {
  MemoFormSteps,
  setMemoFormStep,
  setSelectedMemoId,
} from "../../slices/memoSlice";
import { useAppDispatch } from "src/store";
import usePartialUpdateMemoFormDataMutation from "../../hooks/usePartialUpdateMemoFormDataMutation";
import {
  ButtonColors,
  ButtonSizes,
} from "src/components/Buttons/buttons.types";
import DragAndDropFileInput from "src/components/Forms/DragAndDropFileInput";

export default function MemoImageForm() {
  const dispatch = useAppDispatch();

  const { selectedMemoId } = useMemoFormState();

  const { data: memo } = useGetMemoQuery({
    id: selectedMemoId ? String(selectedMemoId) : undefined,
  });

  const partialUpdateMemoFormDataMutation =
    usePartialUpdateMemoFormDataMutation();

  const form = useForm<MemoImageUpdateFormShemaType>({
    resolver: zodResolver(memoImageUpdateSchema),
    defaultValues: {
      id: memo?.id,
      file_url: memo?.file_url,
      name: memo?.name,
      ext: memo?.ext,
    },
  });

  const onSubmit = useCallback(
    (data: MemoImageUpdateFormShemaType) => {
      const formData = new FormData();

      formData.append("name", data.name || "");
      formData.append("ext", data.ext || "");

      partialUpdateMemoFormDataMutation.mutate({
        id: String(data.id),
        data: formData,
      });
    },
    [partialUpdateMemoFormDataMutation]
  );

  const handleGoToLastStep = () => {
    dispatch(setSelectedMemoId(memo?.id));
    dispatch(setMemoFormStep(MemoFormSteps.AddDetails));
  };

  const handleSuccessfulUpload = useCallback(
    (_: any, variables: { get_url: string; name: string; ext: string }) => {
      form.setValue("file_url", variables.get_url);
      form.setValue("name", variables.name);
      form.setValue("ext", variables.ext);
    },
    [form]
  );

  const handleRemovefile = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      form.setValue("file_url", "");
      form.setValue("name", "");
      form.setValue("ext", "");
    },
    [form]
  );

  return (
    <StepContainer>
      <div className="mb-20">
        <span className="text-muted">
          Enhance your memo by adding an image, video, or PDF file. This allows
          you to provide richer content and more engaging information within
          your memo.
        </span>
      </div>
      <EditingContainer>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          style={{ marginTop: "20px", overflowY: "auto" }}
        >
          <FormRow>
            <DragAndDropFileInput
              s3Folder="private/memos"
              acceptedTypes="image/*,video/*,application/pdf"
              fileUrl={form.watch("file_url") || ""}
              onRemoveFile={handleRemovefile}
              onSuccessfulUpload={handleSuccessfulUpload}
            />
          </FormRow>
          <ButtonsContainer>
            <Button
              color={ButtonColors.GrayAndYellow}
              size={ButtonSizes.MD}
              type="button"
              onClick={handleGoToLastStep}
              disabled={partialUpdateMemoFormDataMutation.isLoading}
            >
              {partialUpdateMemoFormDataMutation.isLoading ? (
                <RotatingLines
                  strokeColor="#f1b70c"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={true}
                />
              ) : (
                "Go back"
              )}
            </Button>
            <Button
              color={ButtonColors.Yellow}
              size={ButtonSizes.MD}
              type="submit"
              disabled={partialUpdateMemoFormDataMutation.isLoading}
            >
              {!partialUpdateMemoFormDataMutation.isLoading ? (
                "Save And Next"
              ) : (
                <RotatingLines
                  strokeColor="#000"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={true}
                />
              )}
            </Button>
          </ButtonsContainer>
        </form>
      </EditingContainer>
    </StepContainer>
  );
}
