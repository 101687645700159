import { useQuery } from "@tanstack/react-query";
import myTodosService, {
  GetMyTodosParams,
} from "../../services/myTodosService";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

export default function useGetMyTodosListQuery({
  objectType,
  status,
}: GetMyTodosParams) {
  return useQuery({
    queryKey: [QueryKeys.MyTodosList, { objectType, status }],
    queryFn: () => myTodosService.getMyTodos({ objectType, status }),
    refetchOnWindowFocus: false,
  });
}
