import { useMutation } from "@tanstack/react-query";
import weeklySchedulesService from "../services/weeklySchedulesService";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";

type CopyLastWeeklyScheduleParams = {
  onSuccess?: () => void;
};

export default function useCopyLastWeeklyScheduleMutation({
  onSuccess,
}: CopyLastWeeklyScheduleParams) {
  const handleErrors = useErrorHandling();

  return useMutation({
    mutationFn: async (id: number) =>
      weeklySchedulesService.copyLastWeeklySchedule(id),
    onSuccess,
    onError: handleErrors,
  });
}
