import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import memosService from "../services/memosService";
import { MemoDetail } from "../types/memos.types";

type UsePartialUpdateMemoMutationProps = {
  onSuccess?: (data: MemoDetail) => void;
};

export default function usePartialUpdateMemoMutation({
  onSuccess,
}: UsePartialUpdateMemoMutationProps) {
  const handleErrors = useErrorHandling();

  return useMutation(memosService.partialUpdateMemo, {
    onSuccess,
    onError: (err: AxiosError) => handleErrors(err),
  });
}
