import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import Dialog from "src/components/Dialog/Dialog";
import DialogContent from "src/components/Dialog/DialogContent";
import DialogDescription from "src/components/Dialog/DialogDescription";
import DialogFooter from "src/components/Dialog/DialogFooter";
import DialogHeader from "src/components/Dialog/DialogHeader";
import FormRow from "src/components/Forms/FormRow";
import InputContainerV2 from "src/components/Inputs/InputContainerV2";
import InputLabel from "src/components/Inputs/InputLabel";
import TextArea from "src/components/Inputs/TextArea";
import useAuth from "src/features/auth/hooks/useAuth";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import { z } from "zod";
import timeOffRequestService from "../services/timeOffRequestService";

const timeOffRequestSchema = z.object({
  dateRange: z
    .array(z.date().nullable())
    .length(2)
    .refine(
      ([start, end]) => start !== null && end !== null,
      "Both start and end dates are required"
    ),
  reason: z.string().min(1, "Reason is required"),
});

type TimeOffRequestFormValues = z.infer<typeof timeOffRequestSchema>;

type TimeOffRequestDialogProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function TimeOffRequestDialog({
  isOpen,
  setIsOpen,
}: TimeOffRequestDialogProps) {
  const { employee } = useAuth();
  const handleErrors = useErrorHandling();
  const queryClient = useQueryClient();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<TimeOffRequestFormValues>({
    resolver: zodResolver(timeOffRequestSchema),
  });

  const mutation = useMutation({
    mutationFn: timeOffRequestService.createTimeOffRequest,
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.TimeOffRequests]);
      setIsOpen(false);
    },
    onError: handleErrors,
  });

  const onSubmit = (data: TimeOffRequestFormValues) => {
    const [startDate, endDate] = data.dateRange;
    if (!startDate || !endDate || !employee?.id) return;

    const formattedStartDate = startDate.toISOString().split("T")[0];
    const formattedEndDate = endDate.toISOString().split("T")[0];

    mutation.mutate({
      employee: employee?.id,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      reason: data.reason,
    });
  };

  const handleClose = () => reset();

  return (
    <Dialog isOpen={isOpen} setIsOpen={setIsOpen} onClose={handleClose}>
      <DialogHeader>New Time-Off Request</DialogHeader>
      <DialogDescription>
        Please fill out the form below to request time off.
      </DialogDescription>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormRow>
            <InputContainerV2>
              <InputLabel>Requested Dates</InputLabel>
              <Controller
                name="dateRange"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    selected={value ? value[0] : null}
                    startDate={value ? value[0] : null}
                    endDate={value ? value[1] : null}
                    onChange={(dates) =>
                      onChange(dates as [Date | null, Date | null])
                    }
                    selectsRange
                    minDate={
                      new Date(new Date().setDate(new Date().getDate() + 1))
                    }
                    className={"inputBlack"}
                    monthsShown={2}
                    portalId="datepicker-portal"
                    popperPlacement="bottom"
                    placeholderText="Select Date Range"
                  />
                )}
              />
              {errors.dateRange && <span>{errors.dateRange.message}</span>}
            </InputContainerV2>
          </FormRow>

          <FormRow>
            <InputContainerV2>
              <InputLabel>Reason for Time Off</InputLabel>
              <Controller
                name="reason"
                control={control}
                render={({ field }) => (
                  <TextArea
                    {...field}
                    label="Reason"
                    name="reason"
                    placeholder="Reason for Time Off"
                    inputClassName="text-area-input-black"
                    errorClassName="text-area-input-black-error"
                    error={errors.reason?.message}
                    rows={10}
                    cols={50}
                  />
                )}
              />
            </InputContainerV2>
          </FormRow>

          <DialogFooter>
            <Button
              color={ButtonColors.Yellow}
              type="submit"
              title="Submit Time-Off Request"
              disabled={mutation.isLoading}
            />
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
