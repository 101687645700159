import React from "react";
import styles from "./EmployeeCardText.module.css";

const DATA_TEST_ID = "employeeCardText";

type EmployeeCardTextProps = {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  containerStyle?: React.CSSProperties;
};

export default function EmployeeCardText({
  title,
  subtitle,
  containerStyle,
}: EmployeeCardTextProps) {
  return (
    <div
      className={styles.textContainer}
      style={containerStyle}
      data-testid={DATA_TEST_ID}
    >
      <span className={styles.title}>{title}</span>
      <span className={styles.subtitle}>{subtitle}</span>
    </div>
  );
}
