import { useQuery } from "@tanstack/react-query";
import getFullName from "src/features/employees/utils/getFullName";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import timeOffRequestService, {
  TimeOffStatus,
} from "../services/timeOffRequestService";

type UseGetTimeOffRequestsForShiftQuery = {
  scheduleRole?: number | string;
  startDate?: string;
  endDate?: string;
  status?: TimeOffStatus;
  search?: string;
};

export default function useGetTimeOffRequestsByScheduleRoleQuery({
  scheduleRole,
  startDate,
  endDate,
  status,
  search,
}: UseGetTimeOffRequestsForShiftQuery) {
  return useQuery({
    queryFn:
      scheduleRole && startDate && endDate
        ? () =>
            timeOffRequestService.getTimeOffRequestsByScheduleRole({
              scheduleRole,
              startDate,
              endDate,
              status,
            })
        : undefined,
    queryKey: [
      QueryKeys.TimeOffRequests,
      { scheduleRole: scheduleRole, startDate, endDate, status },
    ],
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!scheduleRole && !!startDate && !!endDate,
    select: (data) => {
      let filteredData = data;

      if (search) {
        filteredData = filteredData?.filter((timeOffRequest) => {
          const fullName = getFullName(timeOffRequest.employee);
          return fullName.toLowerCase().includes(search.toLowerCase());
        });
      }

      return filteredData;
    },
  });
}
