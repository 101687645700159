import React from "react";
import styles from "./SectionHeader.module.css";
import Placeholder from "../Placeholder/Placeholder";

interface Props {
  description?: string;
  id?: string;
  leftAction?: React.ReactNode;
  title: React.ReactNode;
  children?: React.ReactNode;
  filter?: React.ReactNode;
}

export default function SectionHeader({
  children,
  leftAction,
  description,
  title,
  filter,
}: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        {leftAction ? leftAction : null}
        {title ? <h2 className={styles.title}>{title}</h2> : null}
        {description ? <Placeholder title={description} /> : null}
        {filter ? <div className={styles.filter}>{filter}</div> : null}
      </div>
      {children ? (
        <div className={styles.headerRightContainer}>{children}</div>
      ) : null}
    </div>
  );
}
