import { useQuery } from "@tanstack/react-query";

import myLearningPathsService from "../services/myLearningPathsService";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

type LearningPathTake = {
  id?: string;
};

export default function useGetMyLearningPathTake({ id }: LearningPathTake) {
  return useQuery({
    queryKey: [QueryKeys.MyLearningPathTake],
    queryFn: id
      ? () => myLearningPathsService.getMyLearningPathTake(id)
      : undefined,
    enabled: !!id,
    refetchOnWindowFocus: false,
  });
}
