const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#F4B90D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M18.335 8.333V12.5c0 4.167-1.667 5.833-5.834 5.833h-5c-4.166 0-5.833-1.666-5.833-5.833v-5c0-4.167 1.667-5.833 5.833-5.833h4.167"
    />
    <path
      stroke="#F4B90D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M18.335 8.333H15c-2.5 0-3.333-.833-3.333-3.333V1.667l6.667 6.666ZM5.832 10.833h5M5.832 14.167h3.333"
    />
  </svg>
);
export default SvgComponent;
