import {
  AuthenticatedEmployee,
  EmployeeListItem,
} from "../types/employees.type";

function getFullName(
  employee?: AuthenticatedEmployee | EmployeeListItem | null
) {
  if (!employee) return "";
  return `${employee.first_name} ${employee.last_name}`;
}

export default getFullName;
