import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={30}
    height={30}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      opacity={0.5}
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m9.697 9.697 10.607 10.606M9.696 20.303 20.303 9.697" />
    </g>
  </svg>
)

export default SvgComponent
