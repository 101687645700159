import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useAlert } from "@blaumaus/react-alert";
import { useNavigate } from "react-router-dom";
import styles from "../../organization/styles/OrganizationRegistration.module.css";
import { normalizePhoneNumber } from "../../phone/utils/normalizePhoneNumber";
import { steps } from "../../../components/JoyRide/SetUpAdminAccountSteps";
import useApi from "../../axios/hooks/useApi";
import useAPIErrorAlert from "../../axios/hooks/useAPIErrorAlert";
import Button from "../../../components/Buttons/Button";
import Container from "../../../components/Container/Container";
import EmailSmallIcon from "../../../components/Icons/EmailSmallIcon";
import LoadingAnimation from "../../../components/Animations/LoadingAnimation";
import Header from "../../../components/Headers/Header";
import PersonSmallIcon from "../../../components/Icons/PersonSmallIcon";
import PhoneSmallIcon from "../../../components/Icons/PhoneSmallIcon";
import RoleSmallIcon from "../../../components/Icons/RoleSmallIcon";
import UploadAndDisplayImage from "../../../components/Inputs/UploadAndDisplayImage";
import TextInput from "../../../components/Inputs/TextInput";
import SelectInput from "../../../components/Inputs/SelectInput";
import JoyrideWrapper from "../../../components/JoyRide/JoyrideWrapper";
import { useAppOutletContext } from "src/features/reactRouterDom/hooks/useAppOutletContext";
import { createAdmin } from "src/features/employees/apis";
import { getAdminRoles } from "src/features/employeeTypes/apis";
import FormRow from "../../../components/Forms/FormRow";
import inputStyles from "../../../components/Inputs/Inputs.module.css";
import InputLabel from "../../../components/Inputs/InputLabel";
import { ButtonColors } from "../../../components/Buttons/buttons.types";

type AdminForm = {
  first_name: string;
  last_name: string;
  email: string;
  username: string;
  phone: string;
  role: string;
  badge_num: string;
  password: string;
  confirm_password: string;
  image: File | null;
};

interface IFormErrors {
  [error: string]: string;
}

const initialFormState = {
  username: "",
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  badge_num: "",
  role: "",
  password: "",
  confirm_password: "",
  image: null,
};

export default function OnboardingAdminAccount() {
  const alert = useAlert();
  let navigate = useNavigate();
  const { setCurrentStep } = useAppOutletContext();
  const createAdminEmployeeApi = useApi(createAdmin);
  const getAdminRolesApi = useApi<any>(getAdminRoles);
  const [formValues, setFormValues] = useState<AdminForm>(initialFormState);
  const [formErrors, setFormErrors] = useState<IFormErrors | null>(null);
  const [nextStepDisabled, setNextStepDisabled] = useState<boolean>(true);

  useEffect(() => {
    setCurrentStep(4);
  }, [setCurrentStep]);

  const handleFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormValues((prevState) => {
      // If input field is a phone, only allow digits
      if (e.target.id === "phone") {
        e.target.value =
          normalizePhoneNumber(e.target.value, formValues.phone) || "";
      }
      // If the input is username, only allow letters and numbers
      if (e.target.id === "username" && e.target.value) {
        e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
      }
      // If all the fields are in formValues have been filled, enable the next button
      if (Object.values(formValues).every((value) => value !== "")) {
        // Set up Yup validation schema according to which step of the
        // form it is on
        const validationSchema = yup.object().shape({
          username: yup.string().required(),
          first_name: yup.string().required(),
          last_name: yup.string().required(),
          phone: yup.string().required().min(14).max(14),
          email: yup.string().email().required(),
          badge_num: yup.string().required(),
          password: yup.string().required().min(8),
          confirm_password: yup
            .string()
            .oneOf([yup.ref("password"), null], "Passwords must match"),
        });

        // Validation for form fields in each step.
        // if validation passes then enable the "Save & Continue" button
        validationSchema
          .validate({ ...formValues, [e.target.id]: e.target.value })
          .then(() => {
            setFormErrors({});
            setNextStepDisabled(false);
          })
          .catch((e) => {
            setFormErrors({ ...formErrors, [e.path]: e.message });
            setNextStepDisabled(true);
          });
      }
      return { ...prevState, [e.target.id]: e.target.value };
    });
  };

  useEffect(() => {
    getAdminRolesApi.request();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // After the Admin roles API response is received, set the initial
  // role form value to the first role in the list.
  useEffect(() => {
    if (getAdminRolesApi?.data) {
      setFormValues({ ...formValues, role: getAdminRolesApi.data[0].id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAdminRolesApi.data]);

  const handleSaveAdmin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    var formData = new FormData();
    if (formValues.image) {
      formData.append("image", formValues.image, formValues.image.name);
    }
    formData.append("username", formValues.username);
    formData.append("first_name", formValues.first_name);
    formData.append("last_name", formValues.last_name);
    formData.append("phone", formValues.phone.replace(/[^\d]/g, ""));
    formData.append("email", formValues.email);
    formData.append("badge_num", formValues.badge_num);
    formData.append("password", formValues.password);
    formData.append("role", formValues.role);

    createAdminEmployeeApi.request(formData);
  };

  useEffect(() => {
    let isMounted = false;
    if (createAdminEmployeeApi.success) {
      if (!isMounted) {
        alert.success("Admin account created successfully!");
        window.location.href = "/v2/login/";
      }
    }
    return () => {
      isMounted = true;
    };
  }, [createAdminEmployeeApi.success, alert, navigate]);

  useAPIErrorAlert(createAdminEmployeeApi);
  useAPIErrorAlert(getAdminRolesApi);

  let loadingOrSuccess =
    getAdminRolesApi.isLoading ||
    createAdminEmployeeApi.isLoading ||
    createAdminEmployeeApi.success;

  return (
    <div className={loadingOrSuccess ? styles.formCardCenter : styles.formCard}>
      {loadingOrSuccess ? <LoadingAnimation /> : null}
      {!loadingOrSuccess ? (
        <form onSubmit={handleSaveAdmin} style={{ width: "100%" }}>
          <JoyrideWrapper
            steps={steps}
            storageKey="hideAdminAccountFormSteps"
          />
          <Header id="setupAdminAccountHeader" title="Set Up Admin Account" />
          <Container style={{ alignItems: "center", justifyContent: "center" }}>
            <UploadAndDisplayImage
              formValues={formValues}
              setFormValues={setFormValues}
            />
          </Container>
          <Container>
            <FormRow>
              <Container>
                <InputLabel
                  className={inputStyles.label}
                  labelIcon={<PersonSmallIcon />}
                >
                  {"Username"}
                </InputLabel>
                <TextInput
                  error={
                    createAdminEmployeeApi.error?.username ||
                    formErrors?.username
                  }
                  id="username"
                  name="username"
                  placeholder={"Enter username"}
                  onChange={handleFormChange}
                  required={true}
                  type="text"
                  value={formValues.username}
                  containerClassName={inputStyles.inputContainer}
                  errorClassName={inputStyles.darkInputError}
                  inputClassName={inputStyles.darkInput}
                />
              </Container>
            </FormRow>
            <FormRow>
              <Container>
                <InputLabel
                  className={inputStyles.label}
                  labelIcon={<PersonSmallIcon />}
                >
                  {"First Name"}
                </InputLabel>
                <TextInput
                  error={
                    createAdminEmployeeApi.error?.first_name ||
                    formErrors?.first_name
                  }
                  id="first_name"
                  name="first_name"
                  placeholder={"Enter first name"}
                  onChange={handleFormChange}
                  required={true}
                  type="text"
                  value={formValues.first_name}
                  containerClassName={inputStyles.inputContainer}
                  errorClassName={inputStyles.darkInputError}
                  inputClassName={inputStyles.darkInput}
                />
              </Container>
              <Container>
                <InputLabel
                  className={inputStyles.label}
                  labelIcon={<PersonSmallIcon />}
                >
                  {"Last Name"}
                </InputLabel>
                <TextInput
                  error={
                    createAdminEmployeeApi.error?.last_name ||
                    formErrors?.last_name
                  }
                  id="last_name"
                  name="last_name"
                  placeholder={"Enter last name"}
                  onChange={handleFormChange}
                  required={true}
                  type="text"
                  value={formValues.last_name}
                  containerClassName={inputStyles.inputContainer}
                  errorClassName={inputStyles.darkInputError}
                  inputClassName={inputStyles.darkInput}
                />
              </Container>
            </FormRow>
            <FormRow>
              <Container>
                <InputLabel
                  className={inputStyles.label}
                  labelIcon={<PhoneSmallIcon />}
                >
                  {"Phone"}
                </InputLabel>
                <TextInput
                  error={
                    createAdminEmployeeApi.error?.phone || formErrors?.phone
                  }
                  id="phone"
                  name="phone"
                  placeholder={"Enter phone number"}
                  onChange={handleFormChange}
                  required={true}
                  type="text"
                  value={formValues.phone}
                  containerClassName={inputStyles.inputContainer}
                  errorClassName={inputStyles.darkInputError}
                  inputClassName={inputStyles.darkInput}
                />
              </Container>
              <Container>
                <InputLabel
                  className={inputStyles.label}
                  labelIcon={<EmailSmallIcon />}
                >
                  {"Email"}
                </InputLabel>
                <TextInput
                  error={
                    createAdminEmployeeApi.error?.email || formErrors?.email
                  }
                  id="email"
                  label="Email"
                  labelIcon={<EmailSmallIcon />}
                  name="email"
                  placeholder={"Enter email"}
                  onChange={handleFormChange}
                  required={true}
                  type="text"
                  value={formValues.email}
                  containerClassName={inputStyles.inputContainer}
                  errorClassName={inputStyles.darkInputError}
                  inputClassName={inputStyles.darkInput}
                />
              </Container>
            </FormRow>
            <FormRow>
              <Container>
                <InputLabel
                  className={inputStyles.label}
                  labelIcon={<PersonSmallIcon />}
                >
                  {"Badge Number"}
                </InputLabel>
                <TextInput
                  error={
                    createAdminEmployeeApi.error?.badge_num ||
                    formErrors?.badge_num
                  }
                  id="badge_num"
                  name="badge_num"
                  placeholder={"Enter badge number"}
                  onChange={handleFormChange}
                  required={true}
                  type="text"
                  value={formValues.badge_num}
                  containerClassName={inputStyles.inputContainer}
                  errorClassName={inputStyles.darkInputError}
                  inputClassName={inputStyles.darkInput}
                />
              </Container>
              <SelectInput
                id="role"
                label="Role"
                labelIcon={<RoleSmallIcon />}
                name="role"
                onChange={handleFormChange}
                options={getAdminRolesApi.data}
                optionLabel="name"
                optionKey={"id"}
                optionValue={"id"}
                value={formValues.role}
                errors={undefined}
                removeBtn={undefined}
                labelStyle={{ padding: "7px 0" }}
              />
            </FormRow>
            <FormRow>
              <Container>
                <InputLabel
                  className={inputStyles.label}
                  labelIcon={<PersonSmallIcon />}
                >
                  {"Password"}
                </InputLabel>
                <TextInput
                  error={
                    createAdminEmployeeApi.error?.password ||
                    formErrors?.password
                  }
                  id="password"
                  name="password"
                  placeholder={"Enter password"}
                  onChange={handleFormChange}
                  required={true}
                  type="password"
                  value={formValues.password}
                  containerClassName={inputStyles.inputContainer}
                  errorClassName={inputStyles.darkInputError}
                  inputClassName={inputStyles.darkInput}
                />
              </Container>
              <Container>
                <InputLabel
                  className={inputStyles.label}
                  labelIcon={<PersonSmallIcon />}
                >
                  {"Confirm Password"}
                </InputLabel>
                <TextInput
                  error={
                    createAdminEmployeeApi.error?.confirm_password ||
                    formErrors?.confirm_password
                  }
                  id="confirm_password"
                  name="confirm_password"
                  placeholder={"Confirm password"}
                  onChange={handleFormChange}
                  required={true}
                  type="password"
                  value={formValues.confirm_password}
                  containerClassName={inputStyles.inputContainer}
                  errorClassName={inputStyles.darkInputError}
                  inputClassName={inputStyles.darkInput}
                />
              </Container>
            </FormRow>
          </Container>
          <Container
            style={{
              gap: "1em",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              padding: "1em 0",
            }}
          >
            <Button
              title="Back"
              id="backButton"
              color={ButtonColors.GrayAndYellow}
              onClick={() => navigate(-1)}
            />
            <Button
              title="Save & Continue"
              color={ButtonColors.Yellow}
              id="save"
              type="submit"
              disabled={nextStepDisabled}
            />
          </Container>
        </form>
      ) : null}
    </div>
  );
}
