import { Provider as AlertProvider } from "@blaumaus/react-alert";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { SkeletonTheme } from "react-loading-skeleton";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import * as serviceWorker from "./serviceWorker";

import App from "./App";
import LoadingAnimation from "./components/Animations/LoadingAnimation";
import { options } from "./features/alert/AlertTemplate";
import useAuth from "./features/auth/hooks/useAuth";
import hasGroupPermissions from "./features/auth/utils/hasGroupPermissions";
import DocumentApprovalForm from "./features/e-signatures/components/Documents/DocumentApprovalForm";
import DocumentSignRoute from "./features/e-signatures/routes/DocumentSignRoute";
import ESignaturesDashboard from "./features/e-signatures/routes/ESignaturesDashboard";
import ProtectedESignaturesWithSidebarRoute from "./features/e-signatures/routes/ProtectedESignaturesWithSidebarRoute";
import UpdateEmployeeTypesForm from "./features/employeeTypes/components/UpdateEmployeeTypesForm";
import { NON_EMPLOYEES_GROUP } from "./features/groups/groups";
import LearningPathsTakeRoute from "./features/learningPaths/routes/LearningPathsTakeRoute";
import MemoCreateEditPage from "./features/memos/components/Steps/MemoCreateEditPage";
import OnboardingAdminAccount from "./features/onboarding/routes/OnboardingAdminAccount";
import OnboardingDepartments from "./features/onboarding/routes/OnboardingDepartments";
import OnboardingEmployeeTypes from "./features/onboarding/routes/OnboardingEmployeeTypes";
import OnboardingWrapper from "./features/onboarding/routes/OnboardingWrapper";
import PolicyCreateEditPage from "./features/policies/components/Steps/PolicyCreateEditPage";
import EmployeeWeeklySchedule from "./features/schedules/components/EmployeeWeeklySchedule";
import LaborBudgetWeeklyPage from "./features/schedules/components/LaborBudgetWeeklyPage";
import SchedulesSupervisorDashboard from "./features/schedules/components/SchedulesSupervisorDashboard";
import ShiftConflicts from "./features/schedules/components/ShiftConflicts";
import ShiftTransferDetailPage from "./features/schedules/components/ShiftTransferDetailPage";
import ShiftTransfers from "./features/schedules/components/ShiftTransfers";
import TimeOffRequestDetailPage from "./features/schedules/components/TimeOffRequestDetailPage";
import TimeOffRequests from "./features/schedules/components/TimeOffRequests";
import ProtectedSchedulesRoute from "./features/schedules/routes/ProtectedSchedulesRoute";
import ProtectedSupervisorScheduleCalendar from "./features/schedules/routes/ProtectedSupervisorScheduleCalendar";
import ScheduleRolesSyncRoute from "./features/schedules/routes/ScheduleRolesSyncRoute";
import VerifyEmployeeHourlyRates from "./features/schedules/routes/VerifyEmployeeHourlyRates";
import VerifyScheduleRoles from "./features/schedules/routes/VerifyScheduleRoles";
import lazyRetry from "./features/utils/lazyRetry";
import globalStyles from "./globalStyles.module.css";
import "./index.css";
import { persistor, store } from "./store";

const LearningPathBuilder = lazy(() =>
  lazyRetry(() => import("./features/learningPaths/routes/LearningPathBuilder"))
);
const MyLearningPathsPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "./features/learningPaths/components/MyLearningPaths/MyLearningPathsPage"
      )
  )
);
const LPReportsPage = lazy(() =>
  lazyRetry(
    () => import("./features/learningPaths/components/LPReports/LPReportsPage")
  )
);
const LPReportTable = lazy(() =>
  lazyRetry(
    () => import("./features/learningPaths/components/LPReports/LPReportTable")
  )
);
const LoginPage = lazy(() =>
  lazyRetry(() => import("./features/auth/routes/LoginPage"))
);
const PasswordResetRequestPage = lazy(() =>
  lazyRetry(() => import("./features/auth/routes/PasswordResetRequestPage"))
);
const UsernameRequestPage = lazy(() =>
  lazyRetry(() => import("./features/auth/routes/UsernameRequestPage"))
);
const BillingSetupFlow = lazy(() =>
  lazyRetry(() => import("./features/settings/BillingSetupFlow"))
);
const BillingDetailsPage = lazy(() =>
  lazyRetry(() => import("./features/settings/BillingDetailsPage"))
);
const SubscriptionAndTerms = lazy(() =>
  lazyRetry(() => import("./features/settings/SubscriptionAndTerms"))
);
const PaymentMethod = lazy(() =>
  lazyRetry(() => import("./features/settings/PaymentMethod"))
);
const PoliciesStatsPage = lazy(() =>
  lazyRetry(() => import("./features/policies/components/PoliciesStatsPage"))
);
const ProtectedAssessmentsRoute = lazy(() =>
  lazyRetry(
    () => import("./features/assessments/routes/ProtectedAssessmentsRoute")
  )
);
const SittingTake = lazy(() =>
  lazyRetry(() => import("./features/assessments/components/SittingTake"))
);
const AssessmentStatsPage = lazy(() =>
  lazyRetry(() => import("./features/assessments/routes/AssessmentStatsPage"))
);
const DocumentDetailRoute = lazy(() =>
  lazyRetry(() => import("./features/e-signatures/routes/DocumentDetailRoute"))
);
const LearningPathDetailPage = lazy(() =>
  lazyRetry(
    () => import("./features/learningPaths/routes/LearningPathDetailPage")
  )
);
const EmployeeLearningPathDetailPage = lazy(() =>
  lazyRetry(
    () =>
      import("./features/learningPaths/routes/EmployeeLearningPathDetailPage")
  )
);
const AssessmentCreateEditPage = lazy(() =>
  lazyRetry(() => import("./features/assessments/routes/AssessmentForm"))
);
const AssessmentDetailPage = lazy(() =>
  lazyRetry(() => import("./features/assessments/routes/AssessmentDetailPage"))
);
const EmployeeSittingRoute = lazy(() =>
  lazyRetry(() => import("./features/assessments/routes/EmployeeSittingRoute"))
);
const ProtectedESignaturesRoute = lazy(() =>
  lazyRetry(
    () => import("./features/e-signatures/routes/ProtectedESignaturesRoute")
  )
);
const TemplateForm = lazy(() =>
  lazyRetry(
    () =>
      import(
        "./features/e-signatures/components/TemplateComponents/TemplateForm"
      )
  )
);
const DocumentAssignForm = lazy(() =>
  lazyRetry(
    () =>
      import("./features/e-signatures/components/Documents/DocumentAssignForm")
  )
);
const LogoutPage = lazy(() =>
  lazyRetry(() => import("./features/auth/routes/LogoutPage"))
);
const OrganizationForm = lazy(() =>
  lazyRetry(() => import("./components/Forms/OrganizationForm"))
);
const LandingPage = lazy(() =>
  lazyRetry(() => import("./features/landing/routes/LandingPage"))
);
const ProtectedDashboard = lazy(() =>
  lazyRetry(() => import("./features/dashboard/routes/ProtectedDashboardPage"))
);
const ProtectedDiscussions = lazy(() =>
  lazyRetry(() => import("./features/discussions/routes/ProtectedDiscussions"))
);
const Discussion = lazy(() =>
  lazyRetry(() => import("./features/discussions/components/Discussion"))
);
const ProtectedOrganization = lazy(() =>
  lazyRetry(
    () => import("./features/organization/routes/ProtectedOrganization")
  )
);
const OrganizationInfo = lazy(() =>
  lazyRetry(() => import("./features/organization/components/OrganizationInfo"))
);
const ProtectedUpdateOrganization = lazy(() =>
  lazyRetry(
    () => import("./features/organization/routes/ProtectedUpdateOrganization")
  )
);
const ProtectedDepartments = lazy(() =>
  lazyRetry(() => import("./features/departments/routes/ProtectedDepartments"))
);
const Department = lazy(() =>
  lazyRetry(() => import("./features/departments/components/Department"))
);
const ProtectedDepartmentsUpdate = lazy(() =>
  lazyRetry(
    () => import("./features/departments/routes/ProtectedDepartmentsUpdate")
  )
);
const ProtectedRoles = lazy(() =>
  lazyRetry(() => import("./features/employeeTypes/routes/ProtectedRoles"))
);
const ProtectedEmployeesRoute = lazy(() =>
  lazyRetry(() => import("./features/employees/routes/ProtectedEmployeesRoute"))
);
const ProtectedEmployeeDetail = lazy(() =>
  lazyRetry(() => import("./features/employees/routes/ProtectedEmployeeDetail"))
);
const ProtectedMemosRoute = lazy(() =>
  lazyRetry(() => import("./features/memos/routes/ProtectedMemosRoute"))
);
const MemoStatsPage = lazy(() =>
  lazyRetry(() => import("./features/memos/components/MemoStatsPage"))
);
const MemoDetailPage = lazy(() =>
  lazyRetry(() => import("./features/memos/components/MemoDetailPage"))
);
const ProtectedVideosRoute = lazy(() =>
  lazyRetry(() => import("./features/videos/routes/ProtectedVideosRoute"))
);
const SettingsPage = lazy(() =>
  lazyRetry(() => import("./features/settings/routes/ProtectedSettingsRoute"))
);
const VideosListPage = lazy(() =>
  lazyRetry(() => import("./features/videos/components/VideosListPage"))
);
const VideosDetailPage = lazy(() =>
  lazyRetry(() => import("./features/videos/components/VideosDetailPage"))
);
const VideosStatsPage = lazy(() =>
  lazyRetry(() => import("./features/videos/components/VideosStatsPage"))
);
const NotFound = lazy(() =>
  lazyRetry(() => import("./features/errors/routes/NotFound"))
);

const ProtectedLearningPaths = lazy(() =>
  lazyRetry(
    () => import("./features/learningPaths/routes/ProtectedLearningPaths")
  )
);

const LearningPathsDashboard = lazy(() =>
  lazyRetry(
    () => import("./features/learningPaths/routes/LearningPathsDashboard")
  )
);

const EmployeeLPMultipleReportTable = lazy(() =>
  lazyRetry(
    () =>
      import(
        "./features/learningPaths/components/LPReports/EmployeeLPMultipleReportTable"
      )
  )
);

const EmployeeLPSingleReportTable = lazy(() =>
  lazyRetry(
    () =>
      import(
        "./features/learningPaths/components/LPReports/EmployeeLPSingleReportTable"
      )
  )
);

const LPEmployeeQuarterlyReports = lazy(() =>
  lazyRetry(
    () =>
      import(
        "./features/learningPaths/components/LPReports/LPEmployeeQuarterlyReports"
      )
  )
);

const ProtectedPoliciesRoute = lazy(() =>
  lazyRetry(() => import("./features/policies/routes/ProtectedPoliciesRoute"))
);

const PoliciesDetailPage = lazy(() =>
  lazyRetry(() => import("./features/policies/components/PoliciesDetailPage"))
);

Sentry.init({
  dsn: "https://682e0f1de6554c62a2734b02e5cf94fe@o1380729.ingest.sentry.io/4503903422119936",
  integrations: [
    new BrowserTracing(),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  environment: process.env.NODE_ENV,
  enabled: process.env.NODE_ENV === "production",
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5, // Set to lower value as errors occur less frequently e.g. 0.2 - 20%
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});

const AppRoutes = () => {
  const { employee } = useAuth();
  return (
    <BrowserRouter basename={"/v2"}>
      <Suspense
        fallback={<LoadingAnimation containerClassName={globalStyles.flex} />}
      >
        <Routes>
          <Route path={"login"} element={<LoginPage />} />
          <Route path={"logout"} element={<LogoutPage />} />
          <Route path={"username-request"} element={<UsernameRequestPage />} />
          <Route
            path={"password-reset-request"}
            element={<PasswordResetRequestPage />}
          />
          <Route path={"register"} element={<OnboardingWrapper />}>
            <Route path={"organization"} element={<OrganizationForm />} />
            <Route path={"roles"} element={<OnboardingEmployeeTypes />} />
            <Route path={"departments"} element={<OnboardingDepartments />} />
            <Route path={"admin"} element={<OnboardingAdminAccount />} />
          </Route>
          <Route
            path={"register"}
            element={<OnboardingWrapper totalSteps={3} />}
          >
            <Route path={"schedules"} element={<ScheduleRolesSyncRoute />} />
            <Route
              path={"schedules/roles/verify"}
              element={<VerifyScheduleRoles />}
            />
            <Route
              path={"schedules/employees/verify"}
              element={<VerifyEmployeeHourlyRates />}
            />
          </Route>
          <Route path={"billing"} element={<BillingSetupFlow />}>
            <Route path={"subscription"} element={<SubscriptionAndTerms />} />
            <Route path={"details"} element={<BillingDetailsPage />} />
            <Route path={"payment-method"} element={<PaymentMethod />} />
          </Route>
          <Route path={"/"} element={<App />}>
            <Route path={"landing"} element={<LandingPage />} />
            <Route path={"dashboard"} element={<ProtectedDashboard />} />
            <Route path={"schedules"} element={<ProtectedSchedulesRoute />}>
              <Route
                index
                element={
                  hasGroupPermissions(employee, NON_EMPLOYEES_GROUP) ? (
                    <SchedulesSupervisorDashboard />
                  ) : (
                    <EmployeeWeeklySchedule />
                  )
                }
              />
              <Route path="me" element={<EmployeeWeeklySchedule />} />
              <Route
                path={"departments/:id"}
                element={<ProtectedSupervisorScheduleCalendar />}
              />
              <Route path={"time-off-requests"} element={<TimeOffRequests />} />
              <Route
                path={"time-off-requests/:id"}
                element={<TimeOffRequestDetailPage />}
              />
              <Route path={"shift-transfers"} element={<ShiftTransfers />} />
              <Route
                path="shift-transfers/:id"
                element={<ShiftTransferDetailPage />}
              />
              <Route path={"shift-conflicts"} element={<ShiftConflicts />} />
              <Route
                path={"labor-budget"}
                element={<LaborBudgetWeeklyPage />}
              />
            </Route>
            <Route path={"discussions"} element={<ProtectedDiscussions />}>
              <Route index element={<Discussion />} />
              <Route path={":uuid"} element={<Discussion />} />
            </Route>
            <Route path={"memos"} element={<ProtectedMemosRoute />}>
              <Route
                index
                element={
                  hasGroupPermissions(employee, NON_EMPLOYEES_GROUP) ? (
                    <MemoStatsPage />
                  ) : (
                    <MemoDetailPage />
                  )
                }
              />
              <Route path={":id"} element={<MemoDetailPage />} />
              <Route path={"form"} element={<MemoCreateEditPage />} />
              <Route path={"form/:id"} element={<MemoCreateEditPage />} />
            </Route>
            <Route path={"organization"} element={<ProtectedOrganization />}>
              <Route index element={<OrganizationInfo />} />
              <Route
                path={"update"}
                element={<ProtectedUpdateOrganization />}
              />
            </Route>
            <Route path={"departments"} element={<ProtectedDepartments />}>
              <Route path={":selectedDepartment"} element={<Department />} />
              <Route path={"update"} element={<ProtectedDepartmentsUpdate />} />
            </Route>
            <Route path={"roles"} element={<ProtectedRoles />}>
              <Route path={"update"} element={<UpdateEmployeeTypesForm />} />
            </Route>
            <Route path={"employees"} element={<ProtectedEmployeesRoute />}>
              <Route path={":id"} element={<ProtectedEmployeeDetail />} />
            </Route>
            <Route path={"assessments"} element={<ProtectedAssessmentsRoute />}>
              <Route
                index
                element={
                  hasGroupPermissions(employee, NON_EMPLOYEES_GROUP) ? (
                    <AssessmentStatsPage />
                  ) : (
                    <AssessmentDetailPage />
                  )
                }
              />
              <Route path={":id"} element={<AssessmentDetailPage />} />
              <Route path={"form"} element={<AssessmentCreateEditPage />} />
              <Route path={`form/:id`} element={<AssessmentCreateEditPage />} />
              <Route path={":id/take"} element={<SittingTake />} />
              <Route path={`marking/:id`} element={<EmployeeSittingRoute />} />
            </Route>
            <Route path={"learning-paths"} element={<ProtectedLearningPaths />}>
              <Route
                index
                element={
                  hasGroupPermissions(employee, NON_EMPLOYEES_GROUP) ? (
                    <LearningPathsDashboard />
                  ) : (
                    <MyLearningPathsPage />
                  )
                }
              />
              <Route path={":id"} element={<LearningPathDetailPage />} />
              <Route path={"form"} element={<LearningPathBuilder />} />
              <Route path={`form/:id/`} element={<LearningPathBuilder />} />
              <Route
                path={`employee-path/:id/`}
                element={<EmployeeLearningPathDetailPage />}
              />
            </Route>
            <Route
              path={"learning-paths/:id/take"}
              element={<LearningPathsTakeRoute />}
            />
            <Route path={"learning-paths/reports"} element={<LPReportsPage />}>
              <Route index element={<LPReportTable />} />
              <Route path={":id"} element={<LPEmployeeQuarterlyReports />} />
              <Route path={`path/:id`} element={<LPReportTable />} />
              <Route
                path={":id/single/:pathId/"}
                element={<EmployeeLPSingleReportTable />}
              />
              <Route
                path={":id/multiple/"}
                element={<EmployeeLPMultipleReportTable />}
              />
            </Route>
            <Route path={"policies"} element={<ProtectedPoliciesRoute />}>
              <Route
                index
                element={
                  hasGroupPermissions(employee, NON_EMPLOYEES_GROUP) ? (
                    <PoliciesStatsPage />
                  ) : (
                    <PoliciesDetailPage />
                  )
                }
              />
              <Route path={":id"} element={<PoliciesDetailPage />} />
              <Route path={"form"} element={<PolicyCreateEditPage />} />
              <Route path={`form/:id`} element={<PolicyCreateEditPage />} />
            </Route>
            <Route
              path={"e-signatures"}
              element={<ProtectedESignaturesRoute />}
            >
              <Route index element={<ESignaturesDashboard />} />
            </Route>
            <Route
              path={"e-signatures"}
              element={<ProtectedESignaturesWithSidebarRoute />}
            >
              <Route path={"templates/create"} element={<TemplateForm />} />
              <Route path={`templates/:id/edit`} element={<TemplateForm />} />
              <Route path={`documents/:id`} element={<DocumentDetailRoute />} />
              <Route
                path="documents/:id/sign"
                element={<DocumentSignRoute />}
              />
              <Route
                path={"documents/:id/approve"}
                element={<DocumentApprovalForm />}
              />
              <Route
                path={`documents/:id/assign`}
                element={<DocumentAssignForm />}
              />
            </Route>
            <Route path={"videos"} element={<ProtectedVideosRoute />}>
              <Route index element={<VideosListPage />} />
              <Route path={":id"} element={<VideosDetailPage />} />
              <Route path={"stats"} element={<VideosStatsPage />} />
            </Route>
            <Route path={"settings"} element={<SettingsPage />} />
          </Route>
          <Route path={"*"} element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

const stripePublishableKey = process.env.REACT_APP_STRIPE_API_KEY;

if (!stripePublishableKey) {
  console.log("node env", process.env.NODE_ENV);
  console.log("stripe key", stripePublishableKey);
  console.log("stripe key", process.env.REACT_APP_STRIPE_API_KEY);
  throw new Error("Stripe publishable key not found");
}

const queryClient = new QueryClient();
const stripePromise = loadStripe(stripePublishableKey);
const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <AlertProvider {...options}>
      <PersistGate loading={<LoadingAnimation />} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <SkeletonTheme
            baseColor="#131316"
            highlightColor="#232227"
            borderRadius={5}
          >
            <Elements stripe={stripePromise}>
              <AppRoutes />
            </Elements>
          </SkeletonTheme>
        </QueryClientProvider>
      </PersistGate>
    </AlertProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
