export enum CanEmployeeSkipChoices {
  NEVER = 0,
  ALWAYS = 1,
  AFTER_DEADLINE = 2,
  AFTER_COMPLETED_ONCE = 3,
  AFTER_DEADLINE_OR_COMPLETED_ONCE = 4,
}

export enum ModuleContentTypes {
  ModuleContentFile = "modulecontentfile",
  Assessment = "assessment",
  Video = "video",
  Policy = "policy",
}
