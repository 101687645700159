import React from "react";
import Placeholder from "../Placeholder/Placeholder";

interface IProps {
  title: React.ReactNode;
  children?: React.ReactNode;
}

export default function CardSectionHeader({ children, title }: IProps) {
  return (
    <div className={"cardSectionHeader"}>
      <Placeholder title={title} style={{ alignItems: "flex-start" }} />
      {children ? <div className={"buttonContainer"}>{children}</div> : null}
    </div>
  );
}
