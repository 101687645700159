import axiosClient from "src/features/axios/client";
import {
  MemoDetailsFormSchemaType,
  MemoUpdateFormSchemaType,
} from "../zod/schemas";
import { Memo, MemoDetail } from "../types/memos.types";
import { InfiniteQueryFilters } from "src/components/Lists/FilterableListSidebar";
import { PaginatedApiResponse } from "src/features/axios/axios.types";

class MemosService {
  async createMemo(data: MemoDetailsFormSchemaType): Promise<Memo> {
    return await axiosClient.post("/api/memos/", data).then((res) => res.data);
  }

  async enhanceMemo({ content }: { content: string }) {
    return await axiosClient
      .post("/api/memos/enhance/", { content })
      .then((res) => res.data);
  }

  async getMemo(id: string): Promise<MemoDetail> {
    return await axiosClient.get(`/api/memos/${id}/`).then((res) => res.data);
  }

  async getMemos({
    pageParam,
    startDate,
    endDate,
    ...filters
  }: InfiniteQueryFilters): Promise<PaginatedApiResponse<Memo>> {
    return await axiosClient.get("/api/memos/", {
      params: {
        page: pageParam,
        start_date: startDate
          ? new Date(startDate).toISOString().split("T")[0]
          : undefined,
        end_date: endDate
          ? new Date(endDate).toISOString().split("T")[0]
          : undefined,
        ...filters,
      },
    });
  }

  async getUnreadMemos() {
    return await axiosClient.get("/api/memos/unread").then((res) => res.data);
  }

  async sendReminder({
    memoId,
    employeeId,
  }: {
    memoId: number;
    employeeId: number;
  }) {
    return await axiosClient
      .post(`/api/memos/${memoId}/send-reminder/`, { employee_id: employeeId })
      .then((res) => res.data);
  }

  async partialUpdateMemo(
    data: Partial<MemoUpdateFormSchemaType>
  ): Promise<MemoDetail> {
    return await axiosClient
      .patch(`/api/memos/${data.id}/`, data)
      .then((res) => res.data);
  }

  async partialUpdateMemoUsingFormData(
    id: string,
    formData: FormData
  ): Promise<Memo> {
    return await axiosClient
      .patch(`/api/memos/${id}/`, formData)
      .then((res) => res.data);
  }
}

const memosService = new MemosService();

export default memosService;
