import { useState, useCallback } from "react";
import Skeleton from "react-loading-skeleton";
import { Page, Document } from "react-pdf";
import PdfViewerPagesContainer from "./PdfViewerPagesContainer";
import PdfThumbnail from "./PdfThumbnail";
import PdfViewerContainer from "./PdfViewerContainer";
import PdfViewerThumbnailListContainer from "./PdfViewerThumbnailListContainer";
import useIntersectionObserver from "src/hooks/useIntersectionObserver";
import styles from "../ESignatures.module.css";
import DocumentApprovalAnnotation from "../Documents/DocumentApprovalAnnotation";
import { ContainerSize } from "../../routes/DocumentDetailRoute";
import { DocumentApprovalAnnotationType } from "../Documents/DocumentApprovalForm";

interface VerticalDocumentApprovalViewerProps {
  fileUrl?: string | null;
  isLoading: boolean;
  approvalAnnotations: DocumentApprovalAnnotationType[];
  numPages?: number;
  pageNumber: number;
  pageRefs: React.MutableRefObject<(HTMLDivElement | null)[]>;
  setPageNumber: (pageNumber: number) => void;
  setApprovalAnnotations: (
    updater: (
      annotations: DocumentApprovalAnnotationType[]
    ) => DocumentApprovalAnnotationType[]
  ) => void;
  onApprovalAnnotationClick: (uuid: string) => void;
  onDocumentLoadSuccess?: ({ numPages }: { numPages: number }) => void;
}

export default function VerticalDocumentApprovalViewer({
  fileUrl,
  isLoading = false,
  numPages,
  pageNumber,
  approvalAnnotations,
  pageRefs,
  setApprovalAnnotations,
  onApprovalAnnotationClick,
  onDocumentLoadSuccess,
  setPageNumber,
}: VerticalDocumentApprovalViewerProps) {
  const [containerSize, setContainerSize] = useState<ContainerSize>({
    width: 0,
    height: 0,
  });
  const handleRenderSuccess = useCallback((pageSize: ContainerSize) => {
    setContainerSize({ width: pageSize.width, height: pageSize.height });
  }, []);

  const handleThumbnailClick = (page: number) => {
    setPageNumber(page);
    const pageRef = pageRefs.current[page - 1];
    if (pageRef) {
      pageRef.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useIntersectionObserver({
    refs: pageRefs,
    onIntersect: (entry) => {
      const pageNumberString = entry.target.getAttribute("data-page-number");
      if (pageNumberString !== null) {
        setPageNumber(parseInt(pageNumberString));
      }
    },
  });

  return (
    <div className={styles.rowContainer}>
      <PdfViewerThumbnailListContainer>
        {numPages && fileUrl
          ? Array.from({ length: numPages }).map((_, index) => (
              <PdfThumbnail
                key={index + 1}
                fileSrc={fileUrl}
                pageNumber={index + 1}
                currentPage={pageNumber}
                onThumbnailClick={() => handleThumbnailClick(index + 1)}
              />
            ))
          : null}
      </PdfViewerThumbnailListContainer>
      <PdfViewerContainer>
        {!isLoading && fileUrl ? (
          <PdfViewerPagesContainer>
            <Document
              file={fileUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<Skeleton height={792} width={612} />}
            >
              {numPages &&
                Array.from({ length: numPages }, (_, index) => (
                  <div
                    key={index + 1}
                    ref={(el) => (pageRefs.current[index] = el)}
                    data-page-number={index + 1}
                    style={{
                      marginBottom: "20px",
                      position: "relative",
                      width: containerSize.width
                        ? `${containerSize.width}px`
                        : "auto",
                      height: containerSize.height
                        ? `${containerSize.height}px`
                        : "auto",
                    }}
                  >
                    <Page
                      pageNumber={index + 1}
                      onRenderSuccess={handleRenderSuccess}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                    />
                    {approvalAnnotations &&
                      approvalAnnotations.length > 0 &&
                      approvalAnnotations
                        .filter(
                          (ann) => ann.page === index + 1 && !ann.completed_at
                        )
                        .map((ann) => (
                          <DocumentApprovalAnnotation
                            key={ann.uuid}
                            approvalAnnotation={ann}
                            setApprovalAnnotations={setApprovalAnnotations}
                            onApprovalAnnotationClick={
                              onApprovalAnnotationClick
                            }
                          />
                        ))}
                  </div>
                ))}
            </Document>
          </PdfViewerPagesContainer>
        ) : null}
      </PdfViewerContainer>
    </div>
  );
}
