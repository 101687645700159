import React from "react";

export type AlertType = {
  success: (msg: string) => void;
  error: (msg: string) => void;
  info: (msg: string) => void;
};

const alertStyle: React.CSSProperties = {
  backgroundColor: "#232227",
  borderRadius: "3px",
  boxSizing: "border-box",
  color: "#FFF",
  padding: "10px",
  display: "flex",
  alignItems: "center",
  width: "350px",
  margin: "5px",
};

const AlertTemplate = ({ style, options, message, close }: any) => (
  <div
    style={{
      ...style,
      ...alertStyle,
      border: `1px solid ${
        options.type === "success"
          ? "#00FF00"
          : options.type === "info"
          ? "#ffc107" // cleartpath yellow
          : options.type === "error"
          ? "red" // red
          : "#FF0000"
      }`,
      borderLeft: `10px solid ${
        options.type === "success"
          ? "#00FF00"
          : options.type === "info"
          ? "#ffc107" // cleartpath yellow
          : options.type === "error"
          ? "red" // red
          : "#FF0000"
      }`,
    }}
  >
    {message}
  </div>
);

const options: any = {
  template: AlertTemplate,
  position: "bottom right",
  timeout: 5000,
  transition: "fade",
  containerStyle: {
    zIndex: 3100,
  },
};

export { options };
export default AlertTemplate;
