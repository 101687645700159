import { createSlice } from "@reduxjs/toolkit";

export interface ModalState {
  isVisible: boolean;
  title: string;
  selectedItem: any | null;
}

const initialState: ModalState = {
  isVisible: false,
  title: "",
  selectedItem: null,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state) => {
      state.isVisible = true;
    },
    setModalOpen: (state, action) => {
      state.isVisible = action.payload;
    },
    setModalTitle: (state, action) => {
      state.title = action.payload;
    },
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
    toggleModal: (state) => {
      state.isVisible = !state.isVisible;
    },
  },
});

export const {
  openModal,
  setModalOpen,
  setModalTitle,
  setSelectedItem,
  toggleModal,
} = modalSlice.actions;

export default modalSlice.reducer;
