import { useAlert } from "@blaumaus/react-alert";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { MdAdd } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { useSearchParams } from "react-router-dom";
import Button from "src/components/Buttons/Button";
import FormRow from "src/components/Forms/FormRow";
import InputContainerV2 from "src/components/Inputs/InputContainerV2";
import InputLabel from "src/components/Inputs/InputLabel";
import Placeholder from "src/components/Placeholder/Placeholder";
import useAuth from "src/features/auth/hooks/useAuth";
import hasGroupPermissions from "src/features/auth/utils/hasGroupPermissions";
import useDateRangeFromUrl from "src/features/datetime/hooks/useDateRangeFromUrl";
import { CRUD_GROUP_WITH_HR } from "src/features/groups/groups";
import useGetTimeOffRequestsByStartDateQuery from "../hooks/useGetTimeOffRequestsByStartDateQuery";
import {
  TimeOffRequest,
  TimeOffStatus,
} from "../services/timeOffRequestService";
import TimeOffRequestCard from "./TimeOffRequestCard";
import TimeOffCreateDialog from "./TimeOffRequestCreateDialog";
import TimeOffRequestDecisionDialog from "./TimeOffRequestDecisionDialog";

export default function TimeOffRequests() {
  const { employee } = useAuth();
  const [isTimeOffDialogOpen, setIsTimeOffDialogOpen] = useState(false);
  const [isDecisionDialogOpen, setIsDecisionDialogOpen] = useState(false);
  const [selectedTimeOffRequest, setSelectedTimeOffRequest] =
    useState<TimeOffRequest | null>(null);
  const [dateRange, setDateRange] = useDateRangeFromUrl();
  const [searchParams, setSearchParams] = useSearchParams();
  const alert = useAlert();
  const status = searchParams.get("status") || TimeOffStatus.Pending;

  const {
    data: timeOffRequests,
    isLoading: isTimeOffRequestsLoading,
    isError: isTimeOffRequestsError,
    refetch: refetchTimeOffRequests,
  } = useGetTimeOffRequestsByStartDateQuery({
    startDate: dateRange[0]?.toISOString().slice(0, 10),
    endDate: dateRange[1]?.toISOString().slice(0, 10),
    status: status === "all" ? undefined : (status as TimeOffStatus),
  });

  const handleOpenDecisionDialog = (
    e: React.MouseEvent<Element, MouseEvent>
  ) => {
    const request = timeOffRequests?.find(
      (req) => req.id === Number(e.currentTarget.id)
    );
    if (!request) {
      alert.error("Could not find the time off request! Please try again.");
      return;
    }
    setSelectedTimeOffRequest(request);
    setIsDecisionDialogOpen(true);
  };

  const handleCloseDecisionDialog = () => {
    setSelectedTimeOffRequest(null);
    refetchTimeOffRequests();
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      status: e.target.value,
    });
  };

  const handleDateRangeChange = (dates: [Date | null, Date | null]) =>
    setDateRange(dates);

  return (
    <div className="detail-card">
      <TimeOffCreateDialog
        isOpen={isTimeOffDialogOpen}
        setIsOpen={setIsTimeOffDialogOpen}
      />
      {selectedTimeOffRequest && (
        <TimeOffRequestDecisionDialog
          timeOffRequest={selectedTimeOffRequest}
          isOpen={isDecisionDialogOpen}
          setIsOpen={setIsDecisionDialogOpen}
          onClose={handleCloseDecisionDialog}
        />
      )}
      <div className="px-10">
        <div className="border-bottom-light-gray pb-5">
          <FormRow>
            <InputContainerV2>
              <InputLabel>Status</InputLabel>
              <select
                className="inputBlack"
                defaultValue={status}
                onChange={handleStatusChange}
              >
                <option value="all">All</option>
                <option value={TimeOffStatus.Pending}>Pending</option>
                <option value={TimeOffStatus.Approved}>Approved</option>
                <option value={TimeOffStatus.Declined}>Declined</option>
              </select>
            </InputContainerV2>
            <InputContainerV2>
              <InputLabel>Time-Off Dates</InputLabel>
              <DatePicker
                selected={dateRange[0]}
                startDate={dateRange[0]}
                endDate={dateRange[1]}
                onChange={(dates) =>
                  handleDateRangeChange(dates as [Date | null, Date | null])
                }
                selectsRange
                className={"inputBlack"}
                placeholderText="Select Date Range"
              />
            </InputContainerV2>
            {hasGroupPermissions(employee, CRUD_GROUP_WITH_HR) ? (
              <div className="flex justify-end py-10">
                <Button
                  className={"icon-button"}
                  onClick={() => setIsTimeOffDialogOpen(true)}
                >
                  <MdAdd size={24} />
                </Button>
              </div>
            ) : null}
          </FormRow>
        </div>
        <div className="flex flex-1 gap-10 py-10 min-h-100vh-90 max-h-100vh-90 overflow-y-auto">
          {isTimeOffRequestsLoading &&
            Array.from({ length: 5 }).map((_, i) => (
              <Skeleton height={200} key={i} />
            ))}
          {isTimeOffRequestsError && <p>Error fetching time off requests</p>}
          {timeOffRequests && timeOffRequests?.length === 0 && (
            <div className="flex-1 centered">
              <Placeholder title="No time off requests" />
            </div>
          )}
          {timeOffRequests &&
            timeOffRequests?.length > 0 &&
            timeOffRequests.map((request) => (
              <TimeOffRequestCard
                key={request.id}
                request={request}
                onOpenDecisionDialog={handleOpenDecisionDialog}
              />
            ))}
        </div>
      </div>
    </div>
  );
}
