import React, { memo } from "react";
import * as Sentry from "@sentry/react";
import ErrorFallback from "../Errors/ErrorFallback";

type CardProps = {
  onClick?: () => void;
  className?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
  dataTestId?: string;
};

function Card({ onClick, className, children, style, dataTestId }: CardProps) {
  return (
    <div
      className={`${"card"} ${className}`}
      style={style}
      data-testid={dataTestId || "card"}
      onClick={onClick}
    >
      <Sentry.ErrorBoundary
        fallback={({ error, resetError }) => (
          <ErrorFallback error={error} onReload={resetError} />
        )}
      >
        {children}
      </Sentry.ErrorBoundary>
    </div>
  );
}

export default memo(Card);
