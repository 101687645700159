export const CHECKBOX = "checkbox";
export const DATE = "date";
export const EMAIL = "email";
export const NUMBER = "number";
export const PASSWORD = "password";
export const RADIO = "radio";
export const SELECT = "select";
export const TEXT = "text";
export const TEXTAREA = "textarea";
export const TIME = "time";
export const URL = "url";
