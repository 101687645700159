import { useState } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { Document, Page } from "react-pdf";
import { PageCallback } from "react-pdf/dist/cjs/shared/types";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";

type PdfViewerProps = {
  file?: string;
  width: number;
};

export default function PdfViewer({ file, width }: PdfViewerProps) {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageHeight, setPageHeight] = useState<number>(0);
  const [renderedPageNumber, setRenderedPageNumber] = useState<number | null>(
    null
  );

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) =>
    setNumPages(numPages);

  const changePage = (offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => changePage(-1);

  const nextPage = () => changePage(1);

  const handleRenderSuccess = ({ pageNumber, height }: PageCallback) => {
    setPageHeight(height);
    setRenderedPageNumber(pageNumber);
  };

  const isLoading = renderedPageNumber !== pageNumber;
  /**
   * IMPORTANT: Keys are necessary so that React will know which Page component
   * instances to use.
   * Without keys, on page number update, React would replace the page number
   * in 1st and 2nd page components. This may cause previously rendered page
   * to render again, thus causing a flash.
   * With keys, React, will add prevPage className to previously rendered page,
   * and mount new Page component instance for the new page.
   */
  return (
    <>
      <div
        className="flex flex-1 py-10 justify-center"
        style={{ width: width }}
      >
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<Skeleton width={width} height={width * 1.3} />}
        >
          {isLoading && renderedPageNumber ? (
            <Page
              key={renderedPageNumber}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              className="prevPage"
              pageNumber={renderedPageNumber}
              width={width}
              height={pageHeight}
              onRenderSuccess={handleRenderSuccess}
            />
          ) : null}
          {numPages && numPages > 1 ? (
            <div
              className="flex flex-row prevPage"
              style={{
                width: width,
                height: pageHeight,
                zIndex: 2,
              }}
            >
              <div className="flex flex-1 z-10">
                <Button
                  color={ButtonColors.Transparent}
                  onClick={previousPage}
                  disabled={pageNumber === 1}
                >
                  <MdChevronLeft color={"#f1b70c"} size={30} />
                </Button>
              </div>
              <div className="flex flex-1 align-end z-10">
                <Button
                  color={ButtonColors.Transparent}
                  onClick={nextPage}
                  disabled={pageNumber === numPages}
                >
                  <MdChevronRight color={"#f1b70c"} size={30} />
                </Button>
              </div>
            </div>
          ) : null}
          <Page
            key={pageNumber}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            pageNumber={pageNumber}
            onRenderSuccess={handleRenderSuccess}
            width={width}
            height={pageHeight}
          />
        </Document>
      </div>
    </>
  );
}
