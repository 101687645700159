import { format, parseISO } from "date-fns";
import cn from "src/features/utils/cn";
import {
  TimeOffRequest,
  TimeOffStatus,
} from "../services/timeOffRequestService";

type DayTimeOffRequestCardProps = {
  timeOffRequest: TimeOffRequest;
};

export default function EmployeeWeeklyScheduleCardTimeOffRequestCard({
  timeOffRequest,
}: DayTimeOffRequestCardProps) {
  return (
    <div
      key={timeOffRequest.id}
      className={cn(
        "flex flex-1 flex-col justify-center align-center border-radius-5 gap-10 p-10",
        timeOffRequest.status === TimeOffStatus.Pending
          ? "bg-cp-yellow"
          : timeOffRequest.status === TimeOffStatus.Declined
          ? "bg-cp-danger"
          : "bg-cp-green"
      )}
    >
      <span className="font-bold text-lg">
        Time Off Request {timeOffRequest.status}
      </span>
      <span>{timeOffRequest.reason}</span>
      <span className="font-bold text-sm">
        {format(parseISO(timeOffRequest.start_date), "PP")} -{" "}
        {format(parseISO(timeOffRequest.end_date), "PP")}
      </span>
    </div>
  );
}
