import axiosClient from "src/features/axios/client";

class LPAssessmentsService {
  async submitNumericQuestionAnswer({
    id,
    answer,
    emcId,
  }: {
    id: number | string;
    answer: number | string;
    emcId?: string;
  }) {
    const params = {
      emc_id: emcId,
    };

    const { data } = await axiosClient.post(
      `/api/learning-path-assessments/${id}/take/`,
      { answer },
      { params }
    );
    return data;
  }
}

const lpAssessmentsService = new LPAssessmentsService();

export default lpAssessmentsService;
