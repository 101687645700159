import React from "react";
import styles from "../ESignatures.module.css";

type PdfViewerPagesContainerProps = {
  children?: React.ReactNode;
};

export default function PdfViewerPagesContainer({
  children,
}: PdfViewerPagesContainerProps) {
  return <div className={styles.pdfViewerPagesContainer}>{children}</div>;
}
