import React, { useEffect, useMemo, useRef, useState } from "react";
import Button from "../Buttons/Button";
import useOutsideClick from "src/features/page/hooks/useOutsideClick";
import { ButtonColors, ButtonSizes } from "../Buttons/buttons.types";

type DropDownV2Props = {
  btnClassName?: string;
  children: React.ReactNode;
  icon?: React.ReactNode;
  title?: string | null;
  color?: ButtonColors;
  size?: ButtonSizes;
  setIsFilterVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isFilterVisible: boolean;
};

function DropDownV2({
  btnClassName,
  children,
  icon = null,
  color,
  title,
  size,
  setIsFilterVisible,
  isFilterVisible,
}: DropDownV2Props) {
  const dropDownRef = useRef<HTMLDivElement>(null);
  const menuContainerRef = useRef<HTMLDivElement>(null);
  const [menuStyle, setMenuStyle] = useState({});

  useOutsideClick(dropDownRef, () => setIsFilterVisible(false));

  const handleFilterChange = () => setIsFilterVisible(!isFilterVisible);

  const displayStyle = useMemo(
    () => ({
      display: isFilterVisible ? "flex" : "none",
      ...menuStyle,
    }),
    [isFilterVisible, menuStyle]
  );

  useEffect(() => {
    function handleResize() {
      if (!isFilterVisible) {
        setMenuStyle({}); // Reset style when dropdown is hidden
        return;
      }

      if (menuContainerRef.current && dropDownRef.current) {
        const rect = menuContainerRef.current.getBoundingClientRect();
        const buttonRect = dropDownRef.current.getBoundingClientRect();
        const leftOverflow = rect.left;
        const rightOverflow = window.innerWidth - rect.right;
        const bottomOverflow =
          window.innerHeight - (rect.bottom - buttonRect.height);

        let transformStyles = [];

        // Adjust horizontally
        if (rightOverflow < 0) {
          transformStyles.push(`translateX(${rightOverflow}px)`);
        } else if (leftOverflow < 0) {
          transformStyles.push(`translateX(${-leftOverflow}px)`);
        }

        // Adjust vertically if there's not enough space below
        if (bottomOverflow < 0) {
          const spaceAbove = buttonRect.top;
          const dropdownHeight = rect.height;

          if (spaceAbove > dropdownHeight) {
            transformStyles.push(
              `translateY(-${dropdownHeight + buttonRect.height}px)`
            );
          } else {
            transformStyles.push(`translateY(${bottomOverflow}px)`);
          }
        }

        setMenuStyle({ transform: transformStyles.join(" ") });
      }
    }

    // Attach resize listener only if the dropdown is visible
    if (isFilterVisible) {
      handleResize(); // Call immediately to set position
      window.addEventListener("resize", handleResize);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isFilterVisible]); // Recalculate anytime visibility changes

  useEffect(() => {
    if (!isFilterVisible) {
      setMenuStyle({});
    }
  }, [isFilterVisible]);

  return (
    <div className={"cardFilterSection"} ref={dropDownRef}>
      <Button
        title={title || ""}
        color={color || undefined}
        size={size}
        onClick={handleFilterChange}
        className={btnClassName}
        dataTestid="dropDownButton"
      >
        {icon}
      </Button>
      <div
        className={"dropDownMenuContainer"}
        style={{ ...displayStyle, ...menuStyle }}
        ref={menuContainerRef}
        data-testid="dropDownMenuContainer"
      >
        {children}
      </div>
    </div>
  );
}

export default React.memo(DropDownV2);
