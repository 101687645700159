import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import employeeShiftsService from "../services/employeeShiftsService";

type UseGetEmployeeShiftsByEmployeeIdQuery = {
  employeeid?: number;
  weeklyScheduleStartDate?: string;
};

export default function useGetEmployeeShiftsByEmployeeIdQuery({
  employeeid,
  weeklyScheduleStartDate,
}: UseGetEmployeeShiftsByEmployeeIdQuery) {
  return useQuery({
    queryFn:
      employeeid && weeklyScheduleStartDate
        ? () =>
            employeeShiftsService.getEmployeeShiftsByEmployeeId(
              employeeid,
              weeklyScheduleStartDate
            )
        : undefined,
    queryKey: [
      QueryKeys.EmployeeShiftsList,
      {
        employee: employeeid,
        weeklyScheduleStartDate: weeklyScheduleStartDate,
      },
    ],
    refetchOnWindowFocus: false,
    enabled: !!employeeid && !!weeklyScheduleStartDate,
  });
}
