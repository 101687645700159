import * as React from "react";

const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      stroke="#F3B80C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M1.5 9a7.5 7.5 0 1 0 15 0 7.5 7.5 0 0 0-15 0Z"
    />
    <path
      stroke="#F3B80C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6.353 8.055 9 10.695l2.648-2.64"
    />
  </svg>
);
export default SvgComponent;
