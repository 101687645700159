import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { format, parseISO } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import Badge from "src/components/Badges/Badge";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import Dialog from "src/components/Dialog/Dialog";
import DialogContent from "src/components/Dialog/DialogContent";
import DialogDescription from "src/components/Dialog/DialogDescription";
import DialogFooter from "src/components/Dialog/DialogFooter";
import DialogHeader from "src/components/Dialog/DialogHeader";
import FormRow from "src/components/Forms/FormRow";
import InputContainerV2 from "src/components/Inputs/InputContainerV2";
import InputLabel from "src/components/Inputs/InputLabel";
import TextArea from "src/components/Inputs/TextArea";
import useAuth from "src/features/auth/hooks/useAuth";
import hasGroupPermissions from "src/features/auth/utils/hasGroupPermissions";
import getFullName from "src/features/employees/utils/getFullName";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import { CRUD_GROUP_WITH_HR } from "src/features/groups/groups";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import { z } from "zod";
import timeOffRequestService, {
  TimeOffRequest,
  TimeOffStatus,
} from "../services/timeOffRequestService";
import { getTimeOffRequestsBadgeColor } from "../utils/getTimeOffRequestsBadgeColor";

const timeOffRequestSchema = z.object({
  notes: z.string().optional(),
});

type TimeOffRequestFormValues = z.infer<typeof timeOffRequestSchema>;

type TimeOffRequestDecisionDialogProps = {
  timeOffRequest: TimeOffRequest;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClose?: () => void;
};

export default function TimeOffRequestDecisionDialog({
  timeOffRequest,
  isOpen,
  setIsOpen,
  onClose,
}: TimeOffRequestDecisionDialogProps) {
  const queryClient = useQueryClient();
  const handleErrors = useErrorHandling();
  const { employee } = useAuth();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<TimeOffRequestFormValues>({
    resolver: zodResolver(timeOffRequestSchema),
    defaultValues: {
      notes: timeOffRequest.notes !== null ? timeOffRequest.notes : "",
    },
  });

  const timeOffRequestDecisionMutation = useMutation({
    mutationFn: timeOffRequestService.updateDecision,
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.LaborBudgets]);
      queryClient.invalidateQueries([QueryKeys.EmployeeShiftsList]);
      onClose?.();
      setIsOpen(false);
    },
    onError: handleErrors,
  });

  const onSubmit = async (
    data: TimeOffRequestFormValues,
    e: React.BaseSyntheticEvent
  ) => {
    const nativeEvent = e.nativeEvent as SubmitEvent;
    const decision = nativeEvent.submitter?.id;
    if (!decision) return;
    const submittedData = {
      id: timeOffRequest.id,
      ...data,
      status: decision as TimeOffStatus,
    };
    timeOffRequestDecisionMutation.mutateAsync(submittedData);
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit((data) => onSubmit(data, e))(e);
  };

  return (
    <Dialog isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <form onSubmit={handleFormSubmit}>
        <DialogHeader>Time Off Request Decision</DialogHeader>
        <DialogDescription>
          {hasGroupPermissions(employee, CRUD_GROUP_WITH_HR) &&
          timeOffRequest.status === TimeOffStatus.Pending
            ? "Make a decision on the time off request. Add a reason for your decision."
            : "This request has been decided. You can view the decision."}
        </DialogDescription>
        <DialogContent>
          <div className="flex gap-10 p-20 border-radius-10 bg-cp-black-50">
            <div className="flex flex-row gap-10 align-center">
              <span className="cp-yellow font-bold">
                Time-off requested by:
              </span>
              <div className="flex flex-row flex-1 justify-end">
                <Badge
                  color={getTimeOffRequestsBadgeColor(timeOffRequest.status)}
                  text={timeOffRequest.status}
                />
              </div>
            </div>
            <div className="flex flex-row justify-left">
              <div className="flex flex-row gap-10">
                <img
                  src={timeOffRequest.employee?.image}
                  alt={`${timeOffRequest.employee?.first_name} ${timeOffRequest.employee?.last_name}`}
                  className="w-40 h-40 border-radius-half"
                />
                <div className="flex flex-col">
                  <span className="font-bold">
                    {getFullName(timeOffRequest.employee)}
                  </span>
                  <span className="text-sm text-muted">
                    {timeOffRequest.employee?.username}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-10">
              <div className="flex flex-row gap-10 align-center">
                <span className="text-sm font-bold">Requested on: </span>
                <span className="text-sm">
                  {format(parseISO(timeOffRequest.requested_on), "PP")}
                </span>
              </div>
              <div className="flex flex-row gap-10 align-center">
                <span className="text-sm font-bold">Dates: </span>
                <span className="cp-yellow text-sm font-bold">
                  {format(parseISO(timeOffRequest.start_date), "PP")} -{" "}
                  {format(parseISO(timeOffRequest.end_date), "PP")}
                </span>
              </div>
              <div className="flex flex-row gap-10 align-center">
                <span className="text-sm font-bold">Reason: </span>
                <span className="text-sm">{timeOffRequest.reason}</span>
              </div>
            </div>
          </div>
          {hasGroupPermissions(employee, CRUD_GROUP_WITH_HR) &&
          timeOffRequest.status === TimeOffStatus.Pending ? (
            <FormRow>
              <InputContainerV2>
                <InputLabel>Notes (Optional)</InputLabel>
                <Controller
                  name="notes"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      {...field}
                      label="Notes (Optional)"
                      name="note"
                      placeholder="Add a note..."
                      inputClassName="text-area-input-black"
                      errorClassName="text-area-input-black-error"
                      error={errors.notes?.message}
                      rows={5}
                      cols={50}
                    />
                  )}
                />
              </InputContainerV2>
            </FormRow>
          ) : (
            <div className="flex gap-10 p-20 border-radius-10 bg-cp-black-50 mt-10">
              <span className="cp-yellow font-bold">Decision by:</span>
              <div className="flex flex-row justify-left">
                <div className="flex flex-row gap-10">
                  <img
                    src={timeOffRequest.employee?.image}
                    alt={`${timeOffRequest.employee?.first_name} ${timeOffRequest.employee?.last_name}`}
                    className="w-40 h-40 border-radius-half"
                  />
                  <div className="flex flex-col">
                    <span className="font-bold">
                      {getFullName(timeOffRequest.employee)}
                    </span>
                    <span className="text-sm text-muted">
                      {timeOffRequest.employee?.username}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-10">
                <div className="flex flex-row gap-10 align-center">
                  <span className="text-sm font-bold">
                    {timeOffRequest.status === "Approved" &&
                    timeOffRequest.decision_at
                      ? "Approved On: "
                      : "Declined On: "}
                  </span>
                  <span className="text-sm">
                    {timeOffRequest.decision_at
                      ? format(new Date(timeOffRequest.decision_at), "PP")
                      : ""}
                  </span>
                </div>
                {timeOffRequest.notes && (
                  <div className="flex flex-row gap-10 align-center">
                    <span className="text-sm font-bold">Notes: </span>
                    <span className="text-sm">{timeOffRequest.notes}</span>
                  </div>
                )}
              </div>
            </div>
          )}
        </DialogContent>
        <DialogFooter>
          {timeOffRequest.status === TimeOffStatus.Pending ? (
            <>
              <Button
                id={"Approved"}
                type="submit"
                color={ButtonColors.Green}
                title="Approve"
              />
              <Button
                id={"Declined"}
                type="submit"
                color={ButtonColors.Red}
                title="Decline"
              />
            </>
          ) : (
            <Button
              onClick={() => setIsOpen(false)}
              color={ButtonColors.GrayAndYellow}
              title="Close"
            />
          )}
        </DialogFooter>
      </form>
    </Dialog>
  );
}
