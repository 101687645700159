import { memo } from "react";
import styles from "./StepTitle.module.css";

type StepTitleProps = {
  title: string;
  action?: React.ReactNode;
};

function StepTitle({ title, action }: StepTitleProps) {
  return (
    <div className={styles.stepTitleWrapper}>
      <div className={styles.stepTitleContainer}>
        <h4 className={styles.stepTitle}>{title}</h4>
      </div>
      {action && <div className={styles.stepActionContainer}>{action}</div>}
    </div>
  );
}

export default memo(StepTitle);
