import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import axiosClient from "../../axios/client";
import { IDiscussion } from "../types/discussions.types";

export const fetchDiscussions = createAsyncThunk<
  { data: IDiscussion[]; next: string | null },
  { page: number; employeeId?: string | number },
  { rejectValue: any }
>(
  "auth/fetchDiscussions",
  async ({ page = 1, employeeId }, { rejectWithValue }) => {
    try {
      const endpoint = employeeId
        ? `/api/chats/?employee_id=${employeeId}&page=${page}`
        : `/api/chats/?page=${page}`;
      const response = await axiosClient.get(endpoint);
      return { data: response.data.results, next: response.data.next };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      } else {
        return rejectWithValue({ detail: "An unknown error occurred" });
      }
    }
  }
);

type DiscussionsState = {
  isLoading: boolean;
  isInitialLoading: boolean;
  webSocketStatus:
    | "disconnected"
    | "connected"
    | "connecting"
    | "disconnecting"
    | "error";
  error: any;
  data: IDiscussion[];
  currentDiscussion: string | null;
  status: "idle" | "loading" | "succeeded" | "failed";
  nextPage: string | null;
  messageWithFileToLink: string | null;
};

export interface IDiscussionsState {
  isLoading: boolean;
  isInitialLoading: boolean;
  webSocketStatus:
    | "disconnected"
    | "connected"
    | "connecting"
    | "disconnecting"
    | "error";
  error: string[];
  data: IDiscussion[];
  currentDiscussion: string | null;
  status: string;
  nextPage: string | null;
  messageWithFileToLink: string | null;
}

const initialState: DiscussionsState = {
  isLoading: false,
  isInitialLoading: false,
  webSocketStatus: "disconnected",
  error: null,
  data: [],
  currentDiscussion: null,
  status: "idle",
  nextPage: null,
  messageWithFileToLink: null,
};

export const discussionsSlice = createSlice({
  name: "discussions",
  initialState,
  reducers: {
    muteUnmuteDiscussion: (state, action) => {
      if (action.payload.uuid) {
        const index = state.data.findIndex(
          (discussion) => discussion.uuid === action.payload.uuid
        );
        if (index !== -1) {
          state.data[index].notifications_disabled =
            action.payload.notifications_disabled;
        }
      }
    },
    addMessageToDiscussion: (state, action) => {
      if (action.payload.uuid) {
        const index = state.data.findIndex(
          (discussion) => discussion.uuid === action.payload.uuid
        );
        if (index !== -1) {
          state.data[index].messages.push(action.payload);
          // Remove the updated discussion from its current position and
          // add the updated discussion to the front of the state.data array
          const updatedDiscussion = state.data.splice(index, 1)[0];
          state.data.unshift(updatedDiscussion);
        }
      }
    },
    addOrUpdateMessageWithFileToLink: (state, action) => {
      state.messageWithFileToLink = action.payload;
    },
    addOrUpdateDiscussion: (state, action) => {
      if (action.payload.uuid) {
        const index = state.data.findIndex(
          (discussion) => discussion.uuid === action.payload.uuid
        );
        if (index === -1) {
          state.data.unshift(action.payload);
        } else {
          state.data[index] = action.payload;
        }
      }
    },
    clearDiscussions: (state) => {
      state.data = [];
    },
    setCurrentDiscussion: (state, action) => {
      state.currentDiscussion = action.payload;
    },
    setWebSocketStatus: (state, action) => {
      state.webSocketStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDiscussions.pending, (state, action) => {
        state.error = null;
        // Set isInitialLoading only for the first page
        if (action.meta.arg.page === 1) {
          state.isInitialLoading = true;
          state.data = [];
        }
        state.status = "loading";
      })
      .addCase(fetchDiscussions.fulfilled, (state, action) => {
        // Reset isInitialLoading once data is fetched
        state.isInitialLoading = false;
        state.status = "succeeded";

        // Check if discussions being fetched are already present in state.data
        const newDiscussions = action.payload.data.filter((discussion) => {
          return !state.data.some((d) => d.id === discussion.id);
        });

        state.data = state.data.concat(newDiscussions);
        state.nextPage = action.payload.next;
      })
      .addCase(fetchDiscussions.rejected, (state, action) => {
        // Reset isInitialLoading on error
        state.isInitialLoading = false;
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const {
  addOrUpdateMessageWithFileToLink,
  addMessageToDiscussion,
  addOrUpdateDiscussion,
  clearDiscussions,
  setCurrentDiscussion,
  setWebSocketStatus,
} = discussionsSlice.actions;

export default discussionsSlice.reducer;
