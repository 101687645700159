import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import learningPathAssessmentsService from "../services/learningPathAssessmentsService";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

export default function useLpNumericQuestionMutation() {
  const queryClient = useQueryClient();
  const handleErrors = useErrorHandling();

  return useMutation(
    learningPathAssessmentsService.submitNumericQuestionAnswer,
    {
      onSuccess: (response: any) => {
        queryClient.removeQueries([QueryKeys.MyLearningPathsDetails]);
        queryClient.setQueryData(
          [QueryKeys.MyLearningPathAssessmentTake],
          response
        );
      },
      onError: (error: AxiosError) => handleErrors(error),
    }
  );
}
