import { format, parseISO } from "date-fns";

function formatDateToShortString(dateString: string) {
  const date = parseISO(dateString);

  // Format the Date object into 'MMM d, yy' (e.g., 'Sep 11, 24')
  return format(date, "MMM d, yy");
}

export default formatDateToShortString;
