export const steps = [
  {
    disableBeacon: true,
    target: "#setUpRolesHeader",
    title: "Next task is to set up the roles in your organization.",
    content:
      "Roles are used to control access to items within the ClearPath training platform and are able to be changed at a later time.",
  },
  {
    disableBeacon: true,
    target: "#input0",
    title: "Assign a name for each role.",
    content:
      "A role is an employee type in your organization. For example, your organization may have a role named 'Dealer' and a role named 'Floor Supervisor'. This is where you can put those names.",
  },
  {
    disableBeacon: true,
    target: "#group0",
    title: "Select a group type for each role.",
    content:
      "Groups control access on the ClearPath platform so be sure to select the correct group for each role. Each role can only be assigned to one group.",
  },
  {
    disableBeacon: true,
    target: "#group1",
    title:
      "There are 5 types of user groups on the ClearPath training platform.",
    content:
      "Admin - This group has access to create/edit/view/delete items in your organization. Human Resources - This group has access to create/edit/view/delete items in your organization. Supervisor - This group has access to create/edit/view/delete items within a single department (we will set up departments next). Supervisor (read-only) - Same as Supervisor but cannot create/edit/delete items. Employee - This group has access to view and interact with items (assessments, memos, discussions, videos, etc) sent to them.",
  },
  {
    disableBeacon: true,
    target: "#addButton",
    title: "You can add more roles here.",
    content: "Click this button to more roles to this form.",
  },
];
