import axiosClient from "src/features/axios/client";

export enum ScheduleSetupState {
  NotStarted = "NotStarted",
  SyncScheduledRoles = "SyncScheduledRoles",
  VerifyScheduledRoles = "VerifyScheduledRoles",
  SyncEmployees = "SyncEmployees",
  VerifyEmployees = "VerifyEmployees",
  Completed = "Completed",
}

type ScheduleSetup = {
  id: number;
  state: ScheduleSetupState;
  created_on: string;
  updated_on: string;
};

class ScheduleSetupService {
  async getScheduleSetups(): Promise<ScheduleSetup[]> {
    return await axiosClient
      .get("/api/schedule-setups")
      .then((res) => res.data);
  }
}

const scheduleSetupService = new ScheduleSetupService();

export default scheduleSetupService;
