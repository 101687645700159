import { useMutation } from "@tanstack/react-query";

import documentAnnotationService from "../services/documentAnnotationService";
import { DocumentAnnotation } from "../types/document.types";
import { AxiosError } from "axios";

type CreateDocumentAnnotationMutation = {
  onSuccess?: (data: DocumentAnnotation) => void;
  onError?: (error: AxiosError) => void;
};

export default function useCreateDocumentAnnotationMutation({
  onSuccess,
  onError,
}: CreateDocumentAnnotationMutation) {
  return useMutation({
    mutationFn: documentAnnotationService.createAnnotation,
    onSuccess,
    onError,
  });
}
