import { useState } from "react";
import axios, { AxiosResponse } from "axios";
import { IFormErrors } from "../../errors/types/errors.types";

// TOOD: Remove this. Use useMutation from react-query instead.
export default function useApi<D>(
  apiFunc: (...args: any[]) => Promise<AxiosResponse>
) {
  const [data, setData] = useState<D | null>();
  const [error, setError] = useState<IFormErrors | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const reset = () => {
    setData(null);
    setError(null);
    setIsLoading(false);
    setSuccess(false);
  };

  const request = async (...args: any[]) => {
    setError(null);
    setIsLoading(true);
    try {
      const response = await apiFunc(...args);
      setData(response.data);
      setSuccess(true);
    } catch (err: Error | any) {
      if (axios.isAxiosError(err)) {
        setError(err.response?.data);
      } else {
        setError(err.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    error,
    isLoading,
    request,
    reset,
    success,
  };
}
