import React from "react";
import CardHeader from "../Cards/CardHeader";

interface IProps {
  title: string;
  children?: React.ReactNode;
}

export default function ListCardHeader({
  children,
  title,
}: IProps): JSX.Element {
  return <CardHeader title={title} action={children} />;
}
