import DatePicker from "react-datepicker";
import Skeleton from "react-loading-skeleton";
import { useSearchParams } from "react-router-dom";
import ErrorFallback from "src/components/Errors/ErrorFallback";
import FormRow from "src/components/Forms/FormRow";
import InputContainerV2 from "src/components/Inputs/InputContainerV2";
import InputLabel from "src/components/Inputs/InputLabel";
import Placeholder from "src/components/Placeholder/Placeholder";
import useDateRangeFromUrl from "src/features/datetime/hooks/useDateRangeFromUrl";
import useGetShiftConflictsByDateQuery from "../hooks/useGetShiftConflictsByDateQuery";
import {
  ShiftConflictResolutionStatus,
  ShiftConflictType,
} from "../services/shiftConflictsService";
import ShiftConflictCard from "./ShiftConflictCard";

export default function ShiftConflicts() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [dateRange, setDateRange] = useDateRangeFromUrl();

  const resolutionStatus =
    (searchParams.get("resolution_status") as
      | ShiftConflictResolutionStatus
      | "all") || ShiftConflictResolutionStatus.Unresolved;
  const conflictType =
    (searchParams.get("conflict_type") as ShiftConflictType | "all") || "all";

  const {
    data: shiftConflicts,
    isLoading: isShiftConflictsLoading,
    isError: isShiftConflictsError,
    refetch,
  } = useGetShiftConflictsByDateQuery({
    startDate: dateRange[0]?.toISOString().slice(0, 10),
    endDate: dateRange[1]?.toISOString().slice(0, 10),
    resolutionStatus: resolutionStatus === "all" ? undefined : resolutionStatus,
    conflictType: conflictType === "all" ? undefined : conflictType,
  });

  const handleDateRangeChange = (dates: [Date | null, Date | null]) =>
    setDateRange(dates);

  const handleResolutionStatusChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) =>
    setSearchParams({
      ...Object.fromEntries(searchParams),
      resolution_status: e.target.value,
    });

  const handleConflictTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setSearchParams({
      ...Object.fromEntries(searchParams),
      conflict_type: e.target.value,
    });

  return (
    <div className="detail-card">
      <div className="px-10">
        <div className="border-bottom-light-gray pb-5">
          <FormRow>
            <InputContainerV2>
              <InputLabel>Resolution Status</InputLabel>
              <select
                className="inputBlack"
                defaultValue={resolutionStatus}
                onChange={handleResolutionStatusChange}
              >
                <option value="all">All</option>
                <option value={ShiftConflictResolutionStatus.Unresolved}>
                  {ShiftConflictResolutionStatus.Unresolved}
                </option>
                <option value={ShiftConflictResolutionStatus.Resolved}>
                  {ShiftConflictResolutionStatus.Resolved}
                </option>
                <option value={ShiftConflictResolutionStatus.Dismissed}>
                  {ShiftConflictResolutionStatus.Dismissed}
                </option>
                <option value={ShiftConflictResolutionStatus.Expired}>
                  {ShiftConflictResolutionStatus.Expired}
                </option>
              </select>
            </InputContainerV2>
            <InputContainerV2>
              <InputLabel>Conflict Type</InputLabel>
              <select
                className="inputBlack"
                defaultValue={conflictType}
                onChange={handleConflictTypeChange}
              >
                <option value="all">All</option>
                <option value={ShiftConflictType.OverlappingShifts}>
                  Overlapping Shifts
                </option>
                <option value={ShiftConflictType.ConsecutiveShifts}>
                  Consecutive Shifts
                </option>
                <option value={ShiftConflictType.ExceedsDailyHours}>
                  Exceeds Daily Hours
                </option>
                <option value={ShiftConflictType.ExceedsWeeklyHours}>
                  Exceeds Weekly Hours
                </option>
                <option value={ShiftConflictType.ExceedsWeeklyDays}>
                  Exceeds Weekly Days
                </option>
                <option value={ShiftConflictType.MultipleConflicts}>
                  Multiple Conflicts
                </option>
              </select>
            </InputContainerV2>
            <InputContainerV2>
              <InputLabel>Shift Dates</InputLabel>
              <DatePicker
                selected={dateRange[0]}
                startDate={dateRange[0]}
                endDate={dateRange[1]}
                onChange={(dates) =>
                  handleDateRangeChange(dates as [Date | null, Date | null])
                }
                selectsRange
                className={"inputBlack"}
                monthsShown={2}
                portalId="datepicker-portal"
                popperPlacement="bottom"
                placeholderText="Select Date Range"
              />
            </InputContainerV2>
          </FormRow>
        </div>
        <div className="flex flex-1 gap-10 py-10 min-h-100vh-90 max-h-100vh-90 overflow-y-auto">
          {isShiftConflictsLoading &&
            Array.from({ length: 5 }).map((_, i) => (
              <Skeleton height={200} key={i} />
            ))}
          {isShiftConflictsError && (
            <ErrorFallback
              onReload={refetch}
              error={
                "There was an error fetching shift conflicts. It has been reported and will be investigated. Please try again."
              }
            />
          )}
          {shiftConflicts && shiftConflicts.length === 0 && (
            <div className="flex-1 centered">
              <Placeholder title="No shift conflicts" />
            </div>
          )}
          {shiftConflicts &&
            shiftConflicts.length > 0 &&
            shiftConflicts.map((conflict) => (
              <ShiftConflictCard key={conflict.id} conflict={conflict} />
            ))}
        </div>
      </div>
    </div>
  );
}
