import { Document, Page } from "react-pdf";
import styles from "../ESignatures.module.css";
import Skeleton from "react-loading-skeleton";
import { memo, useMemo } from "react";

type PdfThumbnailProps = {
  pageNumber: number;
  currentPage: number;
  fileSrc: string;
  onThumbnailClick: (pageNumber: number) => void;
};

function PdfThumbnail({
  pageNumber,
  currentPage,
  fileSrc,
  onThumbnailClick,
}: PdfThumbnailProps) {
  const opt = useMemo(() => {
    return {
      cMapUrl: "/bcmaps/",
      cMapPacked: true,
    };
  }, []);
  return (
    <div
      className={styles.pdfThumbnailContainer}
      onClick={() => onThumbnailClick(pageNumber)}
      style={{
        opacity: pageNumber === currentPage ? 1 : 0.5,
      }}
    >
      <div
        style={{
          border:
            pageNumber === currentPage
              ? "3px solid #ffc107"
              : "3px solid transparent",
        }}
      >
        <Document
          file={fileSrc}
          loading={<Skeleton height={79} width={61} />}
          options={opt}
        >
          <Page
            pageNumber={pageNumber}
            scale={0.1}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        </Document>
      </div>
      <span className={styles.pdfThumbnailPageNumber}>{pageNumber}</span>
    </div>
  );
}

export default memo(PdfThumbnail);
