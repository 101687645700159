import { useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import Skeleton from "react-loading-skeleton";
import { RotatingLines } from "react-loader-spinner";
import { useAlert } from "@blaumaus/react-alert";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import useGetDepartmentsListQuery from "src/features/departments/hooks/useGetDepartmentsListQuery";
import useGetEmployeeTypesQuery from "src/features/employeeTypes/hooks/useGetEmployeeTypesQuery";
import FormRow from "../../../components/Forms/FormRow";
import Container from "../../../components/Container/Container";
import InputLabel from "../../../components/Inputs/InputLabel";
import NoteIcon from "../../../components/Icons/NoteIcon";
import TextInput from "../../../components/Inputs/TextInput";
import inputStyles from "src/components/Inputs/Inputs.module.css";
import CloseSmallIcon from "../../../components/Icons/CloseSmallIcon";
import Button from "../../../components/Buttons/Button";
import { ButtonColors } from "../../../components/Buttons/buttons.types";
import OrganizationSmallIcon from "../../../components/Icons/OrganizationSmallIcon";
import ButtonsContainer from "../../../components/Container/ButtonsContainer";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import useCreateDepartmentMutation from "src/features/departments/hooks/useCreateDepartmentMutation";
import useDeleteDepartmentMutation from "src/features/departments/hooks/useDeleteDepartmentMutation";
import useUpdateDepartmentMutation from "src/features/departments/hooks/useUpdateDepartmentMutation";
import Header from "src/components/Headers/Header";
import { useAppOutletContext } from "src/features/reactRouterDom/hooks/useAppOutletContext";
import JoyrideWrapper from "src/components/JoyRide/JoyrideWrapper";
import { steps } from "src/components/JoyRide/SetUpDepartmentsSteps";

const singleDepartmentSchema = z.object({
  id: z.number().optional(),
  name: z.string().min(1, "Department name is required"),
  roles: z.array(z.number()),
});

export type SingleDepartmentFormType = z.infer<typeof singleDepartmentSchema>;

const departmentsFormSchema = z.object({
  departments: z.array(
    z.object({
      id: z.number().optional(),
      name: z.string().min(1, "Department name is required"),
      roles: z.array(
        z.object({
          id: z.number(),
          name: z.string(),
          selected: z.boolean(),
        })
      ),
    })
  ),
});

export type DepartmentsFormType = z.infer<typeof departmentsFormSchema>;

function OnboardingDepartments() {
  const alert = useAlert();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setCurrentStep } = useAppOutletContext() || {};

  const { data: roles, isLoading: isLoadingRoles } = useGetEmployeeTypesQuery();
  const { data: departments, isLoading: isLoadingDepartments } =
    useGetDepartmentsListQuery();
  const form = useForm<DepartmentsFormType>({
    resolver: zodResolver(departmentsFormSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "departments",
  });

  const createDepartmentMutation = useCreateDepartmentMutation();
  const deleteDepartmentMutation = useDeleteDepartmentMutation();
  const updateDepartmentMutation = useUpdateDepartmentMutation();

  const isLoading =
    isLoadingDepartments ||
    isLoadingRoles ||
    createDepartmentMutation.isLoading ||
    updateDepartmentMutation.isLoading;

  const handleAddDepartment = () => {
    append({
      name: "",
      roles:
        roles?.map((role) => ({
          id: role.id,
          name: role.name,
          selected: false,
        })) || [],
    });
  };

  const handleRemoveDepartment = (index: number) => {
    if (fields.length === 1) {
      return;
    }
    const departmentId = form.getValues("departments")[index].id;
    if (departmentId) {
      deleteDepartmentMutation.mutate(departmentId);
    }
    remove(index);
  };

  useEffect(() => {
    if (departments && departments?.length && roles) {
      form.reset({
        departments: departments.map((department) => ({
          ...department,
          roles: roles.map((role) => ({
            ...role,
            selected: department.roles.some(
              (selectedRole) => selectedRole.id === role.id
            ),
          })),
        })),
      });
    } else if (roles) {
      form.reset({
        departments: [
          {
            name: "",
            roles: roles.map((role) => ({
              ...role,
              selected: false,
            })),
          },
          {
            name: "",
            roles: roles.map((role) => ({
              ...role,
              selected: false,
            })),
          },
        ],
      });
    }
  }, [departments, form, roles]);

  useEffect(() => {
    if (setCurrentStep) setCurrentStep(3);
  }, [setCurrentStep]);

  const onSubmit = (data: DepartmentsFormType) => {
    const departments = data.departments.map((department) => ({
      ...department,
      roles: department.roles
        .filter((role) => role.selected)
        .map((role) => role.id),
    }));

    const promises = departments.map((department) => {
      if (department.id) {
        return updateDepartmentMutation.mutateAsync({
          id: department.id,
          data: department,
        });
      } else {
        return createDepartmentMutation.mutateAsync(department);
      }
    });

    Promise.all(promises)
      .then(() => {
        queryClient.invalidateQueries([QueryKeys.DepartmentsList]);
        alert.success("Departments saved successfully!");
        navigate("/register/admin");
      })
      .catch(() => {
        alert.error("Failed to save departments.");
      });
  };

  return (
    <div className={"onboardingCard"}>
      {!isLoading && fields?.length > 0 && (
        <JoyrideWrapper steps={steps} storageKey="hideAdminAccountFormSteps" />
      )}
      <Header
        title={"Set Up Departments"}
        containerStyle={{ maxHeight: "40px" }}
        id={"setupDepartmentsHeader"}
      />
      <form onSubmit={form.handleSubmit(onSubmit)} style={{ flex: 1 }}>
        {isLoading ? (
          <div className="p-20">
            <Skeleton height={300} />
          </div>
        ) : null}
        {!isLoading &&
          fields?.length > 0 &&
          fields.map((department, index) => (
            <fieldset key={department.id} style={{ border: "unset" }}>
              <FormRow>
                <Controller
                  control={form.control}
                  name={`departments.${index}.name`}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Container>
                        <InputLabel
                          className={inputStyles.label}
                          labelIcon={<NoteIcon />}
                        >
                          {"Department Name"}
                        </InputLabel>
                        <TextInput
                          id={`department-name-${index}`}
                          name="name"
                          placeholder={"Enter department name"}
                          onChange={field.onChange}
                          type="text"
                          value={field.value}
                          inputClassName={inputStyles.darkInput}
                          containerClassName={inputStyles.inputContainerColumn}
                          errorClassName={inputStyles.inputError}
                          labelClassName={inputStyles.label}
                          error={error?.message}
                        />
                      </Container>
                    </>
                  )}
                />
                <Container>
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <InputLabel
                      className={inputStyles.label}
                      labelIcon={<OrganizationSmallIcon />}
                    >
                      {"Roles"}
                    </InputLabel>
                    <button
                      style={{
                        all: "unset",
                        marginLeft: "auto",
                        cursor: "pointer",
                      }}
                      onClick={() => handleRemoveDepartment(index)}
                    >
                      <CloseSmallIcon />
                    </button>
                  </span>
                  <div className="multiple-button-input" id={`group-${index}`}>
                    <Controller
                      control={form.control}
                      name={`departments.${index}.roles`}
                      render={({ field }) => (
                        <>
                          {field.value.map((role, roleIndex) => (
                            <div
                              key={roleIndex}
                              className="multiple-button-container"
                            >
                              <button
                                type="button"
                                className={
                                  role.selected
                                    ? "multiple-button-active"
                                    : "multiple-button"
                                }
                                onClick={() => {
                                  const updatedRoles = [...field.value];
                                  updatedRoles[roleIndex] = {
                                    ...role,
                                    selected: !role.selected,
                                  };
                                  field.onChange(updatedRoles);
                                }}
                              >
                                {role.name}
                              </button>
                            </div>
                          ))}{" "}
                        </>
                      )}
                    />
                  </div>
                </Container>
              </FormRow>
            </fieldset>
          ))}
        <Container style={{ alignItems: "flex-end", margin: "0 15px" }}>
          {isLoading ? <Skeleton width={30} height={30} /> : null}
          {!isLoading ? (
            <button
              className={"icon-button"}
              type="button"
              id="addDepartment"
              onClick={handleAddDepartment}
              disabled={isLoading}
            >
              +
            </button>
          ) : null}
        </Container>
        <ButtonsContainer>
          <Button
            id="backButton"
            color={ButtonColors.GrayAndYellow}
            onClick={() => navigate(-1)}
          >
            {isLoading ? (
              <RotatingLines
                strokeColor="#f1b70c"
                strokeWidth="5"
                animationDuration="0.75"
                width="20"
                visible={true}
              />
            ) : (
              "Go Back"
            )}
          </Button>
          <Button
            type="submit"
            color={ButtonColors.Yellow}
            disabled={isLoading}
          >
            {isLoading ? (
              <RotatingLines
                strokeColor="#000"
                strokeWidth="5"
                animationDuration="0.75"
                width="20"
                visible={true}
              />
            ) : (
              "Save Departments"
            )}
          </Button>
        </ButtonsContainer>
      </form>
    </div>
  );
}

export default OnboardingDepartments;
