import axiosClient from "src/features/axios/client";
import {
  DocumentAnnotation,
  DocumentAnnotationForm,
} from "../types/document.types";

class DocumentAnnotationService {
  async createAnnotation(
    data: DocumentAnnotationForm
  ): Promise<DocumentAnnotation> {
    return await axiosClient
      .post("/api/document-annotations/", data)
      .then((res) => res.data);
  }

  async partialUpdateAnnotation(
    data: Partial<DocumentAnnotationForm>
  ): Promise<DocumentAnnotation> {
    return await axiosClient
      .patch(`/api/document-annotations/${data.id}/`, data)
      .then((res) => res.data);
  }
}

const documentAnnotationService = new DocumentAnnotationService();

export default documentAnnotationService;
