import { TemplateAnnotation } from "../../types/template.types";
import { DocumentAnnotation } from "../../types/document.types";
import { selectAnnotation } from "../../slices/templateAnnotationsSlice";
import { useAppDispatch } from "src/store";

type AnnotationDropdownType = {
  annotation: TemplateAnnotation | DocumentAnnotation;
  selectedValue?: string | string[];
  onSelect?: (value: string) => void;
  className?: string;
};

export default function AnnotationDropdown({
  annotation,
  selectedValue,
  onSelect,
  className,
}: AnnotationDropdownType) {
  const dispatch = useAppDispatch();
  const handleClickCheckboxes = (e: React.MouseEvent) => {
    dispatch(selectAnnotation(annotation.uuid));
  };
  return (
    <div
      className={className || "document-annotation-dropdown"}
      onClick={handleClickCheckboxes}
      style={{
        top: annotation.y,
        left: annotation.x,
        width: annotation.width,
        height: annotation.height,
      }}
    >
      <select
        value={selectedValue}
        onChange={(e) => onSelect?.(e.target.value)}
        style={{
          width: "100%",
          height: "100%",
          border: "none",
          backgroundColor: "transparent",
          fontSize: "10px",
        }}
      >
        <option value="" disabled selected={!selectedValue}>
          Select an option
        </option>
        {annotation?.options?.map((item) => (
          <option key={item.value} value={item.value}>
            {item.value}
          </option>
        ))}
      </select>
    </div>
  );
}
