import Badge from "src/components/Badges/Badge";
import { EmployeeShiftStatus } from "../services/employeeShiftsService";

type BadgeProps = {
  status: EmployeeShiftStatus;
};

export default function EmployeeShiftStatusBadge({ status }: BadgeProps) {
  const badgeColors: Record<EmployeeShiftStatus, string> = {
    Pending: "var(--clearpath-yellow)",
    PendingCancellation: "var(--clearpath-orange)",
    Published: "var(--clearpath-green)",
    Cancelled: "var(--clearpath-danger)",
    PendingDrop: "var(--clearpath-blue)",
    Dropped: "var(--clearpath-danger)",
    PendingPickupApproval: "var(--clearpath-blue)",
    PendingSwapApproval: "var(--clearpath-blue)",
    PickedUp: "var(--clearpath-blue)",
    SwapRequested: "var(--clearpath-blue)",
    SwapApproved: "var(--clearpath-green-light)",
    SwapDeclined: "var(--clearpath-danger)",
  };

  return <Badge color={badgeColors[status]} text={status} />;
}
