import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";

type SchedulesWeekNavigationProps = {
  onNavigateWeek: (direction: "prev" | "next") => void;
  weekDateTitle: string;
};

export default function SchedulesWeekNavigation({
  onNavigateWeek,
  weekDateTitle,
}: SchedulesWeekNavigationProps) {
  return (
    <div className="flex flex-1 flex-row align-center justify-between gap-20 h-60">
      <div className="flex flex-1 flex-row gap-20 align-center justify-center absolute left-1/2 transform -translate-x-1/2">
        <Button
          color={ButtonColors.Yellow}
          className="border-radius-5 p-0"
          onClick={() => onNavigateWeek("prev")}
        >
          <MdChevronLeft className="cursor-pointer" size={28} />
        </Button>
        <span className="mobile-title">{weekDateTitle}</span>
        <Button
          color={ButtonColors.Yellow}
          className="border-radius-5 p-0"
          onClick={() => onNavigateWeek("next")}
        >
          <MdChevronRight className="cursor-pointer" size={28} />
        </Button>
      </div>
    </div>
  );
}
