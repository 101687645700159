import { useNavigate, useParams } from "react-router-dom";
import { useAlert } from "@blaumaus/react-alert";
import { useQueryClient } from "@tanstack/react-query";

import Button from "src/components/Buttons/Button";
import ButtonsContainer from "src/components/Container/ButtonsContainer";
import { SAVE_AS_DRAFT } from "src/features/buttons/titles";
import StepContainer from "src/components/Steps/StepContainer";
import EditingContainer from "src/features/learningPaths/components/LPBuilder/Forms/EditingContainer";
import usePartialUpdatePolicyMutation from "../../hooks/usePartialUpdatePolicyMutation";
import usePolicyFormState from "../../hooks/usePolicyFormState";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

export default function PolicyPublishForm() {
  const alert = useAlert();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const { selectedPolicyId } = usePolicyFormState();

  const combinedId = id || selectedPolicyId;

  const partialUpdatePolicyMutation = usePartialUpdatePolicyMutation({
    onSuccess: async (data) => {
      await queryClient.invalidateQueries([QueryKeys.MyTodosList]);
      await queryClient.invalidateQueries([QueryKeys.PoliciesList]);
      await queryClient.invalidateQueries([QueryKeys.PoliciesListPaginated]);
      queryClient.setQueryData([QueryKeys.Policy, String(data.id)], data);
      alert.success("Policy saved successfully!");
      navigate(`/policies/${data.id}`);
    },
  });

  const handleSaveAsDraft = () => {
    if (combinedId) {
      partialUpdatePolicyMutation.mutate({
        id: combinedId,
        draft: true,
      });
    }
  };

  const handlePublishPolicy = () => {
    if (combinedId)
      partialUpdatePolicyMutation.mutate({
        id: combinedId,
        draft: false,
      });
  };

  return (
    <StepContainer>
      <div className="mb-20">
        <span className="text-muted">
          Publish the policy or save it as a draft. Once published, the policy
          will be sent to the designated recipients assigned in the Schedules.
          Saving as draft will remove any assigned "To-Do" tasks employees have
          already received.
        </span>
      </div>
      <EditingContainer>
        <ButtonsContainer>
          <Button
            title={SAVE_AS_DRAFT}
            color={ButtonColors.GrayAndYellow}
            onClick={handleSaveAsDraft}
          />
          <Button
            title="Publish Policy"
            color={ButtonColors.Yellow}
            onClick={handlePublishPolicy}
          />
        </ButtonsContainer>
      </EditingContainer>
    </StepContainer>
  );
}
