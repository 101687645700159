import { useMutation } from "@tanstack/react-query";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import shiftConflictsService, {
  ShiftConflict,
} from "../services/shiftConflictsService";

type UsePartialUpdateEmployeeShiftMutation = {
  onSuccess?: (shift: ShiftConflict) => void;
};

export type CancelShiftMutationVariables = {
  id: number;
  employeeShiftId: number;
};

export default function useCancelEmployeeShiftMutation({
  onSuccess,
}: UsePartialUpdateEmployeeShiftMutation) {
  const handleErrors = useErrorHandling();
  return useMutation({
    mutationFn: shiftConflictsService.cancelEmployeeShift,
    onSuccess,
    onError: handleErrors,
  });
}
