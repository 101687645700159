import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../axios/client";
import { EmployeeTypeDetail } from "../types/employeeTypes.types";

export const getRoles = createAsyncThunk<null, void, { rejectValue: any }>(
  "roles/getRoles",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get("/api/roles/");
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return rejectWithValue(err.response.data);
      } else if (err.request) {
        return rejectWithValue({ detail: "An unknown error occured" });
      } else {
        return rejectWithValue({ detail: "An unknown error occured" });
      }
    }
  }
);

export const createRole = createAsyncThunk<any, any, { rejectValue: any }>(
  "roles/createRole",
  async (role, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post("/api/roles/", role);
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return rejectWithValue(err.response.data);
      } else if (err.request) {
        return rejectWithValue({ detail: "An unknown error occured" });
      } else {
        return rejectWithValue({ detail: "An unknown error occured" });
      }
    }
  }
);

export const updateRole = createAsyncThunk<any, any, { rejectValue: any }>(
  "roles/updateRole",
  async (role, { rejectWithValue }) => {
    try {
      const response = await axiosClient.put(`/api/roles/${role.id}/`, role);
      return response.data;
    } catch (err: any) {
      if (err.response) {
        return rejectWithValue(err.response.data);
      } else if (err.request) {
        return rejectWithValue({ detail: "An unknown error occured" });
      } else {
        return rejectWithValue({ detail: "An unknown error occured" });
      }
    }
  }
);

export const deleteRole = createAsyncThunk<any, any, { rejectValue: any }>(
  "roles/deleteRole",
  async (id, { rejectWithValue }) => {
    try {
      await axiosClient.delete(`/api/roles/${id}/`);
      return id;
    } catch (err: any) {
      if (err.response) {
        return rejectWithValue(err.response.data);
      } else if (err.request) {
        return rejectWithValue({ detail: "An unknown error occured" });
      } else {
        return rejectWithValue({ detail: "An unknown error occured" });
      }
    }
  }
);

export interface IRolesState {
  data: EmployeeTypeDetail[];
  status: "idle" | "loading" | "succeeded" | "failed";
  errors: string[] | null;
}

const initialState: IRolesState = {
  status: "idle",
  data: [],
  errors: null,
};

const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRoles.pending, (state) => {
        state.status = "loading";
        state.errors = null;
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload) {
          state.data = action.payload;
        }
      })
      .addCase(getRoles.rejected, (state, action) => {
        state.status = "failed";
        state.errors = action.payload;
      })
      .addCase(createRole.pending, (state) => {
        state.status = "loading";
        state.errors = null;
      })
      .addCase(createRole.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(createRole.rejected, (state, action) => {
        state.status = "failed";
        state.errors = action.payload;
      })
      .addCase(updateRole.pending, (state) => {
        state.status = "loading";
        state.errors = null;
      })
      .addCase(updateRole.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(updateRole.rejected, (state, action) => {
        state.status = "failed";
        state.errors = action.payload;
      })
      .addCase(deleteRole.pending, (state) => {
        state.status = "loading";
        state.errors = null;
      })
      .addCase(deleteRole.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = state.data.filter((role) => role.id !== action.payload);
      })
      .addCase(deleteRole.rejected, (state, action) => {
        state.status = "failed";
        state.errors = action.payload;
      });
  },
});

export default rolesSlice.reducer;
