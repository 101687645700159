import axiosClient from "src/features/axios/client";
import {
  TodoObjectTypes,
  ScheduledTodoStatus,
  TodoStatus,
} from "../types/todosEnums.types";
import { ScheduledTodoDetail, TodoDetail } from "../types/todos.types";
import { PaginatedApiResponse } from "src/features/axios/axios.types";

type CreateScheduledTodosParams = {
  title: string;
  objectId: number | string;
  objectType: TodoObjectTypes;
  employees: (number | string)[];
  roles: (number | string)[];
  status: ScheduledTodoStatus;
  start_date: string | null;
  send_notifications: boolean;
  allow_duplicate_todos: boolean;
  deadline: string | null;
  recurrence: string | null;
};

type DeleteScheduledTodosParams = {
  id: number | string;
};

type DeleteTodoParams = {
  id: number | string;
};

type GetScheduledTodosParams = {
  objectId?: number | string;
  objectType?: TodoObjectTypes;
};

type PartialUpdateScheduledTodosParams = {
  id: number | string;
  objectType: TodoObjectTypes;
  status: ScheduledTodoStatus;
  employees: (number | string)[];
  roles: (number | string)[];
};

type UpdateScheduledTodosParams = {
  id: number | string;
  title: string;
  objectId: number | string;
  objectType: TodoObjectTypes;
  employees: (number | string)[];
  roles: (number | string)[];
  status: ScheduledTodoStatus;
  start_date: string | null;
  send_notifications: boolean;
  allow_duplicate_todos: boolean;
  deadline: string | null;
  recurrence: string | null;
};

type GetTodosParams = {
  objectType: TodoObjectTypes;
  objectId?: number | string;
  employeeId?: number | string;
  status?: TodoStatus | TodoStatus[];
  startDate?: Date | null;
  endDate?: Date | null;
  startedOnStart?: Date | null;
  startedOnEnd?: Date | null;
  completedOnStart?: Date | null;
  completedOnEnd?: Date | null;
  excludeAssignedByNull?: boolean;
  page?: number;
  sortBy?: string;
  sortDirection?: "asc" | "desc";
  filters?: any;
};

class TodosService {
  async createScheduledTodo({
    title,
    objectId,
    objectType,
    roles,
    employees,
    status = ScheduledTodoStatus.Draft,
    send_notifications,
    allow_duplicate_todos,
    start_date,
    deadline,
    recurrence = null,
  }: CreateScheduledTodosParams): Promise<ScheduledTodoDetail> {
    return await axiosClient
      .post("/api/scheduled-todos/", {
        title,
        object_id: objectId,
        object_type: objectType,
        roles,
        employees,
        status,
        send_notifications,
        allow_duplicate_todos,
        start_date,
        deadline,
        recurrence,
      })
      .then((res) => res.data);
  }

  async deleteTodo({ id }: DeleteTodoParams) {
    return await axiosClient
      .delete(`/api/todos/${id}/`)
      .then((res) => res.data);
  }

  async deleteScheduledTodo({ id }: DeleteScheduledTodosParams) {
    return await axiosClient
      .delete(`/api/scheduled-todos/${id}/`)
      .then((res) => res.data);
  }

  async getTodoCompleteIncompleteStats(objectType: TodoObjectTypes) {
    return await axiosClient
      .get(`/api/todo-stats/complete-incomplete-stats/`, {
        params: {
          object_type: objectType,
        },
      })
      .then((res) => res.data);
  }

  async getTodos({
    objectId,
    objectType,
    status,
    startDate,
    endDate,
    startedOnStart,
    startedOnEnd,
    completedOnStart,
    completedOnEnd,
    excludeAssignedByNull,
  }: GetTodosParams): Promise<TodoDetail[]> {
    return await axiosClient
      .get(`/api/todos/`, {
        params: {
          object_id: objectId,
          object_type: objectType,
          status,
          start_date: startDate,
          end_date: endDate,
          started_on_start: startedOnStart,
          started_on_end: startedOnEnd,
          completed_on_start: completedOnStart,
          completed_on_end: completedOnEnd,
          exclude_assigned_by_null: excludeAssignedByNull,
        },
      })
      .then((res) => res.data);
  }

  async getTodosPaginated({
    objectType,
    objectId,
    employeeId,
    status,
    page = 1,
    sortBy,
    sortDirection,
    ...filters
  }: GetTodosParams): Promise<PaginatedApiResponse<TodoDetail>> {
    return await axiosClient.get(`/api/todos/`, {
      params: {
        object_type: objectType,
        object_id: objectId,
        employee_id: employeeId,
        status,
        page,
        sortBy,
        sortDirection,
        ...filters,
      },
    });
  }

  async getScheduledTodo({ id }: { id: number | string }) {
    return await axiosClient
      .get(`/api/scheduled-todos/${id}/`)
      .then((res) => res.data);
  }

  async getScheduledTodos({ objectId, objectType }: GetScheduledTodosParams) {
    return await axiosClient
      .get("/api/scheduled-todos/", {
        params: {
          object_id: objectId,
          object_type: objectType,
        },
      })
      .then((res) => res.data);
  }

  async getScheduledTodosByType({
    objectId,
    objectType,
    page = 1,
    sortBy,
    sortDirection,
    ...filters
  }: any) {
    return await axiosClient
      .get(`/api/scheduled-todos/`, {
        params: {
          object_id: objectId,
          object_type: objectType,
          page,
          sortBy,
          sortDirection,
          ...filters,
        },
      })
      .then((res) => res.data);
  }

  async getScheduledTodosRecurrenceChoices() {
    return await axiosClient
      .get("/api/scheduled-todos/recurrence-choices/")
      .then((res) => res.data);
  }

  async getScheduledTodosDeadlineChoices() {
    return await axiosClient
      .get("/api/scheduled-todos/deadline-choices/")
      .then((res) => res.data);
  }

  async partialUpdateSchedule({
    ...params
  }: Partial<UpdateScheduledTodosParams>) {
    return await axiosClient
      .patch(`/api/scheduled-todos/${params.id}/`, {
        ...params,
      })
      .then((res) => res.data);
  }

  async partialUpdateScheduledTodos({
    id,
    objectType,
    status = ScheduledTodoStatus.Draft,
    employees,
    roles,
  }: PartialUpdateScheduledTodosParams) {
    return await axiosClient
      .patch(`/api/scheduled-todos/${id}/`, {
        object_type: objectType,
        status,
        employees,
        roles,
      })
      .then((res) => res.data);
  }

  async reissueObjectTodo({
    objectId,
    objectType,
    employeeId,
  }: {
    objectId: number | string;
    objectType: TodoObjectTypes;
    employeeId: number | string;
  }) {
    return await axiosClient
      .post(`/api/todos/reissue/`, {
        object_id: objectId,
        object_type: objectType,
        employee_id: employeeId,
      })
      .then((res) => res.data);
  }

  async sendObjectTodoReminders({
    objectId,
    objectType,
  }: {
    objectId: number | string;
    objectType: TodoObjectTypes;
  }) {
    return await axiosClient
      .post(`/api/todos/send-reminders/`, {
        object_id: objectId,
        object_type: objectType,
      })
      .then((res) => res.data);
  }

  async sendTodoReminder({ id }: { id: number | string }) {
    return await axiosClient
      .post(`/api/todos/${id}/send-reminder/`)
      .then((res) => res.data);
  }

  async updateScheduledTodo({
    id,
    title,
    objectId,
    objectType,
    roles,
    employees,
    status = ScheduledTodoStatus.Draft,
    send_notifications,
    allow_duplicate_todos,
    start_date,
    deadline,
    recurrence = null,
  }: UpdateScheduledTodosParams): Promise<ScheduledTodoDetail> {
    return await axiosClient
      .put(`/api/scheduled-todos/${id}/`, {
        title,
        object_id: objectId,
        object_type: objectType,
        status,
        send_notifications,
        allow_duplicate_todos,
        employees,
        roles,
        start_date,
        deadline,
        recurrence,
      })
      .then((res) => res.data);
  }
}

const todosService = new TodosService();

export default todosService;
