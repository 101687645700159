import { Editor } from "@tiptap/react";
import Button from "src/components/Buttons/Button";
import {
  ButtonColors,
  ButtonSizes,
} from "src/components/Buttons/buttons.types";

type MenuBarProps = {
  editor: Editor | null;
};

const MenuBar = ({ editor }: MenuBarProps) => {
  if (!editor) return null;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "8px",
        marginTop: "10px",
      }}
    >
      <Button
        type="button"
        size={ButtonSizes.SM}
        style={{ fontSize: "12px" }}
        color={
          editor.isActive("heading", { level: 1 })
            ? ButtonColors.Yellow
            : ButtonColors.Gray
        }
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
      >
        h1
      </Button>
      <Button
        type="button"
        size={ButtonSizes.SM}
        style={{ fontSize: "12px" }}
        color={
          editor.isActive("heading", { level: 2 })
            ? ButtonColors.Yellow
            : ButtonColors.Gray
        }
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
      >
        h2
      </Button>
      <Button
        type="button"
        size={ButtonSizes.SM}
        style={{ fontSize: "12px" }}
        color={
          editor.isActive("heading", { level: 3 })
            ? ButtonColors.Yellow
            : ButtonColors.Gray
        }
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
      >
        h3
      </Button>
      <Button
        type="button"
        size={ButtonSizes.SM}
        style={{ fontSize: "12px" }}
        color={
          editor.isActive("bold") ? ButtonColors.Yellow : ButtonColors.Gray
        }
        onClick={() => editor.chain().focus().toggleBold().run()}
      >
        bold
      </Button>
      <Button
        type="button"
        size={ButtonSizes.SM}
        style={{ fontSize: "12px" }}
        color={
          editor.isActive("italic") ? ButtonColors.Yellow : ButtonColors.Gray
        }
        onClick={() => editor.chain().focus().toggleItalic().run()}
      >
        italic
      </Button>
      <Button
        type="button"
        size={ButtonSizes.SM}
        style={{ fontSize: "12px" }}
        color={
          editor.isActive("strike") ? ButtonColors.Yellow : ButtonColors.Gray
        }
        onClick={() => editor.chain().focus().toggleStrike().run()}
      >
        strike
      </Button>
      <Button
        type="button"
        size={ButtonSizes.SM}
        style={{ fontSize: "12px" }}
        color={
          editor.isActive("highlight") ? ButtonColors.Yellow : ButtonColors.Gray
        }
        onClick={() => editor.chain().focus().toggleHighlight().run()}
      >
        highlight
      </Button>
      <Button
        type="button"
        size={ButtonSizes.SM}
        style={{ fontSize: "12px" }}
        color={
          editor.isActive({ textAlign: "left" })
            ? ButtonColors.Yellow
            : ButtonColors.Gray
        }
        onClick={() => editor.chain().focus().setTextAlign("left").run()}
      >
        left
      </Button>
      <Button
        type="button"
        size={ButtonSizes.SM}
        style={{ fontSize: "12px" }}
        color={
          editor.isActive({ textAlign: "center" })
            ? ButtonColors.Yellow
            : ButtonColors.Gray
        }
        onClick={() => editor.chain().focus().setTextAlign("center").run()}
      >
        center
      </Button>
      <Button
        type="button"
        size={ButtonSizes.SM}
        style={{ fontSize: "12px" }}
        color={
          editor.isActive({ textAlign: "right" })
            ? ButtonColors.Yellow
            : ButtonColors.Gray
        }
        onClick={() => editor.chain().focus().setTextAlign("right").run()}
      >
        right
      </Button>
    </div>
  );
};

export default MenuBar;
